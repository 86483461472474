import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alert } from '@edw-entities/alert';
import { Message } from 'primeng-lts';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EDWHttpService } from './httpService/edw-http.service';


@Injectable()
export class AlertsService {
  // control the show/hiding of services
  // a repo of all active alerts
  // a place where all crud activities happen for an alert.


  public showAlerts$ : BehaviorSubject<boolean> = new BehaviorSubject(true);
  public get showAlerts(){return this.showAlerts$.value;}
  public set showAlerts(val: boolean){this.showAlerts$.next(val);}

  msgs: Message[] = [];


  alerts$: BehaviorSubject<Alert[]> = new BehaviorSubject([]);
  get alerts(){return this.alerts$.value;}
  set alerts(val: Alert[]){ this.alerts$.next(val); }



  // private alertSubject: BehaviorSubject<any> = new BehaviorSubject<any>({show: false });
  // alertObservable = this.alertSubject.asObservable();
  // private alertsShown: boolean = true;

  constructor(
    private http: HttpClient,
    private httpService : EDWHttpService
  ) {
    this.getAlerts().subscribe();
  }

  getAlerts = () => {
    return this.getUserAlerts().pipe(
      map( data => {
        this.alerts = data['data'].map(alert => new Alert(alert));
        return this.alerts
      })
    );
  }




  getUserAlerts = (): Observable<Alert[]> => {
    return this.http
      .get<Alert[]>(environment.api + '/Alert/getUserAlerts');
  }

  getAllAlerts = (query: string, page: number = 1): Observable<Alert[]> => {

    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Alert[]>(environment.api + '/Alert/GetAll', options);
  }

  getAlert = (alertId: string): Observable<Alert[]> => {

    let params = new HttpParams();
    params = params.set('AlertId', alertId);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Alert[]>(environment.api + '/Alert/get', options);
  }


  alertSave = (data : Alert) => {
    if( data == null ){throw "Error retreving data."}
    let endpoint = data.id > 0
      ? `Alert/update?alertId=${data.id}`
      : 'Alert/add';

    return this.httpService.httpPost(endpoint, data);
  }

  saveAlert(id: number, data: Partial<Alert>): Observable<any> {

    const options: Object = {
      observe: 'body'
    };

    return this.http.post(environment.api + '/Alert/update?AlertId=' + id, data, options);
  }

  createAlert(data: Partial<Alert>) {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(environment.api + '/Alert/add', data, options);
  }

  deleteAlert(id: number): Observable<any> {

    const options: Object = {
      observe: 'body'
    };

    return this.http.post(environment.api + '/Alert/remove?AlertId=' + id, {}, options);
  }

}
