import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators'
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { ResourceStatus } from '@edw-enums/resource-status';

@Injectable()
export class RecipientGroupGuard implements CanActivate {

  canActivateSubject = new Subject<boolean>();
  canActivateObservable = this.canActivateSubject.asObservable();
  urlFragments: string[] = [];
  lastParam: string = "";
  canActivateRoute: boolean = false;

  constructor(private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.urlFragments = state.url.split('/');
    this.lastParam = state.url.split('/')[this.urlFragments.length - 1];


    return true;
    //return this.canActivateObservable;
  }


}