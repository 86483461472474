import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService} from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions'; 
import { SessionStorageService } from '@edw-services/session-storage.service';

@Injectable()
export class ManageExtractsGuard implements CanActivate, CanActivateChild {

  urlFragments: string[] = [];
  lastParam: string = "";
  canActivateRoute: boolean = false;

  constructor(private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.canActivateRoute = false;

    this.urlFragments = state.url.split('/');
    this.lastParam = state.url.split('/')[this.urlFragments.length - 1];

    this.canActivateExtracts();
    this.evaluateCanActivate();

    return this.canActivateRoute;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.canActivateRoute = false;

    this.urlFragments = state.url.split('/');
    this.lastParam = state.url.split('/')[this.urlFragments.length - 1];

    this.canActivateExtracts();
    this.evaluateCanActivate();

    return this.canActivateRoute;
  }

  canActivateExtracts() {
    const canViewExtract = this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canView);

    if (this.urlFragments.indexOf('extracts') > -1) {
      if (canViewExtract) {
        this.canActivateRoute = true;
      }
    }
  }

  evaluateCanActivate() {
    if (!this.canActivateRoute) {
      this.router.navigated = false;
      //this.router.navigateByUrl('/app/resources/favorite');
      this.sessionStorageService.add('message-error', 'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.');
    }
  }
}
