

import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { KeyValuePair } from '@edw-entities/key-value-pair';
import { RelatedEntityService } from '@edw-entities/related-entity-service.interface';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { ResourceGroupDataService } from '@edw-services/resource-group.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'edw-resource-group-detail',
  templateUrl: './resource-group-detail.component.html',
  styleUrls: ['./resource-group-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResourceGroupDetailComponent implements OnInit, OnDestroy {
  title: string;

  isInAddNewMode = false;

  deleteDialogVisible = false;
  addResourcesDialogVisible = false;

  resourceGroupDetails = {
    id: 0,
    name: '',
    description: '',
    resourceContents: [],
    users: [],
    userGroups: []
  };

  noResourcesResult: Message[] = [{
    severity: 'info',
    summary: '',
    detail: 'No assigned resources.'
  }];

  query: string;
  permissions;
  userSuggestions;
  resourceSuggestions;
  userGroupsSuggestions;
  resources = [];

  formSubmitted = false;

  relatedEntitiesPermissions = {
    resources: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    userGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    users: {
      canAddEntity: null,
      canRemoveEntity: null
    }
  };

  userGroupHandler: RelatedEntityService;
  resourceHandler: RelatedEntityService;
  userHandler: RelatedEntityService;

  // this will be the collection which will be passed to the multiple-choice-component
  selectedResources: KeyValuePair[] =  [];

  @ViewChild('accordion', {static: true}) accordion;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private resourcesGroupDataService: ResourceGroupDataService,
              private sessionStorageService: SessionStorageService,
              private loader: LoaderService,
              private errorService: ErrorHandlerService,
              private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;

        this.relatedEntitiesPermissions.resources.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.resources.canRemoveEntity = this.permissions.canModifyContent;

        this.relatedEntitiesPermissions.userGroups.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.userGroups.canRemoveEntity = this.permissions.canModifyContent;

        this.relatedEntitiesPermissions.users.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.users.canRemoveEntity = this.permissions.canModifyContent;

        if (data.componentData.hasOwnProperty('resourceGroupDetails')) {
          this.resourceGroupDetails = data.componentData['resourceGroupDetails'];

          if (this.resourceGroupDetails.resourceContents.length === 0) {
            this.resourceGroupDetails.resourceContents = [];
          } else {
            this.updateResourcesCollection(data.componentData['resourceGroupDetails'].resourceContents);
          }

          if (this.resourceGroupDetails.userGroups.length === 0) {
            this.resourceGroupDetails.userGroups = [];
          } else {
            this.updateUserGroupsCollection(data.componentData['resourceGroupDetails'].userGroups);
          }

          if (this.resourceGroupDetails.users.length === 0) {
            this.resourceGroupDetails.users = [];
          } else {
            this.updateUsersCollection(data.componentData['resourceGroupDetails'].users);
          }

          this.sessionStorageService.add('currentResourceGroup', this.resourceGroupDetails);
        } else {
          this.isInAddNewMode = true;
        }

        this.title = data.componentData.title;
      });


    this.userGroupHandler = {
      getAddedEntity: this.resourcesGroupDataService.findAddedUserGroup,
      getNonAddedEntity: this.resourcesGroupDataService.findNonAddedUserGroup,
      persistEntityList: this.resourcesGroupDataService.associateResourceGroupsWithUserGroups,
      removeEntityFromGroup: this.resourcesGroupDataService.removeResourceGroupsUserGroupsRelationship
    };

    this.userHandler = {
      getAddedEntity: this.resourcesGroupDataService.findAddedUser,
      getNonAddedEntity: this.resourcesGroupDataService.findNonAddedUser,
      persistEntityList: this.resourcesGroupDataService.addUserToGroup,
      removeEntityFromGroup: this.resourcesGroupDataService.removeUserFromGroup
    };

    this.resourceHandler = {
      getAddedEntity: this.resourcesGroupDataService.findAddedResource,
      getNonAddedEntity: this.resourcesGroupDataService.findNonAddedResource,
      persistEntityList: this.resourcesGroupDataService.addResourceToGroup,
      removeEntityFromGroup: this.resourcesGroupDataService.removeResourceFromGroup
    };
  }


  ngOnDestroy() {
    this.sessionStorageService.remove('currentResourceGroup');
  }

  saveResourceGroup(form) {
    this.formSubmitted = true;

    if (form.valid) {
      if (this.isInAddNewMode === true) {
        this.addNewResourceGroup();
      } else {
        this.editResourceGroup();
      }
    }
  }

  private editResourceGroup() {
    this.loader.showLoader();
    const subscription: Subscription = this.resourcesGroupDataService
    .update(this.resourceGroupDetails)
    .pipe(
    finalize(() => {
      this.loader.hideLoader();
      subscription.unsubscribe();
    }))
    .subscribe(response => {
      if (response['data'] === true) {
        this.toastMessageService.addMessageObj({
          severity: 'success',
          summary: '',
          detail: 'Successfully added updated the resource group.'
        });
      }
    }, httpErrorResponse => {
      this.errorService.httpErrorHandler(httpErrorResponse);
    });
  }

  private updateResourcesCollection(data) {
    this.resourceGroupDetails.resourceContents = data
      .map((resource) => {
        resource['displayName'] = resource.resourceName + ' (' + resource.resourceType + ')';
        resource['link'] = ['/app/manage/resources/deployed/', resource.id];

        return resource;
      });
  }

  private updateUserGroupsCollection(data) {
    this.resourceGroupDetails.userGroups = data
      .map((userGroup) => {
        userGroup.displayName = userGroup.name;

        if (userGroup.description) {
          userGroup.displayName = userGroup.displayName + ' - (' + userGroup.description + ')';
        }

        userGroup['link'] = ['/app/manage/user-groups/', userGroup.id];
        return userGroup;
      });
  }

  private updateUsersCollection(data) {
    this.resourceGroupDetails.users = data
      .map((user) => {
        user['displayName'] = user.firstName + ' ' + user.lastName + ' (' + user.email + ')';
        user['link'] = ['/app/manage/users/', user.id];

        return user;
      });
  }

  private addNewResourceGroup() {
    this.loader.showLoader();

    const subscription: Subscription = this.resourcesGroupDataService
      .addResourceGroup(this.resourceGroupDetails)
      .pipe(
        finalize(() => {
        this.loader.hideLoader();
        subscription.unsubscribe();
      }))
      .subscribe(response => {
        if (response['data'] && parseInt(response['data'], 10)) {
          this.router.navigate(['/app/manage/resource-groups', response['data']]);
        }
      }, httpErrorResponse => {
        this.errorService.httpErrorHandler(httpErrorResponse);
      });
  }

  showConfirmationDialog() {
    this.deleteDialogVisible = true;
  }

  hideConfirmationDialog() {
    this.deleteDialogVisible = false;
  }

  deleteResourceGroup() {
    this.hideConfirmationDialog();

    const subscription: Subscription = this.resourcesGroupDataService
      .removeResourceGroup(this.resourceGroupDetails.id.toString())
      .subscribe(response => {
        if (response['data'] === true) {
        this.sessionStorageService.add('message', 'Successfully removed ' + this.resourceGroupDetails.name + ' resource group.');
        this.router.navigate(['/app/manage/resource-groups']);
        }
        subscription.unsubscribe();
      });
  }

}
