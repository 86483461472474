import { Injectable } from '@angular/core';
import { UtilService } from '../utilities.service';
import { EDWHttpService } from './edw-http.service';

@Injectable()
export class APIMapService {

  constructor( private httpService : EDWHttpService, private util: UtilService ) { }

  public tests = {
    ManagerCorortActivation : () => this.httpService.httpGet(`/cohort/manageactivation`),
    SyncADGroupToUserGroups : () => this.httpService.httpGet(`/userGroup/SynchronizeADUserGroups`)
  };

  public calls = {
    Alerts:{
      Update:{ endpoint:'Alert/update', params: (alertId) => `?AlertId=${alertId}` },
      Add : { endpoint: 'Alert/add', params: () => "" }
    },
    Cohorts:{
      GetCohortByReportId: { endpoint : 'cohort/getCohortByReportId', params: (reportId) => `?reportId=${reportId}` }
    }
  }


  public httpGet = (endpoint) => {
    if( this.util.isNullOrEmpty(endpoint) ){ throw "Empty endpoint.";}
    return this.httpService.httpGet(endpoint);
  }

  public httpPost = (endpoint, data, headers?) => {
    if( this.util.isNullOrEmpty(endpoint) ){ throw "Empty endpoint.";}
    return this.httpService.httpPost(endpoint, data, headers ?? null);
  }

}
