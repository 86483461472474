import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: 'reports',
    loadChildren: './all-resources/resources-reports.module#ResourcesReportsModule'
  },
  {
    path: 'requests',
    loadChildren: './requests/requests.module#RequestsModule'
  },
  {
    path: 'favorites',
    loadChildren: './collections/resource-collections.module#ResourceCollectionModule'
  },
  {
    path: 'product-pages',
    loadChildren: './product-pages/product-pages.module#ProductPagesModule'
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ResourcesRoutingModule { }
