export class ExNotificationLog {
    
    //These objects are used for GET extract list method. 
    //Only the below columns will be visible
  
    extract_notification_log_key: number;
    extract_execution_log_key: number;
    extract_configuration_description: string;
    extract_name: string;
    notification_type_text: string;
    from_email: string;
    to_email: string;
    subject: string;
    body:string;
    sent_dttm: string;
    error_message: string;
    smtp_status: string;
    is_adhoc:boolean;
    
      constructor(exnotificationlog) {
        
        this.extract_notification_log_key = exnotificationlog.extract_notification_log_key;
        this.extract_execution_log_key = exnotificationlog.extract_execution_log_key;
        this.extract_configuration_description=exnotificationlog.extract_configuration_description;
        this.extract_name = exnotificationlog.extract_name;
        this.notification_type_text = exnotificationlog.notification_type_text;
        this.from_email = exnotificationlog.from_email;
        this.to_email = exnotificationlog.to_email;
        this.subject = exnotificationlog.subject;
        this.body = exnotificationlog.body;
        this.sent_dttm = exnotificationlog.sent_dttm;
        this.error_message = exnotificationlog.error_message;
        this.smtp_status = exnotificationlog.smtp_status;
        this.is_adhoc=exnotificationlog.is_adhoc;
      }
    }