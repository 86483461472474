
import { IResource } from '@edw-entities/resource';
import { IUser } from '@edw-entities/user';
//import { BP_PREFIX } from 'blocking-proxy/built/lib/blockingproxy';

export type ReasonType = 'Recruitment' | 'Retrospective Chart Review' | 'Prospective Research' | 'De-identified Data';

// the following will be valid if upgrade to typescript 2.4+
// export enum ReasonType {
//   Recruitment = 'Recruitment',
//   RetrospectiveChartReview = 'Retrospective Chart Review',
//   ProspectiveResearch = 'Prospective Research',
//   DeIdentifiedData = 'De-identified Data',
// }
export const Reasons = {
  Recruitment: 'Recruitment',
  RetrospectiveChartReview: 'Retrospective Chart Review',
  ProspectiveResearch: 'Prospective Research',
  DeIdentifiedData: 'De-identified Data',
};

export const ReasonsList = [
  'Recruitment',
  'Retrospective Chart Review',
  'Prospective Research',
  'De-identified Data'
];

export class Cohort {
  id: number;
  name: string;
  description: string;
  cohortCreatedBy: string;
  reason: ReasonType;
  irbNumber: string;
  isDeleted: boolean;
  sql: string;
  approved: boolean;
  autoRefresh: boolean;
  patientStudyId: boolean;
  reports: IResource[];
  users?: IUser[];
  disableApproving: boolean;
  patientCount: string;

  constructor(cohort) {
    this.id = cohort.id;
    this.name = cohort.name;
    this.description = cohort.description;
    this.cohortCreatedBy = cohort.cohortCreatedBy;
    this.isDeleted = cohort.isDeleted;
    this.reason = cohort.reason;
    this.irbNumber = cohort.irbNumber;
    this.sql = cohort.sql;
    this.approved = cohort.approved;
    this.autoRefresh = cohort.autoRefresh;
    this.patientStudyId = cohort.patientStudyId;
    this.reports = cohort.reports;
    this.users = cohort.users;
    this.disableApproving = false;
    this.patientCount = cohort.patientCount;
  }
}

export interface ICohort {
  id?: number;
  name: string;
  description?: string;
  cohortCreatedBy?: string;
  reason?: ReasonType;
  irbNumber?: string;
  sql: string;
  approved: boolean;
  isDeleted: boolean;
  autoRefresh: boolean;
  patientStudyId: boolean;
  reports?: IResource[];
  users?: IUser[];
  disableApproving: boolean;
  patientCount: string;
}

