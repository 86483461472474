import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppNotification } from '@edw-entities/notification';
import { Observable, Subject } from 'rxjs';
import { tap, pluck} from 'rxjs/operators'
import { environment } from '../../environments/environment';
import { SystemLoadStatus } from '../entities/system-load-status';


@Injectable()
export class NotificationsService {
  constructor(private http: HttpClient) { }

  private notificationsCountSubject = new Subject<number>();
  notificationsCountObservable = this.notificationsCountSubject.asObservable();

  private notificationsLoadStatusSubject= new Subject<SystemLoadStatus>();
  notificationsLoadStatusObservable = this.notificationsLoadStatusSubject.asObservable();

  broadcastNotificationsCountChange(count: number) {
    this.notificationsCountSubject.next(count);
  }

  broadcastLoadStatustChange(status: SystemLoadStatus) {
    this.notificationsLoadStatusSubject.next(status);
  }

  getNotifications(): Observable<any> {
    let params = new HttpParams();
    params = params.set('maxReadCount', '20');

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get(environment.api + '/notification/get', options)
      .pipe(tap((response) => {
        response['data'].readNotifications = response['data'].readNotifications
          .map((rawNotification) => {
            return new AppNotification(rawNotification);
          });

        response['data'].unreadNotifications = response['data'].unreadNotifications
          .map((rawNotification) => {
            return new AppNotification(rawNotification);
          });

        return response;
      }));
  }

  getLastSystemLoadDate(): Observable<any> { 
    const options: Object = {
      observe: 'body' 
    }; 
    return this.http
      .get<Response>(environment.api + '/notification/getLastSystemLoadDate', options)
      .pipe(pluck('data'));
  } 

  getUnreadCount(): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get(environment.api + '/notification/get/unreadCount', options)
      .pipe(pluck('data'));
  }

  markAsRead(notificationIds: number[]): Observable<any> {
    let body = notificationIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/notification/markAsRead', body, options);
  } 

  markAllAsRead(): Observable<any> {
    let body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/notification/markAllAsRead', body, options);
  }

}
