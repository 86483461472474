import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@edw-entities/user';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Table } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.scss']
})
export class UserListingComponent implements OnInit, OnDestroy {
  private userGroupDataSubsription: Subscription;
  userInfo = {
    id: '1'
  };

  permissions;
  users: User[] = [];
  query: string;
  displayLookupUserDialog = false;

  @ViewChild('dataTable', {static:true}) dataTable : Table;

  constructor(private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;
        this.users = data.componentData.users.map(element => {
          return new User(element);
        });
      });
  }

  ngOnDestroy() {

  }

  lazyLoadMore($event) {
    let lastPage = this.dataTable.totalRecords / this.dataTable.rows;
    let firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;

    if ($event.first === firstIndexOnLastPage) {
      //BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      let nextPage = Math.ceil(lastPage / 10) + 1;

      this.userDataService.getAllUsers('', nextPage)
        .subscribe(response => {
          if (response['data'] && response['data'].length > 0) {
            let newUsers = response['data'].map(element => {
              return new User(element);
            });

            this.users = this.users.concat(newUsers);
          }
        });
    }
  }

  clearSearch(autoCompleteRef) {
    this.query = '';
    //the next two lines are here because the autocomplete
    //component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  search() {
    this.userDataService.getAllUsers(this.query)
      .subscribe(responseBody => {
        this.users = [];
        if (responseBody['data']) {
          this.users = responseBody['data'].map(element => {
            return new User(element);
          });
        }
      });
  }


}
