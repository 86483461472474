import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ValueRatingService } from '@edw-app-root/shared/value-rating/value-rating.component';
import { ReportsService } from '@edw-services/reports.service';
import { Report } from '@edw-entities/report';
@Injectable({ providedIn: 'root' })
export class ValueRatingResolver implements Resolve<any> {
  constructor ( private valueRatingService : ValueRatingService, private reportsService:ReportsService ){}
  resolve( route: ActivatedRouteSnapshot ) {
    this.valueRatingService.getResourceRating(route.params.id);
    return this.reportsService.getResource(route.params.id).subscribe( ( res )=> { this.valueRatingService.resource = res['data']; return this.valueRatingService.resource; } );
  }
}
