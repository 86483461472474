export class ExtractConfigList {

  //These objects are used for GET extract configuration method.
  //Only the below columns will be visible

  extract_configuration_key: number;
  extract_setup_key: number;
  extract_output_configuration_key: number;
  schedule_key: number;
  schedule_text: string;
  extract_name: string;
  extract_description: string;
  extract_configuration_description: string;
  max_drop_time: string;
  max_allowed_runtime_minutes: number;
  effective_from_dttm: string;
  effective_to_dttm: string;
  is_active: boolean;
  is_portal_user_adhoc: boolean;

  constructor(extractconfiglist) {
    this.extract_configuration_key = extractconfiglist.extract_configuration_key;
    this.extract_setup_key = extractconfiglist.extract_setup_key;
    this.extract_output_configuration_key = extractconfiglist.extract_output_configuration_key;
    this.schedule_key = extractconfiglist.schedule_key;
    this.schedule_text = extractconfiglist.schedule_text;
    this.extract_configuration_description = extractconfiglist.extract_configuration_description;
    this.extract_name= extractconfiglist.extract_name;
    this.extract_description = extractconfiglist.extract_description;
    this.max_drop_time = extractconfiglist.max_drop_time;
    this.max_allowed_runtime_minutes = extractconfiglist.max_allowed_runtime_minutes;
    this.effective_from_dttm = extractconfiglist.effective_from_dttm;
    this.effective_to_dttm = extractconfiglist.effective_to_dttm;
    this.is_active=extractconfiglist.is_active;
    this.is_portal_user_adhoc = extractconfiglist.is_portal_user_ad_hoc;
  }
}
