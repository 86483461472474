import { AbstractControl } from '@angular/forms';

export function RequiredDeadlineReason(control: AbstractControl): {[key: string]: boolean} {

  const deadline = control.get('deadline');
  const deadlineReason = control.get('deadlineReason');

  if (deadlineReason == null || deadline == null) {
    return null;
  }

  // tslint:disable-next-line:max-line-length
  return (deadline.value !== '' && deadline.value !== null && (deadlineReason.value == null || deadlineReason.value == '')) ? { requiredDeadlineReason: true } : null;
}
