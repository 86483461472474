import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToggleNavigationService {
  private toggleNavSubject: BehaviorSubject<any> = new BehaviorSubject<any>({show: true});
  toggleNavObservable = this.toggleNavSubject.asObservable();

  private navShown: boolean = true;

  constructor() { }

  showNav() {
    if (this.navShown !== true) {
      this.navShown = true;

      this.toggleNavSubject.next({
        show: true
      });
    }
  }

  hideNav() {
    if (this.navShown !== false) {
      this.navShown = false;

      this.toggleNavSubject.next({
        show: false
      });
    }
  }

  getObservable() {
    return this.toggleNavObservable;
  }

}
