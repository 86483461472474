import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';

@Injectable()
export class DataStewardQueuePermissionsResolver implements Resolve<any> {

  constructor(
    private userDataService: UserDataService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const permissions = {
      canViewRequest: this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canView),
      canApproveRequest: this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canApprove),
      canViewAllRequests: this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canViewAll)
    };

    return permissions;
  }
}
