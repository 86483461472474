import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PowerBIService } from '@edw-app-root/services/power-bi.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng-lts';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'powerbi-export',
  templateUrl: 'powerbi-export.component.html',
  styles:[`
      .green-txt{color:green; }
      .gt{color:green;}
  `
  ],
  encapsulation:ViewEncapsulation.None
})

export class PowerBiExportComponent implements OnInit {

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public powerBiService : PowerBIService
  ) { }

  ngOnInit() {
    console.log(this.powerBiService.exportEvents);
  }

  exportIsWorking = false;
  exportFileType = "";

  exportMessages  = {
    loading:  (formatType : string) => '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i><strong>Genereating your '+formatType+' file.</strong> Please do not close this window.',
    complete: (formatType : string) => `
      <span class="green-txt">
        <i class="fa fa-check "></i>
        <strong>Your ${formatType} was downloaded successfully.</strong>
      </span>
      Please check your downloads.
      <br/>
      <br/>
      <p>You may now safely close this window.</p>
    `,
    error :   (formatType: string )  => 'There was an issue generating and downloading your '+formatType+'. Please try again. If this error happens again, please call 6-HELP and issue a ticket with Software Development. <p>You may close this window.</p>'
  }

  exportMessage = "";

  triggerExport = (formatType: string) => {
    this.exportIsWorking = true;
    this.exportFileType = formatType;
    this.exportMessage = this.exportMessages.loading(formatType);
    this.powerBiService.exportPowerBIReport(formatType).pipe(
      map(()=>{ this.exportMessage = this.exportMessages.complete(formatType); }),
      catchError((err)=>{this.exportMessage = this.exportMessages.error(formatType); throw err;})
    ).subscribe()
    console.log(formatType);
  }

  close = () => { this.ref.close(); }
}
