import { Directive, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Directive({
  selector: '[edwClickOutside]'
})
export class ClickOutsideDirective {
  private listening: boolean;
  private globalClick: Subscription;

  @Output('onClickOutside') onClickOutside: EventEmitter<Object>;
  @Input() parent?: ElementRef;

  constructor(private _elRef: ElementRef) {
    this.listening = false;
    this.onClickOutside = new EventEmitter();
  }

  ngOnInit() {
    this.globalClick = 
      fromEvent(document, 'click').pipe(
       delay(1),
      tap(() => {
        this.listening = true;
      })).subscribe((event: MouseEvent) => {
        this.onGlobalClick(event);
      });
  }

  ngOnDestroy() {
    this.globalClick.unsubscribe();
  }

  onGlobalClick(event: MouseEvent) {
    if (event instanceof MouseEvent && this.listening === true) {
      if (this.isDescendant(this._elRef.nativeElement, event.target) === true || this.isDescendant(this.parent, event.target) === true) {
        this.onClickOutside.emit({
          target: (event.target || null),
          isOutside: false
        });
      } else {
        this.onClickOutside.emit({
          target: (event.target || null),
          isOutside: true
        });
      }
    }
  }

  isDescendant(parent, child) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }

}
