import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ReportsService } from '@edw-app-root/services/reports.service';
import {StagingReportsService} from '@edw-app-root/services/staging-reports.service'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PreviewRoutingResolver implements Resolve<any> {

  constructor(private stagingReportsService: StagingReportsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];
    const secondToLastParam = state.url.split('/')[urlFragments.length - 2];
    const componentData = {}; 

    if(secondToLastParam === 'preview'){ 
      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        return this.stagingReportsService.getResourceDetails(lastParam)
          .pipe(map(responseBody => {
            componentData['resource'] = responseBody['data'];
            componentData['isPreview'] = true;
            return componentData;
          }));
    }  
  }
}
}
