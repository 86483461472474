import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

//import { SelectItem } from 'primeng';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { AdhocExtract } from '@edw-entities/extract-adhoc';
import { ExtractDataService } from '@edw-services/extract.service';
import { LoaderService } from '@edw-services/loader.service';




@Component({
  selector: 'edw-execute-adhoc-extract-confirmation',
  templateUrl: './execute-adhoc-extract-confirmation.component.html',
  styleUrls: ['./execute-adhoc-extract-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExecuteAdhocExtractConfirmationComponent implements OnInit {
  supportsTextareaResize: boolean = true;
  isVisible: boolean;
  extractConfigurationKey: number;
  allParametersAreNonEditable: boolean = true;

  status

  adhocExtractParameter: {
    extract_parameter_key: 0,
    extract_setup_key: 0,
    parameter_text: '',
    is_dynamic: false,
    default_value: '',
    data_type: '',
    user_editable: false,
    user_edited_value: ''
  }[] = [];

  constructor(
    private extractDataService: ExtractDataService,
    private loader: LoaderService,
    private toastMessageService: ToastMessageService
  ) { }

  ngOnInit() {
    this.supportsTextareaResize = document.createElement('textarea').style.resize !== undefined;
  }

  showDialog(extractConfigurationKey) {
    if (extractConfigurationKey != null && extractConfigurationKey > 0) {
      this.loader.showLoader();
      const subscription: Subscription = this.extractDataService
        .getExtractParameterByExtractConfigId(extractConfigurationKey.toString())
        .subscribe(responseBody => {
          if (responseBody['data']) {
            this.adhocExtractParameter = responseBody['data'];
            this.allParametersAreNonEditable = this.adhocExtractParameter.every((parameter) => {
              return parameter.user_editable === false;
            });

            this.extractConfigurationKey = extractConfigurationKey;
          }
          this.isVisible = true;

          this.loader.hideLoader();
          subscription.unsubscribe();
        });

    } else {
      this.toastMessageService.addMessageObj({
        severity: 'error',
        summary: 'Info Message',
        detail: 'Invalid extract setup key.'
      });
    }
  }

  runAdhocExtract() {
    this.isVisible = false;

    let adhoc = new AdhocExtract(this.extractConfigurationKey, this.adhocExtractParameter);

    const subscription: Subscription = this.extractDataService
      .executeExtract(adhoc)
      .subscribe(response => {
        if (response['data'] === true) {
          this.toastMessageService.addMessageObj({
            severity: 'success',
            summary: 'Info Message',
            detail: 'Adhoc extract queued for run'
          });
        }

        subscription.unsubscribe();
      }, httpErrorResponse => {
        let httpError;
        if (httpErrorResponse.error) {
          httpError = typeof httpErrorResponse.error === 'string' ? JSON.parse(httpErrorResponse.error).message : httpErrorResponse.error.message;
        }
        let message = httpError || httpErrorResponse.message;

        this.toastMessageService.addMessageObj({
          severity: 'error',
          summary: 'Something went wrong.',
          detail: message
        });
        subscription.unsubscribe();
      });
  }


}
