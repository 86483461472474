import { AdhocExtractParameter } from './extract-adhoc-parameter';
export class AdhocExtract {
  extract_configuration_key: number;
  parameter_list: AdhocExtractParameter[];

constructor(key,list) { 
  this.extract_configuration_key = key;
    if (list && list.length > 0) {
      this.parameter_list = list.map((param) => {
        return new AdhocExtractParameter(param);
      });
    }
  }
  
}
