import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { IUserSettings, UserSettings } from '@edw-entities/user-settings';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { UserSettingsService } from '@edw-services/user-settings.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
@Component({
    selector: 'user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
  })
  export class UserSettingsComponent implements OnInit, OnDestroy {

    public defaultPages = [
      { label: 'All Resources', value: '/app/resources/reports/all' },
      { label: 'Favorites', value: '/app/resources/reports/favorite'},
      { label: 'Product Pages', value: '/app/resources/product-pages/all'}
    ];

    public
    public model: IUserSettings = new UserSettings();

    constructor(
        // private router: Router,
        // private route: ActivatedRoute,
        // private sessionStorageService: SessionStorageService,
        private loader: LoaderService,
        private errorService: ErrorHandlerService,
        private userSettingsService: UserSettingsService,
        private toastMessageService: ToastMessageService
      ) {
      }

      ngOnInit() {
          this.getUserSettings();
      }

      ngOnDestroy() {
      }

      getUserSettings() {
        this.userSettingsService.getUserSettings()
          .subscribe(response => {
            if (response['data']) {
              this.setUserSettings(response['data']);
            }
        });
      }

      saveUserSettings() {
        this.loader.showLoader();
        const subscription: Subscription = this.userSettingsService
          .updateUserSettings(this.model)
          .pipe(finalize(() => {
            subscription.unsubscribe();
          }))
          .subscribe(response => {
            if (response['data']) {
              this.toastMessageService.addMessageObj({
                severity: 'success',
                summary: '',
                detail: 'Settings saved successfully.'
              });
              this.loader.hideLoader();
            }
          }, httpErrorResponse => {
              this.errorService.httpErrorHandler(httpErrorResponse);
              this.loader.hideLoader();
          });
      }

      private setUserSettings(data) {
        this.model = new UserSettings();
        this.model.defaultPage = data["defaultPage"];
        this.model.pageView = data["pageView"];
      }
  }
