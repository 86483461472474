import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root/permissions';
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class ManageRequestsRoutingResolver implements Resolve<any> {
  constructor(private stagingReportsService: StagingReportsService,
    private userDataService: UserDataService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const canApproveReports = this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canApprove);
    const canApproveExtracts = this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canApprove);
    const canApproveSubscriptions = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canApprove);

    if (canApproveReports) {
      return this.stagingReportsService.getPendingRequests()
        .pipe(
         map(responseBody => {
          return { requests: responseBody['data'] };
        }));
    } else {
      return of({ requests: [] });
    }
  }
}

