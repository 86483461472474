import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  AutoCompleteModule,
  ButtonModule,
  DialogModule, MessagesModule,
  PaginatorModule,
  SharedModule,
  TableModule,
  ToastModule
} from 'primeng-lts';

import { EDWSharedModule } from '@edw-app-root/shared/shared.module';

import { RequestsComponent } from './requests.component';
import { ManageRequestsRouteGuard } from './requests.guard';
import { ManageRequestsRoutingResolver } from './requests.resolver';

export const requestsRoutes = [
  {
    path: 'requests',
    component: RequestsComponent,
    canActivate: [ManageRequestsRouteGuard],
    resolve: {
      componentData: ManageRequestsRoutingResolver
    }
  }
];

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    EDWSharedModule,
    TableModule,
    SharedModule,
    AutoCompleteModule,
    PaginatorModule,
    DialogModule,
    MessagesModule,
    ToastModule,
    ButtonModule
  ],
  declarations: [
    RequestsComponent
  ],
  providers: [
    ManageRequestsRouteGuard,
    ManageRequestsRoutingResolver
  ],
  exports: [
    RequestsComponent
  ]
})
export class RequestsModule { }
