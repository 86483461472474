import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { IDataStewardRequestDetailsResponse } from '@edw-entities/data-steward-queue';
import { DataStewardQueueService } from '@edw-services/data-steward-queue.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable()
export class DataStewardRequestDetailsResolver implements Resolve<any> {

  constructor(
    private router: Router,
    private dataStewardQueueService: DataStewardQueueService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const id = route.params.dsrid;


    return this.dataStewardQueueService.getDataStewardRequestDetails(id).pipe(
      map((responseBody: IDataStewardRequestDetailsResponse) => responseBody),

      catchError((error, caught) => {
        console.log(error);
        this.router.navigate(['/app/manage/data-steward-queue']);
        return Observable.throw(error);
      })
    );

  }
}
