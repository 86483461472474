import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';

import { EDWSharedModule } from '@edw-app-root/shared/shared.module';

//PrimeNG modules
import { MessagesModule, ToastModule } from 'primeng-lts';

//author components
import { NotificationsComponent } from './notifications-component/notifications.component';

//services
import { NotificationsService } from '@edw-services/notifications.service';

//resolvers
import { NotificationsResolver } from './notifications.resolver';


export const notificationsRoutes: Route = {
  path: 'notifications',
  component: NotificationsComponent,
  resolve: {
    componentData: NotificationsResolver
  }
};

@NgModule({
  declarations: [
    NotificationsComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    MessagesModule,
    EDWSharedModule,
    ToastModule
  ],
  providers: [
    DatePipe,
    NotificationsService,
    NotificationsResolver
  ]
})
export class NotificationsModule { }

