import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum AppErrorType {
  HttpError = 1
}

@Injectable()
export class AppErrorsHandler {
  private static errorObservables = [];

  getObservableForError(errorType: AppErrorType): Observable<any> {
    const subject = new Subject<any>();
    let observableIndex = 0;

    //check if an observable with this name has been added
    let existingObservable = AppErrorsHandler.errorObservables.filter((observable, index) => {
      observableIndex = index;
      return errorType === observable.type;
    })[0];

    //if an observable with this name already exists, return it;
    //if not - return a new one and push it in the array
    if (!existingObservable) {
      AppErrorsHandler.errorObservables.push({
        type: errorType,
        subject: subject
      });

      observableIndex = AppErrorsHandler.errorObservables.length - 1;
    }

    return AppErrorsHandler.errorObservables[observableIndex].subject.asObservable();
  }

  broadcastError(errorType: AppErrorType, error: any) {
    let observableIndex = -1;

    AppErrorsHandler.errorObservables.filter((namedObservable, index) => {
      if (errorType === namedObservable.type) {
        observableIndex = index;
      }

      return errorType === namedObservable.type;
    });

    //if the observable exists, broadcast its value
    //and remove it from the array
    if (observableIndex > -1) {
      AppErrorsHandler.errorObservables[observableIndex].subject.next(error);
    }
  }
}
