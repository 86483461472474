import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InputFieldsType } from '@edw-app-root/enums/inputfields-type';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { DeploymentRequestObject } from '@edw-entities/deployment-request';
import { KeyValuePair } from '@edw-entities/key-value-pair';
import { MetadataToFormFieldsTransformer } from '@edw-entities/metadata-to-form-fields';
import { RelatedEntityService } from '@edw-entities/related-entity-service.interface';
import { ReportConfiguration } from '@edw-entities/report-config';
import { Resource } from '@edw-entities/resource';
import { ResourceType } from '@edw-enums/resource-type';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { ReportsService } from '@edw-services/reports.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { UserDataService } from '@edw-services/user-data.service';
import * as moment from 'moment';
import { Message, SelectItem } from 'primeng-lts';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'edw-deployed-resource-detail',
  templateUrl: './deployed-resource-detail.component.html',
  styleUrls: ['./deployed-resource-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeployedRespurceDetailComponent implements OnInit, OnDestroy {
  title: string;
  reportCreatedDate: string;
  reportModifiedDate: string;
  isInAddNewMode = false;
  addResourcesDialogVisible = false;
  dataSourceTypeText = InputFieldsType;
  skiptextdisplay = this.dataSourceTypeText.publishingDept.text
    + ',' + this.dataSourceTypeText.irb.text
    + ',' + this.dataSourceTypeText.redeployTarget.text;

  skipfielddisplay = this.dataSourceTypeText.publishingDept.text
    + ',' + this.dataSourceTypeText.irb.text
    + ',' + this.dataSourceTypeText.redeployTarget.text
    + ',' + this.dataSourceTypeText.resourceowner.text
    + ',' + this.dataSourceTypeText.datasource.text
    + ',' + this.dataSourceTypeText.childReport.text
    + ',' + this.dataSourceTypeText.businessowner.text
    + ',' + this.dataSourceTypeText.optOut.text;


  businessowner = {
    label: 'Business owner',
    placeholder: 'Please Select Business Owner',
    collection: [],
    value: ''
  };

  resourceDetails = {

    id: 0,
    resourceName: '',
    path: '',
    type: '',
    description: '',
    resourceGroups: [],
    resourceDetails: '',
    userGroups: [],
    users: [],
    userExtracts: [],
    subscriptions: [],
    resourceOwner: '',
    dataSource: '',
    associatedIRB: '',
    reportCreatedDate: '',
    reportModifiedDate: '',
    createByUserName: '',
    createDate: '',
    modifyDate: '',
    modifyUserName: '',
    skiptextdisplay: '',
    skipfielddisplay: '',
    fields: new MetadataToFormFieldsTransformer()
  };

  reportConfigs: ReportConfiguration[] = [];

  noResourcesResult: Message[] = [{
    severity: 'info',
    summary: '',
    detail: 'No assigned resources.'
  }];


  query: string;
  public resourceOwner = '';
  public dataSource = '';
  public associatedIRB = '';
  resourceGroupSuggestions;
  userGroupsSuggestions;
  userSuggestions;
  resource: Resource;

  formSubmitted = false;
  hasReportConfigs = false;
  childReportSuggestions: string[];
  //  this will be the collection which will be passed to the multiple-choice-component
  selectedResources: KeyValuePair[] = [];
  permissions;
  relatedEntitiesPermissions = {
    resourceGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    userGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    users: {
      canAddEntity: null,
      canRemoveEntity: null
    }
  };

  deleteDialogVisible = false;
  warnReportConfigVisible = false;
  allSubscriptionsChecked = false;
  enableDelete = false;
  resourceGroupHandler: RelatedEntityService;
  usersHandler: RelatedEntityService;
  userGroupsHandler: RelatedEntityService;

  checkOther = false;
  OtherDataSource = '';

  resourceTypeText = ResourceType;
  selectedRS = '';

  datasourceOptions = '';
  datasources: SelectItem[] = [];
  resourceowner: SelectItem[] = [];
  selecteddatasources: string[] = [];
  dstouched = false;
  resType = '';
  dsMetaOptionId: number;
  isChildReport = false;
  isChildReportSet = false;
  childReportName: '';
  childReportPath: '';


  @ViewChild('accordion', {static: true}) accordion;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private stagingReportsService: StagingReportsService,
    private reportsService: ReportsService,
    private loader: LoaderService,
    private resourceService: ReportsService,
    private userDataService: UserDataService,
    private errorService: ErrorHandlerService,
    private toastMessageService : ToastMessageService) { }

  ngOnInit() {
    this.setResourceOwner();
    this.route.data
      .subscribe((data: { componentData: { permissions: any, title: string, resourceDetails: any } }) => {
        this.permissions = data.componentData.permissions;

        //  console.log("this.permissions");
        //  console.log(this.permissions);
        //  this.resourceDetails.businessowner.collection;
        this.relatedEntitiesPermissions.resourceGroups.canAddEntity = this.permissions.canModifyAssignment;
        this.relatedEntitiesPermissions.resourceGroups.canRemoveEntity = this.permissions.canModifyAssignment;

        this.relatedEntitiesPermissions.userGroups.canAddEntity = this.permissions.canModifyAssignment;
        this.relatedEntitiesPermissions.userGroups.canRemoveEntity = this.permissions.canModifyAssignment;

        this.relatedEntitiesPermissions.users.canAddEntity = this.permissions.canModifyAssignment;
        this.relatedEntitiesPermissions.users.canRemoveEntity = this.permissions.canModifyAssignment;

        if (data.componentData.hasOwnProperty('resourceDetails')) {
          this.resourceDetails = data.componentData.resourceDetails;
          //  console.log('metatda',data.componentData.resourceDetails.metadata)
          this.resourceDetails.fields = new MetadataToFormFieldsTransformer(data.componentData.resourceDetails.metadata);
          this.resourceDetails.skipfielddisplay = this.skipfielddisplay;
          this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.SSRS;
          if (this.resourceDetails.type == this.resourceTypeText.tableau.text) {
            this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.TABLEAU;
          }


          this.resourceDetails.fields.getFields()
            .filter((fields) => {
              if (fields.modelName === this.dataSourceTypeText.resourceowner.text) {
                this.resourceDetails.resourceOwner = fields.value;
              }
              if (fields.modelName === this.dataSourceTypeText.datasource.text) {
                this.resourceDetails.dataSource = fields.value;
              }
              if (fields.modelName === this.dataSourceTypeText.irb.text) {
                this.resourceDetails.associatedIRB = fields.value;
              }
              if (fields.modelName === this.dataSourceTypeText.optOut.text) {
                fields.label = this.dataSourceTypeText.optOut.title;
                fields.value = fields.value == 'true';
              }

              if (fields.modelName === this.dataSourceTypeText.hideToolBar.text) {
                fields.label = this.dataSourceTypeText.hideToolBar.title;

                if (fields.value != "" && fields.value != null && fields.value != undefined)
                  fields.value = Boolean(JSON.parse(fields.value));
              }

              if (fields.modelName === this.dataSourceTypeText.childReport.text) {
                if (fields.value) {
                  let reportParts = fields.value.toString().split('/');
                  this.childReportName = reportParts[reportParts.length - 1];
                  reportParts.pop();
                  this.childReportPath = reportParts.join('/');

                  this.isChildReport = true;
                  this.isChildReportSet = true;
                }
              }
            });
          //  console.log(this.resourceDetails.fields);
          this.setChildReport();
          this.setDataSource();
          this.resourceDetails.skiptextdisplay = this.skiptextdisplay;
          if (this.resourceDetails.createDate) {
            this.resourceDetails.createDate = moment(this.resourceDetails.createDate + 'z').format('MM/DD/YYYY, hh:mm A');
          }
          if (this.resourceDetails.modifyDate) {
            this.resourceDetails.modifyDate = moment(this.resourceDetails.modifyDate + 'z').format('MM/DD/YYYY, hh:mm A');
          }

          // console.log(data.componentData.resourceDetails);

          this.resource = new Resource(data.componentData.resourceDetails);

          if (this.resourceDetails.resourceGroups.length === 0) {
            this.resourceDetails.resourceGroups = [];
          } else {
            this.updateResourceGroupCollection(data.componentData.resourceDetails.resourceGroups);
          }

          if (this.resourceDetails.userGroups.length === 0) {
            this.resourceDetails.userGroups = [];
          } else {
            this.updateUserGroupsCollection(data.componentData.resourceDetails.userGroups);
          }

          if (this.resourceDetails.users.length === 0) {
            this.resourceDetails.users = [];
          } else {
            this.updateUserCollection(data.componentData.resourceDetails.users);
          }

          if (!this.resourceDetails.userExtracts) {
            this.resourceDetails.userExtracts = [];
          }

          if (!this.resourceDetails.subscriptions) {
            this.resourceDetails.subscriptions = [];
          }

          this.sessionStorageService.add('currentResource', this.resourceDetails);
        }

        this.title = data.componentData.title;
      });

    this.resourceGroupHandler = {
      getAddedEntity: this.resourceService.findAddedResourceGroups,
      getNonAddedEntity: this.resourceService.findNonAddedResourceGroup,
      persistEntityList: this.resourceService.addResourceGroups,
      removeEntityFromGroup: this.resourceService.removeResourceGroup
    };

    this.usersHandler = {
      getAddedEntity: this.resourceService.findAddedUser,
      getNonAddedEntity: this.resourceService.findNonAddedUsers,
      persistEntityList: this.resourceService.addUser,
      removeEntityFromGroup: this.resourceService.removeUser
    };

    this.userGroupsHandler = {
      getAddedEntity: this.resourceService.findAddedUserGroup,
      getNonAddedEntity: this.resourceService.findNonAddedUserGroup,
      persistEntityList: this.resourceService.addUserGroups,
      removeEntityFromGroup: this.resourceService.removeUserGroup
    };

    // this.getReportConfigurations();
  }

  ngOnDestroy() {
    this.sessionStorageService.remove('currentResource');
  }

  setChildReport(e?) {

    if (e != undefined) {
      this.onChildReportSelect(e);
    }
    let descobj = this.resourceDetails.fields.getFields()

      .filter(fields => fields.modelName === this.dataSourceTypeText.description.text);
    // descobj[0].value=descobj[0].value.
    if (descobj && descobj[0].value && descobj[0].maxLength > 0 && descobj[0].value.length > descobj[0].maxLength) {
      descobj[0].value = descobj[0].value.substring(0, descobj[0].maxLength);
    }
    // console.log(descobj);
    this.resourceDetails.fields.getFields()
      .filter((fields) => {
        if (fields.modelName === this.dataSourceTypeText.childReport.text) {
          if (fields.value) {
            this.isChildReport = true;
            if (descobj) {
              descobj[0].isMandatory = false;
            }

          } else {
            if (descobj) {
              descobj[0].isMandatory = true;
            }
          }
        }
      });
    // console.log(this.resourceDetails.fields);
  }

  searchUsers(event) {
    this.userDataService.getAllUsers(event.query).pipe(
      map((response: { data: any }) => {
        if (response.data) {
          const data = response.data.map((businessowner) => {
            return businessowner.fullName + ' (' + businessowner.email + ')';
          });
          return data;
        } else {
          return null;
        }
      })
    )
      .subscribe((data) => {
        this.businessowner.collection = data;
      });

  }

  handleChildReport(e, obj) {
    if (!e) {
      obj.value = '';
      obj.tempvalue = '';
      this.clearChildReport();
    }
    this.setChildReport();
  }

  handleRSSelected(e, obj) {
    this.selectedRS = e.value;
    obj.value = e.value;
  }
  setResourceOwner() {
    this.stagingReportsService.
      getResourceOwnerOptions().subscribe((response) => {
        if (response['data']) {
          response['data'].forEach((key) => {
            this.resourceowner.push({ label: key.name, value: key.name });
          });
        }
        // console.log("metadata : ");
        // console.log(this.resourceDetails);
        this.resourceDetails.fields.getFields()
          .filter((fields) => {
            if (fields.modelName === this.dataSourceTypeText.resourceowner.text) {
              this.selectedRS = fields.value;
            }
          });
        // console.log("this.resourceowner :"+this.resourceowner);
        //  console.log(this.resourceowner);
        //  console.log(this.resourceDetails.fields);
      });

  }

  setDataSource() {
    this.stagingReportsService.
      getMetadataElementOptions(this.dsMetaOptionId).subscribe((response) => {
        //  console.log("resonse date : " + response['data']);
        //  console.log(response['data']);
        if (response['data']) {
          for (let str of response['data'].value.split(',')) {
            this.datasources.push({ label: str, value: str });
            this.datasourceOptions += str + ' ';
          }
        }
        //  we get string of datasource from service, convert to array, if other selected set the value in textbox
        let otherDSprovided = '';
        let dsArray: string[];
        let otheravailable: Boolean = false;
        let selectedDS = '';
        this.resourceDetails.fields.getFields()
          .filter((fields) => {
            if (fields.modelName === this.dataSourceTypeText.datasource.text) {
              if (fields.value.toString()) {
                let array = fields.value.toString().split(',');
                if (fields.tempvalue) {
                  array = fields.tempvalue.toString().split(',');
                }
                dsArray = [];
                for (let i = 0; i < array.length; i++) {
                  if (this.datasourceOptions.indexOf(array[i]) < 0) {
                    otherDSprovided += ',' + array[i];
                    otheravailable = true;
                  } else {

                    selectedDS += ',' + array[i];
                    dsArray.push(array[i]);
                  }
                }
              }

              if (otheravailable) {
                dsArray.push(this.dataSourceTypeText.otherdatasource.text);
                selectedDS += ',' + this.dataSourceTypeText.otherdatasource.text;
              }
              if (otherDSprovided && otherDSprovided.trim().length > 0) {
                otherDSprovided = otherDSprovided.replace(',', '');
                this.OtherDataSource = otherDSprovided;
              }
              if (selectedDS && selectedDS.trim().length > 0) {
                selectedDS = selectedDS.replace(',', '');
                this.selecteddatasources = selectedDS.split(',');
              }
              //   console.log(dsArray.length);
              if (dsArray && dsArray.length > 0) {
                fields.value = dsArray;
              }
            }
          });
        // console.log(this.resourceDetails.fields);
      });



  }

  hanldeTouch(e) {
    //  console.log("touched");
    this.dstouched = true;
  }
  hanldeMetafieldsTouch(e, obj) {
    obj.touched = true;
    // console.log(obj)
  }
  handleSelected(e) {
    this.selecteddatasources = e.value;

    if (this.selecteddatasources.indexOf(this.dataSourceTypeText.otherdatasource.text) > -1) {
      this.checkOther = true;
    } else {
      this.checkOther = false;
      this.OtherDataSource = '';
    }
  }

  updateTempValues() {
    this.resourceDetails.fields.getFields()
      .filter((metadataField) => {
        if (metadataField.modelName === this.dataSourceTypeText.datasource.text) {
          // this wont reference same object
          metadataField.tempvalue = Object.assign([], metadataField.value);
          for (let i = 0; i < metadataField.tempvalue.length; i++) {
            if (metadataField.tempvalue[i] === this.dataSourceTypeText.otherdatasource.text) {
              metadataField.tempvalue[i] = this.OtherDataSource;
            }
          }
          metadataField.tempvalue = metadataField.tempvalue.toString();
        } else if (metadataField.modelName === this.dataSourceTypeText.resourceowner.text) {
          metadataField.value = this.selectedRS;
          metadataField.tempvalue = metadataField.value;
        } else {
          // This will reference same object
          metadataField.tempvalue = metadataField.value;
        }
      });
  }

  updateReport() {
    if (!this.isChildReport) {
      this.clearChildReport();
    }
    this.updateTempValues();
    let deploymentObject: DeploymentRequestObject = {
      resourceName: this.resourceDetails.resourceName,
      metadata: this.resourceDetails.fields.getFields()
        .filter((metadataField) => {
          return metadataField.isEditable === true;
        })
        .map((metadataField) => {
          return {
            id: metadataField.id,
            detail: metadataField.tempvalue
          };
        })
    };
    //  console.log("Deployed object");

    console.log(deploymentObject);

    const subscription: Subscription = this.reportsService
      .upDateDeployedData(this.resourceDetails.id, deploymentObject)
      .subscribe((response) => {
        if (response['data'] === true) {
          this.toastMessageService.addMessageObj({severity: 'success',detail: 'Successfully updated ' + this.resourceDetails.resourceName});
          // this.sessionStorageService.add('message', 'Successfully saved ' + this.resourceDetails.resourceName);
          //  this.router.navigate(['/app/manage/resources']);
        } else {

          this.toastMessageService.addMessageObj({ severity: 'error',detail: 'Error updating ' + this.resourceDetails.resourceName});
        }

        subscription.unsubscribe();
      }, httpErrorResponse => {
        this.errorService.httpErrorHandler(httpErrorResponse);
        this.loader.hideLoader();
      });
  }


  showConfirmationDialog() {
    //  check to see if any report configurations exist here
    if (!this.hasReportConfigs) {
      this.deleteDialogVisible = true;
    } else {
      this.warnReportConfigVisible = true;
    }
  }

  getReportConfigurations() {
    this.resourceService.getReportConfigurations(this.resource.id.toString())
      .subscribe((response) => {
        if (response['data'].length > 0) {
          this.hasReportConfigs = true;
          this.reportConfigs = response['data'].map(element => {
            return new ReportConfiguration(element);
          });
        }
      });
  }

  hideConfirmationDialog() {
    this.deleteDialogVisible = false;
  }

  checkIfAllMarked(e) {
    this.reportConfigs.forEach((key) => {
      if (key.isChecked) {
        this.allSubscriptionsChecked = true;
      } else {

        this.allSubscriptionsChecked = false;
      }
    });

    if (this.allSubscriptionsChecked) {

      this.enableDelete = true;
    } else {

      this.enableDelete = false;
    }
  }

  deleteResource() {
    this.deleteDialogVisible = false;
    this.warnReportConfigVisible = false;
    this.loader.showLoader();

    this.resourceService.remove(this.resource.id.toString())
      .subscribe((response) => {
        if (response['data'] === true) {
          this.sessionStorageService.add('message', this.resource.resourceName + ' resource has been deleted successfully.');
          this.router.navigate(['/app/manage/resources']);
        }
      },
        (httpErrorResponse) => {
          this.errorService.httpErrorHandler(httpErrorResponse);
          this.loader.hideLoader();
        });
  }

  syncTableau() {
    this.loader.showLoader();

    this.resourceService.syncTableau(this.resource.id.toString())
      .subscribe((response) => {
        if (response['data'] === true || response['data'] === false) {
          this.sessionStorageService.add('message', this.resource.resourceName + ' resource has been successfully synced with Tableau.');
          this.loader.hideLoader();
          window.location.reload();
        }
      }
        ,
        (httpErrorResponse) => {
          this.errorService.httpErrorHandler(httpErrorResponse);
          this.loader.hideLoader();
        });


  }


  // private editResource() {
  //   this.loader.showLoader();

  //   this.resourceService.update(new Resource(this.resourceDetails))
  //     .subscribe((response) => {
  //       if (response['data'] === true) {
  //         this.toastMessageService.addMessageObj({severity: 'success',detail: 'Successfully updated the resource.'});
  //       }

  //       this.loader.hideLoader();
  //     });
  // }

  private updateUserCollection(data) {
    this.resourceDetails.users = data.map((user) => {
      user.displayName = user.firstName + ' ' + user.lastName + ' (' + user.email + ')';
      user['link'] = ['/app/manage/users/', user.id];

      return user;
    });
  }

  private updateResourceGroupCollection(data) {
    this.resourceDetails.resourceGroups = data.map((resourceGroup) => {
      resourceGroup.displayName = resourceGroup.name;

      if (resourceGroup.description) {
        resourceGroup.displayName = resourceGroup.displayName + ' - (' + resourceGroup.description + ')';
      }

      resourceGroup['link'] = ['/app/manage/resource-groups/', resourceGroup.id];

      return resourceGroup;
    });
  }

  private updateUserGroupsCollection(data) {
    this.resourceDetails.userGroups = data.map((userGroup) => {
      userGroup.displayName = userGroup.name;

      if (userGroup.description) {
        userGroup.displayName = userGroup.displayName + ' - (' + userGroup.description + ')';
      }

      userGroup['link'] = ['/app/manage/user-groups/', userGroup.id];

      return userGroup;
    });
  }

  viewSQL(resource) {
    window.open('#/app/manage/resources/viewsql/' + resource.id + '?isStaging=false');
  }

  lookUpReports(event) {
    this.reportsService.getAll(event.query, 1).subscribe((result: any) => {

      if (result.data) {
        this.childReportSuggestions = result.data.map(v =>
          v.path + v.resourceName
        );
      }

    });
  }

  clearChildReport() {
    this.resourceDetails.fields.getFields()
      .filter((fields) => {
        if (fields.modelName === this.dataSourceTypeText.childReport.text) {
          this.isChildReportSet = false;
          fields.value = '';
        }
      });
  }

  onChildReportSelect(e) {
    this.isChildReportSet = true;
    let reportParts = e.toString().split('/');
    this.childReportName = reportParts[reportParts.length - 1];
    reportParts.pop();
    this.childReportPath = reportParts.join('/');


  }

}
