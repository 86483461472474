import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class ManageUserExtractsGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = false;
    canActivate = true;

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }
    // TODO: restore
    return canActivate;
  }
}
