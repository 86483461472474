import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UserCollectionService } from '@edw-services/user-collection.service';
// import { ReportsService } from '@edw-app-root/services/reports.service';
// import { UserDataService } from '@edw-app-root/services/user-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserCollectionRoutingResolver implements Resolve<any> {

  constructor( private userCollectionService: UserCollectionService ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.userCollectionService.getUserCollections()
      .pipe(map((res) => (
        {
          collections: res['data'],
          title:'collections'
        }
      )
    ))
  }
}
