import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root//permissions';
import { IResource } from '@edw-app-root/entities/resource';
import { ReportsService } from '@edw-services/reports.service';
import { ResourceGroupDataService } from '@edw-services/resource-group.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ManageResourcesRoutingResolver } from './resources.resolver';

@Injectable()
export class ResourcesPageResolver extends ManageResourcesRoutingResolver {
  constructor(

    public resourceGroupDataService: ResourceGroupDataService,
    public resourceDataService: ReportsService,
    public stagingReportsService: StagingReportsService,
    public sessionStorageService: SessionStorageService,
    public userDataService: UserDataService
  ){
    super(resourceGroupDataService, resourceDataService,stagingReportsService,sessionStorageService,userDataService);
  }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    let permissions = {
      canView: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canView),
      canCreate: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canCreate),
      canModifyAssignment: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canModifyAssignments),
      canRemove: this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canRemove)
    };

    let qc = true;
    console.log(qc,"!!!!!!");

    return this.resourceDataService.getAll('',1,qc).pipe(
      map( ( responseBody : {data : IResource[]} ) => {
        return {
          title : 'Manage Resources',
          permissions : permissions,
          resources : responseBody['data']} ;
      })
    );

  }
}
