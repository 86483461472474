import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Permissions } from '@edw-app-root/permissions';
import { ReportParameter } from '@edw-entities/report-parameter';
import { SubscriptionReportConfigEntity } from '@edw-entities/subscription';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-subscription-report-config',
  templateUrl: './subscription-report-config.component.html',
  styleUrls: ['./subscription-report-config.component.scss']
})
export class SubscriptionReportConfigComponent implements OnInit {
  @Input() stepModel: SubscriptionReportConfigEntity;
  @Output() stepModelChange = new EventEmitter();

  @Input() subscriptionId: number = 0;
  @Input() keywords;
  @Output() onCompleteStep = new EventEmitter();
  @Output() onGoBack = new EventEmitter();
  @Output() onSaveReportConfig = new EventEmitter();
  @Output() onRemoveReportConfig = new EventEmitter();

  @Input() messages: Message[];
  @Output() messagesChange = new EventEmitter();

  @Input() editMode: boolean = false;

  permissions = {
    canCreate: false,
    canModify: false
  };

  @ViewChild('accordion', {static: true}) accordion;

  constructor(private loader: LoaderService,
              private subscriptionService: SubscriptionService,
              private sessionStorageService: SessionStorageService,
              private changeDetector: ChangeDetectorRef,
              private userDataService: UserDataService,
              private genericErrorHandlerService: ErrorHandlerService) { }

  ngOnInit() {
    this.permissions.canCreate = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canCreate);
    this.permissions.canModify = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canModify);
  }

  searchForDeployedReports() {
    const subscription: Subscription = this.subscriptionService
      .searchAmongDeployedResources(this.subscriptionId, this.stepModel.searchQuery)
      .subscribe(
        (response) => {
          this.stepModel.autocompleteSuggestions = response['data'];
          subscription.unsubscribe();
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }

  selectReportForConfiguration(selectedItem) {
    this.stepModel.searchQuery = '';

    if (this.stepModel.configurationsPerReport.length < 4) {
      if (!this.sessionStorageService.get('manualLoader')) {
        this.loader.showLoader();
      }

      const subscription: Subscription = this.subscriptionService
        .getParametersForChosenResource(this.subscriptionId, selectedItem.id)
        .subscribe(
          (response) => {
            if (response['data']) {
              const isAlreadyAddedToList = this.stepModel.configurationsPerReport
                .some((resourceConfig) => {
                  return resourceConfig.resourceId === response['data'].resourceId;
                });

              if (!isAlreadyAddedToList) {
                let resourceConfig = {
                  resourceId: response['data'].resourceId,
                  resourceName: response['data'].resourceName,
                  isPersisted: false,
                  configurations: [{
                    configId: 0,
                    configName: '',
                    timestampAdded: new Date().getTime().toString(),
                    parameters: response['data'].parameters.map((parameter) => {
                      return new ReportParameter(parameter, this.keywords);
                    })
                  }]
                };


                this.stepModel.configForms['resource' + resourceConfig.resourceId + '_config' + resourceConfig.configurations[0].timestampAdded] = false;
                this.stepModel.configurationsPerReport.push(resourceConfig);
              }

              this.loader.hideLoader();
            }
          },

          (httpErrorResponse) => {
            let errorMessages = [];
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

            this.messagesChange.emit(errorMessages);

            subscription.unsubscribe();
            this.loader.hideLoader();
          }
        );
    } else {
      this.messagesChange.emit([{
        severity: 'error',
        summary: 'Error',
        detail: 'You cannot configure more than 4 reports per subscription'
      }]);
    }

  }

  addNewConfigToReport(resource) {
    //copy the existing config
    let newConfig = Object.assign({}, resource.configurations[0]);

    //give initial empty values to config name and parameters array
    newConfig.configId = 0;
    newConfig.configName = '';
    newConfig.isPersisted = false;
    newConfig.timestampAdded = new Date().getTime().toString();
    newConfig.parameters = this.copyObjectsToANewArray(resource.configurations[0].parameters);

    newConfig.parameters = newConfig.parameters.map((parameter) => {
      parameter.parameterValue = '';
      parameter.isPersisted = false;
      parameter.isDynamic = false;

      return new ReportParameter(parameter, this.keywords);
    });

    //add the new config to the specific report
    resource.configurations.push(newConfig);

    this.stepModel.configForms['resource' + resource.resourceId + '_config' + newConfig.timestampAdded] = false;
    this.checkIfAllConfigFormsArePersisted();
  }

  removeReportConfig(resource, resourceConfig, reportConfigIndex) {
    let successMessage = [{
      severity: 'success',
      summary: 'Successfully removed configuration.',
      detail: 'Successfully removed configuration ' + resourceConfig.configName + ' from the ' + resource.resourceName + ' report.'
    }];

    if (!resourceConfig.isPersisted) {
      //if this is the last config for this report, remove the whole report accordion
      if (reportConfigIndex === 0 && resource.configurations.length === 1) {
        this.stepModel.configurationsPerReport = this.stepModel.configurationsPerReport
          .filter((resourceWithConfig) => {
            return resourceWithConfig.resourceId !== resource.resourceId;
          });
      } else {
        resource.configurations.splice(reportConfigIndex, 1);
      }

      this.stepModel.configForms['resource' + resource.resourceId + '_config' + resourceConfig.timestampAdded] = null;
      this.checkIfAllConfigFormsArePersisted();

      this.onRemoveReportConfig.emit();

      if (!this.sessionStorageService.get('manualLoader')) {
        this.messagesChange.emit(successMessage);
      } else {
        this.sessionStorageService.add('message', 'Successfully removed configuration ' + resourceConfig.configName + ' from the ' + resource.resourceName + ' report.');
      }


    } else {
      if (!this.sessionStorageService.get('manualLoader')) {
        this.loader.showLoader();
      }

      let subscription: Subscription = this.subscriptionService
        .removeConfiguration(this.subscriptionId, resource.resourceId, resourceConfig.configId)
        .subscribe(
          (response) => {
            if (response['data'] === true) {
              //if this is the last config for this report, remove the whole report accordion
              if (reportConfigIndex === 0 && resource.configurations.length === 1) {
                this.stepModel.configurationsPerReport = this.stepModel.configurationsPerReport.filter((resourceWithConfig) => {
                  return resourceWithConfig.resourceId !== resource.resourceId;
                });
              } else {
                resource.configurations.splice(reportConfigIndex, 1);
              }

              this.stepModel.configForms['resource' + resource.resourceId + '_config' + resourceConfig.timestampAdded] = null;
              this.checkIfAllConfigFormsArePersisted();

              this.onRemoveReportConfig.emit();

              if (!this.sessionStorageService.get('manualLoader')) {
                this.messagesChange.emit(successMessage);
              } else {
                this.sessionStorageService.add('message', 'Successfully removed configuration ' + resourceConfig.configName + ' from the ' + resource.resourceName + ' report.');
              }

              this.loader.hideLoader();
              subscription.unsubscribe();
            }
          },

          (httpErrorResponse) => {
            let errorMessages = [];
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

            this.messagesChange.emit(errorMessages);

            subscription.unsubscribe();
            this.loader.hideLoader();
          }
        );
    }
  }

  persistConfiguration(resource, reportConfigIndex) {
    let currentReportConfig = resource.configurations[reportConfigIndex];

    if (!this.sessionStorageService.get('manualLoader')) {
      this.loader.showLoader();
    }

    if (currentReportConfig.isPersisted === true) {
      this.updateConfiguration(resource, currentReportConfig);
    } else {
      this.addNewReportConfiguration(resource, currentReportConfig);
    }
  }

  toggleReportParamIsDynamicValue(checked, resourceConfigParameter) {
    resourceConfigParameter.parameterValue = '';
    this.changeDetector.detectChanges();
  }

  clearSearch(autoCompleteRef) {
    this.stepModel.searchQuery = '';
    //the next two lines are here because the autocomplete
    //component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
  }

  removeAllConfigsForResource(resource) {
    let hasPersistedConfigs = resource.configurations
      .some((config) => {
        return config.isPersisted === true;
      });

    if (hasPersistedConfigs === true) {
      if (!this.sessionStorageService.get('manualLoader')) {
        this.loader.showLoader();
      }

      let subscription: Subscription = this.subscriptionService
        .removeAllConfigurationsForSpecificResource(this.subscriptionId, resource.resourceId)
        .subscribe(
          (response) => {
            if (response['data'] === true) {
              this.stepModel.configurationsPerReport = this.stepModel.configurationsPerReport
                .filter((resourceWithConfig) => {
                  return resourceWithConfig.resourceId !== resource.resourceId;
                });

              this.messagesChange.emit([{
                severity: 'success',
                summary: 'Successfully removed configurations.',
                detail: 'Successfully removed all configurations for ' + resource.resourceName
              }]);

              this.deleteAllConfigFormsForResource(resource.resourceId);

              this.onRemoveReportConfig.emit();
              subscription.unsubscribe();
              this.loader.hideLoader();
            }
          },
          (httpErrorResponse) => {
            let errorMessages = [];
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

            this.messagesChange.emit(errorMessages);

            subscription.unsubscribe();
            this.loader.hideLoader();
          }
        );
    } else {
      this.stepModel.configurationsPerReport = this.stepModel.configurationsPerReport
        .filter((resourceWithConfig) => {
          return resourceWithConfig.resourceId !== resource.resourceId;
        });

      this.deleteAllConfigFormsForResource(resource.resourceId);

      this.messagesChange.emit([{
        severity: 'success',
        summary: 'Successfully removed configurations.',
        detail: 'Successfully removed all configurations for ' + resource.resourceName
      }]);
    }
  }

  completeStep() {
    this.onCompleteStep.emit();
  }

  goBack() {
    this.onGoBack.emit();
  }

  private copyObjectsToANewArray(arr) {
    arr = arr.map((obj) => {
      if (obj instanceof File) {
        return {
          name: obj.name,
          type: obj.type,
          lastModified: obj.lastModified
        };
      } else {
        return Object.assign({}, obj);
      }
    });

    return arr;
  }

  private updateConfiguration(resource, currentReportConfig) {
    const subscription: Subscription = this.subscriptionService
      .updateReportConfiguration(this.subscriptionId, resource.resourceId, currentReportConfig)
      .subscribe(
        (response) => {
          if (response['data'] === true) {
            this.onSaveReportConfig.emit();

            if (!this.sessionStorageService.get('manualLoader')) {
              this.messagesChange.emit([{
                severity: 'success',
                summary: 'Successfully added configuration.',
                detail: 'Successfully edited ' + currentReportConfig.configName + ' configuration.'
              }]);
            } else {
              this.sessionStorageService.add('message', 'Successfully edited ' + currentReportConfig.configName + ' configuration.');
            }

          }

          subscription.unsubscribe();
          this.loader.hideLoader();
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }

  private addNewReportConfiguration(resource, currentReportConfig) {
    const subscription: Subscription = this.subscriptionService
      .addNewReportConfiguration(this.subscriptionId, resource.resourceId, currentReportConfig)
      .subscribe(
        (response) => {
          if (response['data'] > 0) {
            currentReportConfig.isPersisted = true;
            currentReportConfig.configId = response['data'];

            this.stepModel.configForms['resource' + resource.resourceId + '_config' + currentReportConfig.timestampAdded] = true;
            this.checkIfAllConfigFormsArePersisted();

            this.onSaveReportConfig.emit();

            if (!this.sessionStorageService.get('manualLoader')) {
              this.messagesChange.emit([{
                severity: 'success',
                summary: 'Successfully added configuration.',
                detail: 'Successfully added configuration ' + currentReportConfig.configName + ' to the ' + resource.resourceName + ' report.'
              }]);
            } else {
              this.sessionStorageService.add('message', 'Successfully added configuration ' + currentReportConfig.configName + ' to the ' + resource.resourceName + ' report.');
            }

          }

          subscription.unsubscribe();
          this.loader.hideLoader();
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }

  private checkIfAllConfigFormsArePersisted() {
    let configFormsValues = [];

    if (this.stepModel.configurationsPerReport.length > 0) {
      for (let prop in this.stepModel.configForms) {
        if (this.stepModel.configForms[prop] !== null) {
          configFormsValues.push(this.stepModel.configForms[prop]);
        }
      }

      this.stepModel.areAllConfigFormsPersisted = configFormsValues
        .every((value) => {
          return value === true;
        });
    } else {
      this.stepModel.configForms = {};
      this.stepModel.areAllConfigFormsPersisted = false;
    }
  }

  private deleteAllConfigFormsForResource(resourceId) {
    for (let prop in this.stepModel.configForms) {
      if (prop.indexOf('resource' + resourceId) > -1) {
        this.stepModel.configForms[prop] = null;
      }
    }

    this.checkIfAllConfigFormsArePersisted();
  }

}
