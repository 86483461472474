import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchParams } from '@edw-app-root/entities/searchParams';
import { IUser } from '@edw-app-root/entities/user';
import { IExtractForUser, ISSRSReport, IUserExtract, IUserExtractReport } from '@edw-entities/user-extracts';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class UserExtractsService {
  private endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
  }

  getReports(query: string): Observable<ISSRSReport[]> {
    let params = new HttpParams();
    params = params.set('query', query || '');

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http.get<ISSRSReport[]>(`${this.endpoint}/user-extract/reports`, options);
  }

  getReport(id: string): Observable<IUserExtractReport> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.get<IUserExtractReport>(`${this.endpoint}/user-extract/reports/${id}`, options);
  }
  getUserExtracts(query: string = '', page: number = 1): Observable<IUserExtract[]> {
    let params = new HttpParams();

    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };


    return this.http.get<IUserExtract[]>(`${this.endpoint}/user-extract/getAll`, options);
  }

  getUserExtractsPerUser(query: string = '', page: number = 1): Observable<IExtractForUser[]> {

    let params = new HttpParams();

    params = params.set('query', query);
    params = params.set('page', page.toString());
    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http.get<IExtractForUser[]>(`${this.endpoint}/user-extract/getAllByUser`, options);
  }

  getFavoritesUserExtracts(query: string = '', page: number = 1): Observable<IExtractForUser[]> {

    let params = new HttpParams();

    params = params.set('query', query);
    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http.get<IExtractForUser[]>(`${this.endpoint}/user-extract/getFavoriteUserExtracts`, options);
  }

  getUserParams(ueid: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.get<any>(`${this.endpoint}/user-extract/${ueid}/run/details`, options).pipe(
      map((res) => {
        const newRes = Object.assign({}, {id: ueid}, res);
        return newRes;
      })
    );
  }

  runUserExtract(data: {id: string, parameters: any}): Observable<IExtractForUser> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<IExtractForUser>(`${this.endpoint}/user-extract/${data.id}/run`, {
      parameters: data.parameters
    }, options);
  }


  getUserExtract(ueid: string): Observable<IUserExtract> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.get<IUserExtract>(`${this.endpoint}/user-extract/${ueid}`, options).pipe(
      map((response: IUserExtract) => {
        const res = Object.assign({}, response);
        res.users = response.users.map((user) => {
          const { email, id, firstName, lastName } = user;
          const tempUser = {
            id,
            firstName,
            lastName,
            email,
          };
          return tempUser as IUser;
        });
        return res;
      })
    );
  }

  downloadExtract(executionId, name) {
    return this.http.get(`${this.endpoint}/user-extract/${executionId}/download`, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map((res: any) => {
        console.log(res);
        const contentDisposition = res.headers.get('content-disposition');
        const parts = contentDisposition.split('; ');
        const filenameParts = parts[1].split('=');
        const filename = filenameParts[1].replace(/"/g, '');
        return {
          filename: filename,
          data: res.body
          // data: new Blob( [res.blob()], { type: 'application/octet-stream'} )
        };
      })
    );
  }

  removeUserExtract(id: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<any>(`${this.endpoint}/user-extract/${id}/remove`, {}, options);
  }

  createUserExtract(data: Partial<IUserExtract>): Observable<IUserExtract> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<IUserExtract>(`${this.endpoint}/user-extract/add`, data, options);
  }

  saveUserExtract(id: string, data: Partial<IUserExtract>): Observable<IUserExtract> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<IUserExtract>(`${this.endpoint}/user-extract/${id}/update`, data, options);
  }

  addUserToExtract = (extractId: string, users: number[]): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/user-extract/${extractId}/user/add`, users, options).pipe(
      map((response: any) => {
        const res = {
          data : response
        };
        return res;
      })
      );
  }

  removeUserFromExtract = (extractId: string, userId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/user-extract/${extractId}/user/remove`, parseInt(userId, 10), options).pipe(
      map((response: any) => {
        const res = {
          data : response
        };
        return res;
      })
      );
  }

  findAddedUser = (extractId: string, searchParams: SearchParams): Observable<any> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post(`${this.endpoint}/user-extract/${extractId}/user/get`, postBody, options).pipe(
      map((response: any) => {
        const res = {
          data : response
        };

        res.data = response.map((element) => {
          element['displayName'] = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';
          return element;
        });
        return res;
      })
      );
  }

  findNonAddedUser = (extractId: string, query: string): Observable<any> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post<any>(`${this.endpoint}/user-extract/${extractId}/user/get`, postBody, options)
      .pipe(
      map((response: any) => {

        const res = {
          data : response
        };

        res.data = response.map((element) => {
          element['displayName'] = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';
          return element;
        });
        return res;
      })
      );
  }

  addFavorite = (userExtractId: string): Observable<Object> => {
    const postBody = null;

    let params = new HttpParams();
    params = params.set('userExtractId', userExtractId);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user-extract/addFavorite', postBody, options);
  }

  removeFavorite = (userExtractId: string): Observable<Object> => {
    const postBody = null;

    let params = new HttpParams();
    params = params.set('userExtractId', userExtractId);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user-extract/removeFavorite', postBody, options);
  }
}
