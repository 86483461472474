import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SubscriptionAssociationEntity,
  SubscriptionCreateEmailEntity,
  SubscriptionCreateKeywordsEntity,
  SubscriptionMetadataEntity,
  SubscriptionRecipientConfigEntity,
  SubscriptionReportConfigEntity
} from '@edw-entities/subscription';
import { Status } from '@edw-enums/status';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-subscription-setup',
  templateUrl: './subscription-setup.component.html',
  styleUrls: ['./subscription-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionSetupComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stepper',{static:true}) private stepper;

  constructor(private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private router: Router,
              private changeDetector: ChangeDetectorRef,
              private sessionStorageService: SessionStorageService,
              private genericErrorHandlerService: ErrorHandlerService,
              private loader: LoaderService) { }


  stepStatus = Status;
  steps = ['Metadata', 'Create Keywords', 'Create email subject and body', 'Recipient Configuration', 'Report Configuration', 'Associate report configurations with recipient groups'];

  metadata: SubscriptionMetadataEntity = new SubscriptionMetadataEntity();
  createKeywords: SubscriptionCreateKeywordsEntity = new SubscriptionCreateKeywordsEntity();
  recipientConfig: SubscriptionRecipientConfigEntity = new SubscriptionRecipientConfigEntity();
  createEmail: SubscriptionCreateEmailEntity = new SubscriptionCreateEmailEntity();
  reportConfig: SubscriptionReportConfigEntity = new SubscriptionReportConfigEntity();
  reportConfigAssociations: SubscriptionAssociationEntity = new SubscriptionAssociationEntity();

  formSections;

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        if (data.componentData !== null) {
          this.metadata = data.componentData.metadata;
          this.createKeywords = data.componentData.createKeywords;
          this.createEmail = data.componentData.createEmail;
          this.recipientConfig = data.componentData.recipientConfig;
          this.reportConfig = data.componentData.reportConfig;
          this.reportConfigAssociations = data.componentData.entityAssociations;

          this.formSections = [this.metadata, this.createKeywords, this.createEmail, this.recipientConfig, this.reportConfig, this.reportConfigAssociations];

        } else {
          this.formSections = [this.metadata, this.createKeywords, this.createEmail, this.recipientConfig, this.reportConfig, this.reportConfigAssociations];
          this.recipientConfig.recipientConfigForms['recipientConfig' + this.recipientConfig.recipientGroups[0].timestampAdded] = false;
        }
      });
  }

  ngOnDestroy() {
    this.sessionStorageService.remove('subscriptionCreationWizard');
  }

  ngAfterViewInit() {
    if (this.sessionStorageService.get('subscriptionCreationWizard')) {
      let currentStep = this.sessionStorageService.get('subscriptionCreationWizard').stepIndex;

      this.formSections.forEach((section, index) => {
        if (index < currentStep) {
          section.status = Status.Complete;
        } else {
          section.status = Status.Pending;
        }
      });

      this.formSections[currentStep].status = Status.Current;
      this.stepper.markSpecificStep(currentStep);
      this.changeDetector.detectChanges();
    }
  }

  completeWizard() {
    this.loader.showLoader();

    //Scheduling the newly created subscription
    let subscription: Subscription = this.subscriptionService
      .scheduleSubscription(this.metadata.id)
      .subscribe(
        (response) => {
          if (response['data'] === true) {
            subscription.unsubscribe();
            this.router.navigate(['app/manage/subscriptions']);
          }
        },
        (httpErrorResponse) => {
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }


  prepareDataForReportConfigAssociations() {
    this.loader.showLoader();

    this.reportConfigAssociations.associationForms = {};
    this.reportConfigAssociations.areAllAssociationFormsPersisted = false;

    //prepare the recipient group-wide keywords so that the UI can handle them
    //for each new association
    this.reportConfigAssociations.recipientGroupKeywords = this.createKeywords.recipientGroupKeywords
      .map((recipientGroupKeyword) => {
        return {
          model: '',
          label: recipientGroupKeyword.label,
          value: recipientGroupKeyword.value,
          isDynamic: false
        };
      });

    //prepare the list of configs per report for the dropdowns
    let reportWithConfigsList = this.reportConfig.configurationsPerReport
      .map((reportWithConfigs) => {
        //prepare the list with the configs for the dropdown component
        let configList = reportWithConfigs.configurations
          .map((reportConfig) => {
            return {
              label: reportConfig.configName,
              value: reportConfig.configId
            };
          });

        return {
          label: reportWithConfigs.resourceName,
          value: configList
        };
      });

    this.reportConfigAssociations.configsListPerReport = reportWithConfigsList;

    this.reportConfigAssociations.recipientGroupReportConfigMemberships = this.recipientConfig.recipientGroups
      .map((recipientGroup) => {
        let membership = {
          recipientGroupId: recipientGroup.id,
          recipientGroupName: recipientGroup.name,
          associations: []
        };

        membership.associations.push({
          id: 0,
          isPersisted: false,
          timestampAdded: new Date().getTime().toString(),
          reportConfigs: {
            selectedReport: null,
            selectedConfig: null,
            reportWithConfigsList: this.copyObjectsToANewArray(this.reportConfigAssociations.configsListPerReport)
          },
          recipientGroupKeywords: this.copyObjectsToANewArray(this.reportConfigAssociations.recipientGroupKeywords)
        });

        this.reportConfigAssociations.associationForms[membership.recipientGroupName + '_association' + membership.associations[0].timestampAdded] = false;
        return membership;
      });

    this.persistWizardChange(null);
    this.loader.hideLoader();
  }

  removeSubscription() {
    if (this.metadata.id === 0) {
      this.router.navigate(['app/manage/subscriptions']);
    } else {
      this.loader.showLoader();

      let subscription: Subscription = this.subscriptionService
        .removeSubscription(this.metadata.id.toString())
        .subscribe(
          (response) => {
            if (response['data'] === true) {
              this.router.navigate(['app/manage/subscriptions']);
            }
          },
          (httpErrorResponse) => {
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

            subscription.unsubscribe();
            this.loader.hideLoader();
          }
        );
    }
  }

  clearAllStepsData() {
    this.recipientConfig.isRecipientGroupListCompleted = false;
    this.recipientConfig.recipientConfigForms = {};
    this.recipientConfig.recipientGroups = [{
      id: 0,
      name: '',
      files: [],
      timestampAdded: new Date().getTime().toString(),
      isPersisted: false
    }];

    this.createEmail.emailSubject.model = '';
    this.createEmail.emailSubject.customErrors = [];
    this.createEmail.emailBody.model = '';
    this.createEmail.emailBody.customErrors = [];

    this.reportConfig.searchQuery = '';
    this.reportConfig.autocompleteSuggestions = [];
    this.reportConfig.configurationsPerReport = [];
    this.reportConfig.configForms = {};
    this.reportConfig.areAllConfigFormsPersisted = false;

    this.reportConfigAssociations.recipientGroupReportConfigMemberships = [];
    this.reportConfigAssociations.configsListPerReport = [];
    this.reportConfigAssociations.recipientGroupKeywords = [];
    this.reportConfigAssociations.associationForms = {};
    this.reportConfigAssociations.areAllAssociationFormsPersisted = false;

    this.recipientConfig.recipientConfigForms['recipientConfig' + this.recipientConfig.recipientGroups[0].timestampAdded] = false;

    this.goBack();
  }

  persistWizardChange($event, skipStepCompletion?: boolean) {
    let currentStepIndex: number;

    if (!skipStepCompletion) {
      currentStepIndex = this.completeStep();
      currentStepIndex = currentStepIndex + 1;
    } else {
      currentStepIndex = this.sessionStorageService.get('subscriptionCreationWizard').stepIndex;
    }

    let recipientGroupsToPersist = Object.assign({}, this.recipientConfig);

    recipientGroupsToPersist.recipientGroups = this.recipientConfig.recipientGroups
      .map((recipientGroup) => {
        recipientGroup.files = this.copyObjectsToANewArray(recipientGroup.files);
        return recipientGroup;
      });

    this.sessionStorageService.add('subscriptionCreationWizard', {
      subscriptionId: this.metadata.id,
      stepIndex: currentStepIndex,
      data: {
        metadata: this.metadata,
        createEmail: this.createEmail,
        createKeywords: this.createKeywords,
        recipientConfig: recipientGroupsToPersist,
        reportConfig: this.reportConfig,
        entityAssociations: this.reportConfigAssociations
      }
    });

    if (this.createKeywords.userSpecificKeywords.length === 1 && this.metadata.id > 0 && currentStepIndex === 1) {
      this.sessionStorageService.add('subscriptionWithNoKeywords', this.metadata.id);
    } else {
      this.sessionStorageService.remove('subscriptionWithNoKeywords');
    }
  }

  goBack() {
    let currentIndex = 0;

    this.formSections.forEach((formSection, index) => {
      if (formSection.status === Status.Current) {
        if (index > 0) {
          formSection.status = Status.Pending;
          currentIndex = index;
        }
      }
    });

    if (currentIndex - 1 >= 0) {
      this.formSections[currentIndex - 1].status = Status.Current;

      let recipientGroupsToPersist = Object.assign({}, this.recipientConfig);

      recipientGroupsToPersist.recipientGroups = this.recipientConfig.recipientGroups
        .map((recipientGroup) => {
          recipientGroup.files = this.copyObjectsToANewArray(recipientGroup.files);
          return recipientGroup;
        });

      this.sessionStorageService.add('subscriptionCreationWizard', {
        subscriptionId: this.metadata.id,
        stepIndex: currentIndex - 1,
        data: {
          metadata: this.metadata,
          createEmail: this.createEmail,
          createKeywords: this.createKeywords,
          recipientConfig: recipientGroupsToPersist,
          reportConfig: this.reportConfig,
          entityAssociations: this.reportConfigAssociations
        }
      });

      this.stepper.markPreviousStep();
    }
  }

  completeStep(): number {
    let currentIndex = -1;

    this.formSections.forEach((formSection, index) => {
      if (formSection.status === Status.Current) {
        formSection.status = Status.Complete;
        currentIndex = index;
      }
    });

    if (currentIndex + 1 <= this.formSections.length - 1) {
      this.formSections[currentIndex + 1].status = Status.Current;
      this.stepper.markNextStep();
    }

    return currentIndex;
  }

  private copyObjectsToANewArray(arr) {
    arr = arr.map((obj) => {
      if (obj instanceof File) {
        return {
          name: obj.name,
          type: obj.type,
          lastModified: obj.lastModified
        };
      } else {
        return Object.assign({}, obj);
      }
    });

    return arr;
  }
}
