import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InputFieldsType } from '@edw-app-root/enums/inputfields-type';
import { MetadataToFormFieldsTransformer } from '@edw-entities/metadata-to-form-fields';
import { RelatedEntityService } from '@edw-entities/related-entity-service.interface';
import { ResourceType } from '@edw-enums/resource-type';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { StagingReportsService } from '@edw-services/staging-reports.service';
import * as moment from 'moment';

@Component({
  selector: 'edw-staging-resource-detail',
  templateUrl: './staging-resource-detail.component.html',
  styleUrls: ['./staging-resource-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StagingResourceDetailComponent implements OnInit, OnDestroy {
  public title: string;
  //public resourceDetails;
  public resourceOwner: string = '';
  public dataSource: string = '';
  public associatedIRB: string = '';
  reportCreatedDate: string;
  reportModifiedDate: string;
  resourceTypeText = ResourceType;
  public resourceGroupSuggestions;
  public userGroupsSuggestions;
  public userSuggestions;
  public deleteDialogVisible = false;
  public rescindDialogVisible = false;

  public permissions;
  dataSourceTypeText = InputFieldsType;

  //skiptextdisplay = 'publishingDepartment,associatedIRB,redeploytarget';
  //skipfielddisplay = 'publishingDepartment,associatedIRB,redeploytarget,resourceOwner,dataSource';

  resourceDetails = {
    id: 0,
    resourceName: '',
    path: '',
    type: '',
    description: '',
    resourceGroups: [],
    resourceDetails: '',
    userGroups: [],
    users: [],
    userExtracts: [],
    subscriptions: [],
    resourceOwner: '',
    resourceStatus: '',
    dataSource: '',
    associatedIRB: '',
    reportCreatedDate: '',
    reportModifiedDate: '',
    createByUserName: '',
    createDate: '',
    modifyDate: '',
    modifyUserName: '',
    skiptextdisplay: '',
    skipfielddisplay: '',
    fields: new MetadataToFormFieldsTransformer()
  };


  public relatedEntitiesPermissions = {
    users: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    resourceGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    userGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    }
  };

  public resourceGroupHandler: RelatedEntityService;
  public usersHandler: RelatedEntityService;
  public userGroupsHandler: RelatedEntityService;



  @ViewChild('accordion',{static:true}) public accordion;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private stagingReportsService: StagingReportsService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: { title: string, resourceDetails: any, permissions: any } }) => {
        this.permissions = data.componentData.permissions;

        this.relatedEntitiesPermissions.resourceGroups.canAddEntity = this.permissions.canModifyAssignment;
        this.relatedEntitiesPermissions.resourceGroups.canRemoveEntity = this.permissions.canModifyAssignment;

        this.relatedEntitiesPermissions.userGroups.canAddEntity = this.permissions.canModifyAssignment;
        this.relatedEntitiesPermissions.userGroups.canRemoveEntity = this.permissions.canModifyAssignment;

        this.relatedEntitiesPermissions.users.canAddEntity = this.permissions.canModifyAssignment;
        this.relatedEntitiesPermissions.users.canRemoveEntity = this.permissions.canModifyAssignment;

        if (data.componentData.hasOwnProperty('resourceDetails')) {
          this.resourceDetails = data.componentData.resourceDetails;

          this.resourceDetails.fields = new MetadataToFormFieldsTransformer(data.componentData.resourceDetails.metadata);
          //this.resourceDetails.skipfielddisplay = this.skipfielddisplay;

          this.resourceDetails.fields.getFields()
            .filter((fields) => {
              if (fields.modelName == this.dataSourceTypeText.resourceowner.text) {
                this.resourceDetails.resourceOwner = fields.value;
              }
              if (fields.modelName == this.dataSourceTypeText.datasource.text) {
                this.resourceDetails.dataSource = fields.value;
              }
              if (fields.modelName == this.dataSourceTypeText.irb.text) {
                this.resourceDetails.associatedIRB = fields.value;
              }
              if (fields.modelName == this.dataSourceTypeText.description.text) {
                this.resourceDetails.description = fields.value;
              }
            });
          //this.resourceDetails.skiptextdisplay = this.skiptextdisplay;
          if (this.resourceDetails.createDate) {
            this.resourceDetails.createDate = moment(this.resourceDetails.createDate+"z").format('MM/DD/YYYY, hh:mm A');
          }
          if (this.resourceDetails.modifyDate) {
            this.resourceDetails.modifyDate = moment(this.resourceDetails.modifyDate+"z").format('MM/DD/YYYY, hh:mm A');
          }
          //console.log("resourceDetails");
          // console.log(this.resourceDetails);

          if (this.resourceDetails.resourceGroups.length === 0) {
            this.resourceDetails.resourceGroups = [];
          } else {
            this.updateResourceGroupCollection(data.componentData.resourceDetails.resourceGroups);
          }

          if (this.resourceDetails.userGroups.length === 0) {
            this.resourceDetails.userGroups = [];
          } else {
            this.updateUserGroupsCollection(data.componentData.resourceDetails.userGroups);
          }

          if (this.resourceDetails.users.length === 0) {
            this.resourceDetails.users = [];
          } else {
            this.updateUserCollection(data.componentData.resourceDetails.users);
          }

          this.sessionStorageService.add('currentResource', this.resourceDetails);
        }

        this.title = data.componentData.title;
      });

    this.resourceGroupHandler = {
      getAddedEntity: this.stagingReportsService.findAddedResourceGroups,
      getNonAddedEntity: this.stagingReportsService.findNonAddedResourceGroup,
      persistEntityList: this.stagingReportsService.addResourceGroups,
      removeEntityFromGroup: this.stagingReportsService.removeResourceGroup
    };

    this.usersHandler = {
      getAddedEntity: this.stagingReportsService.findAddedUser,
      getNonAddedEntity: this.stagingReportsService.findNonAddedUsers,
      persistEntityList: this.stagingReportsService.addUsers,
      removeEntityFromGroup: this.stagingReportsService.removeUser
    };

    this.userGroupsHandler = {
      getAddedEntity: this.stagingReportsService.findAddedUserGroup,
      getNonAddedEntity: this.stagingReportsService.findNonAddedUserGroup,
      persistEntityList: this.stagingReportsService.addUserGroups,
      removeEntityFromGroup: this.stagingReportsService.removeUserGroup
    };
  }

  ngOnDestroy() {
    this.sessionStorageService.remove('currentResource');
  }

  private updateUserCollection(data) {
    this.resourceDetails.users = data
      .map((user) => {
        user.displayName = user.firstName + ' ' + user.lastName + ' (' + user.email + ')';
        user['link'] = ['/app/manage/users/', user.id];

        return user;
      });
  }

  private updateResourceGroupCollection(data) {
    this.resourceDetails.resourceGroups = data
      .map((resourceGroup) => {
        resourceGroup.displayName = resourceGroup.name;

        if (resourceGroup.description) {
          resourceGroup.displayName = resourceGroup.displayName + ' - (' + resourceGroup.description + ')';
        }

        resourceGroup['link'] = ['/app/manage/resource-groups/', resourceGroup.id];

        return resourceGroup;
      });
  }

  private updateUserGroupsCollection(data) {
    this.resourceDetails.userGroups = data
      .map((userGroup) => {
        userGroup.displayName = userGroup.name;

        if (userGroup.description) {
          userGroup.displayName = userGroup.displayName + ' - (' + userGroup.description + ')';
        }

        userGroup['link'] = ['/app/manage/user-groups/', userGroup.id];

        return userGroup;
      });
  }

  public showConfirmationDialog() {
    this.deleteDialogVisible = true;

  }

  public hideConfirmationDialog() {
    this.deleteDialogVisible = false;
  }

  viewSQL(resource) {
    window.open('#/app/manage/resources/viewsql/' + resource.id + '?isStaging=true');
  }

  public deleteStagingResource() {
    this.hideConfirmationDialog();

    this.stagingReportsService
      .remove(this.resourceDetails.id.toString())
      .subscribe(response => {
        if (response['data'] === true) {
          this.sessionStorageService.add('message', 'Successfully removed ' + this.resourceDetails.resourceName + ' resource.');
          this.router.navigate(['/app/manage/resources']);
        }

      });
  }

  public showRescindDialog() {
    this.rescindDialogVisible = true;

  }

  public hideRescindDialog() {
    this.rescindDialogVisible = false;
  }
  public rescind(){
    this.hideRescindDialog();
    this.stagingReportsService.rescind(this.resourceDetails.id)
    .subscribe(response => {
      if (response['data'] === true){
        this.sessionStorageService.add('message', 'Successfully rescided  report - ' + this.resourceDetails.resourceName + ' from Approval Queue.');
        window.location.reload();
      }
    })
  }

}
