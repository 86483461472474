import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExtractConfigList } from '@edw-entities/extract-config-list';
import { ExtractConfigDataService } from '@edw-services/extract-config.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'edw-extract-config-list',
  templateUrl: './extract-config-list.component.html',
  styleUrls: ['./extract-config-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ExtractConfigListComponent implements OnInit {
  private extractDataSubsription: Subscription;

  permissions;
  labelextracts: string;
  extracts: ExtractConfigList[] = [];
  allRowsSelected: boolean = false;
  query: string;
  dialogVisible: boolean = false;

  constructor(private extractConfigDataService: ExtractConfigDataService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;
        this.extracts = data.componentData.extracts;
      });
  }

  clearSearch(autoCompleteRef) {
    this.query = '';

    //the next two lines are here because the autocomplete
    //component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  search() {
    let subscription: Subscription = this.extractConfigDataService.getExtractConfigList(this.query)
      .subscribe(response => {
        this.extracts = [];
        if (response['data'].length > 0) {
          this.extracts = response['data'];
        }
        //Unsubscribe here
        subscription.unsubscribe();
      });
    //TODO: error handing here
  }
}

