import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ResourceRating } from '@edw-entities/resource-rating.entity';

@Injectable({providedIn: 'root'})
export class ResourceRatingService {

  constructor(private http: HttpClient ) { }

  private baseUrl : string = `${environment.api}/Rating`;

  private _get = (endpoint) => this.http.get(`${this.baseUrl}/${endpoint}`, {observe:'body'} )

  private _post = ( endpoint , data ) => this.http.post( endpoint, data, {observe:'body'} );

  addRating = ( rating:ResourceRating ) => this._post(`${this.baseUrl}/add`,rating);

  updateRating = ( rating:ResourceRating ) => this._post(`${environment.api}/Rating/update`,rating);

  removeRating = ( ratingId ) => this._post( `${environment.api}/Rating/${ratingId}/remove`, null );

  getResourceRating = ( resourceId ) => this._get(`getUserResourceRating?ResourceKey=${resourceId}`);

  getRating = (ratingId) => this._get(`get?ratingId=${ratingId}`);

}
