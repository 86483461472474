export const NotificationStatus = {
  Unread: 1,
  Read: 2
};

export const NotificationSubjectType = {
  Resource: 1,
  StagingResource: 2
};

export const NotificationActionPrompt = {
  NoAction: 1,
  NavigateTo: {
    DetailsPage: 2,
    ListViewPage: 3,
    PendingApprovals: 4,
    StagingDetailsPage: 5,
    NavigateToDataStewardApproval: 6
  }

};
