import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToIterable'
})
export class ObjectToIterablePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let iterableObjects = [];

    for (let key in value) {
      let iterablObj;

      if (args.hasOwnProperty('exclude')) {
        if (args.exclude.indexOf(key) === -1) {
          iterablObj = {key: key, value: value[key]};
          iterableObjects.push(iterablObj);
        }
      } else {
        iterablObj = {key: key, value: value[key]};
        iterableObjects.push(iterablObj);
      }
    }

    return iterableObjects;
  }

}
