import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { UserCollectionService } from '@edw-services/user-collection.service';
import { Collection, CollectionData, CollectionStore } from '../../components/entities/CollectionDataClasses';
import { CollectionDataService, CollectionService } from '../../components/services/resource.service';

@Component({
  selector: 'edw-collections-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  @Output() CloseAdmin = new EventEmitter<boolean>();

  @Output() passAction = new EventEmitter();
  public adminFilter = '';
  CollectionStore: CollectionStore;
  public collectionData: CollectionData;
  public newCollectionName: string;
  public editCollectionDataID: number;
  public editCollectionID: number;
  public favoriteEdit = false;
  constructor(
    private userCollectionService:UserCollectionService,
    private collectionService: CollectionService,
    private collectionDataService: CollectionDataService,
    private toastMessageService: ToastMessageService
  ) { }

  ngOnInit() {

    this.collectionService.CollectionStore$.subscribe(store => this.CollectionStore = store );

    this.collectionDataService.CollectionData.subscribe((collectionData) => {
      this.collectionData = collectionData;
      this.favoriteEdit = ( collectionData.Collection[0].id === 0 && collectionData.Collection[0].name === 'Favorites')
       ? true : false;
      this.editCollectionDataID = collectionData.Collection[0].id;
    });

    this.collectionDataService.setCollectionData([this.CollectionStore.Collections[0]]);

  }

  public SetActiveCollection( collection ){
    if( this.editCollectionDataID === collection.id ) { return false; }
    this.collectionDataService.setCollectionData([collection]);
    this.editCollectionID = -1;
  }

  toggleEditCollection(collection){
    this.editCollectionID = collection.id;
  }

  public resetCollectionState(collection){
    this.editCollectionDataID = collection.id;
    this.editCollectionID = -1;
  }

  public addNewCollection(collection){
    // INSERT A NEW COLLECTION...
    if(this.CollectionStore.Collections.map(col => col.name).includes(collection.name)){
      this.toastMessageService.addMessage('error','Collection name already in use','Please enter a unique name if you intend to add a new collection.');
      return false;
    }

    if(collection != null && collection.name.length > 0 ){
      this.userCollectionService.addUserCollection(collection.name)
        .subscribe(
          ( res )=>{
            if(res && res.data !== null){
              this.collectionService.addCSCollection(res.data) // res.data = all collections including newly added.
              this.SetActiveCollection(this.CollectionStore.Collections.filter(col => col.name === collection.name)[0]);
            }},(err)=>{console.log(err)}
        );
    }

  }

  public editCollectionName = (( data :{ id:string, name:string }) => {
    const updatedCol: Collection = Object.assign({}, this.collectionData.Collection[0], { name: data.name}) ;
    this.userCollectionService.updateUserCollectionName(updatedCol)
      .subscribe(
        (res)=> {
          this.collectionService.updateCSCollections(res.data, updatedCol.id);
          this.editCollectionID = -1;
          this.SetActiveCollection(updatedCol);
        },
        (err)=> { this.toastMessageService.addMessage('error','An error has occured','Please contact the systems administrator.') }
      );
  });

  deleteCollection(collection: Collection){
    // DELETE COLELCTIOn....
    if(window.confirm('Please confirm that you want to delete this collection.')){
      this.userCollectionService.deleteUserCollection(collection.id).subscribe(
        (res)=>{
          this.collectionService.removeCSCollection( collection );
          this.SetActiveCollection(this.CollectionStore.Collections[0]);
        },
        (err)=>{ this.toastMessageService.addMessage('error','An error has occured','Please contact the systems administrator.') }
      )

    }
  }

  closeAdmin(){
    this.CloseAdmin.emit(false);
  }


}

@Component({
  selector: 'admin-collection-input',
  styleUrls: ['./admin.component.scss'],
  template:`
    <div class="display-flex space-btw">
      <div class="edw-search-bar cell-display">
        <input type="text" pInputText placeholder="Add New Collection..." [(ngModel)]="name" (keyup.enter)="changeState(); name = ''" />
        <button type="button" *ngIf="name" (click)="name=''">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>

      <button (click)="changeState(); name = ''" [disabled]="(name === '')">
        <i class="fa fa-check"></i>
      </button>
      <button (click)="resetState();">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>

    </div>
  `
})
export class AdminInputComponent{
  @Input() name: string;
  @Input() id: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onClickX: EventEmitter<any> = new EventEmitter();

  changeState(): void {
    this.onClick.emit({id: this.id, name: this.name});
  }

  resetState(): void {
    this.id = '-1';
    this.name  = '';
    this.onClickX.emit({id: this.id, name:this.name});
  }
}
