import {Injectable, OnDestroy} from '@angular/core';
import * as _ from 'lodash';
import { CollectionStore, CollectionData, Collection, CollectionType } from '../../components/entities/CollectionDataClasses';
import { IExtractForUser } from '@edw-app-root/entities/user-extracts';
import { Report } from '@edw-entities/report';
import { Observable, BehaviorSubject, throwError, Subscription } from 'rxjs'
import { ReportsService } from '@edw-services/reports.service';
import { UserExtractsService } from '@edw-services/user-extracts.service';

type CollectionType = 'edit' | 'view';

@Injectable()
export class ResourceService{

  public parseData(res) {
    return (res && Array.isArray(res))
    ? [].concat(res).map( ex => this.parseExtractDates(ex) )
    : [];
  }

  public parseExtractDates(extract){
    const whitelabels = ['[NULL]', '[Unresolved]', '[Blank]'];

    if(extract.lastExtractionParameters){
      extract.lastExtractionParameters = extract.lastExtractionParameters.map((param) => {
        if (param.type === 'DateTime' && param.values) {
          param.values = param.values.map((value) => {
            return (value && whitelabels.indexOf(value) === -1) ? new Date(value as string) : value;
          });
        }
        return param;
      });
    }
    return extract;
  }

  public addInlineStyle(element){
    switch(element){
      case 'date':
        return {'width': '131px'};
      case 'name':
        return {'width': '360px'};
      case 'rating':
        return {'width' : '8%', 'text-align' : 'center', 'padding' : '5px'};
      default:
        return '';
    }
  }
}

/**
 * CollectionService acts as the data store for All-Resources & Collections.
 * Any crud changes come through this service.
 * CollectionDataService is used for display.
 */
@Injectable()
export class CollectionService{
  constructor(
    private reportsService: ReportsService,
    private userExtractsService: UserExtractsService,
    private resourceService: ResourceService
  ){}

  private CollectionStore: CollectionStore = new CollectionStore();
  public CollectionStore$ = new BehaviorSubject(this.CollectionStore);

  setCollectionServiceData(reports: Report[], extracts:IExtractForUser[], collections: Collection[]){
    this.CollectionStore = new CollectionStore(reports, extracts, collections);
    this.CollectionStore$.next(this.CollectionStore);
  }

  setCSExtracts(extracts: IExtractForUser[]){
    this.CollectionStore.Extracts = extracts;
    this.CollectionStore$.next(this.CollectionStore);
  }

  setCSReports(reports: Report[]){
    this.CollectionStore.Reports = reports;
    this.CollectionStore$.next(this.CollectionStore);
  }
  setCSCollections(collection: Collection[]){
    this.CollectionStore.Collections = collection;
    this.CollectionStore$.next(this.CollectionStore);
  }

  uniqueArray = (arr) => {
    if( !Array.isArray(arr) || arr.length === 0) return [];
    return Array.from(new Set(arr));
  }

  insertByIndex = (arr, newItem, insertAt) => [ ...arr.slice(0, insertAt), newItem, ...arr.slice(insertAt) ];

  addCSCollection(collections){
    this.CollectionStore.Collections = this.insertByIndex( collections, this.CollectionStore.Collections[0], 0 );
    this.CollectionStore$.next(this.CollectionStore);
  }

  removeCSCollection( colToDelete : Collection ){
    this.CollectionStore.Collections = this.CollectionStore.Collections.filter(collection => collection.id != colToDelete.id );
    this.CollectionStore$.next(this.CollectionStore);
  }

  updateCSCollections(collections, updatedCollectionID){
    const updatedCollection = collections.filter(col => col.id === updatedCollectionID)[0];
    this.CollectionStore.Collections = this.CollectionStore.Collections.map(collection =>
      ( collection.id !== updatedCollectionID )
        ? collection
        : { ...updatedCollection }
    )
    this.CollectionStore$.next(this.CollectionStore);
  }

  updateCSExtracts(extract){
    this.CollectionStore.Extracts = this.CollectionStore.Extracts.map((e) => {
      if( e.id !== extract.id ){ return e; }
      return  this.resourceService.parseExtractDates(extract)
    }) as IExtractForUser[];
    this.CollectionStore$.next(this.CollectionStore);
  }

  saveFavorite(resObj){
    if( resObj.resource == null ){ throw Error('No resource found.'); }
    if( resObj.collectionType === CollectionType.reports ){
      if(resObj.resource.isFavorite){
        // remove favorite from report.
        this.reportsService.removeFavorite(resObj.resource.id).subscribe(
          (res: any) =>  { if( res.data === true){  this.updateFavorites(CollectionType.reports ); } }
        );
      } else{
        // add favorite to report.
        this.reportsService.addFavorite(resObj.resource.id).subscribe(
          (res: any) => { if(res.data === true){ this.updateFavorites(CollectionType.reports); } }
        );
      }
    }else if( resObj.collectionType === CollectionType.extracts){
      if( !!resObj.resource.isFavorite ){
        // remove favorite from extract.
        this.userExtractsService.removeFavorite(resObj.resource.id).subscribe(
          (res) =>  { if( res === true){ this.updateFavorites(CollectionType.extracts ); } }
        );
      } else{
        // add favorite to report.
        this.userExtractsService.addFavorite(resObj.resource.id).subscribe(
          (res) => { if(res === true){ this.updateFavorites(CollectionType.extracts ); } }
        );
      }
    }
  }


  private updateFavorites(collectionType){

    if(collectionType === CollectionType.reports ){

      this.reportsService.getFavoriteReports().subscribe(
        (favs: any) => {
          /* update the CollectionStore with updated favorites values */
          this.CollectionStore.Reports = this.CollectionStore.Reports.map((report) => {
            report.isFavorite = ( favs.data[0].find( fav => fav.id === report.id ) ) ? true : false;
            return report;
          });
          /* update the Favorites Collection with updated ids. The length will be influx */
          this.CollectionStore.Collections[0].reports = this.CollectionStore.Reports
            .filter(report => report.isFavorite)
            .map(report => +report.id) as number[];
        }
      )
    } else if ( collectionType === CollectionType.extracts ) {
      this.userExtractsService.getFavoritesUserExtracts().subscribe(
        (favs) => {
          this.CollectionStore.Extracts = this.CollectionStore.Extracts.map((extract) => {
            extract.isFavorite = ( favs.find( fav => fav.id === extract.id ) ) ? true : false;
            return extract;
          });
          // update Favorite Extract Collection array with fresh ids.
          this.CollectionStore.Collections[0].extracts = this.CollectionStore.Extracts
            .filter(ex => ex.isFavorite)
            .map(ex => ex.id);
        }
      )
    }
    // Update CollectionStore.
    this.CollectionStore$.next(this.CollectionStore);
  }

}

/**
 * CollectionDataService is essentially a ViewModel for All-Resources and COllections
 * There is collection filtering logic that controls what collection to show.
 * If user needs to reference the data in sync with the db... that comes from The CollectionService
 * which acts as a data store.
 */
@Injectable({providedIn:'root'})
export class CollectionDataService implements OnDestroy {

  subscription: Subscription;
  private collectionStore: CollectionStore;

  public CollectionData = new BehaviorSubject<CollectionData>(new CollectionData());
  get collectionData(){ return this.CollectionData.value; }
  set collectionData( data ){this.CollectionData.next(data);}

  constructor(private collectionService: CollectionService){
    this.subscription = this.collectionService.CollectionStore$.subscribe( store => this.collectionStore = store )
  }
  ngOnDestroy(){ this.subscription.unsubscribe(); }

  setCollectionData( collection: Collection[] ){
    this.collectionData = Object.assign({}, { Collection:collection , Reports: this.collectionStore.Reports, Extracts: this.collectionStore.Extracts } );
  }


  setCollectionData_withFilter(collectionIds: string[]){

    const collections: Collection[] = this.collectionStore.Collections.filter(collection => collectionIds.includes(collection.id.toString())); // collectionIds.map(id => this.collectionStore.Collections.filter(collection => collection.id === id ));

    const distinct = (value, index, self ) => self.indexOf(value) === index;

    const reports = collections.map(collection => collection.reports) // loop through the report id array in the selected collections...
      .reduce((p,c) => p.concat(c) ) // concat values in the report id array...
      .filter(distinct) // remove dupes...
      .map( id => this.collectionStore.Reports.filter(report => report.id == id.toString() )[0] ) // map the ids to the stored reports...
      .filter(x => x) // filter out any stale, unavailable reports from the list.
      .map( report => new Report(report));

    const extracts = collections.map(collection => collection.extracts)
      .reduce((p,c) => p.concat(c) )
      .filter(distinct)
      .map( id => this.collectionStore.Extracts.filter(extract => extract.id == id )[0] )
      .filter(x => x);

    this.collectionData = Object.assign({},{Collection:collections},{Reports:reports},{Extracts:extracts});
  }


  collectionFilterSelected(resourceType = 'report'||'extract', filterFavorites: boolean = false){
    let filteredResources = Object.assign({}, this.collectionData);
    if(filterFavorites){
      if(resourceType === 'extract'){
        filteredResources.Extracts = this.collectionData.Extracts.filter(extract => extract.isFavorite)
      }
      else{
        filteredResources.Reports = this.collectionData.Reports.filter(report => report.isFavorite)
      }
    }
    else{
      if(resourceType === 'extract'){
        filteredResources.Extracts = this.collectionData.Extracts.filter(extract => this.collectionData.Collection[0].extracts.includes(+extract.id))
      }
      else{
        filteredResources.Reports = this.collectionData.Reports.filter(report => this.collectionData.Collection[0].reports.includes(+report.id))
      }
    }

    this.collectionData = filteredResources;

  }

  private selectedCollectionData(collection, resource){
    if(collection == null || collection.length === 0){ return []; }
    return resource
      .filter( res =>  collection.includes(res.id) )
      .sort( ( a, b ) => ( collection.indexOf(a.id) < collection.indexOf(b.id) )? -1 : 0 );
  }
}
