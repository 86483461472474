import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root/permissions';
import { UserDataService } from '@edw-services/user-data.service';
import { UserGroupDataService } from '@edw-services/user-group.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class ManagePeopleRoutingResolver implements Resolve<any> {

  constructor(
    private userGroupDataService: UserGroupDataService,
    private userDataService: UserDataService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let permissions = {};
    let componentData = {
      title: '',
      permissions: permissions
    };
    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];

    //#region Users
    if (urlFragments.indexOf('users') > -1) {

      permissions = {
        canView: this.userDataService.checkForSpecificPermission(Permissions.USERS.canView),
        canCreate: this.userDataService.checkForSpecificPermission(Permissions.USERS.canModify),
        canModify: this.userDataService.checkForSpecificPermission(Permissions.USERS.canModify),
        canDeactivate: this.userDataService.checkForSpecificPermission(Permissions.USERS.canDeactivate),
        canModifyContent: this.userDataService.checkForSpecificPermission(Permissions.USERS.canModifyContent),
        canMergeUser: this.userDataService.checkForSpecificPermission(Permissions.USERS.CanMergeUser)
      };

      componentData = {
        title: 'Edit User',
        permissions: permissions
      };

      if (lastParam === 'users') {
        componentData.title = 'Users';
        return this.userDataService.getAllUsers('')
          .pipe(
            map(responseBody => {
              componentData['users'] = responseBody['data'];
              return componentData;
            }));
      }

      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        return this.userDataService
          .getUserDetails(lastParam.toString())
          .pipe(
            map(responseBody => {
              if (responseBody['data']) {
                componentData['userDetails'] = responseBody['data'];
              }
              return componentData;
            }));
      }

      if (lastParam === 'add') {
        componentData.title = 'Add New User';
        return of(componentData);
      }
    }
    //#endregion Users

    //#region User Groups
    if (urlFragments.indexOf('user-groups') > -1) {

      permissions = {
        canView: this.userDataService.checkForSpecificPermission(Permissions.USERGROUPS.canView),
        canCreate: this.userDataService.checkForSpecificPermission(Permissions.USERGROUPS.canCreate),
        canModifyContent: this.userDataService.checkForSpecificPermission(Permissions.USERGROUPS.canModifyContent),
        canRemove: this.userDataService.checkForSpecificPermission(Permissions.USERGROUPS.canRemove)
      };

      componentData = {
        title: 'Edit User Group',
        permissions: permissions
      };

      if (lastParam === 'user-groups') {
        return this.userGroupDataService.getAllUserGroups('')
          .pipe(
            map(responseBody => {
              componentData['userGroups'] = responseBody['data'];
              return componentData;
            }));
      }

      if (lastParam === 'add') {
        componentData.title = 'Add New User Group';
        return of(componentData);
      }

      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        return this.userGroupDataService
          .getUserGroupDetails(lastParam.toString())
          .pipe(
            map(responseBody => {
              if (responseBody['data']) {
                componentData['userGroupDetails'] = responseBody['data'];
              }
              return componentData;
            }));
      }
    }
  }
}

