import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { IDataStewardRequestDetailsResponse } from '@edw-entities/data-steward-queue';
import { ResearchRequestsService } from '@edw-services/research-requests.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable()
export class DataStewardLogViewResolver implements Resolve<any> {

  constructor(
    private router: Router,
    private rrService: ResearchRequestsService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const id = route.params.rid;
    return this.rrService.getActivityDetails(id);  

  }
}
