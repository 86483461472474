import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators'
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { ResourceStatus } from '@edw-enums/resource-status';

@Injectable()
export class ManageSubscriptionsRouteGuard implements CanActivate, CanActivateChild {

  canActivateSubject = new Subject<boolean>();
  canActivateObservable = this.canActivateSubject.asObservable();
  urlFragments: string[] = [];
  lastParam: string = "";
  canActivateRoute: boolean = false;

  constructor(private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.urlFragments = state.url.split('/');
    this.lastParam = state.url.split('/')[this.urlFragments.length - 1];

    this.evaluateCanActivate();

    this.canActivateSubscriptionsListing();
    this.canActivateSubscriptionsDetails();
    this.canActivateSubscriptionsCreate();
    this.canActivateSubscriptionsUpdate();

    return this.canActivateObservable;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.urlFragments = state.url.split('/');
    this.lastParam = state.url.split('/')[this.urlFragments.length - 1];

    this.evaluateCanActivate();

    this.canActivateSubscriptionsListing();
    this.canActivateSubscriptionsDetails();
    this.canActivateSubscriptionsCreate();
    this.canActivateSubscriptionsUpdate();

    return this.canActivateObservable;
  }

  private canActivateSubscriptionsUpdate() {
    if (this.urlFragments.indexOf('update') > -1) {
      let previousStateUrlFragments = this.sessionStorageService.get('previousSubStateArr');
      let isPreviousStateEdit = previousStateUrlFragments.indexOf('subscriptions')
        && !isNaN(parseInt(previousStateUrlFragments[previousStateUrlFragments.length - 1]))
        && parseInt(previousStateUrlFragments[previousStateUrlFragments.length - 1]) > 0;
      setTimeout(() => {
        this.canActivateSubject.next(isPreviousStateEdit);
      }, 100);
    }
  }

  private canActivateSubscriptionsCreate() {
    const canCreateSubscriptions = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canCreate);
    if (this.lastParam === 'add') {
      setTimeout(() => {
        this.canActivateSubject.next(canCreateSubscriptions);
      }, 100);
    }
  }

  private canActivateSubscriptionsDetails() {
    const canViewSubscriptions = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canView);
    if (!isNaN(parseInt(this.lastParam)) && parseInt(this.lastParam) > 0) {
      setTimeout(() => {
        this.canActivateSubject.next(canViewSubscriptions);
      }, 100);
    }
  }

  private canActivateSubscriptionsListing() {
    const canViewSubscriptions = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canView);
    if (this.lastParam === 'subscriptions') {
      setTimeout(() => {
        this.canActivateSubject.next(canViewSubscriptions);
      }, 100);
    }
  }

  private evaluateCanActivate() {
    this.canActivateObservable.subscribe((canActivate) => {
      if (!canActivate) {
        this.router.navigated = false;
        this.sessionStorageService.add('message-error', 'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.');
        //this.router.navigateByUrl('/app/resources/favorite');
      }
    });
  }
}
