import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuditsDataService } from '@edw-app-root/services/audits.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';

@Injectable()
export class AuditListResolveGuard implements Resolve<any> {

  constructor(
    private auditDataService: AuditsDataService,
    private userDataService: UserDataService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let permissions = {};
    let componentData = {
      permissions: permissions
    };
    const urlFragments = state.url.split('/');

    if (urlFragments.indexOf('audit') > -1) {
      permissions = {
        canView: this.userDataService.checkForSpecificPermission(Permissions.AUDIT.canView),
      };

      componentData = {
        permissions: permissions
      };
    }
    return this.auditDataService.getAllAudits()
      .pipe(
       map(responseBody => {
        return responseBody['data'];
      }));
  }
}
