import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IServiceAgreement, IServiceAgreementUpdate, IServiceAgreementSign } from '@edw-entities/research-request';
import { Observable } from 'rxjs';


@Injectable()
export class SAService {
  private endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
  }

  getServiceAgreement(id: string): Observable<IServiceAgreement> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.get<IServiceAgreement>(`${this.endpoint}/service-agreement/${id}`, options);
  }

  updateServiceAgreement(id: string, data: IServiceAgreementUpdate): Observable<IServiceAgreement> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<IServiceAgreement>(`${this.endpoint}/service-agreement/${id}/update`, data, options);
  }
  signServiceAgreement(id: string, data: IServiceAgreementSign): Observable<IServiceAgreement> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<IServiceAgreement>(`${this.endpoint}/service-agreement/${id}/sign`, data, options);
  }

  withdrawServiceAgreement(id: string, data: IServiceAgreementSign): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<any>(`${this.endpoint}/service-agreement/${id}/withdraw`, data, options);
  }

  validateNUChartString(chartString: string): Observable<any> {

    // let params = new HttpParams();
    // params = params.set('chartString', chartString);

    const options: Object = {
      observe: 'body',
      // params: params
    };

    // return this.http.post<any>(`${this.endpoint}/service-agreement/${id}/ValidateNUChartString`, {}, options);
    return this.http.get<any>(`${this.endpoint}/service-agreement/${chartString}/ValidateNUChartString`, options);
  }

}
