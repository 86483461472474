import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class LoaderService {

  loadingSub : BehaviorSubject<boolean> = new BehaviorSubject(false);

  loadingMap : Map<string, boolean> = new Map<string,boolean>();

  private loaderSubject: Subject<any> = new Subject<any>();

  loaderObservable = this.loaderSubject.asObservable();

  constructor() { }

  /**
   * Sets the loadingSub property value based on the following:
   * - If loading is true, add the provided url to the loadingMap with a true value, set loadingSub value to true
   * - If loading is false, remove the loadingMap entry and only when the map is empty will we set loadingSub to false
   * This pattern ensures if there are multiple requests awaiting completion, we don't set loading to false before
   * other requests have completed. At the moment, this function is only called from the @link{HttpRequestInterceptor}
   * @param loading {boolean}
   * @param url {string}
   */
  setLoading(loading: boolean, url: string): void {

    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading === true) {
      this.loadingMap.set(url, loading);
      this.showLoader();
      this.loadingSub.next(true);
    }else if (loading === false && this.loadingMap.has(url)) {


      this.loadingMap.delete(url);
    }
    if (this.loadingMap.size === 0) {
      this.hideLoader();
      this.loadingSub.next(false);
    }
  }

  resetLoader = () => {
    this.loadingMap.clear();
    this.loadingSub.next(false);
  }

  showLoader() {

    this.loaderSubject.next({
      show: true
    });
  }

  hideLoader() {
    this.loaderSubject.next({
      show: false
    });
  }

  getObservable() {
    return this.loaderObservable;
  }

}
