import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'edw-create-new-account',
  templateUrl: './create-new-account.component.html',
  styleUrls: ['./create-new-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateNewAccountComponent implements OnInit, OnDestroy {

  formSubmitted: boolean = false;

  user = {
    firstName: null,
    lastName: null,
    domain: null,
    email: null
  };

  isDisabled = {
    firstName: true,
    lastName: true,
    email: true,
  }

  subscription: Subscription = null;
  constructor(
    private userDataService: UserDataService,
    private sessionStorage: SessionStorageService,
    private router: Router,
    private toastMessageService : ToastMessageService
  ) {
    this.toastMessageService.test();
  }

  ngOnInit() {
    const newUser = this.sessionStorage.get('newUserData');

    this.user.firstName = newUser.firstName;
    this.user.lastName = newUser.lastName;
    this.user.domain = newUser.domain;
    this.user.email = newUser.email;
    this.isDisabled.firstName = this.user.firstName != null && this.user.firstName !== '';
    this.isDisabled.lastName = this.user.lastName != null && this.user.lastName !== '';
    this.isDisabled.email = this.user.email != null && this.user.email !== '';
  }

  ngOnDestroy() {
    this.sessionStorage.remove('newUserData');

  }

  createNewAccount() {
    this.formSubmitted = true;

    this.subscription = this.userDataService.confirmNewAccount(this.user)
      .pipe(
        map(response => {
          if (response['data']) {

            this.toastMessageService.addMessageObj(
              {
                severity: 'success',
                summary: '',
                detail: 'Your account has been successfully created!'
              }
            );
          }

        //confirmNewAccountSubscription.unsubscribe();
        this.userDataService.markAuthenticationAsPassed();
        this.router.navigate(['/app/resources/reports/all']);
      })).subscribe();
  }
}
