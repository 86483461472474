export class ResourceGroup {

id: number;
name: string;
description: string;

constructor(resourceGroup) {
    this.id = resourceGroup.id;
    this.name = resourceGroup.name;
    this.description = resourceGroup.description;
  }

}


export interface IResourceGroup{
  id:number;
  name:string;
  description:string;
}
