import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,CanActivateChild, RouterStateSnapshot, Router } from '@angular/router';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';

@Injectable({providedIn: 'root'})
export class ProductPagesGuard implements CanActivate, CanActivateChild {
  constructor( private router : Router, private userData : UserDataService ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const uriELe = state.url.toLowerCase().split('/')
    let canActivate = ( uriELe.includes('manage') && uriELe.includes('product-pages') )
      ? this.userData.checkForSpecificPermission(Permissions.PRODUCT_PAGES.canManageProductPages)
      : true;
    if(!canActivate) this.router.navigate(['/app/resources/reports/all'])
    return canActivate;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const uriELe = state.url.toLowerCase().split('/')
    let canActivate = ( uriELe.includes('manage') && uriELe.includes('product-pages') )
      ? this.userData.checkForSpecificPermission(Permissions.PRODUCT_PAGES.canManageProductPages)
      : true;
      if(!canActivate) this.router.navigate(['/app/resources/reports/all'])
      return canActivate;
  }
}
