import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IAudit } from '@edw-entities/audit';
import { AuditsDataService } from '@edw-services/audits.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'edw-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuditListComponent implements OnInit, OnDestroy {

  public audits: IAudit[] = [];
  public typeNames = [];
  public minDate;
  public form: FormGroup;


  @ViewChild('dataTable',{static: true}) dataTable;

  private filterSub: Subscription;
  private calendarSubs: Subscription;

  constructor(
    private auditsDataService: AuditsDataService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      query: [''],
      filterByStartDateField: [''],
      filterByEndDateField: [''],
      filterByTypeName: ['']
    });
  }

  ngOnInit() {
    this.auditsDataService.getActionTypesNames()
      .subscribe((response) => {
        this.typeNames = [];
        if (response['data'] && response['data'].length > 0) {
          response['data'].map((element) => {
            this.typeNames.push({ label: element, value: element });
          });
        }
        // add actionType without value in the dropdown
        // so the user can be able to select all types after he/she has selected one particular type
        this.typeNames.unshift({ label: 'All', value: '' });
      });
    this.route.data
      .subscribe((data: { componentData: IAudit[]}) => {
        this.audits = data.componentData;
      });
    this.filterSub = this.form.valueChanges.subscribe((value) => {
      console.log('value: ', value);
      this.search();
    });
    this.calendarSubs = this.form.get('filterByStartDateField').valueChanges.pipe(
        filter((value) => !!value)
      ).subscribe((value: Date) => {
        this.minDate = value;
        console.log('this.minDate: ', this.minDate);
    });
  }

  ngOnDestroy(): void {
    this.filterSub.unsubscribe();
  }

  public search() {
    const query = this.form.get('query').value;
    const filterByStartDateField = this.form.get('filterByStartDateField').value;
    const filterByEndDateField = this.form.get('filterByEndDateField').value;
    const filterByTypeName = this.form.get('filterByTypeName').value;

    this.auditsDataService
      .getAllAudits(
        query,
        filterByStartDateField,
        filterByEndDateField,
        filterByTypeName
      )
      .subscribe((response) => {
        this.audits = [];
        if (response['data'].length > 0) {
          this.audits = response['data'];
        }
      });
    // TODO: error handing here
  }

  public lazyLoadMore($event) {
    const lastPage = this.dataTable.totalRecords / this.dataTable.rows;
    const firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;

    if ($event.first === firstIndexOnLastPage) {
      // BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      const nextPage: any = Math.ceil(lastPage / 10) + 1;
      const query = this.form.get('query').value;
      const filterByStartDateField = this.form.get('filterByStartDateField').value;
      const filterByEndDateField = this.form.get('filterByEndDateField').value;
      const filterByTypeName = this.form.get('filterByTypeName').value;
      this.auditsDataService.getAllAudits(
        query,
        filterByStartDateField,
        filterByEndDateField,
        filterByTypeName, nextPage
        )
        .subscribe((response) => {
          if (response['data'] && response['data'].length > 0) {
            const newAudits = response['data'];
            this.audits = this.audits.concat(newAudits);
          }
        });
    }
  }

  public clearSearch(autoCompleteRef) {
    this.form.get('query').reset('');
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  public onClearStart($event) {
    this.form.get('filterByStartDateField').reset('');
    this.search();
  }

  public onClearEnd($event) {
    this.form.get('filterByEndDateField').reset('');
    this.search();
  }

  public exportAuditsInXML() {
    const query = this.form.get('query').value;
    const filterByStartDateField = this.form.get('filterByStartDateField').value;
    const filterByEndDateField = this.form.get('filterByEndDateField').value;
    const filterByTypeName = this.form.get('filterByTypeName').value;
    this.auditsDataService
      .exportAudit(
        query,
        filterByStartDateField,
        filterByEndDateField,
        filterByTypeName
      )
      .pipe(
        map((res) => {
        return {
          filename: 'filename.xml',
          data: res
        };
      }))
      .subscribe((res) => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = res.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }
}
