import { Account } from './account';

export class User {

  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  accounts: Account[];
  accountsDisplay: string;
  isActive: boolean;
  createDate?: string;
  modifyDate?: string;
  displayName: string;

  constructor(user) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.fullName = user.firstName + ' ' + user.lastName;
    this.email = user.email;
    this.isActive = user.isActive;
    this.accounts = user.accounts;
    this.createDate = user.createDate;
    this.modifyDate = user.modifyDate;
    this.displayName = this.firstName + ' ' + this.lastName;
    if (user.accounts && user.accounts.length > 0) {
      this.accountsDisplay = user.accounts
        .map(function (acc) {
          return acc.activeDirectory + "\\" + acc.accountName;
        })
        .join(', ');
    }
  }

}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  displayName?: string;
  link?: any;
  email: string;
  accounts: Account[];
  accountsDisplay: string;
  createDate?: string;
  modifyDate?: string;
}
