import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs';
import { SubscriptionMetadataEntity, SubscriptionDTO } from '@edw-entities/subscription';
import { Resource } from '@edw-entities/resource';
import { ReportParameter } from '@edw-entities/report-parameter';

@Injectable()
export class SubscriptionService {
  constructor(private http: HttpClient) { }
  private subscriberSource = new Subject<any>();

  subscriptionObservable = this.subscriberSource.asObservable();

  getSubscriptionList(query: string, page: number = 1): Observable<Object> {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
        observe: 'body',
        params: params
    };

    return this.http.get<Response>(environment.api + '/subscription/getAll', options);
  }

  addSubscription(subscription: SubscriptionMetadataEntity): Observable<Object> {
    let postBody = new SubscriptionDTO(subscription);

    const options: Object = {
      observe: 'body'
    };

    return this.http.post(environment.api + '/subscription/add', postBody, options);
  }

  updateSubscription(subscription: SubscriptionMetadataEntity): Observable<Object> {
    let postBody = new SubscriptionDTO(subscription);

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/subscription/' + subscription.id + '/update', postBody, options);
  }

  removeSubscription(subscriptionId: string): Observable<Object> {
    const body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/remove', body, options);
  }

  removeReport(subscriptionId: string): Observable<Object> {
    const body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/removeReport', body, options);
  }

  addRecipientGroup(subscriptionId: number, groupName: string, csvFile: File): Observable<Object> {
    let params = new HttpParams();
    params = params.set('name', groupName);

    let file = new FormData();

    file.append('UploadedFile', csvFile, csvFile.name);

    const options: Object = {
      params: params
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/recipientGroup/add', file, options);
  }

  removeRecipientGroup(subscriptionId: number, recipientGroupId: number): Observable<Object> {
    const body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/recipientGroup/' + recipientGroupId + '/remove', body, options);
  }

  addUserKeywords(subscriptionId: number, keywords: string[]): Observable<Object> {
    let postBody = keywords;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/addUserKeywords', postBody, options);
  }

  addGroupKeywords(subscriptionId: number, keywords: string[]): Observable<Object> {
    let postBody = keywords;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/addGroupKeywords', postBody, options);
  }

  createOrUpdateEmail(subscriptionId: number, emailSubject: string, emailBody: string): Observable<Object> {
    let params = new HttpParams();
    params = params.set('emailSubject', emailSubject);
    params = params.set('emailBody', emailBody);

    let postBody = null;

    const options: Object = {
      params: params
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/createOrUpdateEmail', postBody, options);
  }

  searchAmongDeployedResources(subscriptionId: number, query: string): Observable<Object> {
    let postBody = {
      query: query,
      page: 1,
      isAdded: false
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/subscription/' + subscriptionId + '/configuration/findResource', postBody, options)
        .pipe(tap((response) => {
          if (response['data'] && response['data'].length > 0) {
            response['data'] = response['data'].map((rawResource) => {
              return new Resource(rawResource);
            });

          } else {
            response['data'] = [];
          }

          return response;
        }));
  }

  getParametersForChosenResource(subscriptionId: number, resourceId: number): Observable<Object> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<Response>(environment.api + '/subscription/' + subscriptionId + '/configuration/' + resourceId + '/getParameters', options)
      .pipe(tap((response) => {
        if (response['data']) {
          let resourceWithParams = {
            resourceId: response['data'].id,
            resourceName: response['data'].resourceName,
            parameters: []
          };

          if (response['data'].resourceParameters && response['data'].resourceParameters.length > 0) {
            resourceWithParams.parameters = response['data'].resourceParameters.map((parameter) => {
              return new ReportParameter(parameter);
            });
          }

          response['data'] = resourceWithParams;
        }

        return response;
      }));
  }

  addNewReportConfiguration(subscriptionId: number, resourceId: number, reportConfig): Observable<Object> {
    let postBody = {
      'name': reportConfig.configName,
      'parameterValues': []
    };

    postBody.parameterValues = reportConfig.parameters
      .map((parameter) => {
        return {
          'parameterId': parameter.parameterId,
          'parameterValue': parameter.parameterValue,
          'isDynamic': parameter.isDynamic
        };
      });

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/configuration/' + resourceId + '/add', postBody, options);
  }

  removeConfiguration(subscriptionId: number, resourceId: number, configurationId: number): Observable<Object> {
    let postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/configuration/' + resourceId + '/' + configurationId + '/remove', postBody, options);
  }

  removeAllConfigurationsForSpecificResource(subscriptionId: number, resourceId: number): Observable<Object> {
    let postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/configuration/' + resourceId + '/removeAll', postBody, options);
  }

  removeAllConfigurations(subscriptionId: number): Observable<Object> {
    let postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/configuration/removeAll', postBody, options);
  }

  addNewAssociation(subscriptionId: number, recipientGroupId: number, association): Observable<Object> {
    let postBody = {
      recipientGroupId: recipientGroupId,
      reportConfigurationId: association.reportConfigs.selectedConfig,
      keywordValues: []
    };

    postBody.keywordValues = association.recipientGroupKeywords
      .map((keyword) => {
        return {
          keywordName: keyword.value,
          value: keyword.model,
          isDynamic: keyword.isDynamic
        };
      });

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/reportConfigurationMembership/add', postBody, options);
  }

  removeAllAssociationsForSubscription(subscriptionId: number): Observable<Object> {
    let postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/reportConfigurationMembership/removeAll', postBody, options);
  }

  removeSpecificAssociation(subscriptionId: number, reportConfigurationId: number) {
    let postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/reportConfigurationMembership/' + reportConfigurationId + '/remove', postBody, options);
  }

  scheduleSubscription(subscriptionId: number): Observable<Object> {
    let postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/schedule', postBody, options);
  }

  getCsvUsersForAssociation(subscriptionId: number, associationId: number, query: string) {
    let params = new HttpParams();

    params = params.set('query', query);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get(environment.api + '/subscription/' + subscriptionId + '/reportConfigurationMembership/' + associationId + '/findMember', options);
  }

  testAssociation(subscriptionId: number, associationId: number, rowIndex: number): Observable<Object> {
    let postBody = null;
    let params = new HttpParams();

    params = params.set('rowIndex', rowIndex.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/reportConfigurationMembership/' + associationId + '/test', postBody, options);
  }

  getSubscription(subscriptionId: number) {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get(environment.api + '/subscription/' + subscriptionId, options);
  }

  updateReportConfiguration(subscriptionId: number, resourceId: number, reportConfig): Observable<Object> {
    let postBody = {
      'name': reportConfig.configName,
      'parameterValues': []
    };

    postBody.parameterValues = reportConfig.parameters
      .map((parameter) => {
        return {
          'parameterId': parameter.parameterId,
          'parameterValue': parameter.parameterValue,
          'isDynamic': parameter.isDynamic
        };
      });

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription/' + subscriptionId + '/configuration/' + resourceId + '/' + reportConfig.configId + '/update', postBody, options);
  }
}
