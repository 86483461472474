import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Alert } from '@edw-entities/alert';
import { AlertsService } from '@edw-services/alerts.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';


@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AlertsComponent implements OnInit, OnDestroy {
  //@Input() show: boolean;
  private alertsServiceSubscription: Subscription;
  private isUpdated: Subscription;
  msgs: Message[] = [];
  alerts: Alert[];
  displayDialog: boolean = false;
  alertsloaded: boolean = false;
  title: string;
  body: string;
  type: string;



  constructor(public alertsService: AlertsService) {}


  ngOnInit() {

    // this.alertsServiceSubscription = this.alertsService.getUserAlerts().subscribe((data) => {
    //   this.alerts = data['data'];
    //   console.log(this.alerts);
    //   this.alertsloaded = true;
    // });

    // this.isUpdated = this.alertsService.getObservable()
    // .subscribe((info) => {
    //   if (info.update === true) {
    //      this.alertsService.getUserAlerts().subscribe((data) => {
    //       this.alerts = data['data'];

    //       if(this.alerts.length > 0){
    //         this.alertsloaded = true;
    //         this.show = true;
    //       }
    //      });
    //     }
    //   }
    // );
  }

  showDialog(id, title, body, type) {
    this.title=title;
    this.body=body;
    this.type=type.toLowerCase();
    this.displayDialog = true;
}


  ngOnDestroy() {
    //this.alertsServiceSubscription.unsubscribe();
    //this.isUpdated.unsubscribe();
  }

}
