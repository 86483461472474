
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ResourceReference } from '@edw-entities/resource';
import { ResourceStatus } from '@edw-enums/resource-status';
import { ReportsService } from '@edw-services/reports.service';
import { SelectItem } from 'primeng-lts';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'edw-resource-listing',
  templateUrl: './resource-listing.component.html',
  styleUrls: ['./resource-listing.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ResourceListingComponent implements OnInit, OnDestroy {

  isLoading = false;
  selectedResourceType: string  ="";
  labelResource: string;
  masterResources: ResourceReference[] = [];
  allRowsSelected: boolean = false;
  query: string = "";
  dialogVisible: boolean = false;
  resourceStatus = ResourceStatus;
  permissions;
  title = "Manage Resources";
  resourceTypes: SelectItem[] = [
    { label: 'All Report Types', value: '' },
    { label: 'SSRS', value: 'SSRS' },
    { label: 'Tableau', value: 'TABLEAU' },
    { label: 'Power BI Report', value: 'POWER_BI_REPORT' }
  ];

  resources$ : BehaviorSubject<ResourceReference[]> = new BehaviorSubject([]);
  get resources() {return this.resources$.value; }
  set resources(res: ResourceReference[]){this.resources$.next(res);}


  subs: Subscription[] = [];

  @ViewChild('dataTable',{static:true}) dataTable;

  constructor(
    private resourceDataService: ReportsService
  ) { }

  ngOnInit() {
    this.subs.push(
      this.getResources().subscribe()
    );
  }

  ngOnDestroy(){ this.subs.map(sub => sub.unsubscribe() ); }

  getResources = ( query = "", page = 1, quickCall = false  ) => {
    this.isLoading = true;
    return this.resourceDataService.getAll(query, page, quickCall).pipe(
      map( response => {
        if (response['data'] && response['data'].length > 0) {
          this.masterResources= response['data'].map( element => new ResourceReference(element) );
          this.resources = this.masterResources;
        }
      }),
      tap(() => {this.isLoading = false}),
      catchError(err => {throw err;})
    )
  }

  clearSearch() {
    console.log("clear search");
    this.query = "";
    this.search();
  }

  private filterByString = (toQuery:string[], queryString: string) => {

    //console.log(toQuery, queryString);
    return toQuery.map( item => item?.includes(queryString ) ).filter( x => x ).length > 0;
  }

  search() {

    if(this.selectedResourceType == "" && this.query == ""){
      this.resources = [...this.masterResources];
      return
    }

    // evaluate query as uppercase...
    let _query = this.query.toUpperCase();

    if( this.selectedResourceType != "" ){

      //console.log("resource and query filter", this.selectedResourceType);

      this.resources = this.masterResources.filter(
        resource => resource.resourceType == this.selectedResourceType
      ).filter( resource =>{
          //console.log(resource.id);
          return _query == ""
            ? resource
            : this.filterByString([ resource?.path?.toUpperCase(), resource?.resourceName?.toUpperCase(), resource?.id.toString() ], _query)
        }
      );

      return;
    }
    //console.log("query filter");
    this.resources = this.masterResources.filter( resource =>
      this.filterByString([ resource?.path?.toUpperCase(), resource?.resourceName?.toUpperCase(), resource?.id.toString() ], _query)
    );

  }




}
