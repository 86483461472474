import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchParams } from '@edw-app-root/entities/searchParams';
import { ICohort } from '@edw-entities/cohort';
import { IRestResponse } from '@edw-entities/rest-response';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EDWHttpService } from './httpService/edw-http.service';


@Injectable()
export class CohortsDataService {
  private endpoint: string;

  constructor(
    private http: HttpClient,
    private httpService : EDWHttpService
  ) {
    this.endpoint = environment.api;
  }

  getAllCohorts(query: string = '', id: string = '', page: number = 1, onlyActive: boolean = true): Observable<IRestResponse<ICohort[]>> {
    let params = new HttpParams();
    params = params.set('query', query || '');
    params = params.set('id', id || '');
    params = params.set('page', page.toString());
    params = params.set('onlyActive', onlyActive.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<IRestResponse<ICohort[]>>(`${this.endpoint}/cohort/getAll`, options);
  }

  getAllUnapprovedCohorts(query: string = '', id: string = '', page: number = 1): Observable<IRestResponse<ICohort[]>> {
    let params = new HttpParams();
    params = params.set('query', query || '');
    params = params.set('id', id || '');
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<IRestResponse<ICohort[]>>(`${this.endpoint}/cohort/getAllUnapproved`, options);
  }
  getCohortsDetails(cohortId: string): Observable<IRestResponse<ICohort>> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<IRestResponse<ICohort>>(`${this.endpoint}/cohort/${cohortId}/getDetails`, options);
  }

  updateCohortStatus(cohortId: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/cohort/${cohortId}/updateCohortStatus`, {}, options);
  }

  approve(cohortId: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/cohort/${cohortId}/approve`, {}, options);
  }

  manualRefreshCohort(cohortId: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post(`${this.endpoint}/cohort/${cohortId}/refresh`, {}, options);
  }

  saveCohort(data: Partial<ICohort>): any {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post(`${this.endpoint}/cohort/${data.id}/update`, data, options);
  }

  createCohort(data: Partial<ICohort>) {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/cohort/add`, data, options);
  }


  addResourceToCohort = (cohortId: string, resources: number[]): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/cohort/${cohortId}/report/add`, resources, options);
  }

  removeResourceFromCohort = (cohortId: string, resourceId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/cohort/${cohortId}/report/remove`, [parseInt(resourceId, 10)], options);
  }

  findAddedResource = (cohortId: string, searchParams: SearchParams): Observable<any> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post(`${this.endpoint}/cohort/${cohortId}/report/get`, postBody, options);
  }

  findNonAddedResource = (cohortId: string, query: string): Observable<any> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post<Response>(`${this.endpoint}/cohort/${cohortId}/report/get`, postBody, options)
      .pipe(
        map((response) => {
          response['data'] = response['data'].map(element => {
            element['displayName'] = '(' + element.id + ') ' + element.resourceName;
            return element;
          });
          return response;
        })
      );
  }


  addUserToCohort = (cohortId: string, users: number[]): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/cohort/${cohortId}/user/add`, users, options).pipe(
      catchError((err)=>{
        console.error(err);
        throw err;
        //return of({data:null})
      })
    );
  }

  removeUserFromCohort = (cohortId: string, userId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/cohort/${cohortId}/user/remove`, parseInt(userId, 10), options);
  }

  findAddedUser = (cohortId: string, searchParams: SearchParams): Observable<any> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post(`${this.endpoint}/cohort/${cohortId}/user/get`, postBody, options);
  }

  findNonAddedUser = (cohortId: string, query: string): Observable<any> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post<Response>(`${this.endpoint}/cohort/${cohortId}/user/get`, postBody, options)
      .pipe(
        map((response) => {
          response['data'] = response['data'].map(element => {
            element['displayName'] = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';
            return element;
          });
          return response;
        })
      );
  }

  getCohortByReportId = (reportId) => {

    return this.httpService.httpGet(`cohort/getCohortByReportId?reportId=${reportId}`,null, null, 'response').pipe(
      map((res)=>  ({data: res?.['body'] ?? null})),
      catchError( (e : Error) =>{
        console.error(e);
        return of({data:null});
      } )
    );
  }
}
