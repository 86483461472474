import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageRouteGuard } from '@edw-app-root/guards/manage.guard';
import { ResourcesRouteGuard } from '@edw-app-root/guards/resources.guard';
import { AppWrapperComponent } from './app-wrapper/app-wrapper.component';
import { AppResolver } from './app.resolver';
import { LoginComponent } from './login/login.component';
import { notificationsRoutes } from './notifications/notifications.module';
import { userSettingsRoutes } from './user-settings/user-settings.module';

// import { manageRoutes } from './manage/manage.module';
// import { reportsListingRoutes } from './resources/resources.module';

const appRoutes: Routes = [
  {
    path: 'app',
    component: AppWrapperComponent,
    // TODO: IMPORTANT!! This should be
    // uncommented when the redirect URI is
    // switched to /app/auth!!!
    // canActivate: [ AppGuard ],
    resolve: {
      data: AppResolver
    },
    // All new routes which concern the features
    // when the user has already been authenticated
    // should be placed in this array
    children: [
      // reportsListingRoutes,
      {
        path: 'resources',
        canActivate: [ResourcesRouteGuard],
        loadChildren: './resources/resources.module#ResourcesModule'
      },
      {
        path: 'resources/favorite',
        redirectTo: 'resources/reports/favorite',
        pathMatch: 'full'
      },
      {
        path: 'resources/collections',
        redirectTo: 'resources/favorites/collections',
        pathMatch: 'full'
      },
      {
        path: 'resources/all',
        redirectTo: 'resources/reports/all',
        pathMatch: 'full'
      },
      {
        path: 'resources/:id',
        redirectTo: 'resources/reports/:id',
        pathMatch: 'full'
      },
      {
        path: 'manage',
        canActivate: [ManageRouteGuard],
        loadChildren: './manage/manage.module#ManageModule'
      },
      {
        path: 'non-prod-tests',
        loadChildren: './non-prod-tests/non-prod-test.module#NonProdTestModule'
      },
      // manageRoutes,
      notificationsRoutes,
      userSettingsRoutes
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/auth',
    pathMatch: 'full'
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      // enableTracing: !environment.production,
      // preloadingStrategy: PreloadAllModules
    })
  ],
  providers: [
    AppResolver,
    // AppGuard
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
