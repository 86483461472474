
import { Injectable } from '@angular/core';

export class EDWModal{
  public showModal: boolean = false;
  public title: string = "**";
  public body: string = "***";
  public footer: string = "";
  constructor(){}

  /** Updates the EDWModal class state.
   * @param  {Array<any>} state - [showModel: bool, title: string, body: string]
   * @todo add footer param
   * @returns EDWModal
   */
  updateState = (state: Array<any>): EDWModal => {
    return Object.assign({}, this, { showModal: state[0], title: state[1], body: state[2] } )
  }

}

@Injectable()
export class EDWModalService{

  initModal(){ return new EDWModal(); }

  /** Updates the EDWModal class state.
   * @param  {Array<any>} state - [showModel: bool, title: string, body: string]
   * @todo add footer param
   * @returns EDWModal
   */
  updateModal = (modal, state: Array<any>): EDWModal => {
    return Object.assign({}, modal, { showModal: state[0], title: state[1], body: state[2] } )
  }

}


// @Component({
//   selector:'edw-resource-modal',
//   template:
//   `
//     <p-dialog [(visible)]="showModal">
//       <p-header>title</p-header>
//         body
//       <p-footer>footer</p-footer>
//     </p-dialog>
//   `
// })
// export class EDWModalComponent implements OnInit{

//   @Input() showModal = false;
//   @Input() modal;
//   visible = true;
//   constructor(private modalService: EDWModalService){}
//   ngOnInit(){
//     //const modal = this;
//     this.modalService.add(new EDWModal());
//   }

//   open(modalDataArray){
//     console.log("mc open", modalDataArray);
//     this.modalService.open(modalDataArray)
//     //this.showModal = true;
//   }

//   close(){
//     this.showModal = false;
//   }
// }
