import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchParams } from '@edw-app-root/entities/searchParams';
import { IPermission } from '@edw-entities/permission';
import { IPermissionGroup } from '@edw-entities/permission-group';
import { IRestResponse } from '@edw-entities/rest-response';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class PermissionGroupDataService {
  private endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
  }

  getAllPermissionGroups(query: string, page: number = 1): Observable<IRestResponse<IPermissionGroup[]>> {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<IRestResponse<IPermissionGroup[]>>(`${this.endpoint}/permissionGroup/getAll`, options);
  }

  getPermissionGroupDetails(groupId: string): Observable<IRestResponse<IPermissionGroup>> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<IRestResponse<IPermissionGroup>>(`${this.endpoint}/permissionGroup/${groupId}/getDetails`, options);
  }

  getAllPermissions(): Observable<IRestResponse<IPermission[]>> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<IRestResponse<IPermission[]>>(`${this.endpoint}/permissionType/getAll`, options);
  }

  addUserToGroup = (groupId: string, users: number[]): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/permissionGroup/${groupId}/user/add`, users, options);
  }

  removeUserFromGroup = (groupId: string, userId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/permissionGroup/${groupId}/user/remove`, parseInt(userId, 10), options);
  }

  removeUsersFromGroup = (groupId: string, users: number[]): Observable<any> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/permissionGroup/${groupId}/user/remove`, users, options);
  }

  removeGroup(groupId: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/permissionGroup/${groupId}/remove`, {}, options);
  }

  saveGroup(data: Partial<IPermissionGroup>): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post(`${this.endpoint}/permissionGroup/${data.id}/update`, data, options);
  }

  createGroup(data: Partial<IPermissionGroup>) {
    const options: Object = {
      observe: 'body'
    };
    return this.http.post(`${this.endpoint}/permissionGroup/add`, data, options);
  }


  findAddedUser = (groupId: string, searchParams: SearchParams): Observable<any> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post(`${this.endpoint}/permissionGroup/${groupId}/user/get`, postBody, options);
  }

  findNonAddedUser = (groupId: string, query: string): Observable<any> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http.post<Response>(`${this.endpoint}/permissionGroup/${groupId}/user/get`, postBody, options)
    .pipe(
      map((response) => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';
          return element;
        });
        return response;
      })
    );
  }

}
