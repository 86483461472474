import { Injectable } from '@angular/core';
import {
  Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';

import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { UserDataService } from '@edw-services/user-data.service';
import { UserGroupDataService } from '@edw-services/user-group.service';
import { ExtractDataService } from '@edw-services/extract.service';
import { ExtractSetupDataService } from '@edw-services/extract-setup.service';
import { ExtractConfigDataService } from '@edw-services/extract-config.service';
import { Permissions } from '@edw-app-root/permissions';

@Injectable()
export class ManageExtractRoutingResolver implements Resolve<any> {

  constructor(private userGroupDataService: UserGroupDataService,
    private router: Router,
    private userDataService: UserDataService,
    private extractDataService: ExtractDataService,
    private extractSetupDataService: ExtractSetupDataService,
    private extractConfigDataService: ExtractConfigDataService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];

    let resolverSubject = new Subject<any>();
    let resolverObservable = resolverSubject.asObservable();

    let permissions = {
      canView: this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canView),
      canCreate: this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canCreate),
      canRemove: this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canRemove),
      canModify: this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canModify),
      canApprove: this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canApprove)
    };

    let componentData = {
      title: 'Extracts',
      permissions: permissions
    };

    if (lastParam === 'extracts') {
      return of(componentData);
    }

    //#region Extract Setup List
    if (urlFragments.indexOf('extract-setup-list') > -1) {
      // '/extract-setup-list'
      if (lastParam === 'extract-setup-list') {
        return this.extractSetupDataService.getExtractSetupList('')
          .pipe(map(responseBody => {
            componentData['extracts'] = responseBody['data'];
            return componentData;
          }));
      }

      // '/extract-setup-list/add'
      if (urlFragments.indexOf('add') > -1) {
        componentData.title = 'Add New Extract';
        return of(componentData);
      }

      // '/extract-setup-list/{id}'
      if (!isNaN(parseInt(lastParam)) && parseInt(lastParam) > 0) {
        return this.extractSetupDataService
          .getExtractSetup(lastParam)
          .pipe(map(responseBody => {
            if (responseBody['data']) {
              componentData.title = "Edit Extract";
              componentData['newExtracts'] = responseBody['data'];
              componentData['newExtracts']['extract_output_configuration'].forEach((outputConfig) => {
                if (outputConfig.output_filename_key > 0) {
                  outputConfig.isDynamic = true;
                } else {
                  outputConfig.isDynamic = false;
                }
              });
            }
            return componentData;
          }));
      }
    }
    //#endregion Extract Setup List

    //#region Extract Config List
    if (urlFragments.indexOf('extract-config-list') > -1) {
      // '/extract-config-list'
      if (lastParam === 'extract-config-list') {
        return this.extractConfigDataService.getExtractConfigList('')
        .pipe(map(responseBody => {
            componentData['extracts'] = responseBody['data'];
            return componentData;
          }));
      }

      // '/extract-config-list/add'
      if (urlFragments.indexOf('add') > -1) {
        componentData.title = 'Add New Extract Configuration';
        return of(componentData);
      }

      // '/extract-config-list/{id}'
      if (!isNaN(parseInt(lastParam)) && parseInt(lastParam) > 0) {
        return this.extractConfigDataService
          .getExtractConfig(lastParam.toString())
          .pipe(map(responseBody => {
            if (responseBody['data']) {
              componentData.title = 'Edit Extract Configuration';
              componentData['extractConfig'] = responseBody['data'];
            }
            return componentData;
          }));
      }
    }
    //#endregion Extract Config List

    if (urlFragments.indexOf('schedule') > -1) {
      return this.extractDataService.getScheduleExtract('')
        .pipe(
         map(responseBody => {
          componentData['extracts'] = responseBody['data'];
          return componentData;
        }));
    }

    if (urlFragments.indexOf('adhoc') > -1) {
      return this.extractDataService.getAdhocExtract('')
      .pipe(
        map(responseBody => {
          componentData['extracts'] = responseBody['data'];
          return componentData;
        }));
    }

    if (urlFragments.indexOf('approvals') > -1) {
      return this.extractDataService.getPendingApprovals('')
      .pipe(
        map(responseBody => {
          componentData['extracts'] = responseBody['data'];
          return componentData;
        }));
    }

    if (urlFragments.indexOf('execution-log') > -1) {
      return this.extractDataService.getExecutionLog('')
      .pipe(
        map(responseBody => {
          componentData['executionlog'] = responseBody['data'];
          return componentData;
        }));
    }

    if (urlFragments.indexOf('notification-log') > -1) {
      return this.extractDataService.getNotificationLog('')
      .pipe(
        map(responseBody => {
          componentData['notificationlog'] = responseBody['data'];
          return componentData;
        }));
    }

    if (urlFragments.indexOf('runadhoc') > -1) {
      if (lastParam === 'runadhoc') {
        return this.extractConfigDataService.getExtractConfigList('')
        .pipe(
          map(responseBody => {
            componentData['extracts'] = responseBody['data'];
            return componentData;
          }));
      }

      if (!isNaN(parseInt(lastParam)) && parseInt(lastParam) > 0) {
        return this.extractDataService
          .getExtractParameter(lastParam.toString())
          .pipe(
            map(responseBody => {
            if (responseBody['data']) {
              componentData['adhoc_extract_parameter'] = responseBody['data'];
              componentData['extract_config_key'] = lastParam;
            }
            return componentData;
          }));
      }
    }
  }
}
