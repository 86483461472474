import { Directive, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[edwExtractSubstringsMatchingRegex]',
  host: {
    '(blur)': 'checkIfMatches()'
  }
})
export class ExtractSubstringsMatchingRegex {
  constructor() { }

  /**
   * @param edwExtractSubstringsMatchingRegex - the source of the string
   * which will be matched against @param checkAgainst
  **/
  @Input('edwExtractSubstringsMatchingRegex') model;


  /**
   * @param checkAgainst- the regex which will be used to extract
   *  substrings from @param edwExtractSubstringsMatchingRegex
  **/
  @Input() checkAgainst: RegExp;


   /**
   * @event onCheckIfMatches - raised on 'blur' event of the input;
   * returns an array with the substrings matching the regex;
   * if there are no matches, returns an empry array
  **/
  @Output() onCheckIfMatches = new EventEmitter<Object>();

  checkIfMatches() {
    if (this.model !== '') {
      let newArr = this.model.match(this.checkAgainst);
      let result = {
        extractedKeywords: newArr || []
      };

      this.onCheckIfMatches.emit(result);
    }
  }

}
