import { Pipe, PipeTransform } from '@angular/core';

/*
  Custom pipe that truncates text.
  In view it can be accessed lke so:
  {{ str | truncate:[20] }} // if you want only truncation to 20 chars
  {{ str | truncate:[20, '...'] }} // truncates 20 chars, followed by the string, in this case '...'

  https://stackoverflow.com/questions/44669340/how-to-truncate-text-in-angular2/44669515
*/

@Pipe({
 name: 'truncate'
})

export class TruncatePipe implements PipeTransform {

transform(value: string, args: string[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
   }
}

import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class UtilService {
  constructor() { }
  public isNullOrEmpty = ( value : string ) => [ '', null, undefined ].includes(value);
}
