export class MetadataToFormFieldsTransformer {
  formFieldsArr = [];

  constructor(metadataArr = []) {
    if (metadataArr.length > 0) {
      metadataArr.forEach(field => {
        this.formFieldsArr.push({
          id: field.id,
          modelName: this.modelNameGenerator(field.name),
          label: field.name,
          value: field.detail || '',
          isEditable: field.isEditable,
          isRequired: field.isRequired,
          inputType:field.inputType?field.inputType:'',
          maxLength:field.inputLength?field.inputLength:'',
          tempvalue:'',
          isMandatory:field.isRequired,
          touched:''
        });
      });
    }
  }

  getFields() {
    return this.formFieldsArr;
  }

  private modelNameGenerator(fieldName): string {
    let modelNameSplit = fieldName.split(' ');
    modelNameSplit[0] = modelNameSplit[0].toLowerCase();
    return modelNameSplit.join('');
  }
}
