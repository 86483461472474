import { Injectable } from '@angular/core';
import { CollectionUpdateObject, Collection, CollectionData, CollectionType, CollectionStore } from '../entities/CollectionDataClasses';
import { UserCollectionService } from '@edw-services/user-collection.service';
import { ResourceService, CollectionService, CollectionDataService } from '../../components/services/resource.service';

@Injectable()
export class UserCollectionDataService{

  store:any;

  constructor(
    private userCollectionService: UserCollectionService,
    private collectionService: CollectionService,
    private collectionDataService: CollectionDataService,
  ){
    // this.collectionService.CollectionStore.subscribe(data => console.log(data) );

  }


  updateCollectionData(updateObj: CollectionUpdateObject){
    const collection: Collection = this.editCollectionDataSelection(updateObj) || null;
    if(collection != null){
    this.userCollectionService.updateCollectionResources(collection)
      .subscribe(
        (res)=>{ this.collectionService.updateCSCollections(res.data, collection.id) },
        (err)=>{ console.log(err) }
      )
      this.collectionDataService.setCollectionData([collection]);
    }
  }


  private editCollectionDataSelection(obj: CollectionUpdateObject): Collection{
    // const collection = obj.collection[obj.collectionType] || null;
    // if( collection == null ) throw Error('Unable to find collection.');
    const type = obj.collectionType;
    // const ind = obj.collection[type].indexOf(obj.resource.id);
    obj.collection[type] = ( obj.collection[type].includes(obj.resource.id) )
      ? obj.collection[type].filter(resource => resource != obj.resource.id )
      : [obj.resource.id].concat(obj.collection[type]);

    return obj.collection;
  }
}
