import { faCoffee } from '@fortawesome/free-solid-svg-icons';

import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class FontAwesomeService {

  fa = {
    faCoffee: faCoffee
  };

  constructor() { }


}
