import { NotificationSubjectType, NotificationActionPrompt, NotificationStatus } from '../../app/notifications/notification-constants';
import * as moment from 'moment';

export class AppNotification {
  id: number;
  message: string = '';
  action;
  dateTime;
  removedFromUnread: boolean = false;

  constructor(rawNotification) {
    this.id = rawNotification['id'];
    this.message = this.constructNotificationMessage(rawNotification);
    this.dateTime = this.populateDateTimeObject(rawNotification);
    this.action = this.determineAction(rawNotification);

    if (rawNotification['statusId'] === NotificationStatus.Read) {
      this.removedFromUnread = true;
    }
  }

  private constructNotificationMessage(rawNotification): string {
    let message = rawNotification['message'];
    let messageSubjectsCount = rawNotification['subjects'].length;

    for (let i = 0; i <= messageSubjectsCount - 1; i++) {
      let subject = rawNotification['subjects']
        .filter((subject) => {
          return subject['placeHolderIndex'] === i;
        })[0];

      message = message.replace('{' + i + '}', '<span class="subject">' + subject['value'] + '</span>');
    }

    return message;
  }

  private populateDateTimeObject(rawNotification): Object {
    let dateTime = {
      full: '',
      time: '',
      date: ''
    };

    dateTime.full = moment(rawNotification['createdDate']).format('MM/DD/YYYY, hh:mm A');
    dateTime.time = moment(rawNotification['createdDate']).format('hh:mm A');
    dateTime.date = moment(rawNotification['createdDate']).format('MM/DD/YYYY');

    return dateTime;
  }

  private determineAction(rawNotification) {
    let redirectTo = '/app/manage/';
    let action = {
      link: '',
      routerLink: []
    };

    if (rawNotification['subjects'].length > 0) {
      let subjectId = rawNotification['subjects'][0]['subjectId'];
      let actionType = rawNotification['subjects'][0]['actionId'];

      switch (actionType) {
        case NotificationActionPrompt.NavigateTo.DetailsPage: //deployed resource
          redirectTo = redirectTo + 'resources/deployed';
          action.routerLink = [redirectTo, subjectId];
          action.link = window.location.origin + '/#' + redirectTo + '/' + subjectId;
          break;
        case NotificationActionPrompt.NavigateTo.PendingApprovals: //pending approval
          redirectTo = redirectTo + 'requests';
          action.routerLink = [redirectTo];
          action.link = window.location.origin + '/#' + redirectTo;
          break;
        case NotificationActionPrompt.NavigateTo.StagingDetailsPage: //pending approval
          redirectTo = redirectTo + 'resources/staging';
          action.routerLink = [redirectTo, subjectId];
          action.link = window.location.origin + '/#' + redirectTo + '/' + subjectId;
          break;
        case NotificationActionPrompt.NavigateTo.NavigateToDataStewardApproval: //data steward approval 
          redirectTo = redirectTo + 'data-steward-queue';
          action.routerLink = [redirectTo, subjectId];
          action.link = window.location.origin + '/#' + redirectTo + '/' + subjectId;
        default: ;
      }
    }

    return action;
  }
}
