import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';

@Component({
  selector: 'edw-update-redirect',
  templateUrl: './update-redirect.component.html',
  styleUrls: ['./update-redirect.component.scss']
})
export class UpdateRedirectComponent implements OnInit, OnDestroy {
  //This component exists to force-reload
  //the /subscriptions/:id component, since Angular
  //doesn't still offer any type of current page reload.
  //This component also acts as a manual loader
  //which gets triggered instead of the regular one.
  //The trigger is an item in sessionStorage called "manualLoader"
  //-if it's been set to true, then the report-config and recipient-config
  //components don't call the actual loader.
  constructor(private route: ActivatedRoute,
              private sessionStorageService: SessionStorageService,
              private loader: LoaderService,
              private router: Router) {
  }

  @ViewChild('loader',{static:true}) loaderElement;

  ngOnInit() {
    this.loaderElement.nativeElement.classList.add('fade-in');

    this.sessionStorageService.add('subscriptionTabId', parseInt(this.route.snapshot.queryParams.tab));
    this.router.navigate(['/app/manage/subscriptions', this.route.snapshot.params.id]);
  }

  ngOnDestroy() {
    this.loaderElement.nativeElement.classList.remove('fade-in');
  }

}
