import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EDWSharedModule } from '@edw-app-root/shared/shared.module';
import { ResourcesModule } from '../../resources/resources.module';
import { ManageProductPagesDetailComponent } from './product-pages-detail/product-pages-detail.component';

import { ProductPagesRoutingResolver } from './product-pages.resolver';

import { ManageSecurityService } from '@edw-app-root/shared/manage.security/manage.security.component';

import {
    AccordionModule,
    AutoCompleteModule,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    DialogModule,
    DropdownModule,
    InputMaskModule,
    MessagesModule,
    PaginatorModule,
    SharedModule,
    SplitButtonModule,
    TabMenuModule,
    TabViewModule,
    TableModule,
    ToastModule
} from 'primeng-lts';

@NgModule({
  declarations: [ ManageProductPagesDetailComponent ],
  imports: [
    RouterModule,
    CommonModule,
    ResourcesModule,
    AccordionModule,
    AutoCompleteModule,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    TableModule,
    DialogModule,
    DropdownModule,
    ToastModule,
    InputMaskModule,
    MessagesModule,
    SharedModule,
    TabMenuModule,
    TabViewModule,
    PaginatorModule,
    SplitButtonModule,
    EDWSharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:
  [
    // OrderBySelected
  ],
  providers: [
    ProductPagesRoutingResolver,
    ManageSecurityService
  ],
})
export class ManageProductPagesModule { }
