export interface IResource {
  id: number;
  resourceName: string;
  path: string;
  resourceType: string;
  resourceStatus: string;
  resourceStatusId :number;
  externalId : string;
  ratingOptout : string;
  calulatedRatingValue : number;
  calculatedRatingCount : number;
  modifyByUserKey : number;
  modifyUserName : string;
  workspaceId : string;
  modifyDate : Date;
  metadata : IResourceMetadata[]
}


export class ResourceReference{
  public id: number;
  public resourceName?: string;
  public path?: string;
  public resourceType?: string;
  public resourceStatus?: string;
  constructor(resource: IResource){
    this.id = resource.id;
    this.resourceName = resource.resourceName;
    this.path = resource.path;
    this.resourceType = resource.resourceType;
    this.resourceStatus = resource.resourceStatus
  }
}

export class Resource {
  public id?: number;
  public resourceName?: string;
  public path?: string;
  public resourceType?: string;
  public resourceStatus?: string;
  public resourceStatusId? :number;
  public externalId? : string;
  public ratingOptout? : string;
  public calulatedRatingValue? : number;
  public calculatedRatingCount? : number;
  public modifyByUserKey? : number;
  public modifyUserName? : string;
  public workspaceId? : string;
  public modifyDate? : Date;
  public metadata? : ResourceMetadata[];
  constructor(resource:IResource) {
    this.id = resource.id;
    this.resourceName = resource.resourceName;
    this.path = resource.path;
    this.resourceType = resource.resourceType;
    this.resourceStatus = resource.resourceStatus;
    this.resourceStatusId = resource.resourceStatusId;
    this.externalId = resource.externalId;
    this.ratingOptout = resource.ratingOptout;
    this.calulatedRatingValue = resource.calulatedRatingValue;
    this.calculatedRatingCount = resource.calculatedRatingCount;
    this.modifyByUserKey = resource.modifyByUserKey;
    this.modifyUserName = resource.modifyUserName;
    this.workspaceId = resource.workspaceId;
    this.modifyDate = resource.modifyDate;
    this.metadata = resource.metadata;

  }
}




export interface IResourceMetadata{
  id : number;
  name : string;
  detail : string;
  isEditable : boolean;
  isRequired : boolean;
  inputType : string;
  inputLength : number;
}

export class ResourceMetadata{
  constructor(
    public id : number,
    public name : string,
    public detail : string,
    public isEditable : boolean,
    public isRequired : boolean,
    public inputType : string,
    public inputLength : number
  ){}
}


export class ResourceType {
  id: number;
  name: string;

  constructor(resourceType) {
    this.id = resourceType.id;
    this.name = resourceType.name;
  }
}

export interface IResourceType {
  id: number;
  name: string;
}


export class MetadataElement {
  id: number;
  metadataDisplayName: string;
  metadataName: string;
  isConsumerVisableFlag: boolean | undefined | null;
  isAdminVisableFlag: boolean;
  isEditableFlag: boolean;
  isRequiredFlag: boolean;
  resourceTypeKey: boolean;
  inputType: string;
  inputLength: number | undefined | null;
  resourceMetadataElementOptionsDTOs: MetadataElementOption[];

  constructor(metadataElement) {
    this.id = metadataElement.id;
    this.metadataDisplayName = metadataElement.metadataDisplayName;
    this.metadataName = metadataElement.metadataName;
    this.isConsumerVisableFlag = metadataElement.isConsumerVisableFlag;
    this.isAdminVisableFlag = metadataElement.isAdminVisableFlag;
    this.isEditableFlag = metadataElement.isEditableFlag;
    this.isRequiredFlag = metadataElement.isRequiredFlag;
    this.resourceTypeKey = metadataElement.resourceTypeKey;
    this.inputType = this.inputType;
    this.inputLength = this.inputLength;
    this.resourceMetadataElementOptionsDTOs = metadataElement.resourceMetadataElementOptionsDTOs;
  }

}

export interface IMetadataElement {
  id: number;
  metadataDisplayName: string;
  metadataName: string;
  isConsumerVisableFlag: boolean | undefined | null;
  isAdminVisableFlag: boolean;
  isEditableFlag: boolean;
  isRequiredFlag: boolean;
  resourceTypeKey: boolean;
  inputType: string;
  inputLength: number | undefined | null;
  resourceMetadataElementOptionsDTOs: MetadataElementOption[];

}

export class MetadataElementOption {
  id: number;
  metadataElementKey: number;
  value: string;

  constructor(metadataElementOption) {
    this.id = metadataElementOption.id;
    this.metadataElementKey = metadataElementOption.metadataElementKey;
    this.value = metadataElementOption.value;
  }
}

export interface IMetadataElementOption {
  id: number;
  metadataElementKey: number;
  value: string;
}

export enum resourceTypeEnum {
  SSRS = 1,
  TABLEAU = 2,
  POWER_BI_REPORT = 5
}

export enum resourceStatusEnum {
  Staging = 1,
  UnderReview = 2,
  Deployed = 3,
  Archived = 4
}
