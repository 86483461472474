import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IExtractForUser } from '@edw-entities/user-extracts';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterInputService } from '../../../components/filter-input/filter-input.component';
import { FilterPipe, SortAlpha } from '../../../components/resource.filter';
import { CollectionDataService, CollectionService, ResourceService } from '../../../components/services/resource.service';
import { Collection, CollectionData, CollectionType, CollectionUpdateObject } from '../../entities/CollectionDataClasses';
import { UserCollectionDataService } from '../../services/user-collection-data.service';

@Component({
  selector: 'edw-extract-list',
  templateUrl: './extract-list.component.html',
  styleUrls: ['../../tileAndListStyles.scss', 'extract-list.component.scss'],
  providers:[FilterPipe, SortAlpha]
})

export class ExtractListComponent implements OnInit, OnDestroy {
  @Input() layoutTile: boolean;
  @Input() collectionEdit: boolean;
  @Input() favoriteEdit = false;

  subs : Subscription[];
  collectionData: CollectionData;
  collection: Collection[];
  extracts: IExtractForUser[];
  pager = { start: 0, end: 12 }
  showSelected = false;
  filterText = "";

  tileView = {
    baseCnt : 12,
    i: 1,
    filterKey: '',
    items : [],
    viewMore : ( list: any[] ) => {
      this.tileView.i+=1;
      this.tileView.filterItems(list);
    },
    setItems: ( list: any[], filterKey ) => {
      this.tileView.i = 1;
      this.tileView.filterKey = filterKey;
      this.tileView.filterItems(list);
    },
    filterItems: (list : any[]) =>{
      list = this.sortAlpha.transform( list, this.tileView.filterKey );
      this.tileView.items = list.slice( 0, this.tileView.baseCnt * this.tileView.i);
    }
  }

  constructor(
    private collectionService: CollectionService,
    private collectionDataService: CollectionDataService,
    private resourceService: ResourceService,
    private userCollectionDataService: UserCollectionDataService,
    private filterInputService:FilterInputService,
    private filterPipe: FilterPipe,
    private sortAlpha : SortAlpha
  ) {}

  ngOnInit() {

    this.subs = [
      this.collectionDataService.CollectionData.pipe(map(
        collection => {
          this.collectionData = collection;
          this.collection = collection.Collection;
          this.extracts = collection.Extracts;
          this.showSelected = false; // reset selected filter.
          this.tileView.setItems(this.extracts, 'name');
        }
      )).subscribe(),

      this.filterInputService.query.pipe(map(
        filter => {
          if(filter != null){
            this.extracts = ( filter.length > 0 )
              ? this.filterPipe.transform(this.collectionData.Extracts, filter, ['name'])
              : this.collectionData.Extracts;

            this.tileView.setItems( this.extracts, 'name' );
          }
        }
      )).subscribe()
    ];
  }

  ngOnDestroy(){
    this.filterInputService.clearQuery();
    this.subs.map( sub => sub.unsubscribe());
  }

  toggleShowSelected(showFavorties: boolean = false){

    /* toggles showSelected flag */
    this.showSelected = !this.showSelected;

    /* if showSelected is true... filter reports*/
    if( this.showSelected ){
      /* filter by favorites or collection inclusion, based on showFavorites bool param */
      this.extracts = ( showFavorties )
        ? this.extracts.filter(extract => extract.isFavorite ) // favorites
        : this.extracts.filter(extract => this.collection[0].extracts.includes(+extract.id)) // collection
      return false;
    }
    /*if showSelected is false, put all reports back in scope.*/
    this.extracts = this.collectionData.Extracts;
  }


  public editCollectionData(resource){
    this.userCollectionDataService.updateCollectionData( new CollectionUpdateObject( 'extracts', resource, this.collection[0] ) );
  }

  public editFavorites(extract:IExtractForUser){
    this.collectionService.saveFavorite({resource: extract, collectionType: CollectionType.extracts});
  }

  log(val){
  //  console.log(val);
  }

  addInlineStyle(val){
    return this.resourceService.addInlineStyle(val);
  }

  updatePager(event){
    this.pager.start = event.first;
    this.pager.end = event.first + event.rows;
  }



}
