import { ResourceGroup } from '@edw-entities/resource-group';
import { User } from '@edw-entities/user';
import { IResource } from './resource';

// export class IResource{
//   externalId: string;
//   id: number;
//   path: string;
//   resourceName: string;
//   resourceStatus: string;
//   resourceStatusId: number;
//   resourceType: string;
// }


export class Product {
  active: string = 'Y';
  createByUserKey: number = 0 ;
  createByUserName: string;
  createDate: Date|string = new Date();
  description: string;
  id: number = 0;
  modifyByUserKey: number= 0;
  modifyUserName: string;
  modifyDate: Date|string = new Date();
  name: string;
  owner: string;
  ownerDetails: User;
  ownerName: string = '';
  pageId: number = 0;
  reportId: string;
  resource: IResource;
  reportName:string = '';
  resourceGroups: ResourceGroup[];
  version: number = 1;
  team: string = "";
  teamId: string = "";

  constructor(product? : Product) {
    if(!!product){
      this.active = product.active;
      this.createByUserKey = product.createByUserKey;
      this.createByUserName = product.createByUserName;
      this.createDate = ( !!product.createDate ) ? product.createDate : null;
      this.description = product.description;
      this.id = ( product.id > 0) ? product.id : 0;
      this.modifyByUserKey = product.modifyByUserKey;
      this.modifyUserName = product.modifyUserName;
      this.modifyDate = ( !!product.modifyDate ) ? product.modifyDate : null;
      this.name = product.name;
      this.owner = product.owner;
      this.ownerDetails = product.ownerDetails;
      this.reportId = product.reportId;
      this.resource = product.resource;
      this.pageId = product.pageId;
      this.resourceGroups = product.resourceGroups;
      this.version = product.version;
      this.team = product.team || "";
      this.teamId = product.teamId || "";
    }
  }
}

export interface IProduct {
  id?: number;
  name: string;
  description: string;
  owner?: number;
  reportId?: number;
  pageId?: number;
  createbyuserkey?: number;
  createdate?: boolean;
  modifybyuserkey?: number;
  modifydate?: boolean;
  version?: number;
  active?: boolean;
  ownerDetails?: User;
  resource:IResource;
}


export class ProductViewModel{
  id:number;
  reportId:number;
  pageId:number;
  name:string;
  description:string;
  ownerName:string;
  reportName:string;
  constructor(product:IProduct){
    this.id = product.id;
    this.reportId = product.reportId;
    this.pageId = product.pageId;
    this.name = product.name;
    this.description = product.description;
    this.ownerName = ( !!product.ownerDetails ) ? `${ product.ownerDetails.firstName } ${ product.ownerDetails.lastName }` : '-';
    this.reportName = ( !!product.resource ) ?`${ product.resource.resourceName }` : '-';
  }
}
