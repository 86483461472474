import { Component, OnInit, OnDestroy, Input, Injectable } from '@angular/core';
import { RelatedEntityServices } from '@edw-app-root/entities/related-entity-service.interface';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IResourceGroup } from '@edw-app-root/entities/resource-group';
//import { IResourceGroup } from '@edw-app-root/entities/resource-group';

export class EntityCollection{
  displayName: string;
  id: number;
  link:string[];
  constructor(name, id, link){
    this.displayName = name;
    this.id = id;
    this.link = [link, id];
  }
}

export class SecurityUser extends EntityCollection{
  email: string;
  firstName: string;
  lastName:string;
  constructor(name, id, link, email, firstName, lastName){
    super(name,id,link);
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}

export class Group extends EntityCollection{
  description: string;
  name: string;
  constructor(displayName, id, link, desc, name){
    super(displayName,id,link);
    this.description = desc;
    this.name = name;
  }
}

export class Resource extends EntityCollection{
  path:string;
  resourceName:string;
  resourceStatus:string;
  resourceType:string;
  constructor(displayName, id, link, path, rname, rstatus, rtype){
    super(displayName,id,link);
    this.path = path;
    this.resourceName = rname;
    this.resourceStatus = rstatus;
    this.resourceType = rtype;
  }
}

export class ResourceGroup extends EntityCollection{
  name: string = "";
  description: string = "";
  constructor(displayName, id, description? ){
    super(displayName, id, '/app/manage/resource-groups/');
    this.name = displayName;
    this.description = ( !!description && description != "") ? description : "";
  }
}

export class ManageSecurityObj{
  displayName: string;
  permissions: Permissions;
  collection: any[]; //Resource[] | Group[] | User[];
  relatedId: number = 0;
  services: RelatedEntityServices;
  link: string;

  constructor(name, link, relatedId, permissions?, services?, collection?){
    this.displayName = name;
    this.link = link;
    this.relatedId = relatedId;
    this.permissions = permissions || null;
    this.services = services || null;
    this.collection = collection || null;
  }

}

export class Permissions{
  canAddEntity;
  canRemoveEntity;
  constructor(add = false, remove = false){
    this.canAddEntity = add;
    this.canRemoveEntity = remove;
  }
}

@Injectable({providedIn:"root"})
export class ManageSecurityService {
  public EntityModel$: BehaviorSubject<ManageSecurityObj[]> = new BehaviorSubject<ManageSecurityObj[]>([]);
  get entityModel(){ return this.EntityModel$.value; }
  set entityModel( arr:ManageSecurityObj[] ) { this.EntityModel$.next(arr); }

  addModel = ( obj:ManageSecurityObj  ) => {  this.entityModel.push(obj); this.entityModel = this.entityModel; }

  updateResourceGroupsCollection = ( groups: IResourceGroup[] ) => {

    let xxx =  ( groups == null || groups.length === 0 )
      ? null
      : groups.map( group => new ResourceGroup( group.name, group.id, group.description ) );

      console.log(groups, xxx);
      return xxx;
  }


  updateUsersCollection = ( users: SecurityUser[] ) => {
    return ( users == null || users.length === 0 )
      ? null
      : users.map( user => {
        let displayName = `${user.firstName} ${user.lastName} (${user.email})`;
        let link = ['/app/manage/users/', user.id];
        return new SecurityUser(displayName, user.id, link, user.email, user.firstName, user.lastName);
      });
  }

  updateUserGroupsCollection(userGroups: Group[] ) {
    return (userGroups == null || userGroups.length === 0)
      ? null
      : userGroups.map( group => {
          let displayName = (!!group.description)
            ? `${group.name} - (${group.description})` // name - (description)
            : group.name;
          let link = ['/app/manage/user-groups/', group.id];
          return new Group(displayName, group.id, link, group.description, group.name);
      })
  }

}




@Component({
  selector: 'manage-security',
  template: `
    <p-accordion [multiple]="false" #accordion>
      <edw-expand-collapse-entities [accordion]="accordion"></edw-expand-collapse-entities>
      <ng-container *ngFor=" let entity of entityModel">
        <edw-related-entity
          entityName="{{entity.displayName}}"
          [permissions]="entity.permissions"
          [entityCollection]="entity.collection"
          [relatedEntityId]="entity.relatedId"
          [service]="entity.services"
          link="{{entity.link}}">
        </edw-related-entity>
      </ng-container>
    </p-accordion>
  `
})
export class ManageSecurityComponent implements OnInit {

  entityModel: ManageSecurityObj[];
  subscription: Subscription;
  constructor(private manageSecurityService: ManageSecurityService) {
    this.subscription = this.manageSecurityService.EntityModel$.subscribe(data => {
      this.entityModel = data;
    });
  }

  ngOnInit() {}

  ngOnDestroy(){
    this.manageSecurityService.entityModel = null;
    this.subscription.unsubscribe();
  }


}




