import { Report } from '../../../entities/report';
import { IUserExtract, IExtractForUser } from '../../../entities/user-extracts';

export class Collection{
  id:             number;
  name:           string;
  reports:        number[] = [];
  extracts:       number[] = [];
  constructor(id:number, name:string, reports, extracts){
    this.id       = id;
    this.name    = name;
    this.reports  = reports || [];
    this.extracts = extracts || [];
  }
}

export class CollectionData{
  public Reports: Report[] = [];
  public Extracts: IExtractForUser[] = [];
  public Collection :Collection[] = [];
  constructor(reports: Report[] = [], extracts: IExtractForUser[] = [], collection: Collection[] = [] ){
    this.Reports    = reports;
    this.Extracts   = extracts;
    this.Collection = collection
  }

}

// export class CollectionData{
//   public Reports?: Report[];
//   public Extracts?: IExtractForUser[];
//   public Collection?;
//   constructor( ){
//     this.Reports    = [];
//     this.Extracts   = [];
//     this.Collection = {};
//   }

// }

export class CollectionStore{
  public Collections: Collection[];
  public Reports: Report[];
  public Extracts: IExtractForUser[];
  constructor( reports?: Report[], extracts?: IExtractForUser[], collections?: Collection[] )
  {
    this.Collections =  collections || [];
    this.Reports =      reports || [];
    this.Extracts =     extracts || [];
  }
}

export const CollectionType = {
  reports:'reports',
  extracts:'extracts'
}

export class CollectionUpdateObject{
  public collectionType: string;
  public resource: Report | IExtractForUser;
  public collection: Collection;
  constructor( collectionType: string, resource, collection: Collection){
    this.collectionType = collectionType;
    this.resource = resource;
    this.collection = collection;
  }
}

// export class CollectionUpdateObject{
//   public collectionType: string;
//   public resourceID: string;
//   public collection: Collection;
//   constructor( collectionType: string, resourceID: string, collection: Collection){
//     this.collectionType = collectionType;
//     this.resourceID = resourceID;
//     this.collection = collection;
//   }
// }
