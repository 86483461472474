import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListToggleFilterService } from '@edw-app-root/services/list-filter.service';
import { ExExecutionLog } from '@edw-entities/ex-execution-log';
import { ExtractDataService } from '@edw-services/extract.service';
import { SelectItem } from 'primeng';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
    selector: 'edw-extract-execution-log',
    templateUrl: './extract-execution-log.component.html',
    styleUrls: ['./extract-execution-log.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class ExtractExecutionLogComponent implements OnInit, OnDestroy {
  executionLog: ExExecutionLog[] = [];
  query: string;
  adhoc: SelectItem[] = [
    { label: '---', value: null },
    { label: 'No', value: false },
    { label: 'Yes', value: true }
  ];

  subs: Subscription[] = [];

  constructor(
    private extractDataService: ExtractDataService,
    private route: ActivatedRoute,
    private listFilter : ListToggleFilterService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.route.data.pipe(
        map( ( data: { componentData: any } ) => {

          this.executionLog  = data.componentData.executionlog.map(element => {
            return new ExExecutionLog(element);
          });

          this.listFilter.initService(this.executionLog, [{field:'is_adhoc', filterVal: null}]);

        }
      )).subscribe()
    );
  }
  ngOnDestroy(): void {
      this.subs.map(sub => sub.unsubscribe());
  }

  refreshLog(): void {
    this.query='';
    this.search();
  }

  clearSearch() {
    this.query = '';
    this.search();
    // //the next two lines are here because the autocomplete
    // //component doesn't offer a native way to clear the value :(
    // autoCompleteRef.inputEL.nativeElement.value = '';
    // autoCompleteRef.inputEL.nativeElement.focus();
  }

  search() {

    this.subs.push(
      this.extractDataService.getExecutionLog(this.query).pipe(
        map(response => {
          if (response['data'].length > 0) {
            let list = response['data'].map(element => {
              return new ExExecutionLog(element);
            });
            this.executionLog = this.listFilter.filterListByCurrentState(list);
          }
        })
      ).subscribe()
    )
    //TODO: error handing here
  }

  filterBy = (event:{originalEvent:any, value: boolean}, field) => {
    this.executionLog = this.listFilter.filterBy( field, event.value );
  }

}
