import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({providedIn: 'root'})
export class EDWHttpService {

  constructor(private httpClient: HttpClient) { }


  public httpGet = ( endpoint: string, httpHeaders?: HttpHeaders,_responseType?, observe? ) => {
    return this.httpClient.get(`${environment.api}/${endpoint}`, {headers : httpHeaders, responseType:_responseType, observe: observe } );
  }

  public httpPost = ( endpoint:string, body:any, httpHeaders?: HttpHeaders, _responseType?, observe? ) => {
    return this.httpClient.post(`${environment.api}/${endpoint}`,body, {headers : httpHeaders, responseType:_responseType, observe: observe } );
  }

}
