import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { ExtractSetupList } from '@edw-entities/extract-setup-list';
import { ExtractDataService } from '@edw-services/extract.service';
import { LoaderService } from '@edw-services/loader.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';



@Component({
    selector: 'edw-extract-approvals',
    templateUrl: './extract-approvals.component.html',
    styleUrls: ['./extract-approvals.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class ExtractApprovalsComponent implements OnInit {
  private extractDataSubsription: Subscription;

  extracts: ExtractSetupList[] = [];
  query: string;
  permissions;

  msgs: Message[] = [];

  newExtracts: Object = {
    extract_setup_keys: []
  };

constructor(
  private extractDataService: ExtractDataService,
  private route: ActivatedRoute,
  private loader: LoaderService,
  private toastMessageService : ToastMessageService
) {}

  ngOnInit() {
    this.route.data
    .subscribe((data: { componentData: any }) => {
      this.permissions = data.componentData.permissions;
      this.extracts  = data.componentData.extracts.map(element => {
          return new ExtractSetupList(element);
        });
    });
  }

  clearSearch(autoCompleteRef) {
    this.query = '';
    //the next two lines are here because the autocomplete
    //component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  search() {
    this.extractDataService.getPendingApprovals(this.query)
    .subscribe(response => {
      this.extracts = [];
      if (response['data'].length > 0) {
        this.extracts  = response['data'].map(element => {
          return new ExtractSetupList(element);
        });
      }
      //Unsubscribe here
      this.extractDataSubsription.unsubscribe();
    });
    //TODO: error handing here
  }

  submitApprovals(esk) {
    this.newExtracts['extract_setup_keys'] = [esk];
    this.approval();
  }

  private approval() {
    let extractFields = this.newExtracts['extract_setup_keys'];

    this.loader.showLoader();

    const subscription: Subscription = this.extractDataService
      .approveExtract(extractFields)
      .subscribe(response => {
        if (response['data'] === true) {
          this.extracts = this.extracts.filter((extractSetup) => {
            return this.newExtracts['extract_setup_keys'].indexOf(extractSetup.extract_setup_key) === -1;
          });

          this.toastMessageService.addMessageObj({ severity: 'success', summary: 'Info Message', detail: 'Successfully approved' });
        };

        this.loader.hideLoader();
        subscription.unsubscribe();
      });
  }
}

