export class Report {
  id: string;
  externalId: string;
  title: string;
  description: string;
  resourceGroupName: string;
  createdBy: string;
  createdOn: string;
  lastExtraction: string;
  initialDeployment: string;
  isFavorite: boolean;
  favoriteId: number;
  typeId: number;
  calculatedRatingCount: number;
  calulatedRatingValue: number;
  ratingOptout: string = null;
  constructor(report) {
    this.id = report.id;
    this.externalId = report.externalId;
    this.title = report.title;
    this.description = report.description;
    this.resourceGroupName = report.resourceGroupName;
    this.createdBy = report.createdBy;
    this.createdOn = report.createdOn;
    this.lastExtraction = report.lastExtraction;
    this.initialDeployment = report.initialDeployment;
    this.isFavorite = report.isFavorite;
    this.favoriteId = report.favoriteId;
    this.typeId = report.typeId;
    this.calculatedRatingCount = (!!report.calculatedRatingCount) ? report.calculatedRatingCount : null;
    this.calulatedRatingValue = (!!report.calulatedRatingValue) ? report.calulatedRatingValue : null;
    this.ratingOptout = report.ratingOptout;
  }
}
