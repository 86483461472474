import { ITeam } from '@edw-entities/team';
import { IUser } from '@edw-entities/user';
import { IUserExtractParameter } from '@edw-entities/user-extracts';

export class Subscription {
  id?: number = 0;
  configurationId?: number = 0;
  name?: string = '';
  description?: string = '';
  fromEmailAddress?: string = null;
  fromDisplayName? : string;
  formatType?: string = 'CSV';
  isActive: boolean = false;
  email: Email = new Email();
  report: Report = null;
  team: ITeam;
  recipients: Recipients[] = [];
  schedule: Schedule = new Schedule();
  author: IUser;
  updateSchedule: boolean = false;
  modifiedDate: any;
  modifiedBy: IUser;
  createdDate: Date;

  constructor(name?: string, description?: string, author?: IUser, team?: ITeam, modifiedBy?: IUser, createdDate?: Date) {
    this.name = name || '';
    this.description = description || '';
    this.author = author || null;
    this.team = team || null;
    this.updateSchedule = false;
    this.modifiedBy = author || null;
    this.createdDate = createdDate || null;
  }

  clear() {

    this.email.clear();
    this.report.clear();
  }
}

export class Report {
  name?: string = '';
  reportId?: number = -1;
  parameters?: IUserExtractParameter[] = [];

  constructor(name?: string, reportId?: number, params?: IUserExtractParameter[]) {
    this.name = name || '';
    this.reportId = reportId || -1;
    this.parameters = params || [];
  }

  clear?() {
    this.name = '';
    this.parameters = [];
    this.reportId = -1;
  }
}


export class Email {
  body?: string = '';
  subject?: string = '';

  clear?() {
    this.body = '';
    this.subject = '';
  }
}


export class Recipients {
  id?: number = -1;
  firstName?: string = '';
  lastName?: string = '';
  email?: string = '';
  parameters?: ParameterMapping[] = [];

  constructor(firstName?, lastName?, email?, id?) {
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.email = email || '';
    this.id = id || -1;
  }

}
export class ParameterMapping {
  id?: number = 0;
  parameterId?: number = -1;
  value?: string[];
  default?: boolean = false;

  constructor(parameterId?: number, value?: string[]) {
    this.parameterId = parameterId || -1;
    if (value != null && value[0] === 'SSRSDefault') {
      this.default = true;
      this.value = [''];
    }
    else {
      this.value = value;
    }
  }

}

export class Schedule {
  scheduleType?: number;
  day?: number;
  customDays?: String;
  days?: String[];
  executionTime?: Date;

  constructor(scheduleType?, day?, executionTime?: any) {
    this.day = day || -1;
    this.scheduleType = scheduleType || -1;
    this.executionTime = executionTime || new Date();
    this.days = this.customDays ? this.customDays.split(',') : [];
  }
}


export const ReportFormat = {
  XML: 'XML',
  CSV: 'CSV',
  PDF: 'PDF',
  EXCEL: 'EXCEL'
}



// xml, csv, pdf, excel
