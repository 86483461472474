import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'resourceFilter',
    pure: false
})
export class ResourceFilter implements PipeTransform {
    transform(items: any[], callback: (item: any) => boolean): any {
         return (!items || !callback) ?  items : items.filter(item => callback(item));
    }
}


@Pipe({ name: 'filterBy' })
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, targetKey: string[] ): any[] {
        if (!items) { return []; }
        if (!searchText || targetKey.length < 1) { return items; }

        searchText = searchText.toLowerCase();

        return items.filter(
          item => targetKey.filter( key => {
            if( item[key] != null ){ return ( item[key].toLowerCase().includes( searchText ) ); }
          })[0]
        );
    }
}



@Pipe({
    name: 'orderBySelected'
})
export class OrderBySelected  implements PipeTransform {
    transform(allResources:any[], selectedResources:any[], bySelected: boolean ): any[]{
        if(!selectedResources || selectedResources.length === 0){ return allResources; }
    }
}

@Pipe({
    name: 'filterBySelected'
})
export class FilterBySelected  implements PipeTransform {
    transform(allResources:any[], selectedResources:any[], bySelected: boolean ): any[]{
        if(!selectedResources || selectedResources.length === 0){ return allResources; }
        return ( bySelected )
            ? allResources.filter(res=> selectedResources.indexOf(res.id) > -1)
            : allResources.filter(res=> selectedResources.indexOf(res.id) === -1);
    }
}


@Pipe({
    name: 'onlyShowSelected'
})
export class OnlyShowSelected implements PipeTransform{
    transform(allResources:any[], selectedResources:any[], toFilter: boolean ): any[]{
        if( !selectedResources || selectedResources.length === 0 ){ return allResources; }
        return ( toFilter )
            ? allResources.filter(res=> selectedResources.includes(res.id))
            : allResources;
    }
}

@Pipe({
    name: 'truncate'
  })
  export class TruncatePipe implements PipeTransform {
    transform(value: string, limit = 25, completeWords = false, ellipsis = '') {
      if(value.length > limit){
        if (completeWords) {
          limit = value.substr(0, limit).lastIndexOf(' ');
        }
        // return `${value.substr(0, limit)}`;
        return `${value.substr(0, limit)}<span class="pointer">${ellipsis}</span>`;
      }
      return value;
    }
  }



  @Pipe({
    name: 'sortAlphaBy'
  })
  export class SortAlpha implements PipeTransform {
    transform(resources:any[], field: string) {

        // Do YOUR LOGIC HERE, like this code below
      if( !!resources && resources.length > 0 ){
        resources.sort((a: any, b: any) => {
          if (a[field] < b[field]) {
            return -1;
          } else if (a[field] > b[field]) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      return resources;
    }
  }

