import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root/permissions';
import { ReportParameter } from '@edw-entities/report-parameter';
import {
  SubscriptionAssociationEntity,
  SubscriptionCreateEmailEntity,
  SubscriptionCreateKeywordsEntity,
  SubscriptionMetadataEntity,
  SubscriptionRecipientConfigEntity,
  SubscriptionReportConfigEntity
} from '@edw-entities/subscription';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Subject, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';


@Injectable()
export class ManageSubscriptionsRoutingResolver implements Resolve<any> {

  constructor(private subscriptionService: SubscriptionService,
    private router: Router,
    private userDataService: UserDataService,
    private location: Location,
    private sessionStorageService: SessionStorageService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];
    let permissions = {
      canView: this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canView),
      canCreate: this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canCreate),
      canRemove: this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canRemove),
      canModify: this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canModify),
      canApprove: this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canApprove)
    };

    let componentData = {
      title: 'Edit a Subscription',
      permissions: permissions
    };

    //#region Subscriptions
    if (urlFragments.indexOf('subscriptions') > -1) {
      if (lastParam === 'subscriptions') {
        //if a subscription with no keywords is persisted,
        //we delete it since we can't edit the keywords through the
        //subscription update UI
        if (this.sessionStorageService.get('subscriptionWithNoKeywords')) {
          let subWithNoKeywordsDeletedSubject = new Subject();
          let subWithNoKeywordsDeletedObservable = subWithNoKeywordsDeletedSubject.asObservable();

          this.subscriptionService
            .removeSubscription(this.sessionStorageService.get('subscriptionWithNoKeywords'))
            .pipe(
             finalize(() => {
              this.subscriptionService.getSubscriptionList('')
                .subscribe((response) => {
                  componentData['subscriptions'] = response['data'];
                  subWithNoKeywordsDeletedSubject.next(componentData);
                  subWithNoKeywordsDeletedSubject.complete();
                });
            }))
            .subscribe(
              (response) => {
                if (response['data']) {
                  this.sessionStorageService.remove('subscriptionWithNoKeywords');
                } else {
                  this.sessionStorageService.add('message', { type: 'error', text: 'Error removing subscription' });
                }
              },
              (error) => {
                this.sessionStorageService.add('message', { type: 'error', text: 'Error removing subscription'});
              }
            );

          return subWithNoKeywordsDeletedSubject.asObservable();
        } else {
          return this.subscriptionService.getSubscriptionList('')
            .pipe(
             map(responseBody => {
              componentData['subscriptions'] = responseBody['data'];
              return componentData;
            }));
        }
      }

      if (lastParam === 'add') {
        let resolverObject = null;

        if (this.sessionStorageService.get('subscriptionCreationWizard')) {
          let wizardInfo = this.sessionStorageService.get('subscriptionCreationWizard');
          let metadata = wizardInfo.data.metadata;
          let createEmail = wizardInfo.data.createEmail;
          let createKeywords = wizardInfo.data.createKeywords;
          let recipientConfig = wizardInfo.data.recipientConfig;
          let reportConfig = wizardInfo.data.reportConfig;
          let entityAssociations = wizardInfo.data.entityAssociations;

          //create FileList for the cached recipient groups
          recipientConfig.recipientGroups = recipientConfig.recipientGroups
            .map((recipientGroup) => {
              recipientGroup.files = recipientGroup.files.map((file) => {
              // replace new File([new ArrayBuffer(0)], file.name) with Blob contructor in order to work in IE11
                return new Blob([new ArrayBuffer(0)], {type: 'text/plain'});
              });
              return recipientGroup;
            });
          //reinitialize regex, as it gets replaced by an {} when persisting into sessionStorage
          createEmail.regExSeeIfContainsKeywords = /{{[\s\S]*?}}|{[\s\S]*?}/g;

          //make sure that the appropriate report is selected for association
          entityAssociations.recipientGroupReportConfigMemberships.forEach((membership) => {
            membership.associations.forEach((association) => {
              association.reportConfigs.reportWithConfigsList.forEach((configsForReport) => {
                let match = configsForReport.value.filter((config) => {
                  return config.value === association.reportConfigs.selectedConfig;
                })[0];

                if (match) {
                  association.reportConfigs.selectedReport = configsForReport.value;
                }
              });
            });
          });

          resolverObject = {
            metadata: metadata,
            createEmail: createEmail,
            createKeywords: createKeywords,
            recipientConfig: recipientConfig,
            reportConfig: reportConfig,
            entityAssociations: entityAssociations
          };
        }

        return  of(resolverObject);
      }

      if (route.params.hasOwnProperty('id') && !isNaN(parseInt(route.params.id)) && parseInt(route.params.id) > 0) {
        let id = parseInt(route.params.id);
        let resolverObject = null;

        return this.subscriptionService.getSubscription(id)
          .pipe(
            map(responseBody => {
            let metadata = this.constructGeneralMetadata(responseBody['data'], id);
            let keywords = this.constructKeywords(responseBody['data']);
            let email = this.constructEmail(responseBody['data'], keywords);
            let recipientConfig = this.constructRecipientConfigs(responseBody['data']);
            let reportConfigs = this.constructReportConfigs(responseBody['data'], keywords.userSpecificKeywords);
            let entityAssociations = this.constructEntityAssociation(responseBody['data'], reportConfigs, keywords);

            resolverObject = {
              metadata: metadata,
              keywords: keywords,
              email: email,
              recipientConfig: recipientConfig,
              reportConfig: reportConfigs,
              entityAssociations: entityAssociations
            };

            return resolverObject;
          }));
      }
    }
    //#endregion Subscriptions
  }

  private constructGeneralMetadata(responseData, subscriptionId): SubscriptionMetadataEntity {
    let metadata: SubscriptionMetadataEntity = new SubscriptionMetadataEntity();

    metadata.id = subscriptionId;
    metadata.name = responseData.name;
    metadata.description = responseData.description;
    metadata.schedule = responseData.schedule;

    return metadata;
  }

  private constructEmail(responseData, keywords): SubscriptionCreateEmailEntity {
    let createEmail: SubscriptionCreateEmailEntity = new SubscriptionCreateEmailEntity();

    createEmail.emailSubject.model = this.substituteKeywordsValueWithLabel(responseData.emailSubject, keywords);
    createEmail.emailBody.model = this.substituteKeywordsValueWithLabel(responseData.emailBody, keywords);

    return createEmail;
  }

  private constructKeywords(responseData): SubscriptionCreateKeywordsEntity {
    let createKeywords: SubscriptionCreateKeywordsEntity = new SubscriptionCreateKeywordsEntity();

    createKeywords.userSpecificKeywords = responseData['userKeywords'].map((keyword) => {
      let splitKeywordMarkup = keyword.markup.split('');
      splitKeywordMarkup.splice(2, 0, 'user:');

      return {
        label: splitKeywordMarkup.join(''),
        value: keyword.markup
      };
    });

    createKeywords.recipientGroupKeywords = responseData['groupKeywords'].map((keyword) => {
      return {
        label: keyword.markup,
        value: keyword.markup
      };
    });

    return createKeywords;
  }

  private constructRecipientConfigs(responseData) {
    let recipientConfig: SubscriptionRecipientConfigEntity = new SubscriptionRecipientConfigEntity();

    recipientConfig.isRecipientGroupListCompleted = true;

    if (responseData.recipientGroups.length > 0) {
      recipientConfig.recipientGroups = responseData.recipientGroups.map((recipientGroup) => {
        let timestamp = new Date().getTime().toString();
        recipientConfig.recipientConfigForms['recipientConfig' + recipientGroup.timestampAdded] = true;
        const blob = new Blob([new ArrayBuffer(0)], {type: 'text/plain'});
        return {
          id: recipientGroup.recipientGroupId,
          name: recipientGroup.recipientGroupName,
          isPersisted: true,
          timestampAdded: timestamp,
          // replace [new File([new ArrayBuffer(0)], recipientGroup.recipientGroupFileName)] with Blob contructor in order to work in IE11
          files: [blob]
        };
      });
    }

    return recipientConfig;
  }

  private constructReportConfigs(responseData, keywords): SubscriptionReportConfigEntity {
    let generatedReportsWithConfigs: SubscriptionReportConfigEntity = new SubscriptionReportConfigEntity();

    responseData.reportConfigurations.forEach((reportConfig) => {
      let timestamp = new Date().getTime().toString();

      let configuration = {
        configId: reportConfig.reportConfigurationId,
        configName: reportConfig.reportConfigurationName,
        isPersisted: true,
        timestampAdded: timestamp,
        parameters: reportConfig.parameters.map((parameter) => {
          return new ReportParameter(parameter, keywords);
        })
      };

      generatedReportsWithConfigs.configForms['resource' + reportConfig.resourceId + '_config' + configuration.timestampAdded] = true;

      let alreadyAddedReportWithConfig = generatedReportsWithConfigs.configurationsPerReport.filter((reportWithConfig) => {
        return reportConfig.resourceId === reportWithConfig.resourceId;
      })[0];

      if (alreadyAddedReportWithConfig) {
        alreadyAddedReportWithConfig.configurations.push(configuration);
      } else {
        generatedReportsWithConfigs.configurationsPerReport.push({
          resourceId: reportConfig.resourceId,
          resourceName: reportConfig.resourceName,
          isPersisted: true,
          configurations: [configuration]
        });
      }
    });

    return generatedReportsWithConfigs;
  }

  private constructEntityAssociation(responseData, reportConfigs, keywords): SubscriptionAssociationEntity {
    let generatedMembership: SubscriptionAssociationEntity = new SubscriptionAssociationEntity();
    let selectedConfig, selectedReport;

    generatedMembership.recipientGroupKeywords = keywords.recipientGroupKeywords
      .map((recipientGroupKeyword) => {
        return {
          model: '',
          label: recipientGroupKeyword.label,
          value: recipientGroupKeyword.value,
          isDynamic: false
        };
      });

    //prepare the list of configs per report for the dropdowns
    generatedMembership.configsListPerReport = reportConfigs.configurationsPerReport
      .map((reportWithConfigs) => {
        //prepare the list with the configs for the dropdown component
        let configList = reportWithConfigs.configurations
          .map((reportConfig) => {
            return {
              label: reportConfig.configName,
              value: reportConfig.configId
            };
          });

        return {
          label: reportWithConfigs.resourceName,
          value: configList
        };
      });

    responseData.reportConfigurationMemberships.forEach((membership) => {
      let timestamp = new Date().getTime().toString();

      let associationForGroup = {
        id: membership.reportConfigurationMembershipId,
        isPersisted: true,
        timestampAdded: timestamp,
        reportConfigs: {
          selectedConfig: null,
          selectedReport: null,
          reportWithConfigsList: this.copyObjectsToANewArray(generatedMembership.configsListPerReport),
        },
        recipientGroupKeywords: this.copyObjectsToANewArray(generatedMembership.recipientGroupKeywords)
      };

      associationForGroup.reportConfigs.reportWithConfigsList.forEach((reportWithConfig) => {
        let match = reportWithConfig.value.filter((config) => {
          return membership.reportConfigurationId === config.value;
        })[0];

        if (match) {
          associationForGroup.reportConfigs.selectedConfig = match.value;
          associationForGroup.reportConfigs.selectedReport = reportWithConfig.value;
        }
      });

      generatedMembership.associationForms[membership.recipinetGroupName + '_association' + timestamp] = true;

      let alreadyAddedRecipientGroupWithAssociation = generatedMembership.recipientGroupReportConfigMemberships
        .filter((recipientGroupWithAssociation) => {
          return membership.recipinetGroupId === recipientGroupWithAssociation.recipientGroupId;
        })[0];

      if (alreadyAddedRecipientGroupWithAssociation) {
        alreadyAddedRecipientGroupWithAssociation.associations.push(associationForGroup);
      } else {
        generatedMembership.recipientGroupReportConfigMemberships.push({
          recipientGroupId: membership.recipinetGroupId,
          recipientGroupName: membership.recipinetGroupName,
          associations: [associationForGroup]
        });
      }

      generatedMembership.areAllAssociationFormsPersisted = true;
    });

    return generatedMembership;
  }

  private copyObjectsToANewArray(arr) {
    arr = arr.map((obj) => {
      return Object.assign({}, obj);
    });

    return arr;
  }

  private substituteKeywordsValueWithLabel(model, frontEndModelKeywords): string {
    let frontEndModel = '';

    if (model) {
      let extractedKeywordsFromBackendText = model.match(/{{[\s\S]*?}}|{[\s\S]*?}/g) || [];
      let modelCopy = model;

      //if we find keywords with {{ }}, then we replace them
      //according to what's present as labels among both the
      //group and user-specific keywords
      if (extractedKeywordsFromBackendText.length > 0) {
        let replacedKeywordsWithIndexesInModel = extractedKeywordsFromBackendText.forEach((keyword) => {
          let replacedKeyword = frontEndModelKeywords.userSpecificKeywords
            .concat(frontEndModelKeywords.recipientGroupKeywords)
            .filter((frontEndModelKeyword) => {
              return keyword === frontEndModelKeyword.value;
            })[0];

          let stringIndex = modelCopy.indexOf(keyword);

          modelCopy = modelCopy.substr(0, stringIndex) + replacedKeyword.label + modelCopy.substr(stringIndex + keyword.length);
          frontEndModel = modelCopy;
        });
      }
      //if we don't have any keywords used at all, then we just have a plain string
      else {
        frontEndModel = model;
      }
    }

    return frontEndModel;
  }
}

