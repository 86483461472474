import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ITeam, Team } from '@edw-app-root/entities/team';
import { IUser } from '@edw-app-root/entities/user';
import { ParameterMapping, Recipients, Report, Subscription } from '@edw-entities/subscription.2';
import { IUserExtractReport } from '@edw-entities/user-extracts';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { ToastMessageService } from '@edw-services/messaging.service';
import { EDWSubscription2Service } from '@edw-services/subscription2.service';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';



@Injectable()
export class SubscriptionFormService {

  private _subscriptionData: Subscription = new Subscription();
  public subscriptionData = new BehaviorSubject(this._subscriptionData);

  constructor(
    private edwSubscription2Service: EDWSubscription2Service,
    private errorService: ErrorHandlerService,
    private router: Router,
    private toastMessageService: ToastMessageService
  ) { }


  isSubscriptionValid = () => {
    let subval = this.subscriptionData.value;
    let val = subval.author != null && subval.report != null && subval.team != null && subval.name != "" && subval.description != "";
    return val;
  }

  getNewSubscription() {
    this._subscriptionData = new Subscription();
    this.subscriptionData.next(this._subscriptionData);
  }

  setSubscription = (subscription: Subscription) => {
    //we get comma seperated string for customDays, we will split it to store as arrays in Days property in Schedule
    if (subscription.schedule) {
      subscription.schedule.days = subscription.schedule.customDays ? subscription.schedule.customDays.split(',') : [];
    }

    this._subscriptionData = subscription;
    this.subscriptionData.next(this._subscriptionData);
  }

  setReportData(reportData: IUserExtractReport) {
    this._subscriptionData.report = new Report(reportData.name, reportData.reportId, reportData.parameters);
    this.subscriptionData.next(this._subscriptionData);
  }

  setTeamData(teamData: ITeam) {
    this._subscriptionData.team = new Team(teamData);
    this.subscriptionData.next(this._subscriptionData);
  }

  deleteReportData() {
    this._subscriptionData.report = null;
    this._subscriptionData.isActive = false;
    this.subscriptionData.next(this._subscriptionData);
  }

  deleteTeamData() {
    this._subscriptionData.team = null;
    this._subscriptionData.isActive = false;
    this.subscriptionData.next(this._subscriptionData);
  }

  setAuthorData(authorData: IUser) {
    this._subscriptionData.author = authorData;
    this.subscriptionData.next(this._subscriptionData);
  }

  deleteAuthorData() {
    this._subscriptionData.author = null;
    this._subscriptionData.isActive = false;
    this.subscriptionData.next(this._subscriptionData);
  }

  setNewRecipient = (recipients: Recipients[]) => {
    this._subscriptionData.recipients = this._subscriptionData.recipients.concat(recipients);
    this.subscriptionData.next(this._subscriptionData);
  }

  updateRecipients = (recipients: Recipients[]) => {

    recipients.forEach((rec, ind, arr) => {
      let idx = this._subscriptionData.recipients.findIndex(_rec => _rec.id == rec.id); // find index of recipient in _subData
      this._subscriptionData.recipients = Object.assign([...this._subscriptionData.recipients], { [idx]: rec }); // recplace the old recipient obj with the new.
    });
    this.subscriptionData.next(this._subscriptionData);
  }

  deleteSubscriptionRecipients = () => {
    this._subscriptionData.recipients = [];
    this.subscriptionData.next(this._subscriptionData);
  }

  createRecipient(data: DataRow): Recipients {
    const ids = this._subscriptionData.report.parameters.map(param => param.id.toString())
    const recipient = new Recipients(data.firstName, data.lastName, data.email, data.recipientID);

    recipient.parameters = Object.keys(data)
      .filter(key => ids.indexOf(key.toString()) > -1)
      .map(key => {
        if (Array.isArray(data[key])) {
          return new ParameterMapping(parseInt(key, 10), data[key])
        }
        return new ParameterMapping(parseInt(key, 10), [data[key]])
      });

    return recipient;
  }


  /* CRUD */

  onError = (httpErrorResponse) =>
    this.toastMessageService.addMessage('Error', 'Error', 'There was an error creating or updating the subscription.');
  ;

  getSubscription = (id: string) => {
    this.edwSubscription2Service.getSubscription(id).subscribe(res => {
      this._subscriptionData = res.data;
      //we get comma seperated string for customDays, we will split it to store as arrays in Days property in Schedule
      if (this._subscriptionData.schedule) {
        this._subscriptionData.schedule.days = this._subscriptionData.schedule.customDays ? this._subscriptionData.schedule.customDays.split(',') : [];
      }
      this.subscriptionData.next(this._subscriptionData);
    });
  }



  saveRecipient = (type, recipientArr: Recipients[], onSuccess) => {
    switch (type) {
      case ('New'):
        return this.edwSubscription2Service.addRecipients(this._subscriptionData.id.toString(), recipientArr).subscribe(
          data => onSuccess(data),
          error => this.onError(error)
        );
      case ('Update'):
        return this.edwSubscription2Service.updateRecipients(this._subscriptionData.id.toString(), recipientArr).subscribe(
          data => onSuccess(data),
          error => this.onError(error)
        );
    }
  }

  deleteRecipients = (recipientArr: Recipients[], onSuccess) => {
    return this.edwSubscription2Service.deleteRecipients(this._subscriptionData.id.toString(), recipientArr).subscribe(
      data => onSuccess(data),
      error => this.onError(error)
    )
  }

  updateSubscription(shouldUpdateSchedule) {
    if (this._subscriptionData.schedule) {
      this._subscriptionData.schedule.customDays = this._subscriptionData.schedule.days ? this._subscriptionData.schedule.days.join() : null;
    }
    //clone and remove days which is not needed in the backend. only customDays is needed for backend
    const clonedObject = _.cloneDeep(this._subscriptionData)
    if (clonedObject.schedule) {
    delete clonedObject.schedule.days;
    }
    this.edwSubscription2Service.updateSubscription(clonedObject, shouldUpdateSchedule).subscribe(
      response => {
        if (response['data'] == true) {
          this.toastMessageService.addMessage('success', 'Saved', 'The subscription was saved succesfully.')
        }
      },
      error => this.onError(error)
    );
  }

  deleteSubscription() {
    this.edwSubscription2Service.removeSubscription(this._subscriptionData.id.toString()).subscribe(
      response => {
        if (response['data'] == true) {
          this.toastMessageService.addMessage('success', 'Saved', 'The subscription was deleted succesfully.');
          this.router.navigate(['/app/manage/subscriptions2']);
        }
      },
      error => this.onError(error)
    );
  }


}



export class DataRow {
  invalidRows = [];
  recipientID = -1;
  rowIsValid = false;
  dataState = '';
  email = '';
  lastName = '';
  firstName = '';
  constructor(recipient?: Recipients) {

    this.recipientID = recipient.id;
    this.email = recipient.email;
    this.lastName = recipient.lastName;
    this.firstName = recipient.firstName;

  }
}

  // initClass(type: string) {
  //   switch (type) {
  //     case ('Email'): {
  //       return this._subscriptionData.email = new Email();
  //     }
  //     case ('Schedule'): {
  //       return this._subscriptionData.schedule = new Schedule();
  //     }
  //     default: {
  //       this._subscriptionData = this._subscriptionData;
  //     }
  //   }
  //   this.subscriptionData.next(this._subscriptionData);
  // }
