import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { Cohort, ICohort } from '@edw-entities/cohort';
import { CohortsDataService } from '@edw-services/cohorts.service';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'edw-cohort-queue-list',
  templateUrl: './cohort-queue-list.component.html',
  styleUrls: ['./cohort-queue-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CohortQueueListComponent implements OnInit, OnDestroy {

  public permissions: any;
  public cohorts: ICohort[] = [];

  public allRowsSelected = false;
  public query = '';
  public searching = false;

  public filterByIdField: FormControl;

  private cohortsDataSubsription: Subscription;
  @ViewChild('dataTable',{static:true}) dataTable;

  constructor(
    private cohortsDataService: CohortsDataService,
    private errorService: ErrorHandlerService,
    private toastMessageService : ToastMessageService,
    private route: ActivatedRoute
  ) {
    this.filterByIdField = new FormControl();
  }

  ngOnInit() {
    this.route.data
      .subscribe((data: { permissions: any, cohorts: ICohort[] }) => {
        this.permissions = data.permissions;
        this.cohorts = data.cohorts.map(element => {
          return new Cohort(element);
        });
      });

    this.filterByIdField.valueChanges.pipe(
      filter((data) => !this.searching)
    ).subscribe((value) => {
      if (this.dataTable) {
        console.log(value);
        this.search();
      }
    });
  }

  ngOnDestroy() {

  }

  public lazyLoadMore($event) {
    const lastPage = this.dataTable.totalRecords / this.dataTable.rows;
    const firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;
    console.log($event.first, this.dataTable.totalRecords, this.dataTable.rows, firstIndexOnLastPage);
    if ($event.first === firstIndexOnLastPage) {
      // BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      const nextPage = Math.ceil(lastPage / 10) + 1;
      this.searching = true;
      this.cohortsDataService.getAllUnapprovedCohorts(this.query, this.filterByIdField.value || '', nextPage)
        .subscribe((response) => {
          if (response['data'] && response['data'].length > 0) {
            const newCohorts = response['data'].map(element => {
              return new Cohort(element);
            });

            this.cohorts = this.cohorts.concat(newCohorts);
            this.searching = false;
          }
        });
    }
  }

  public clearSearch(autoCompleteRef) {
    this.query = '';
    // the next two lines are here because the autocomplete
    // component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  public clearSearchById($event) {
    this.filterByIdField.setValue('');
    this.search();
  }

  public search() {
    this.searching = true;
    this.cohortsDataSubsription = this.cohortsDataService
      .getAllUnapprovedCohorts(this.query, this.filterByIdField.value)
      .pipe(
        take(1)
      )
      .subscribe((response) => {
        this.cohorts = [];
        if (response['data'].length > 0) {
          this.cohorts = response['data'].map(element => {
            return new Cohort(element);
          });
        }
        this.searching = false;
      });
    // TODO: error handing here
  }

  approve(cohort) {
    cohort.disableApproving = true;

    this.cohortsDataService.approve(cohort.id)
      .subscribe(
        (response) => {
          if (response['data'] === true) {

            this.cohorts = this.cohorts.filter((request) => {
              return request.id !== cohort.id;
            });

            this.toastMessageService.addMessageObj({
              severity: 'success',
              summary: '',
              detail: 'Successfully approved '+ cohort.name +'.'
            })

          }
        }, (httpErrorResponse) => {
          this.errorService.httpErrorHandler(httpErrorResponse);
          cohort.disableApproving = false;
        }
      );
  }


}
