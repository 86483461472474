import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionCreateKeywordsEntity } from '@edw-entities/subscription';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-subscription-create-keywords',
  templateUrl: './subscription-create-keywords.component.html',
  styleUrls: ['./subscription-create-keywords.component.scss']
})
export class SubscriptionCreateKeywordsComponent implements OnInit {
  @Input() stepModel: SubscriptionCreateKeywordsEntity;
  @Output() stepModelChange = new EventEmitter();

  @Input() subscriptionId: number = 0;
  @Output() onCompleteStep = new EventEmitter();
  @Output() onGoBack = new EventEmitter();


  @Input() messages: Message[] = [];
  @Output() messagesChange = new EventEmitter();

  constructor(private loader: LoaderService,
              private subscriptionService: SubscriptionService,
              private genericErrorHandlerService: ErrorHandlerService) { }

  ngOnInit() {
  }

  addKeyword(keywordData) {
    let keywordInput = keywordData.isUserSpecific ? this.stepModel.userSpecificKeywordInput : this.stepModel.recipientGroupKeywordInput;
    const isNotEmpty = keywordInput !== '' && keywordInput !== ' ';

    if (isNotEmpty) {
      let keywordWithCurlies = '{{' + keywordInput + '}}';
      let keywordsArray = this.stepModel.userSpecificKeywords;

      if (!keywordData.isUserSpecific) {
        keywordsArray = this.stepModel.recipientGroupKeywords;
      }

      const isAlreadyCreated = keywordsArray
        .some((keywordObj) => {
          return keywordWithCurlies === keywordObj.value;
        });

      if (!isAlreadyCreated) {
        let displayName = '{{user:' + keywordInput + '}}';

        if (!keywordData.isUserSpecific) {
          displayName = keywordWithCurlies;
        }

        keywordsArray.push({
          label: displayName,
          value: keywordWithCurlies
        });
      }

      if (keywordData.isUserSpecific) {
        this.stepModel.userSpecificKeywordInput = '';
      } else {
        this.stepModel.recipientGroupKeywordInput = '';
      }
    }
  }

  removeKeyword(keywordData) {
    let keywordsArray = this.stepModel.userSpecificKeywords;

    if (!keywordData.isUserSpecific) {
      keywordsArray = this.stepModel.recipientGroupKeywords;
    }

    keywordsArray.splice(keywordData.keywordIndex, 1);
  }

  persistKeywords() {
    let subcriptionId = this.subscriptionId;

    // parse out the stepModel.userSpecificKeywords object into an array
    let userSpecificKeywordArray = this.stepModel.userSpecificKeywords.map((userKeyword) => {
      return userKeyword.value;
    });

    // parse out the stepModel.recipientGroupKeywords object into an array
    let recipientGroupKeywordArray = this.stepModel.recipientGroupKeywords.map((recipientGroupKeyword) => {
      return recipientGroupKeyword.value;
    });

    this.loader.showLoader();

    // post the user keywords
    const subscription: Subscription = this.subscriptionService
      .addUserKeywords(subcriptionId, userSpecificKeywordArray)
      .subscribe(
        (response) => {
          if (response['data'] === true) {
            let successMessage = [{
              severity: 'success',
              summary: 'Successfully added keywords.',
              detail: 'Successfully added user-sepcific keywords'
            }];

            if (recipientGroupKeywordArray.length > 0) {
              // post the group keywords
              const groupKeywordsSubscription: Subscription = this.subscriptionService
                .addGroupKeywords(subcriptionId, recipientGroupKeywordArray)
                .subscribe(
                  (response) => {
                    if (response['data'] === true) {
                      this.messagesChange.emit(successMessage);

                      groupKeywordsSubscription.unsubscribe();
                      this.loader.hideLoader();
                      this.onCompleteStep.emit();
                    }
                  },

                  (httpErrorResponse) => {
                    let errorMessages = [];
                    this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

                    this.messagesChange.emit(errorMessages);

                    this.loader.hideLoader();
                    groupKeywordsSubscription.unsubscribe();
                  }
                );
            } else {
              this.messagesChange.emit(successMessage);
              this.loader.hideLoader();
              this.onCompleteStep.emit();
            }
          }

          subscription.unsubscribe();
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          this.loader.hideLoader();
          subscription.unsubscribe();
        }
      );
  }

  goBack() {
    this.onGoBack.emit();
  }
}
