import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Accordion } from 'primeng-lts';

@Component({
  selector: 'edw-expand-collapse-entities',
  templateUrl: './expand-collapse-entities.component.html',
  styleUrls: ['./expand-collapse-entities.component.scss']
})
export class ExpandCollapseEntitiesComponent implements OnInit, DoCheck {
  isExpanded: boolean = false;
  @Input() accordion: Accordion;

  constructor() { }

  ngOnInit() {}

  ngDoCheck() {
    let allTabsAreExpanded = false;
    let allTabsAreCollapsed = this.accordion.tabs.every((tab) => {
      return tab.selected === false;
    });

    if (allTabsAreCollapsed) {
      this.isExpanded = false;
    } else {
      allTabsAreExpanded = this.accordion.tabs.every((tab) => {
        return tab.selected === true;
      });

      if (allTabsAreExpanded) {
        this.isExpanded = true;
      }
    }
  }

  toggleView() {
    this.isExpanded = !this.isExpanded;
    this.accordion.multiple = !this.accordion.multiple;

    this.accordion.tabs.forEach((tab) => {
      tab.selected = this.isExpanded;
    });
  }

}
