import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceType } from '@edw-enums/resource-type';
import { SessionStorageService } from '@edw-services/session-storage.service';


@Component({
  selector: 'viewsql-detail',
  templateUrl: './viewsql.component.html',
  styleUrls: ['./viewsql.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewSqlComponent implements OnInit, OnDestroy {
  title: string;
  buttonText:string='Copy SQL';
  resourceTypeText = ResourceType;

  resourceDetails : any = "";

  @ViewChild('accordion', {static: true}) accordion;

  constructor(
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private clipboard: Clipboard
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: { permissions: any, title: string, resourceDetails: any }}) => {

        if (data.componentData.hasOwnProperty('resourceDetails')) {
          this.title = data.componentData.title;
          this.resourceDetails = this._escapeHtml(data.componentData.resourceDetails);
        }


      });
  }
  //https://stackoverflow.com/a/6234804/838100
  private _escapeHtml (unsafe) {
    return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
  }


  // https://developapa.com/angular-clipboard/
  copyToClipboard = (value : HTMLElement) => {
    const text = value || value.textContent || '';
    this.clipboard.copy(text.toString());
    return this.copied();
  }

  UpdateButtonText() {
    setTimeout(() => {
      this.buttonText='Copy SQL';
    }, 2000);
  }

  copied(){
      this.buttonText='Copied';
      this.UpdateButtonText();
  }

  copyError(event){
    this.buttonText='Error in Copy';
      this.UpdateButtonText();
  }

  ngOnDestroy() {
    this.sessionStorageService.remove('currentResource');
  }

}
