import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuditGuard } from '@edw-app-root/manage/audit/audit.guard';
import { AuditListComponent } from '@edw-app-root/manage/audit/containers/audit-list/audit-list.component';
import { AuditListResolveGuard } from '@edw-app-root/manage/audit/resolvers/audit-list.resolver';
import { AuditsDataService } from '@edw-app-root/services/audits.service';
import { EDWSharedModule } from '@edw-app-root/shared/shared.module';
import {
  AutoCompleteModule,
  CalendarModule,
  DropdownModule,
  TableModule,
  TreeTableModule
} from 'primeng-lts';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    EDWSharedModule,
    TableModule,
    CalendarModule,
    AutoCompleteModule,
    DropdownModule,
    TreeTableModule
  ],
  declarations: [
    AuditListComponent
  ],
  providers: [
    AuditsDataService,
    AuditListResolveGuard,
    AuditGuard
  ]
})
export class AuditModule { }
