export const CalendarPeriods = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  Quarterly: 4,
  CustomDays: 5,
  Fiscal_Quarterly: 6,
};

export const WeekDays = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
};
