import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListToggleFilterService } from '@edw-app-root/services/list-filter.service';
import { ExtractSchedule } from '@edw-entities/extract-schedule';
import { ExtractDataService } from '@edw-services/extract.service';
import { SelectItem } from 'primeng';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
    selector: 'edw-schedule-listings',
    templateUrl: './extract-schedule.component.html',
    styleUrls: ['./extract-schedule.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class ExtractScheduleComponent implements OnInit, OnDestroy {
  extracts: ExtractSchedule[] = [];
  query: string;
  completed: SelectItem[] = [
    { label: '---', value: null },
    { label: 'No', value: false },
    { label: 'Yes', value: true }
  ];
  subs : Subscription[] = [];
  constructor(
    private extractDataService: ExtractDataService,
    private route: ActivatedRoute,
    private listFilter : ListToggleFilterService
  ) { }

  ngOnInit() {
    this.subs.push(
      this.route.data.pipe(
        map((data: { componentData: any }) => {

          this.extracts  = data.componentData.extracts.map(element => {
            return new ExtractSchedule(element);
          });

          this.listFilter.initService(this.extracts, [{ field:'is_completed',filterVal: null}]);
        })
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
      this.subs.map(s => s.unsubscribe());
  }
  refreshSchedule() {
    this.query = '';
    this.search();
  }

  clearSearch() {
    this.query = '';
    this.search();
  }

  search() {
    this.subs.push (
      this.extractDataService.getScheduleExtract(this.query).pipe(map(response => {

        if (response['data'].length > 0) {
          let list = response['data'].map(element => {
            return new ExtractSchedule(element);
          });
          this.extracts = this.listFilter.filterListByCurrentState(list);
        }

      //TODO: error handing here

      })).subscribe()
    )
  }

  filterBy = ( event:{value:boolean}, field: string = "is_completed"  ) => {
    console.log(event, field);
    this.extracts = this.listFilter.filterBy(field, event.value);
  }

}
