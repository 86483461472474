export class UserSettings {
  id: number;
  defaultPage: string;
  userKey: number;
  modifiedDate: any;
  pageView: string;
  isActive: boolean;

  constructor(userSettings?) {
    if (userSettings) {
      this.id = userSettings.id;
      this.defaultPage = userSettings.defaultPage;
      this.userKey = userSettings.userKey;
      this.modifiedDate = userSettings.modifiedDate;
      this.pageView = userSettings.pageView;
      this.isActive = userSettings.isActive;
    }
  }
}

export interface IUserSettings {
  id?: number;
  defaultPage: string;
  userKey: number;
  modifiedDate: any;
  pageView: string;
  isActive: boolean;
}
