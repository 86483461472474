//http://jasonwatmore.com/post/2019/02/07/angular-7-communicating-between-components-with-observable-subject

import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng-lts';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class ToastMessageService{

  constructor(private primengMessageService: MessageService) {}

  public Messages$ : BehaviorSubject<Message[]> = new BehaviorSubject([]);

  get messages(){return this.Messages$.value;}

  set messages(_messages: Message[]){this.Messages$.next(_messages)}

  test(component: string = "a component"){

    this.addMessageObj({severity:"success", summary: `testing toast from ${component}`, detail:"A longer message that should still be pretty ledgible.", sticky:true});
  }

  addMessageObj(messageObj: Message){
    this.messages = [...this.messages, messageObj];
    this.primengMessageService.add(messageObj);
    //console.log(this.messages);
  }

  addMessage(severity: string, summary: string, detail: string) {
    this.addMessageObj({severity: severity, summary: summary, detail: detail} as Message)
  }

  clearMessages = () => {
    this.primengMessageService.clear();
  }

}

@Injectable()
export class EDWMessageService{

  private subject = new Subject<any>();

  sendMessage(severity: string, summary: string, detail: string) {
    this.subject.next({severity: severity, summary: summary, detail: detail} as Message);
  }

  clearMessage() {
      this.subject.next();
  }

  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }
}
