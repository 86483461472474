import { UserSearchResult } from '@edw-entities/user-search-result';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs';
import { ResourceGroup } from '@edw-entities/resource-group';
import { ResourceSearchResult } from '@edw-entities/resource-search-result';
import { SearchParams } from '@edw-entities/searchParams';
import { ITeam } from '@edw-app-root/entities/team';


@Injectable()
export class TeamService {

  constructor(private http: HttpClient) { } 

  getAll(query: string = '', page: number = 1) {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<any>(environment.api + '/team/getAll', options);
  }

 
  get(teamId: number) {
    let params = new HttpParams();
    params = params.set('teamId', teamId.toString()) ;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<any>(environment.api + '/team/'+teamId+'/get', options);
  }

  add(team: ITeam) {
    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<any>(environment.api + '/team/add',team, options);
  }

  update(team: ITeam) {
    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<any>(environment.api + '/team/update',team, options);
  }

  delete(teamId: number) {
    const options: Object = {
      observe: 'body',
    };

    return this.http
      .delete<any>(environment.api + '/team/'+teamId+'/delete', options);
  }


}
