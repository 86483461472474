import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@edw-app-root/entities/user';
import { UserDetails } from '@edw-app-root/entities/userDetails';
import { UserDataService } from '@edw-app-root/services/user-data.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'edw-merge-user-account',
  templateUrl: './merge-user-account.component.html',
  styleUrls: ['./merge-user-account.component.scss']
})
export class MergeUserAccountComponent implements OnInit {
  @Input() displayDialog = false;
  @Input() targetUser: UserDetails = null;
  @Output() displayDialogChange = new EventEmitter<boolean>();

  @Output() mergeResult = new EventEmitter<string>();

  sourceUser: User[] = [];
  users: User[] = [];
  message = null;

  constructor(private userDataService: UserDataService) { }

  ngOnInit() {
  }


  search(event) {

    var sourceDomain = this.getSourceDomain(this.targetUser.accountsDisplay.split("\\")[0]);

    this.userDataService.getAllUsers(event.query)
      .subscribe((response: { data: User[] }) => {
        this.users = response.data.map(u => {

          u.displayName = u.fullName + " (" + u.email + ")";
          return u;
        })
          .filter(u => u.accounts.length <= 1)
          .filter(u => u.accounts[0].activeDirectory == sourceDomain)
      });

  }

  scheduleMerge() {
    this.userDataService.scheduleMergeUserAccount(Number(this.sourceUser[0].id), this.targetUser.id)
      .subscribe(result => {
        if (result) {
          this.mergeResult.emit("true")
          this.close();
        } else {
          this.mergeResult.emit("false");
          this.close;
        }
      })
  }


  close() {
    this.displayDialog = false;
    this.displayDialogChange.emit(false);
  }

  getSourceDomain(domain) {
    switch (domain) {
      case "nm.org": {
        return "northwestern.edu";
        break;
      }
      case "northwestern.edu": {
        //statements;
        return "nm.org";
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }


  checkMergeHistory(event) {
    this.userDataService.getUserDetails(this.sourceUser[0].id.toString())
      .subscribe((result: { data: UserDetails }) => {
        if (result.data.mergedAccount)
          if (result.data.mergedAccount.status == "Scheduled") {
            this.message = result.data.mergedAccount.message;
          }
      });

  }
}
