import { AbstractControl } from '@angular/forms';

export function RequiredFunding(control: AbstractControl): {[key: string]: boolean} {

  const fundingSource = control.get('fundingSource');
  const fundingSourceText = control.get('fundingSourceText');
  if (fundingSource == null || fundingSourceText == null) {
    return null;
  }
  if (fundingSource.value == null || fundingSourceText.value == null) {
    return null;
  }
  // tslint:disable-next-line:max-line-length
  return ((fundingSource.value.toLowerCase() === 'other' && fundingSourceText.value !== '')
  || (fundingSource.value.toLowerCase() !== 'other' && fundingSourceText.value === ''))
  ? null : { requiredFunding: true };
}
