import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from '@edw-app-root/services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-app-root/services/session-storage.service';

@Injectable()
export class ManageRouteGuard implements CanActivate {
  constructor(private router: Router,
              private userDataService: UserDataService,
              private sessionStorageService: SessionStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let canActivate = false;

    if (state.url === '/app/manage' || state.url === '/app/manage/') {
      this.router.navigated = false;
      this.router.navigateByUrl('/app/resources/favorite');
    } else {
      canActivate = true;
    }
    return canActivate;
  }
}
