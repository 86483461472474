import { Component, Input, OnInit } from '@angular/core';
import { Report } from '@edw-entities/report';
//import { start } from 'repl';


@Component({
  selector: 'value-rating-display',
  template: `
  <ng-container *ngIf="value != null">
    <span *ngFor="let star of display"  [ngSwitch]="star">
      <i class="fa fa-star" *ngSwitchCase="1"></i>
      <i class="fa fa-star-half-o" *ngSwitchCase="0"></i>
      <i class="fa fa-star-o" *ngSwitchDefault></i>
    </span>
</ng-container>
  `
})

export class ValueRatingDisplayComponent implements OnInit {

  @Input() value : number;
  @Input() report : Report;
  starValue = { FULL : 1, HALF : 0, EMPTY: -1 }
  display : number[] = [];
  constructor() {}
  ngOnInit() {
    if(  this.report.ratingOptout != 'true' && !!this.report.calulatedRatingValue ){
      this.display = this.starDisplay(this.report.calulatedRatingValue);
    }
  }



  starDisplay = ( value ) => {
    // convert the number value into a string. split it by the decimal point and convert the strings back to an array of numbers.
    // value = 4.75;
    // '4.75'.split('.') === ['4','75'];
    // convert to numbers (map(x=> +x)) and the output is [ 4, 75 ]
    let splitVal = `${value}`.split('.').map(x => +x);

    let starCnt = [];

    // use whole number (splitVal[0]) to drive full stars
    while( +splitVal[0] > 0 ){ starCnt.push( this.starValue.FULL ); --splitVal[0]; }

    if( splitVal.length > 1 ){
      // convert decimal to a base 10 number. if number is 3.8, the split above makes the decimal 8.
      // we need this single digit reference of .8 to be 80% or .80... without the decimal point.
      // the decimal var will be a 2 digit number.
      let decimal = ( `${splitVal[1]}`.length == 1 ) ? splitVal[1] * 10 : splitVal[1];
      if( decimal >= 75 ){
          starCnt.push( this.starValue.FULL )
        }
      else if( decimal < 75 && decimal >= 25 ){
        starCnt.push( this.starValue.HALF )
      }
    }

    // flesh out the remaining stars to complete the 5 starts.
    while( starCnt.length < 5 ){ starCnt.push( this.starValue.EMPTY );}
    return starCnt;
  }

}
