import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';

import {
  AccordionModule,
  DialogModule,
  DropdownModule,
  MessagesModule,
  PanelModule,
  RadioButtonModule,
  SharedModule,
  ToastModule
} from 'primeng-lts';

import { EDWSharedModule } from '@edw-app-root/shared/shared.module';

import { UserSettingsComponent } from './user-settings.component';

export const userSettingsRoutes: Route = {
  path: 'settings',
  component: UserSettingsComponent,
  // resolve: {
  //   //componentData: NotificationsResolver
  // }
};

  @NgModule({
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      EDWSharedModule,
      SharedModule,
      DialogModule,
      AccordionModule,
      MessagesModule,
      ToastModule,
      DropdownModule,
      RadioButtonModule,
      PanelModule
    ],
    declarations: [
      UserSettingsComponent
    ],
    providers: [
      //ManagePeopleRoutingResolver,
      //ManagePeopleRouteGuard
    ],
    exports: [
      UserSettingsComponent,
    ]
  })
  export class UserSettingsModule { }
