import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListToggleFilterService } from '@edw-app-root/services/list-filter.service';
import { ExtractSetupList } from '@edw-entities/extract-setup-list';
import { ExtractSetupDataService } from '@edw-services/extract-setup.service';
import { SelectItem } from 'primeng';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'edw-extract-setup-list',
  templateUrl: './extract-setup-list.component.html',
  styleUrls: ['./extract-setup-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ExtractSetupListComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  extractStore: ExtractSetupList[] = [];

  extracts: ExtractSetupList[] = [];
  query: string;
  permissions;

  boolSelect: SelectItem[] = [
    { label: '---', value: null },
    { label: 'No', value: false },
    { label: 'Yes', value: true }
  ];

  constructor(
    private extractSetupDataService: ExtractSetupDataService,
    private route: ActivatedRoute,
    private listFilter : ListToggleFilterService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.route.data.pipe(

        map( (data: { componentData: any }) => {
          this.permissions = data.componentData.permissions;

          this.extracts = data.componentData.extracts.map(element => {
            return new ExtractSetupList(element);
          });

          this.listFilter.initService(
            this.extracts,
            [
              { field:'is_approved', filterVal: null },
              { field:'is_active'  , filterVal: null }
            ]
          );
          }
        )
      ).subscribe()

    );
  }

  ngOnDestroy(): void {

  }

  clearSearch() {
    this.query = '';
    this.search();
  }

  search() {
    this.subs.push(
      this.extractSetupDataService.getExtractSetupList(this.query)
        .pipe(
          map(response => {

          if (response?.data?.length > 0) {
            let list = response.data.map(element => {
              return new ExtractSetupList(element);
            });
            this.extracts = this.listFilter.filterListByCurrentState(list);
          }

        })).subscribe()
        // TODO: Add error handling.
    );
  }



  filterBy = ( event :{originalEvent:any, value: boolean}, field ) => {

    this.extracts = this.listFilter.filterBy(field,event.value);

  }

}
