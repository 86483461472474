import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { DataStewardQueueService } from '@edw-app-root/services/data-steward-queue.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';

@Injectable()
export class DataStewardQueueResolver implements Resolve<any> {

  constructor(
    private userDataService: UserDataService,
    private dataStewardQueueService: DataStewardQueueService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    const permissions = {
      canViewRequest: this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canView),
      canApproveRequest: this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canApprove),
      canViewAllRequests: this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canViewAll)
    };

    return this.dataStewardQueueService.getDataStewardQueue('', 1, !permissions.canViewAllRequests)
    .pipe(
     map((responseBody) => {
      return responseBody;
    }));
  }
}
