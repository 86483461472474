import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Observable } from 'rxjs';

@Injectable()
export class DataStewardQueueGuard implements CanActivate {

  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = false;

    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];

    const canViewRequest = this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canView);
    const canApproveRequest = this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canApprove);
    const canViewAllRequests = this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canViewAll);


    if (urlFragments.indexOf('data-steward-queue') > -1) {
      if ((canViewRequest || canViewAllRequests) && canApproveRequest) {
        canActivate = true;
      }
    }

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }
    // TODO: restore
    return true;
  }
}
