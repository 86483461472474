export class ExtractSetupList {
  
  //These objects are used for GET extract list method. 
  //Only the below columns will be visible

  extract_setup_key: number;
  extract_name: string;
  extract_description: string;
  extract_business_owner: string;
  extract_developer: string;
  extract_sp_name: string;
  is_approved: boolean;
  is_active: boolean;
    
  
  
    constructor(extractsetuplist) {
      
      this.extract_setup_key = extractsetuplist.extract_setup_key;
      this.extract_name = extractsetuplist.extract_name;
      this.extract_description = extractsetuplist.extract_description;
      this.extract_business_owner = extractsetuplist.extract_business_owner;
      this.extract_developer = extractsetuplist.extract_developer;
      this.extract_sp_name = extractsetuplist.extract_sp_name;
      this.is_approved = extractsetuplist.is_approved;
      this.is_active = extractsetuplist.is_active;
    }
  }