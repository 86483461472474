import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'edw-multiple-choice-list',
  templateUrl: './multiple-choice-list.component.html',
  styleUrls: ['./multiple-choice-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultipleChoiceListComponent implements OnInit, OnDestroy, OnChanges {
  //two-way bindings
  @Input() collection;
  @Output() collectionChange: EventEmitter<any> = new EventEmitter<any>();

  //one-way and plain string bindings
  @Input() autocompleteSuggestions = [];
  @Input() searchBarPlaceholder: string = 'Search';

  //this property limits the number of chosen
  //items from the autocomplete; if it's set to 0,
  //then it's assumed that there's no specific limit
  @Input() maxSelectedEntityNumber: number = 0;

  //events
  @Output() searchForEntitiesEvent: EventEmitter<any> = new EventEmitter();
  @Output() onSelectEntityEvent: EventEmitter<any> = new EventEmitter();

  @Input() isLoading: boolean = false;
  @Output() isLoadingChange: EventEmitter<any> = new EventEmitter()

  @ViewChild('autoCompleteRef', {static: true}) private autocomplete;

  query: string;
  maxSelectedEntityNumberReached: boolean = false;

  private maxSelectedEntityNumberReachedSubject = new BehaviorSubject<boolean>(false);
  private maxSelectedEntityNumberReachedObservable = this.maxSelectedEntityNumberReachedSubject.asObservable();
  private maxSelectedEntitySubscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.maxSelectedEntitySubscription = this.maxSelectedEntityNumberReachedObservable
      .subscribe((maxReached) => {
        this.maxSelectedEntityNumberReached = maxReached;
        this.changeDetector.detectChanges();
      });
  }

  ngOnDestroy() {
    this.maxSelectedEntitySubscription.unsubscribe();
  }

  ngOnChanges(changesObj) {
    if (changesObj.hasOwnProperty('collection')) {
      if (changesObj.collection.currentValue.length > 0) {
        if (this.maxSelectedEntityNumber > 0 && this.collection.length >= this.maxSelectedEntityNumber) {
          this.maxSelectedEntityNumberReachedSubject.next(true);
        } else {
          this.maxSelectedEntityNumberReachedSubject.next(false);
        }
      } else {
        this.maxSelectedEntityNumberReachedSubject.next(false);
      }
    }
  }

  clearSearch(autoCompleteRef) {
    this.query = '';
    //the next two lines are here because the autocomplete
    //component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';

    if (!this.maxSelectedEntityNumberReached) {
      autoCompleteRef.inputEL.nativeElement.focus();
    }
  }

  focusOnSearchField() {
    this.autocomplete.inputEL.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  blurFromSearchField() {
    this.autocomplete.inputEL.nativeElement.blur();
    this.changeDetector.detectChanges();
  }

  searchForEntities($event) {
    this.searchForEntitiesEvent.emit($event);
  }

  removeItem(selectedItemId) {
    this.collection = this.collection.filter((item) => {
      return selectedItemId !== item.id;
    });

    this.collectionChange.emit(this.collection);
  }

  onSelectItem(selectedItem, autoCompleteRef) {
    let isItemAddedToList = this.collection
      .some((item) => selectedItem.id === item.id);

    if (!isItemAddedToList) {
      this.collection.push(selectedItem);
    }

    if (this.maxSelectedEntityNumber > 0 && this.collection.length >= this.maxSelectedEntityNumber) {
      this.maxSelectedEntityNumberReachedSubject.next(true);
    } else {
      this.maxSelectedEntityNumberReachedSubject.next(false);
    }

    this.onSelectEntityEvent.emit(selectedItem);
    this.clearSearch(autoCompleteRef);
  }
}
