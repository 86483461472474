export const Permissions = {
  USERS: {
    canView: 'View_User',
    canDeactivate: 'Delete_User',
    canModify: 'Modify_User',
    canModifyContent: 'Modify_User_Content',
    CanMergeUser: 'Merge_User_Account'
  },

  USERGROUPS: {
    canView: 'View_UserGroup',
    canCreate: 'Create_UserGroup',
    canRemove: 'Delete_UserGroup',
    canModifyContent: 'Modify_UserGroup_Content',
  },

  RESOURCES: {
    canView: 'View_Resource',
    canCreate: 'Create_Resource', // Also used as Deploy_Resource
    canRemove: 'Delete_Resource',
    canModify: 'Modify_Resource',
    canModifyAssignments: 'Modify_Resource_Assignments',
    canApprove: 'Approve_Resource',
    canViewSQL: 'ViewDataSetSQL_Resource'
  },

  RESOURCEGROUPS: {
    canView: 'View_ResourceGroup',
    canCreate: 'Create_ResourceGroup',
    canRemove: 'Delete_ResourceGroup',
    canModifyContent: 'Modify_ResourceGroup_Content'
  },

  SUBSCRIPTIONS: {
    canView: 'View_Subscription',
    canCreate: 'Create_Subscription',
    canRemove: 'Delete_Subscription',
    canModify: 'Modify_Subscription',
    canApprove: 'Approve_Subscription'
  },

  EXTRACTS: {
    canView: 'View_Extract',
    canCreate: 'Create_Extract',
    canRemove: 'Delete_Extract',
    canModify: 'Modify_Extract',
    canApprove: 'Approve_Extract'
  },

  USER_EXTRACTS: {
    canManage: 'Manage_UserExtracts',
    canModifyContent: 'Modify_UserExtracts_Content'
  },

  PERMISSION_GROUPS: {
    canView: 'View_UserPermissionGroup',
    canCreate: 'Create_UserPermissionGroup',
    canDelete: 'Delete_UserPermissionGroup',
    canModifyContent: 'Modify_UserPermissionGroup_Content',
  },

  RESEARCH_REQUEST: {
    canManage: 'Manage_Research_Requests',
    canApprove: 'View_Research_ApprovalRequest',
    canDelete: 'Delete_Research'
  },

  COHORTS: {
    canView: 'View_Cohort',
    canCreate: 'Create_Cohort',
    canDelete: 'Delete_Cohort',
    canModifyContent: 'Modify_Cohort_Content',
    canApprove: 'Approve_Cohort'
  },

  APPROVAL_REQUEST: {
    canView: 'View_Research_ApprovalRequest',
    canApprove: 'Approve_Research_ApprovalRequest',
    canViewAll: 'View_Research_ApprovalRequest_All'
  },

  AUDIT: {
    canView: 'View_Audit'
  },

  ALERTS: {
    canManage: 'Manage_Alert'
  },

  PRODUCT_PAGES: {
    canManageProductPages: 'Manage_ProductPages'
  },
  METADATA: {
    canManageMetadata: 'Manage_Metadata'
  }

};

export const hasOverallPermissionsFor = function (permissionsGroup: any, permissionsArray: string[]) {
  const permissions = extractValuesFor(permissionsGroup);

  const hasPermission = permissionsArray.some((permission) => {
    return permissionsArray.indexOf(permission) > -1 ? true : false;
  });

  return hasPermission;
};

export const hasSpecificPermissionFor = function (specificPermission: string, permissionsArray: string[]) {
  return permissionsArray.indexOf(specificPermission) > -1 ? true : false;
};

function extractValuesFor(permissionGroup): string[] {
  const valuesArray: string[] = [];

  for (let prop in permissionGroup) {
    if (permissionGroup[prop]) {
      valuesArray.push(permissionGroup[prop]);
    }
  }

  return valuesArray;
}
