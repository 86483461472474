import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';

@Injectable()
export class ManageRequestsRouteGuard implements CanActivate {
  constructor(private router: Router,
              private userDataService: UserDataService,
              private sessionStorageService: SessionStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let canActivate = false;
    const canApproveReports = this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canApprove);
    const canApproveExtracts = this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canApprove);
    const canApproveSubscriptions = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canApprove);

    if (canApproveReports || canApproveExtracts || canApproveSubscriptions) {
      canActivate = true;
    }

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add('message-error', 'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.');
      //this.router.navigateByUrl('/app/resources/favorite');
    }

    return canActivate;
  }
}
