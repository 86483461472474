import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionStorageService } from '@edw-services/session-storage.service';

@Injectable()
export class AuthenticationRouteGuard implements CanActivate {
  constructor(private router: Router,
              private sessionStorageService: SessionStorageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let canActivate: boolean = false;
    let previousState = this.sessionStorageService.get('previousState');

    // no authenticated users should be able to
    // access this state
    // if (state.url === '/auth') {
    //   if (previousState !== 'app') {
    //     canActivate = true;
    //   } else {
    //     
    //       this.router.navigated = false;
    //       this.router.navigateByUrl('/app/resources/favorite');
    //     
    //   }
    // }

    if (state.url === '/auth') {
      canActivate = true;
    }

    // making sure we can access this state
    // only when we are unauthenticated and
    // the previous referrer was the /auth intermediate state
    if (state.url === '/auth/create-new-account') {
      if (previousState === 'auth' && this.sessionStorageService.get('newUserData')) {
        canActivate = true;
      } else {
        if (previousState === 'app') {
          this.router.navigated = false;
          this.router.navigateByUrl('/resources/reports/all');
        }
      }
    }

    return canActivate;
  }
}
