import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExtractConfigList } from '@edw-entities/extract-config-list';
import { ExtractConfigDataService } from '@edw-services/extract-config.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'edw-run-adhoc',
  templateUrl: './extract-run-adhoc.component.html',
  styleUrls: ['./extract-run-adhoc.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ExtractRunAdhocComponent implements OnInit, OnDestroy {
  labelextracts: string;
  extracts: ExtractConfigList[] = [];
  allRowsSelected: boolean = false;
  query: string;
  dialogVisible: boolean = false;


  @ViewChild('executeExtractConfirmationModal',{static: true}) executeExtractConfirmationModal;

  selectedForExecution: number;
  newExtracts: Object = {extract_configuration_keys: []}
  extract_setup_key: number = 0;
  extract_configuration_key: number = 0;
  permissions;

  subs : Subscription[] =[];
  // adhoc_extract_parameter: {
  //   extract_parameter_key: 0,
  //   extract_setup_key: 0,
  //   parameter_text: '',
  //   is_dynamic: false,
  //   default_value: '',
  //   data_type: '',
  //   user_editable: false,
  //   user_edited_value: ''
  // }[] = [];

  // visible: boolean;
  // visible2: boolean;
  //paramexists: boolean;

  constructor(
    private route: ActivatedRoute,
    private extractConfigDataService: ExtractConfigDataService,
    // private userDataService: UserDataService,
    // private extractDataService: ExtractDataService,
    // private loader: LoaderService,
    // private router: Router,
    // private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit() {
    this.subs.push(
      this.route.data.pipe( map( ( data: { componentData: any } ) => {
        this.permissions = data.componentData.permissions;
        this.extracts = data.componentData.extracts.map(element => {
          return new ExtractConfigList(element);
        });
      })).subscribe()
    );
  }

  ngOnDestroy(): void {
      this.subs.map(sub => sub.unsubscribe());
  }

  clearSearch(autoCompleteRef) {
    this.query = '';
    this.search();
  }

  search() {

    this.subs.push(
      this.extractConfigDataService.getExtractConfigList(this.query).pipe(
        map( response => {
          if (response['data'].length > 0) {
            this.extracts = response['data'].map(element => {
              return new ExtractConfigList(element);
            });
          }
        })
      ).subscribe()
    );
  }

  executeExtract(eck, esk) {
    this.extract_configuration_key = eck
    this.extract_setup_key = esk;
    this.executeExtractConfirmationModal.showDialog(this.extract_configuration_key);
  }

}

