export const ResourceType = {
  ssrs: {
    text: 'SSRS',
    value: 1
  },
  tableau: {
    text: 'TABLEAU',
    value: 2
  },
  extract: {
    text: 'EXTRACT',
    value: 3
  },
  powerbi: {
    text: 'POWER_BI_REPORT',
    value: 5
  }
}
