export class UserGroup {
  id: number;
  name: string;
  description: string;
  isSyncedWithAD: boolean;

  constructor(userGroup) {
    this.id = userGroup.id;
    this.name = userGroup.name;
    this.description = userGroup.description;
    this.isSyncedWithAD = userGroup.isSyncedWithAD;
  }
}
