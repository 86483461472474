import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root/permissions';
import { ProductsService } from '@edw-services/products.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Observable, pipe} from 'rxjs';
import { map} from 'rxjs/operators';
import { StagingReportsService } from '@edw-services/staging-reports.service';
@Injectable()
export class ProductPagesRoutingResolver implements Resolve<any> {

  constructor(
    private us: UserDataService,
    private productsService: ProductsService
  ) { }

  resolve(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    let id = route.params['id'];
    let componentData = {
      title: 'Product Pages',
      permissions: { canManageProductPages: this.us.checkForSpecificPermission(Permissions.PRODUCT_PAGES.canManageProductPages) },
      products:[]
    };

    if ( route.data.page === 'list') {
      return this.productsService.getAll('')
        .pipe(
          map((responseBody) => {
            componentData.products = responseBody['data'];
            return componentData;
          })
        );
    }

    if ( route.data.page === 'add' ) {
      componentData.title = 'Add Product Page';
      return componentData;
    }

    //edit page
    if (+id > 0) {
      return this.productsService.getProduct(id).pipe(
        map((details) => {
          componentData.products = details['data'];
          componentData.title = 'Edit Product Page';
          return componentData;
        })
      );
    }
  }
}

@Injectable({providedIn:'root'})
export class ResourceTeamListResolver implements Resolve<any> {
  constructor( private stagingService: StagingReportsService ) { }
  resolve(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return  this.stagingService.getResourceOwnerOptions().pipe( map((res) => res['data'] ) );
  }
}
