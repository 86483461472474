import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdhocExtract } from '@edw-entities/extract-adhoc';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { camelCaseToSnakeCase, snakeCaseToCamelCase } from './../shame';

@Injectable()
export class ExtractDataService {
  constructor(private http: HttpClient) { }

  approveExtract(ids: number[]): Observable<Object> {
    return this.http
      .post<Response>(environment.api + '/extractSetup/approveExtracts', ids);
  }

  adhocExtractRun(parameters: Object, extract_config_key: Object): Observable<Object> {
    let params = {
      extract_configuration_key: extract_config_key,
      adhoc_extract_parameter: parameters,
    };

    let body = snakeCaseToCamelCase(params);

    return this.http
      .post(environment.api + '/extract/runAdhocExtract', body);
  }

  executeExtract(extractObj: AdhocExtract): Observable<Object> {
    let postBody = snakeCaseToCamelCase(extractObj);

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/extract/execute', postBody, options);
  }

  runScheduled() {
    let body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http.post<Response>(environment.api + '/extract/executeSchedule', body)
      .pipe(
       map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  createDailySchedule() {
    const options: Object = {
      observe: 'body'
    };

    return this.http.get<Response>(environment.api + '/extract/createDailySchedule', options)
    .pipe(
     map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getScheduleExtract(query: string) {
    let params = new HttpParams();
    params = params.set('query', query);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extract/get', options)
      .pipe(
        map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getExecutionLog(query: string) {
    let params = new HttpParams();
    params = params.set('query', query);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extractexecutionlog/get', options)
      .pipe(
        map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getNotificationLog(query: string) {
    let params = new HttpParams();
    params = params.set('query', query);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extractnotificationlog/get', options)
      .pipe(
        map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getAdhocExtract(query: string) {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('approvals', 'false');

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extract/getAdhoc', options)
      .pipe(
        map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getPendingApprovals(query: string) {
    let params = new HttpParams();
    params = params.set('query', query);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extractSetup/GetExtractsPendingApproval', options)
      .pipe(
        map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getExtractParameter(id: string) {
    let params = new HttpParams();
    params = params.set('setupId', id);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extract/getParameterBySetupId', options)
      .pipe(
        map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getExtractParameterByExtractConfigId(extractConfigId: string) {
    let params = new HttpParams();
    params = params.set('extractConfigId', extractConfigId);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extract/getParameterByExtractConfigId', options)
      .pipe(
        map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }
}
