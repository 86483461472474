import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { SubscriptionDTO } from '@edw-entities/subscription';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-subscription-setup-list',
  templateUrl: './subscription-setup-list.component.html',
  styleUrls: ['./subscription-setup-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SubscriptionSetupListComponent implements OnInit, OnDestroy {
  subscriptions = [];
  query: string;

  permissions;

  @ViewChild('dataTable',{static:true}) dataTable;

  constructor(private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private loader: LoaderService,
              private genericErrorHandlerService: ErrorHandlerService,
              private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;

        this.subscriptions = data.componentData.subscriptions.map(element => {
          return {
            id: element.id,
            name: element.name,
            description: element.description
          };
        })
      });
  }

  ngOnDestroy() {

  }

  lazyLoadMore($event) {
    let lastPage = this.dataTable.totalRecords / this.dataTable.rows;
    let firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;

    if ($event.first === firstIndexOnLastPage) {
      //BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      let nextPage = Math.ceil(lastPage / 10) + 1;

      this.subscriptionService.getSubscriptionList('', nextPage)
        .subscribe(response => {
          if (response['data'] && response['data'].length > 0) {
            let newSubscriptions = response['data'].map(element => {
              return {
                id: element.id,
                name: element.name,
                description: element.description
              };
            });

            this.subscriptions = this.subscriptions.concat(newSubscriptions);
          }
        });
    }
  }

  clearSearch(autoCompleteRef) {
    this.query = '';
    //the next two lines are here because the autocomplete
    //component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  search() {
    this.subscriptionService.getSubscriptionList(this.query)
      .subscribe(responseBody => {
        this.subscriptions = [];
        if (responseBody['data']) {
          this.subscriptions = responseBody['data'].map(element => {
            return new SubscriptionDTO(element);
          });
        }
      });
  }

  deleteSubscription(subscription) {
    this.loader.showLoader();

    let rxjsSubscription: Subscription = this.subscriptionService
      .removeSubscription(subscription.id.toString())
      .subscribe(
        (response) => {
          if (response['data'] === true) {
            this.subscriptions = this.subscriptions.filter((subscriptionFromList) => {
              return subscriptionFromList.id !== subscription.id;
            });

            this.toastMessageService.addMessageObj({
              severity: 'success',
              summary: 'Success',
              detail: 'You have successfully deleted ' + subscription.name + ' from the subscriptions list.'
            });

            rxjsSubscription.unsubscribe();
            this.loader.hideLoader();
          }
        },
        (httpErrorResponse) => {
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          rxjsSubscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }
}

