import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { ToggleNavigationService } from '@edw-services/toggle-navigation.service';
import { UserDataService } from '@edw-services/user-data.service';
import { filter, map, pairwise } from 'rxjs/operators';
import { AppErrorType, AppErrorsHandler } from './custom-error-handlers/app-errors-handler.service';
import { AlertsService } from './services/alerts.service';
import { ToastMessageService } from './services/messaging.service';


@Component({
  selector: 'edw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  stickyMessage: boolean;

  constructor(private userDataSrevice: UserDataService,
    private router: Router,
    private loader: LoaderService,
    private sessionStorageService: SessionStorageService,
    private appErrorsHandlerService: AppErrorsHandler,
    private navService: ToggleNavigationService,
    private alertService: AlertsService,
    private toastMessageService : ToastMessageService) { }

  ngOnInit() {
    this.sessionStorageService.remove('previousState');
    this.sessionStorageService.remove('newUserData');

    // this here's a global observer for
    // all types of HTTP errors
    this.appErrorsHandlerService
      .getObservableForError(AppErrorType.HttpError)
      .subscribe((httpResponse) => {
        this.stickyMessage = true;
        let httpError;
        const message_error = this.sessionStorageService.get('message-error');

        if (httpResponse.error) {
          httpError = typeof httpResponse.error === 'string' ? JSON.parse(httpResponse.error).message : httpResponse.error.message;
        }

        const message = httpError || httpResponse.message;

        if (message_error) {

          this.toastMessageService.addMessageObj({
            sticky: this.stickyMessage,
            severity: 'error',
            summary: 'Unable to process requested action.',
            detail: message_error
          });
          this.removeErrMessageFromSession();
        }
        else {
          this.toastMessageService.addMessageObj({
            sticky: this.stickyMessage,
            severity: 'error',
            summary: 'Something went wrong.',
            detail: message
          });
        }
      });

    this.storePreviousPageState().subscribe();

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const urlFragments = event['url'].substring(1).split('/');
          this.stickyMessage = false;
          this.loader.showLoader();

          if (urlFragments.indexOf('resources') > -1 && urlFragments.indexOf('manage') === -1 && urlFragments.indexOf('requests') === -1 &&
            !isNaN(parseInt(urlFragments[urlFragments.length - 1], 10))) {
            this.navService.hideNav();
            this.alertService.showAlerts = false;
          } else {
            this.navService.showNav();
            this.alertService.showAlerts = true;
          }


          if (urlFragments.indexOf('viewsql') > -1 && urlFragments.indexOf('resources') > -1  && urlFragments.indexOf('requests') === -1 &&
            !isNaN(parseInt(urlFragments[urlFragments.length - 1], 10))) {
            this.navService.hideNav();
          }

        } else {
          setTimeout(() => {
            this.loader.hideLoader();
            const message = this.sessionStorageService.get('message');
            const message_error = this.sessionStorageService.get('message-error');
            this.removeMessageFromSession();
            this.removeErrMessageFromSession();

            setTimeout(() => {
              if (message) {
                if (typeof message === 'string') {
                  this.toastMessageService.addMessageObj({
                    sticky: this.stickyMessage,
                    severity: 'success',
                    summary: '',
                    detail: message
                  });
                } else if (message_error) {
                  this.toastMessageService.addMessageObj({
                    sticky: this.stickyMessage,
                    severity: 'error',
                    summary: 'Unable to process requested action.',
                    detail: message_error
                  });

                } else {
                  this.toastMessageService.addMessageObj({
                    sticky: this.stickyMessage,
                    severity: message.type,
                    summary: '',
                    detail: message.text
                  });
                }
              }
            }, 200);
          }, 200);
        }


      });
  }


  storePreviousPageState = () => {
        // we persist the previous state in sessionStorage
    // cause the native router doesn't give us
    // this information :)
    return this.router.events.pipe( filter(e => e.constructor.name === 'RoutesRecognized'), pairwise()).pipe(
      map((e: any[]) => {

        const paramsArray = e[0].urlAfterRedirects.split('/');

        this.sessionStorageService.add('previousState', paramsArray[1]);

        if (paramsArray[1] === 'app') {
          this.sessionStorageService.add('previousSubStateArr', paramsArray.slice(2));
        }

      })
    );
  }


  removeMessageFromSession() {
    this.sessionStorageService.remove('message');
  }

  removeErrMessageFromSession() {
    this.sessionStorageService.remove('message-error');
  }
}
