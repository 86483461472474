import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { ExtractSetupDependency, ExtractSetupOutputConfig, ExtractSetupParameter } from '@edw-entities/extract-setup-entities';
import { ExtractSetupOptions } from '@edw-entities/extract-setup-options';
import { ExtractSetupDataService } from '@edw-services/extract-setup.service';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserDataService } from '@edw-services/user-data.service';
import { SelectItem } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-extract-setup',
  templateUrl: './extract-setup.component.html',
  styleUrls: ['./extract-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExtractSetupComponent implements OnInit {
  title: string;
  isInAddNewMode: boolean = false;
  deleteDialogVisible: boolean = false;

  permissions;
  newExtracts = {
    extract_priority_key: 0,
    extract_name: '',
    extract_description: '',
    extract_business_owner: '',
    extract_developer: '',
    extract_sp_name: '',
    extract_sp_code: '',
    effective_from_dttm: '',
    effective_to_dttm: '',
    notification_email_list: '',
    is_approved: false,
    is_active: true,
    extract_output_configuration: this.createAnArrWithFixedLength(new ExtractSetupOutputConfig(), 1),
    extract_dependency: this.createAnArrWithFixedLength(new ExtractSetupDependency(), 1),
    extract_parameter: this.createAnArrWithFixedLength(new ExtractSetupParameter(), 1)
  };

  ui = {
    extractOutputConfigurations: [],
    extractParameters: [],
    extractDependencies: []
  };

  formSubmitted: boolean = false;
  eso: ExtractSetupOptions;

  priorityname: SelectItem[] = [
    { label: 'Very Low', value: 1 },
    { label: 'Low', value: 2 },
    { label: 'Medium', value: 3 },
    { label: 'High', value: 4 },
    { label: 'Very High', value: 5 },
    { label: 'Urgent', value: 6 }
  ];

  boolvalue: SelectItem[] = [
    { label: 'False', value: false },
    { label: 'True', value: true }
  ];

  datatype: SelectItem[] = [
    { label: 'INT', value: 'Int' },
    { label: 'VARCHAR', value: 'varchar(25)' },
    { label: 'DATETIME', value: 'Datetime2(2)' },
    { label: 'NUMERIC', value: 'Numeric(18,0)' },
    { label: 'DOUBLE', value: 'Double' },
    { label: 'FLOAT', value: 'Float' }
  ];

  deplist: Object[] = [];
  destlist: Object[] = [];
  fmtlist: Object[] = [];
  dynfilelist: Object[] = [];
  splist: Object[] = [];
  supportsTextareaResize: boolean = true;

  constructor(private userDataService: UserDataService,
    private extractSetupDataService: ExtractSetupDataService,
    private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private sessionStorageService: SessionStorageService,
    private toastMessageService : ToastMessageService
    ) {
    this.supportsTextareaResize = document.createElement('textarea').style.resize !== undefined;
  }

  ngOnInit() {
    this.loadExtractSetupOptions();

    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;
        if (data.componentData.hasOwnProperty('newExtracts')) {
          this.newExtracts = data.componentData['newExtracts'];

          if (this.newExtracts['extract_output_configuration'].length === 0) {
            this.ui.extractOutputConfigurations.push(new ExtractSetupOutputConfig());
          } else {
            this.ui.extractOutputConfigurations = this.copyObjectsToANewArray(this.newExtracts['extract_output_configuration']);
          }

          if (this.newExtracts['extract_dependency'].length === 0) {
            this.ui.extractDependencies.push(new ExtractSetupDependency());
          } else {
            this.ui.extractDependencies = this.copyObjectsToANewArray(this.newExtracts['extract_dependency']);
          }

          if (this.newExtracts['extract_parameter'].length === 0) {
            let newParameter = new ExtractSetupParameter();
            newParameter.data_type = this.datatype[0].value;

            this.newExtracts['extract_parameter'].push(newParameter);
            this.ui.extractParameters.push(newParameter);
          } else {
            this.ui.extractParameters = this.copyObjectsToANewArray(this.newExtracts['extract_parameter']);
          }

        } else {
          this.ui.extractOutputConfigurations.push(new ExtractSetupOutputConfig());
          this.ui.extractDependencies.push(new ExtractSetupDependency());
          this.ui.extractParameters.push(new ExtractSetupParameter());

          this.isInAddNewMode = true;
        }

        this.title = data.componentData.title;
      });
  }

  clearOutputConfigName($event, outputConfig) {
    if ($event === true) {
      outputConfig.static_filename = '';
    } else {
      outputConfig.output_filename_key = 0;
    }

    this.changeDetector.detectChanges();
  }

  submitNewExtractSetup(form) {
    this.formSubmitted = true;

    if (!form.valid
      || this.newExtracts.extract_priority_key === 0
      || this.newExtracts.extract_sp_name === ''
      || !this.areExtractParametersValid()) {
      return;
    }

    if (this.isInAddNewMode === true) {
      this.newExtracts.extract_parameter = this.copyObjectsToANewArray(this.ui.extractParameters);
      this.newExtracts.extract_dependency = this.copyObjectsToANewArray(this.ui.extractDependencies);
      this.newExtracts.extract_output_configuration = this.copyObjectsToANewArray(this.ui.extractOutputConfigurations);
    } else {
      this.newExtracts.extract_parameter = this.newExtracts.extract_parameter.map((extractParam, index) => {
        let uiExtractParams = this.ui.extractParameters.filter((userGeneratedParam) => {
          return userGeneratedParam.parameter_text === extractParam.parameter_text;
        });

        if (uiExtractParams[0]) {
          extractParam = Object.assign({}, uiExtractParams[0]);
        }

        return extractParam;
      });

      this.newExtracts.extract_dependency = this.newExtracts.extract_dependency.map((extractDependency, index) => {
        let uiExtractDependency = this.ui.extractDependencies.filter((userGeneratedDep) => {
          return userGeneratedDep.extract_dependency_key === extractDependency.extract_dependency_key;
        })[0];

        if (uiExtractDependency) {
          extractDependency = Object.assign({}, uiExtractDependency);
        }

        return extractDependency;
      });

      this.newExtracts.extract_output_configuration = this.newExtracts.extract_output_configuration.map((extractOutput, index) => {
        let uiExtractOutput = this.ui.extractOutputConfigurations.filter((userGeneratedOutput) => {
          return userGeneratedOutput.extract_output_configuration_key === extractOutput.extract_output_configuration_key;
        })[0];

        if (uiExtractOutput) {
          extractOutput = Object.assign({}, uiExtractOutput);
        }

        return extractOutput;
      });
    }

    if (this.newExtracts.extract_output_configuration.some(function (outputConfig: ExtractSetupOutputConfig) {
        return outputConfig.output_destination_key !== 0
          && outputConfig.output_format_key !== 0
          && outputConfig.output_subfolder !== ''
          && (outputConfig.output_filename_key !== 0 || outputConfig.static_filename !== '')
      })) {
      if (this.isInAddNewMode === true) {
        this.addNewExtract();
      } else {
        this.editExtract();
      }
    }
  }

  private areExtractParametersValid(): boolean {
    let hasDuplicates: boolean = this.newExtracts.extract_parameter.some(extractParam => {
      let uiExtractParams = this.ui.extractParameters.filter((userGeneratedParam) => {
        return userGeneratedParam.parameter_text === extractParam.parameter_text;
      });
      if (uiExtractParams.length > 1) {
        this.toastMessageService.addMessageObj({
          severity: 'error',
          summary: 'Error',
          detail: 'An extract parameter with the same name (' + extractParam.parameter_text + ') already exists.'
        });
      }
      return uiExtractParams.length > 1;
    });

    return !hasDuplicates;
  }

  private addNewExtract() {
    this.loader.showLoader();
    const subscription: Subscription = this.extractSetupDataService
      .addExtractSetup(this.newExtracts)
      .subscribe(
      (response) => {
        if (response['data'] && parseInt(response['data'])) {
          this.sessionStorageService.add('message', 'Successfully added new extract ' + this.newExtracts['extract_name']);
          this.router.navigate(['/app/manage/extracts/extract-setup-list']);
        }

        subscription.unsubscribe();
      },

      (httpErrorResponse) => {
        let httpError;
        if (httpErrorResponse.error) {
          httpError = typeof httpErrorResponse.error === 'string' ? JSON.parse(httpErrorResponse.error).message : httpErrorResponse.error.message;
        }

        let message = httpError || httpErrorResponse.message;

        this.toastMessageService.addMessageObj({
          severity: 'error',
          summary: 'Something went wrong.',
          detail: message
        });

        this.loader.hideLoader();
        subscription.unsubscribe();
      });
  }

  private editExtract() {
    let extractFields = Object.assign({}, this.newExtracts);
    this.loader.showLoader();
    const subscription: Subscription = this.extractSetupDataService
      .editExtractSetup(extractFields)
      .subscribe(response => {
        if (response['data'] === true) {
          this.sessionStorageService.add('message', 'Successfully updated extract ' + this.newExtracts['extract_name']);
          this.router.navigate(['/app/manage/extracts/extract-setup-list']);

        };
        subscription.unsubscribe();
      }, httpErrorResponse => {
        let httpError;
        if (httpErrorResponse.error) {
          httpError = typeof httpErrorResponse.error === 'string' ? JSON.parse(httpErrorResponse.error).message : httpErrorResponse.error.message;
        }
        let message = httpError || httpErrorResponse.message;

        this.toastMessageService.addMessageObj({
          severity: 'error',
          summary: 'Something went wrong.',
          detail: message
        });
        this.loader.hideLoader();
        subscription.unsubscribe();
      });
  }

  showConfirmationDialog() {
    this.deleteDialogVisible = true;
  }

  hideConfirmationDialog() {
    this.deleteDialogVisible = false;
  }

  deactivateExtractSetup() {
    this.hideConfirmationDialog();
    let extractFields = this.newExtracts['extract_setup_key'];

    const subscription: Subscription = this.extractSetupDataService
      .deactivateExtractSetup(extractFields)
      .subscribe(response => {
        if (response['data'] === true) {
          this.sessionStorageService.add('message', 'Successfully removed extract');
          this.router.navigate(['/app/manage/extracts/extract-setup-list']);
        }

        subscription.unsubscribe();
      });
  }

  loadExtractSetupOptions() {
    this.extractSetupDataService
      .getExtractSetupOptions()
      .subscribe(responseBody => {
        if (responseBody['data']) {
          this.eso = responseBody['data'];

          this.dynfilelist = this.eso['dynamic_filename_list'].map((fl) => {
            return {
              label: fl.filename_text + '--' + fl.filename_description,
              value: fl.output_filename_key
            };
          });

          this.fmtlist = this.eso['output_format_list'].map((fmt) => {
            return {
              label: fmt.format_text,
              value: fmt.output_format_key
            };
          });

          this.destlist = this.eso['output_destination_list'].map((dest) => {
            return {
              label: dest.destination_description + '--' + dest.destination_path,
              value: dest.output_destination_key
            };
          });

          this.deplist = this.eso['dependency_list'].map((dep) => {
            return {
              label: dep.dependency_text,
              value: dep.dependency_detail_key
            };
          })

          this.splist = this.eso['stored_procedure_list']
            .filter((storedProcedure) => {
              return storedProcedure !== null;
            })
            .map((storedProcedure) => {
              return {
                label: storedProcedure.extract_sp_name,
                value: storedProcedure.extract_sp_name
              };
            });
        }
      });
  }

  getSP($event) {
    this.newExtracts['extract_sp_name'] = $event.value;
  }

  clearOutput(index) {
    if (this.ui.extractOutputConfigurations.length > 1) {
      this.newExtracts['extract_output_configuration'][index] = new ExtractSetupOutputConfig();
      this.ui.extractOutputConfigurations.splice(index, 1);
    }
  }

  addDependency() {
    if (this.ui.extractDependencies.length < 5) {
      let newDependency = new ExtractSetupDependency();

      this.newExtracts['extract_dependency'].push(newDependency);
      this.ui.extractDependencies.push(newDependency);
    } else {
      this.toastMessageService.addMessageObj({
        severity: 'error',
        summary: 'Error',
        detail: 'You cannot add more than 5 extract dependencies'
      });
    }
  }

  clearDependency(index) {
    if (this.ui.extractDependencies.length > 1) {
      this.newExtracts['extract_dependency'][index].dependency_detail_key = 0;
      this.ui.extractDependencies.splice(index, 1);
    }
  }

  addParameter() {
    if (this.ui.extractParameters.length < 9) {
      let newParameter = new ExtractSetupParameter();
      newParameter.data_type = this.datatype[0].value;

      this.newExtracts['extract_parameter'].push(newParameter);
      this.ui.extractParameters.push(newParameter);
    } else {
      this.toastMessageService.addMessageObj({
        severity: 'error',
        summary: 'Error',
        detail: 'You cannot add more than 9 extract parameters'
      });
    }
  }

  clearParameter(index) {
    this.ui.extractParameters.splice(index, 1);
    this.newExtracts['extract_parameter'].splice(index, 1);

    if (this.ui.extractParameters.length < 1) {
      this.addParameter();
    }
  }

  addOutput() {
    if (this.ui.extractOutputConfigurations.length < 3) {
      let newOutput = new ExtractSetupOutputConfig();

      this.ui.extractOutputConfigurations.push(newOutput);
      this.newExtracts['extract_output_configuration'].push(newOutput);
    } else {
      this.toastMessageService.addMessageObj({
        severity: 'error',
        summary: 'Error',
        detail: 'You cannot add more than 3 output configurations'
      });
    }
  }

  private createAnArrWithFixedLength(value: any, length: number): Array<any> {
    let arr = [];

    for (let i = 0; i < length; i++) {
      if (typeof value === 'object') {
        arr.push(Object.assign({}, value));
      } else {
        arr.push(value);
      }
    }

    return arr;
  }

  private copyObjectsToANewArray(arr) {
    arr = arr.map((obj) => {
      if (obj instanceof File) {
        return {
          name: obj.name,
          type: obj.type,
          lastModified: obj.lastModified
        };
      } else {
        return Object.assign({}, obj);
      }
    });

    return arr;
  }
}
