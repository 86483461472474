import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IActivityDetail } from '@edw-app-root/entities/research-activity-type';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { ResearchRequestsService } from '@edw-services/research-requests.service';

@Component({
  selector: 'edw-data-steward-log-view',
  templateUrl: './data-steward-log-view.component.html',
  styleUrls: ['./data-steward-log-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataStewardLogViewComponent implements OnInit {

  public researchId: number;
  public activites: IActivityDetail[];
  public form: FormGroup;


  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private rrService: ResearchRequestsService,
    private errorService: ErrorHandlerService,
    private toastMessageService : ToastMessageService
  )
  {
    this.form = this.formBuilder.group({'comment': ['',[Validators.required]]});
  }



  ngOnInit() {
    this.route.data
      .subscribe((data: { activites: IActivityDetail[] }) => {
       this.activites = data.activites
      });

      var lastParameter = this.route.snapshot.url.length -1;
      this.researchId = +this.route.snapshot.url[lastParameter].path;

  }

  public addComment(){
  var comment = this.form.get('comment').value

    this.rrService.addComment(this.researchId, comment)
     .subscribe((response: any) => {
      if (response) {

        this.toastMessageService.addMessageObj({severity: 'success', detail: 'Comment added successfully.'})

        this.updateActivityLog();
        this.form.reset();
      }
    }, (httpErrorResponse) => {
      this.errorService.httpErrorHandler(httpErrorResponse);
    });
  }

  private updateActivityLog(){
    this.rrService.getActivityDetails(this.researchId.toString())
    .subscribe((response: any) => {
      if (response) {
      this.activites = response;
      }
    });}

  }

