export class Alert {
  id: number;
  name: string;
  title: string;
  body: string;
  type: string;
  startDate: Date|string;
  expiryDate: Date|string;
  createdbyuserkey: number;
  createdate: string;
  modifybyuserkey: number;
  modifydate: string;
  version: number;
  active: string;

  constructor(alert) {
    this.id = alert.id;
    this.name = alert.name;
    this.title = alert.title;
    this.body = alert.body;
    this.type = alert.type;
    this.startDate = new Date(alert.startDate) ?? alert.startDate;
    this.expiryDate = new Date(alert.expiryDate) ?? alert.expiryDate;
    this.createdbyuserkey = alert.createdbyuserkey;
    this.createdate = alert.createdate;
    this.modifybyuserkey = alert.modifybyuserkey;
    this.modifydate = alert.modifydate;
    this.version = alert.version;
    this.active = alert.active;
  }
}

export interface IAlert {
  id?: number;
  name: string;
  title: string;
  body: string;
  type?: string;
  startDate?: Date|string;
  expiryDate?: Date|string;
  createdbyuserkey?: number;
  createdate?: boolean;
  modifybyuserkey?: number;
  modifydate?: boolean;
  version?: number;
  active?: boolean;
}

