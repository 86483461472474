import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataService } from '@edw-services/user-data.service';
import { UserSettingsService } from '@edw-services/user-settings.service';
import { UserSettings } from '@edw-app-root/entities/user-settings';
import { LocalStorageService } from '@edw-services/local-storage.service';
import { SessionStorageService } from '@edw-services/session-storage.service';

@Component({
  selector: 'edw-check-account',
  templateUrl: './check-account.component.html',
  styleUrls: ['./check-account.component.scss']
})
export class CheckAccountComponent implements OnInit {

  private userSettings: UserSettings;

  constructor(private userDataService: UserDataService,
    private userSettingsService: UserSettingsService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private sessionStorage: SessionStorageService) { }

  ngOnInit() {
    this.localStorageService.remove('currentUser');
    this.userDataService.checkIfUserExists()
      .subscribe((response) => {
        if (response['data'].isExisting === false) {
          this.sessionStorage.add('newUserData', response['data'].userInfo);
          this.router.navigate(['/auth/create-new-account']);
        } else {
          this.userSettingsService.getUserSettings()
            .subscribe((response) => {

              if (!Boolean(response.data["isActive"])) {
                this.router.navigate(['/auth/inactive-account']);
              } else {

                var defaultPage = response.data["defaultPage"];
                this.router.navigate([defaultPage]);
                this.userDataService.markAuthenticationAsPassed();
              }

            });
        }
      });
  }
}
