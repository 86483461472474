import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Observable } from 'rxjs';

@Injectable()
export class ManagePermissionsGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = false;

    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];

    const canViewPermissionGroups = this.userDataService.checkForSpecificPermission(Permissions.PERMISSION_GROUPS.canView);


    if (urlFragments.indexOf('permission-groups') > -1) {
      if (canViewPermissionGroups) {
        canActivate = true;
      }
    }

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }

    return canActivate;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let canActivate = false;

    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];
    //#region Permission Groups
    if (urlFragments.indexOf('permission-groups') > -1) {
      const canViewPermissionGroups = this.userDataService.checkForSpecificPermission(Permissions.PERMISSION_GROUPS.canView);
      const canCreatePermissionGroups = this.userDataService.checkForSpecificPermission(Permissions.PERMISSION_GROUPS.canCreate);

      // permission-groups/
      if (lastParam === 'permission-groups') {
        canActivate = canViewPermissionGroups ? true : false;
      }

      // permission-groups/{id}
      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        canActivate = canViewPermissionGroups ? true : false;
      }

      // permission-groups/add
      if (lastParam === 'add') {
        canActivate = canCreatePermissionGroups ? true : false;
      }
    }
    //#endregion Permission Groups

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }

    return canActivate;
  }
}
