import { Component, Injector, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorageService } from "@edw-app-root/services/session-storage.service";
import { AuditsDataService } from "@edw-services/audits.service";
import { ToastMessageService } from "@edw-services/messaging.service";
import { NotificationsService } from "@edw-services/notifications.service";
import { ToggleNavigationService } from "@edw-services/toggle-navigation.service";
import { UserDataService } from "@edw-services/user-data.service";
import * as moment from 'moment';
import { Subscription } from "rxjs";

@Component({
  selector: "edw-app-wrapper",
  templateUrl: "./app-wrapper.component.html",
  styleUrls: ["./app-wrapper.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppWrapperComponent implements OnInit, OnDestroy {

  private subs: Subscription[];

  showHipaaComplianceModal = false;
  shownewuserMessageModal = false;
  shownNmSafetyIntegrity = false;
  confirmHipaaCompliance = false;
  isNmSafetyCompleted = true;
  isNmSafetynewuser = false;
  leftNavVisible = true;
  alertsVisible = true;
  isLoadBehind: boolean;
  loadStatus = "";
  domain = "nmhc";
  calendaryear = '2020';



  toggleLeftNav(show) {
    this.leftNavVisible = !this.leftNavVisible;
  }

  constructor(
    private userDataSrevice: UserDataService,
    private notificationsService: NotificationsService,
    private navService: ToggleNavigationService,
    private toastMessageService: ToastMessageService,
    private auditService: AuditsDataService,
    private sessionService : SessionStorageService,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.calendaryear = moment(new Date()).format('YYYY');
    this.subs = [
      this.userDataSrevice.userInfoLoadedObservable.subscribe((info) => {
        this.domain = (info.loaded === true) ? this.userDataSrevice.getUserDomain() : "nmhc";
      }),
      this.notificationsService.getUnreadCount().subscribe((count) => { this.notificationsService.broadcastNotificationsCountChange(count);}),
      this.notificationsService.getLastSystemLoadDate().subscribe((status) => { this.notificationsService.broadcastLoadStatustChange(status);}),
      this.navService.getObservable().subscribe((info) => { this.leftNavVisible = info.show === true; }),
      this.userDataSrevice.isnewUserObservable.subscribe((value) => {this.shownewuserMessageModal = value;}),
      this.userDataSrevice.isNmSafetyNewUserObservable.subscribe((value) => { this.isNmSafetynewuser = value;}),
      this.notificationsService.notificationsLoadStatusObservable.subscribe( (status) => { this.loadStatus = status.lastLoadDate; this.isLoadBehind = status.isBehind; })
    ]

    this.userDataSrevice.invokeIsNewUser();
    this.userDataSrevice.invokeIsNmSafetyNewUser();
    this.userDataSrevice.invokeIsNmSafetyCompleted();

    this.evaluateRedirect();

  }

  ngOnDestroy() {
    this.subs.map(sub => sub.unsubscribe());
  }

  /**
   * If attempting to load a link without authentication, we will
   * store the original url in sessionStorage and redirect to login page.
   * We will redirect to this redirectUrl after the user authenticates.
   * If no redirect url is present, we will go to the default main page.
   */
  evaluateRedirect = () =>{
    try{
      let url = this.sessionService.get('redirectUrl');
      if( url != null ){
        // navigateByUrl needs the absolute url,
        // we will use whatever is after the /#/ in url.
        url = url.split("/#/")[1];
        this.sessionService.remove('redirectUrl');
        const router = this.injector.get(Router);
        router.navigateByUrl(url);
      }
    }
    catch(e){console.log(e);}
  }

  saveNmSafetyIntegrity(value) {
    this.shownNmSafetyIntegrity = false;

    this.auditService.updateNmSafetyIntegrity(value).subscribe((result) => {
      this.toastMessageService.addMessageObj({
        severity: "success",
        summary: "",
        detail: "Input saved successfuly.",
      });

    });
  }

  newUserMessageUpdate() {
    this.shownewuserMessageModal = false;
    this.userDataSrevice.invokeIsNmSafetyCompleted();
  }

}
