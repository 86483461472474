import { AbstractControl } from '@angular/forms';

export function RequiredDeadlineReasonText(control: AbstractControl): {[key: string]: boolean} {

  const deadline = control.get('deadline');
  const deadlineReason = control.get('deadlineReason');
  const deadlineReasonText = control.get('deadlineReasonText');

  if (deadline == null || deadlineReason == null || deadlineReasonText == null) {
    return null;
  }
  if (deadline.value == null || deadlineReason.value == null || deadlineReasonText.value == null) {
    return null;
  }

  // tslint:disable-next-line:max-line-length
  return (deadlineReason.value.toLowerCase() === 'other' && deadlineReasonText.value !== '') || (deadlineReason.value.toLowerCase() !== 'other' && deadlineReasonText.value === '') ? null : { requiredDeadlineReasonText: true };
}
