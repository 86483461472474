import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RelatedEntityService } from '@edw-entities/related-entity-service.interface';
import { User } from '@edw-entities/user';
import { UserDetails } from '@edw-entities/userDetails';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import * as moment from 'moment';

@Component({
  selector: 'edw-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  public title: string;

  public isInAddNewMode = false;
  public deleteDialogVisible = false;

  public formSubmitted = false;
  public displayMergeAccountDialog = false;

  public userDetails: UserDetails = new UserDetails();

  public permissions;
  public query: string;

  public userGroupSuggestions;
  public resourceGroupsSuggestions;
  public resourceSuggestions;

  nmSelection = 'N/A';
  nmSelectionDate = 'N/A';

  public relatedEntitiesPermissions = {
    userGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    permissionGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    resources: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    resourceGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    }
  };

  public userGroupHandler: RelatedEntityService;
  public permissionGroupHandler: RelatedEntityService;
  public resourceHandler: RelatedEntityService;
  public resourceGroupHandler: RelatedEntityService;

  @ViewChild('accordion',{static: true}) accordion;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private userService: UserDataService,
    private loader: LoaderService,
    private errorService: ErrorHandlerService,
    private toastMessageService: ToastMessageService
  ) { }


  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;

        this.relatedEntitiesPermissions.userGroups.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.userGroups.canRemoveEntity = this.permissions.canModifyContent;

        this.relatedEntitiesPermissions.permissionGroups.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.permissionGroups.canRemoveEntity = this.permissions.canModifyContent;

        this.relatedEntitiesPermissions.resources.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.resources.canRemoveEntity = this.permissions.canModifyContent;

        this.relatedEntitiesPermissions.resourceGroups.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.resourceGroups.canRemoveEntity = this.permissions.canModifyContent;

        if (data.componentData.hasOwnProperty('userDetails')) {
          this.setUserDetails(data.componentData['userDetails']);

        } else {
          this.setUserDetails(null);
          this.isInAddNewMode = true;
        }

        this.title = data.componentData.title;
      });

    this.userGroupHandler = {
      getAddedEntity: this.userService.findAddedUserGroup,
      getNonAddedEntity: this.userService.findNonAddedUserGroup,
      persistEntityList: this.userService.associateUserWithUserGroups,
      removeEntityFromGroup: this.userService.removeUserToUserGroupsRelationship
    };

    this.resourceGroupHandler = {
      getAddedEntity: this.userService.findAddedResourceGroup,
      getNonAddedEntity: this.userService.findNonAddedResourceGroup,
      persistEntityList: this.userService.associateUserWithResourceGroups,
      removeEntityFromGroup: this.userService.removeUserToResourceGroupsRelationship
    };
    this.permissionGroupHandler = {
      getAddedEntity: this.userService.findAddedPermissionGroup,
      getNonAddedEntity: this.userService.findNonAddedPermissionGroup,
      persistEntityList: this.userService.associateUserWithPermissionGroups,
      removeEntityFromGroup: this.userService.removeUserToPermissionGroupsRelationship
    };

    this.resourceHandler = {
      getAddedEntity: this.userService.findAddedResource,
      getNonAddedEntity: this.userService.findNonAddedResource,
      persistEntityList: this.userService.associateUserWithResources,
      removeEntityFromGroup: this.userService.removeUserToResourcesRelationship
    };
  }

  ngOnDestroy() {
    this.sessionStorageService.remove('currentUserDetails');
  }

  hasMergedAccount() {
    return !(this.userDetails.accounts.findIndex(e => e.accountId === undefined) > -1);
  }
  setUserDetails(newUserDetails) {
    this.userDetails = new UserDetails(newUserDetails);

    console.log(this.userDetails.accounts.length);
    if (this.userDetails.userGroups.length === 0) {
      this.userDetails.userGroups = [];
    } else {
      this.updateUserGroupCollection(newUserDetails.userGroups);
    }

    if (this.userDetails.modifyDate === null) {
      this.userDetails.modifyDate = this.userDetails.createDate;
    }

    if (this.userDetails.NmSafetySelection == 1) {
      this.nmSelection = 'Yes';
      console.log(this.userDetails.NmSafetyUpdatedDate);
      this.nmSelectionDate = moment(this.userDetails.NmSafetyUpdatedDate).format('MM/DD/YYYY hh:mm A');

    } else if (this.userDetails.NmSafetySelection == 2) {
      this.nmSelection = 'No';
      this.nmSelectionDate = moment(this.userDetails.NmSafetyUpdatedDate).format('MM/DD/YYYY hh:mm A');

    }

    if (this.userDetails.resourceGroups.length === 0) {
      this.userDetails.resourceGroups = [];
    } else {
      this.updateResourceGroupCollection(newUserDetails.resourceGroups);
    }

    if (this.userDetails.resources.length === 0) {
      this.userDetails.resources = [];
    } else {
      this.updateResourceCollection(newUserDetails.resources);
    }

    if (this.userDetails.permissionGroups.length === 0) {
      this.userDetails.permissionGroups = [];
    } else {
      this.updatePermissionGroupsCollection(newUserDetails.permissionGroups);
    }

    this.sessionStorageService.add('currentUserDetails', this.userDetails);
  }

  atLeastOneAccountIsFilled() {
    return this.userDetails.accounts.some((account) => {
      return account.accountName !== '' && account.accountName !== null;
    });
  }

  saveUser(form) {
    this.formSubmitted = true;

    if (form.valid) {
      if (this.isInAddNewMode === true) {
        this.addNewUser();
      } else {
        this.editUser();
      }
    }
  }

  private addNewUser() {
    let newUser = new User(this.userDetails);
    this.loader.showLoader();
    const subscription: Subscription = this.userService
      .addUser(newUser)
      .pipe(finalize(() => {
        subscription.unsubscribe();
      }))
      .subscribe(response => {
        if (response['data'] && parseInt(response['data'])) {
          this.router.navigate(['/app/manage/users', response['data']]);
        }
      }, httpErrorResponse => {
        this.errorService.httpErrorHandler(httpErrorResponse);
        this.loader.hideLoader();
      });
  }

  private editUser() {
    let userFields = new User(this.userDetails);
    this.loader.showLoader();
    const subscription: Subscription = this.userService
      .updateUserFields(userFields)
      .subscribe(response => {
        if (response['data']) {
          this.toastMessageService.addMessageObj({
            severity: 'success',
            summary: '',
            detail: 'User has been updated successfully.'
          });
        }
        this.loader.hideLoader();
        subscription.unsubscribe();
        this.setUserDetails(response['data']);
      }, httpErrorResponse => {
        let httpError;
        if (httpErrorResponse.error) {
          httpError = typeof httpErrorResponse.error === 'string' ? JSON.parse(httpErrorResponse.error).message : httpErrorResponse.error.message;
        }
        let message = httpError || httpErrorResponse.message;

        this.toastMessageService.addMessageObj({
          severity: 'error',
          summary: 'Something went wrong.',
          detail: message
        });
        this.loader.hideLoader();
        subscription.unsubscribe();
      });
  }

  private updateUserGroupCollection(data) {
    this.userDetails.userGroups = data
      .map((userGroup) => {
        userGroup['displayName'] = userGroup.name;

        if (userGroup.description) {
          userGroup['displayName'] = userGroup['displayName'] + ' - (' + userGroup.description + ')';
        }

        userGroup['link'] = ['/app/manage/user-groups/', userGroup.id];
        return userGroup;
      });
  }

  private updateResourceGroupCollection(data) {
    this.userDetails.resourceGroups = data
      .map((resourceGroup) => {
        resourceGroup['displayName'] = resourceGroup.name;

        if (resourceGroup.description) {
          resourceGroup['displayName'] = resourceGroup['displayName'] + ' - (' + resourceGroup.description + ')';
        }

        resourceGroup['link'] = ['/app/manage/resource-groups/', resourceGroup.id];
        return resourceGroup;
      });
  }

  private updatePermissionGroupsCollection(data) {
    this.userDetails.permissionGroups = data
      .map((permissionGroup) => {
        permissionGroup['displayName'] = permissionGroup.name;

        if (permissionGroup.description) {
          permissionGroup['displayName'] = permissionGroup['displayName'] + ' - (' + permissionGroup.description + ')';
        }

        permissionGroup['link'] = ['/app/manage/permission-groups/', permissionGroup.id];
        return permissionGroup;
      });
  }

  private updateResourceCollection(data) {
    this.userDetails.resources = data
      .map((resource) => {
        resource['displayName'] = resource.resourceName + ' (' + resource.resourceType + ')';
        resource['link'] = ['/app/manage/resources/deployed/', resource.id];

        return resource;
      });
  }


  handleMergeResult($event) {
    if ($event == "true") {

      this.toastMessageService.addMessageObj({
        severity: 'success',
        summary: '',
        detail: 'Merge has been scheduled successfully.'
      });

      window.location.reload();
    }
    else {
      this.toastMessageService.addMessageObj({
        severity: 'error',
        summary: 'Something went wrong.',
        detail: 'If error persist, please contact EDW Support.'
      });
    }
  }

  cancelScheduledMerge() {
    this.userService.cancelMergeUserAccount(this.userDetails.mergedAccount.id)
      .subscribe(result => {
        if (result) {
          window.location.reload();
          this.toastMessageService.addMessageObj({
            severity: 'success',
            summary: '',
            detail: 'Merge has been canceled successfully.'
          });
        } else {
          this.toastMessageService.addMessageObj({
            severity: 'error',
            summary: 'Something went wrong.',
            detail: 'If error persist, please contact EDW Support.'
          });
        }
      })
  }

  hasScheduledMerge() {
    if (this.userDetails.mergedAccount) {
      if (this.userDetails.mergedAccount.status == 'Cancelled')
        return false;
      else
        return true;
    }
    else
      return false;
  }
}
