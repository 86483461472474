import {Injectable} from '@angular/core'
import {ReplaySubject} from 'rxjs';


/*
  This service creates a subscribeable service that is used to send 
  extract-action event data to the extract-execute component

  https://stackoverflow.com/questions/34376854/delegation-eventemitter-or-observable-in-angular
  https://stackoverflow.com/questions/41954484/communicate-between-two-child-components-in-angular-2

*/

@Injectable()
export class ExtractService {
  // Observable extract source
  private _selectedExtract = new ReplaySubject<{}>();
  // Observable extract stream
  extract = this._selectedExtract.asObservable();
  // service command
  getExtract(extract) {
    this._selectedExtract.next(extract);
  }
}