import { Component, OnInit, Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable() export class FilterInputService{
  public query = new BehaviorSubject("");
  public clearQuery = () => this.query.next("");
}

@Component({
  selector:'filter-input',
  template:`
  <div class="edw-search-bar">
    <div class="search-icon"><i class="fa fa-search"></i></div>
    <input type="text" pInputText (keyup)='filterText()' [(ngModel)]="query"/>
    <button type="button" *ngIf="query" (click)="clearFilter()"><i class="fa fa-times" aria-hidden="true"></i></button>
  </div>
  `
})
export class FilterInputComponent{
  public query;
  constructor(private filterInputService: FilterInputService){}
  public filterText(){ this.filterInputService.query.next(this.query);}
  public clearFilter() { this.query = ''; this.filterText(); }
}

