import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IRecipientGroup, IRecipient } from '@edw-entities/recipient-group'; 
import { IRestResponse } from '@edw-entities/rest-response'; 


@Injectable()
export class RecipientGroupService {
  constructor(private http: HttpClient) { } 

  getAll(query: string, page: number = 1): Observable<IRestResponse<IRecipientGroup[]>> {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
        observe: 'body',
        params: params
    };

    return this.http.get<IRestResponse<IRecipientGroup[]>>(environment.api + '/recipientGroup/getAll', options);
  }

  getById(recipientGroupID: number): Observable<IRestResponse<IRecipientGroup>> {

    const options: Object = {
        observe: 'body'
    };

    return this.http.get<IRestResponse<IRecipientGroup>>(environment.api + '/recipientGroup/'+recipientGroupID, options);
  }

  add(data: IRecipientGroup): Observable<Object> { 

    const options: Object = {
      observe: 'body'
    };

    return this.http.post<IRecipientGroup>(environment.api + '/recipientGroup/add', data, options);
  }

  update(data: IRecipientGroup): Observable<Object> { 

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<IRecipientGroup>(environment.api + '/recipientGroup/' + data.id + '/update', data, options);
  }

  remove(recipientGroupID: string): Observable<Object> {
    const body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/recipientGroup/' + recipientGroupID + '/remove', body, options);
  }

  removeAllRecipients(recipientGroupID: string): Observable<Object> {
    const body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/recipientGroup/' + recipientGroupID + '/removeAllRecipients', body, options);
  }


  addRecipients(data: IRecipient[], recipientGroupID: string): Observable<Object> { 

    const options: Object = {
      observe: 'body'
    };

    return this.http.post<IRecipient[]>(environment.api + '/recipientGroup/' + recipientGroupID + '/addRecipients', data, options);
  }

  addRecipient(data: IRecipient, recipientGroupID: string): Observable<Object> { 

    const options: Object = {
      observe: 'body'
    };

    return this.http.post<IRecipient>(environment.api + '/recipientGroup/' + recipientGroupID + '/addRecipient', data, options);
  }

  updateRecipients(data: IRecipient[], recipientGroupID: string): Observable<Object> { 

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<IRecipient[]>(environment.api + '/recipientGroup/' + recipientGroupID + '/updateRecipients', data, options);
  }

  removeRecipients(data: number[], recipientGroupID): Observable<Object> {
    const body = data;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/recipientGroup/' + recipientGroupID + '/removeRecipients', body, options);
  }

  getRecipients(recipientGroupIds: string[]): Observable<Object> {
 
    let body = recipientGroupIds;
  
    const options: Object = {
        observe: 'body' 
    };

    return this.http
    .post(environment.api + '/recipientGroup/getRecipients', body, options);
  }
}
