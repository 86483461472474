export class ExtractSetupOutputConfig {
  extract_output_configuration_key: number = 0;
  output_destination_key: number = 0;
  output_format_key: number = 0;
  output_filename_key: number = 0;
  output_header: string = '';
  output_footer: string = '';
  static_filename: string = '';
  output_subfolder: string = '';
  output_column_headers: boolean = true;
  is_active: boolean = true;
  delete_output: boolean = false;
  isDynamic: boolean = false;

  constructor() { }
}

export class ExtractSetupDependency {
  extract_dependency_key: number = 0;
  dependency_detail_key: number = 0;
  is_active: boolean = true;

  constructor() { }
}

export class ExtractSetupParameter {
  extract_parameter_key: number = 0;
  parameter_text: any = null;
  is_dynamic: boolean = false;
  default_value: string = '';
  data_type: string = null;
  user_editable: boolean = false;
  is_active: boolean = true;
  delete_param: boolean = false;

  constructor() {
    this.data_type = '';
    this.parameter_text = '';
  }
}
