import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InputFieldsType } from "@edw-app-root/enums/inputfields-type";
import { DeploymentRequestObject } from "@edw-entities/deployment-request";
import { MetadataToFormFieldsTransformer } from "@edw-entities/metadata-to-form-fields";
import { DeploymentResource } from "@edw-entities/report-deploy";
import { ResourceType } from "@edw-enums/resource-type";
import { Status } from "@edw-enums/status";
import { LoaderService } from "@edw-services/loader.service";
import { SessionStorageService } from "@edw-services/session-storage.service";
import { StagingReportsService } from "@edw-services/staging-reports.service";
import { UserDataService } from "@edw-services/user-data.service";
import { SelectItem } from "primeng-lts";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "edw-report-redeploy",
  templateUrl: "./report-redeploy.component.html",
  styleUrls: ["./report-redeploy.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReportRedeployComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("stepper",{static:true}) private stepper;
  @ViewChild("approverField",{static:true}) private approverField: ElementRef;
  @ViewChild("resourceField",{static:true}) private resourceField: ElementRef;

  dataSourceTypeText = InputFieldsType;
  skiptextdisplay =
    this.dataSourceTypeText.publishingDept.text +
    "," +
    this.dataSourceTypeText.irb.text +
    "," +
    this.dataSourceTypeText.redeployTarget.text +
    "," +
    this.dataSourceTypeText.childReport.text;

  skipfielddisplay =
    this.dataSourceTypeText.publishingDept.text +
    "," +
    this.dataSourceTypeText.irb.text +
    "," +
    this.dataSourceTypeText.redeployTarget.text +
    "," +
    this.dataSourceTypeText.resourceowner.text +
    "," +
    this.dataSourceTypeText.datasource.text +
    "," +
    this.dataSourceTypeText.childReport.text +
    "," +
    this.dataSourceTypeText.businessowner.text +
    "," +
    this.dataSourceTypeText.optOut.text;
  // skiptextdisplay = 'publishingDepartment,associatedIRB,redeployTarget,childReport';
  // skipfielddisplay = 'publishingDepartment,associatedIRB,redeployTarget,resourceOwner,dataSource,childReport,businessOwner,optOut';
  steps: string[];
  stepStatus = Status;
  selectedRS = "";
  resourceowner: SelectItem[] = [];
  name: string;
  checkOther = false;
  disabled = false;
  dstouched = false;
  OtherDataSource = "";

  resourceTypeText = ResourceType;
  datasourceOptions = "";
  isChildReport = false;

  datasources: SelectItem[] = [];
  selecteddatasources: string[] = [];
  multiselectdatasources: string[] = [];

  resType = "";
  dsMetaOptionId = 1;
  resourcecOwnerMetaOptionId: number;
  businessOwnerOptionId: number;

  oldReportMetadata = {
    status: Status.Current,
    businessowner: {
      collection: [],
      choice: "",
    },
    resource: {
      label: "Resource being replaced",
      pathLabel: "Report Path",
      typeLabel: "Report Type",
      collection: [
        {
          label: "",
        },
      ],
      choice: {
        label: "",
        value: {
          cohorts:[],
          fields: new MetadataToFormFieldsTransformer(),
          name: "",
          path: "",
          resourceStatus: "",
          resourceStatusId: "",
          resourceType: "",
          userExtracts: [],
          value: "",
          id: 0,
        },
      },
    },
  };

  security = {
    status: Status.Current,
    approvers: {
      label: "Approver",
      collection: [],
      choice: null,
    },

    users: {
      label: "Users",
      collection: [],
    },

    resourceGroups: {
      label: "Resource Groups",
      collection: [],
    },

    userGroups: {
      label: "User Groups",
      collection: [],
    },
  };

  newReportMetadata = {
    status: Status.Pending,
    resourceId: 0,
    fieldlabel: "Resource to be deployed",
    datasource: {
      label: "Data Source of resource to be deployed",
      id: "dataSource",
      selectedlabel: "Selected Data Source",
      otherdslabel: "Other Data Source",
      otherdswarning: "Other Data Source is required.",
      value: "",
    },
    resourceowner: {
      label: "Resource Owner",
      id: "resourceOwner",
      value: "",
    },
    businessowner: {
      label: "Business owner",
      placeholder: "Please Select Business Owner",
      collection: [],
      value: "",
    },

    author: {
      label: "Author",
      value: {
        id: 0,
        name: "",
      },
    },

    name: {
      label: "Report Name",
      value: "",
    },

    description: {
      label: "Description",
      value: "",
    },

    path: {
      label: "Report Path",
      value: "",
    },

    type: {
      label: "Report Type",
      value: "",
    },

    fields: new MetadataToFormFieldsTransformer(),
  };

  finish = {
    status: Status.Pending,
  };

  formSections;

  isOldResourceSelected = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stagingReportsService: StagingReportsService,
    private sessionStorageService: SessionStorageService,
    private changeDetector: ChangeDetectorRef,
    private userDataService: UserDataService,
    private loader: LoaderService
  ) { }

  ngOnInit() {
    if (this.sessionStorageService.get("reportDeployWizard")) {
      const cachedReportReDeployData = this.sessionStorageService.get("reportDeployWizard").data;
      const cachedResourceChoice = cachedReportReDeployData.oldReportMetadata.resource.choice;
      const cachedResourceValue = cachedReportReDeployData.oldReportMetadata.resource.choice.value;

      this.oldReportMetadata.resource.choice = cachedResourceChoice;

      this.newReportMetadata.businessowner.value = cachedReportReDeployData.newReportMetadata.businessowner.choice;

      if (this.oldReportMetadata.resource.choice) {
        this.isOldResourceSelected = true;
      }
      // console.log(cachedReportReDeployData);
      // this.oldReportMetadata.resource.choice.value.fields = new MetadataToFormFieldsTransformer(data.componentData.resourceDetails.metadata);
      //  this.oldReportMetadata.resource.choice.value.name = cachedResourceValue.name;
      //  this.oldReportMetadata.resource.choice.value.path = cachedResourceValue.path;
      //  this.oldReportMetadata.resource.choice.value.fields.formFieldsArr = cachedResourceValue.fields.formFieldsArr;
    }

    this.formSections = [this.oldReportMetadata, this.finish];
    this.steps = ["Metadata", "Finish"];

    this.route.data.subscribe(
      ( data: { componentData: { resourceDetails: any, cachedResourceDetails: any } } ) => {
        if (data.componentData.hasOwnProperty("resourceDetails")) {
          console.log("data.componentData");
          console.log(data.componentData);
          const newResource = new DeploymentResource(
            data.componentData.resourceDetails
          );
          this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.SSRS;
          this.resourcecOwnerMetaOptionId = this.dataSourceTypeText.resourceowneroptionid.SSRS;
          this.businessOwnerOptionId = this.dataSourceTypeText.businessowneroptionid.SSRS;
          if (newResource.resourceType === this.resourceTypeText.tableau.text) {
            this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.TABLEAU;
            this.resourcecOwnerMetaOptionId = this.dataSourceTypeText.resourceowneroptionid.TABLEAU;
            this.businessOwnerOptionId = this.dataSourceTypeText.businessowneroptionid.TABLEAU;
          } else if (newResource.resourceType === this.resourceTypeText.tableau.text) {
            this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.POWER_BI;
            this.resourcecOwnerMetaOptionId = this.dataSourceTypeText.resourceowneroptionid.POWER_BI;
            this.businessOwnerOptionId = this.dataSourceTypeText.businessowneroptionid.POWER_BI;
          }
          this.resType = newResource.resourceType;
          this.setDataSource();
          this.setResourceOwner();
          // console.log('newresource');
          // console.log(newResource);
          this.name = newResource.resourceName;
          this.newReportMetadata.resourceId = newResource.resourceId;
          this.newReportMetadata.name.value = newResource.resourceName;
          this.newReportMetadata.path.value = newResource.path;
          this.newReportMetadata.type.value = newResource.resourceType;
          this.newReportMetadata.author.value.name = newResource.author.name;
          this.newReportMetadata.author.value.id = newResource.author.id;
          this.newReportMetadata.fields = new MetadataToFormFieldsTransformer(
            newResource.metadata
          );
          this.newReportMetadata.fields.getFields().filter((metadataField) => {
            if (
              metadataField.modelName ===
              this.dataSourceTypeText.description.text
            ) {
              this.newReportMetadata.description.value = metadataField.value;
            }
            if (
              metadataField.modelName ===
              this.dataSourceTypeText.businessowner.text
            ) {
              this.newReportMetadata.businessowner.value = metadataField.value;
              // this.newReportMetadata.businessowner.choice = metadataField.value;
            }
            if (
              metadataField.modelName === this.dataSourceTypeText.optOut.text
            ) {
              metadataField.label = this.dataSourceTypeText.optOut.title;
              metadataField.value = metadataField.value == "true";
            }
          });
          //  console.log('fields');
          // console.log(this.newReportMetadata.fields);
          this.setChildReport();
          this.security.approvers.collection = newResource.approvers.map(
            (approver) => {
              return {
                label: approver.displayName,
                value: {
                  displayName: approver.displayName,
                  value: approver.id,
                },
              };
            }
          );
          // console.log('newResource');
          // console.log(newResource);
          this.updateSessionStorageCache(0);
        } else if (data.componentData.hasOwnProperty("cachedResourceDetails")) {
          this.name =
            data.componentData.cachedResourceDetails.newReportMetadata.name.value;
          this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.SSRS;
          this.resourcecOwnerMetaOptionId = this.dataSourceTypeText.resourceowneroptionid.SSRS;
          this.businessOwnerOptionId = this.dataSourceTypeText.businessowneroptionid.SSRS;
          if (
            data.componentData.cachedResourceDetails.newReportMetadata.type.value === this.resourceTypeText.tableau.text
          ) {
            this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.TABLEAU;
            this.resourcecOwnerMetaOptionId = this.dataSourceTypeText.resourceowneroptionid.TABLEAU;
            this.businessOwnerOptionId = this.dataSourceTypeText.businessowneroptionid.TABLEAU;
          } else if (data.componentData.cachedResourceDetails.newReportMetadata.type.value === this.resourceTypeText.powerbi.text) {

            this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.POWER_BI;
            this.resourcecOwnerMetaOptionId = this.dataSourceTypeText.resourceowneroptionid.POWER_BI;
            this.businessOwnerOptionId = this.dataSourceTypeText.businessowneroptionid.POWER_BI;
          }

          this.resType =
            data.componentData.cachedResourceDetails.newReportMetadata.type.value;
          this.setDataSource();
          this.newReportMetadata.resourceId =
            data.componentData.cachedResourceDetails.newReportMetadata.resourceId;
          this.newReportMetadata.name.value =
            data.componentData.cachedResourceDetails.newReportMetadata.name.value;
          this.newReportMetadata.path.value =
            data.componentData.cachedResourceDetails.newReportMetadata.path.value;
          this.newReportMetadata.type.value =
            data.componentData.cachedResourceDetails.newReportMetadata.type.value;
          this.newReportMetadata.fields.formFieldsArr =
            data.componentData.cachedResourceDetails.newReportMetadata.fields.formFieldsArr;
          this.newReportMetadata.status =
            data.componentData.cachedResourceDetails.newReportMetadata.status;
          this.newReportMetadata.datasource.value =
            data.componentData.cachedResourceDetails.newReportMetadata.datasource.value;
          this.newReportMetadata.resourceowner.value =
            data.componentData.cachedResourceDetails.newReportMetadata.resourceowner.value;
          this.newReportMetadata.description.value =
            data.componentData.cachedResourceDetails.newReportMetadata.description.value;
          this.newReportMetadata.businessowner.value =
            data.componentData.cachedResourceDetails.newReportMetadata.businessowner.value;
          //  console.log('data.componentData.cachedResourceDetails');
          //  console.log(data.componentData.cachedResourceDetails);
          this.security = data.componentData.cachedResourceDetails.security;
          this.security.resourceGroups.label = "Resource Groups";
          this.security.userGroups.label = "User Groups";
          this.setResourceOwner();
          this.setChildReport();
          //  console.log(this.newReportMetadata.fields.formFieldsArr);
          this.security.approvers.collection = this.security.approvers.collection.map(
            (approver) => {
              const label =
                approver.firstName +
                " " +
                approver.lastName +
                " (" +
                approver.email +
                ")";
              return {
                label: label,
                value: {
                  displayName: label,
                  value: approver.id,
                },
              };
            }
          );

          this.finish = data.componentData.cachedResourceDetails.finish;

          if (this.security.users.collection.length === 0) {
            this.security.users.collection = [];
          } else {
            this.updateUserCollection(this.security.users.collection);
          }

          if (this.security.userGroups.collection.length > 0) {
            this.updateUserGroupsCollection(
              this.security.userGroups.collection
            );
          }

          if (this.security.resourceGroups.collection.length > 0) {
            this.updateResourceGroupCollection(
              this.security.resourceGroups.collection
            );
          }

          this.formSections = [this.oldReportMetadata, this.finish];
        }
      }
    );
  }

  setChildReport() {
    // console.log(this.resourceDetails.fields);
    const descobj = this.newReportMetadata.fields.getFields().filter(
        (fields) =>
          fields.modelName === this.dataSourceTypeText.description.text
      );
    // descobj[0].value=descobj[0].value.
    if (
      descobj &&
      descobj[0].value &&
      descobj[0].value.length > descobj[0].maxLength
    ) {
      descobj[0].value = descobj[0].value.substring(0, descobj[0].maxLength);
    }
    // console.log(descobj);
    this.newReportMetadata.fields.getFields().filter((fields) => {
      if (fields.modelName === this.dataSourceTypeText.childReport.text) {
        if (fields.value) {
          this.isChildReport = true;
          if (descobj) {
            descobj[0].isMandatory = false;
          }
        } else {
          if (descobj) {
            descobj[0].isMandatory = true;
          }
        }
      }
    });
    // console.log(this.resourceDetails.fields);
  }

  setResourceOwner() {
    this.stagingReportsService
      .getResourceOwnerOptions()
      .subscribe((response) => {
        if (response["data"]) {
          response["data"].forEach((key) => {
            this.resourceowner.push({ label: key.name, value: key.name });
          });
        }
        // console.log(this.newReportMetadata.fields.getFields());
        // console.log(this.newReportMetadata.fields.getFields())
        this.newReportMetadata.fields.getFields().filter((fields) => {
          if (fields.modelName === this.dataSourceTypeText.resourceowner.text) {
            if (this.newReportMetadata.resourceowner.value) {
              this.selectedRS = this.newReportMetadata.resourceowner.value;
            } else {
              this.selectedRS = fields.value;
            }
          }
        });
      });
  }

  setDataSource() {
    // get the list of option to display in dropdown
    this.stagingReportsService
      .getMetadataElementOptions(this.dsMetaOptionId)
      .subscribe((response) => {
        if (response["data"]) {
          for (const str of response["data"].value.split(",")) {
            this.datasources.push({ label: str, value: str });
            this.datasourceOptions += str + " ";
          }
        }

        // if user already selected datasourc value for this report, mark it is selected

        this.newReportMetadata.fields.getFields().filter((metadataField) => {
          if (
            metadataField.modelName === this.dataSourceTypeText.datasource.text
          ) {
            //  console.log(this.newReportMetadata.datasource);
            // we get string of selected datasource from service, convert to array, if other selected set the value in textbox
            let array = [];
            if (
              metadataField.value.toString().indexOf(",") > 0 ||
              metadataField.value.toString().length > 0 ||
              this.newReportMetadata.datasource.value
            ) {
              array = metadataField.value.toString().split(",");
              if (this.newReportMetadata.datasource.value) {
                // when we do refresh during replace operation. modified values will be available in this.newReportMetadata.datasource
                array = this.newReportMetadata.datasource.value.split(",");
              }
            }
            this.setDSFieldProperties(array);
            /*  dsArray = [];
                for (let i = 0; i < array.length; i++) {
                  if (this.datasourceOptions.indexOf(array[i]) < 0) {
                    otherDSprovided += ',' + array[i];
                    otheravailable = true;
                  } else {
                    selectedDS += ',' + array[i];
                    dsArray.push(array[i]);
                  }
                }

                if (otheravailable) {
                  dsArray.push(this.dataSourceTypeText.otherdatasource.text);
                  selectedDS += ',' + this.dataSourceTypeText.otherdatasource.text;
                }
                if (otherDSprovided && otherDSprovided.trim().length > 0) {
                  otherDSprovided = otherDSprovided.replace(',', '');
                  this.OtherDataSource = otherDSprovided;
                }
                if (selectedDS && selectedDS.trim().length > 0) {
                  selectedDS = selectedDS.replace(',', '');
                  this.selecteddatasources = selectedDS.split(',');
                }
                //   console.log(dsArray.length);
                if (dsArray && dsArray.length > 0) {
                  this.multiselectdatasources = dsArray;
                }*/
          }
        });
        //  console.log(this.newReportMetadata.fields);
      });
  }

  setDSFieldProperties(array) {
    let otherDSprovided = "";
    let dsArray: string[];
    let otheravailable: Boolean = false;
    let selectedDS = "";

    dsArray = [];
    for (let i = 0; i < array.length; i++) {
      if (this.datasourceOptions.indexOf(array[i]) < 0) {
        otherDSprovided += "," + array[i];
        otheravailable = true;
      } else {
        selectedDS += "," + array[i];
        dsArray.push(array[i]);
      }
    }

    if (otheravailable) {
      dsArray.push(this.dataSourceTypeText.otherdatasource.text);
      selectedDS += "," + this.dataSourceTypeText.otherdatasource.text;
    }
    if (otherDSprovided && otherDSprovided.trim().length > 0) {
      otherDSprovided = otherDSprovided.replace(",", "");
      this.OtherDataSource = otherDSprovided;
    }
    if (selectedDS && selectedDS.trim().length > 0) {
      selectedDS = selectedDS.replace(",", "");
      this.selecteddatasources = selectedDS.split(",");
    }
    //   console.log(dsArray.length);
    if (dsArray && dsArray.length > 0) {
      this.multiselectdatasources = dsArray;
    }
  }

  handleSelected(e) {
    this.selecteddatasources = e.value;
    if (
      this.selecteddatasources.indexOf(
        this.dataSourceTypeText.otherdatasource.text
      ) > -1
    ) {
      this.checkOther = true;
    } else {
      this.checkOther = false;
      this.OtherDataSource = "";
    }
  }

  hanldeTouch(e) {
    //  console.log('touched');
    this.dstouched = true;
  }

  hanldeMetafieldsTouch(e, obj) {
    obj.touched = true;
  }

  updateOptOut(val) {
    if (
      val.includes("Research Analytics") ||
      val.includes("Legacy Data Archive")
    ) {
      this.newReportMetadata.fields.getFields().filter((metadataField) => {
        if (metadataField.modelName === this.dataSourceTypeText.optOut.text) {
          metadataField.tempvalue = true;
          metadataField.value = true;
        }
      });
    }
  }
  handleRSSelected(e, obj) {
    this.updateOptOut(e.value);
    this.selectedRS = e.value;
    obj.value = e.value;
  }

  searchUsers(event) {
    this.userDataService
      .getAllUsers(event.query)
      .pipe(
        map((response: { data: any }) => {
          if (response.data) {
            const data = response.data.map((businessowner) => {
              return businessowner.fullName + " (" + businessowner.email + ")";
            });
            return data;
          } else {
            return null;
          }
        })
      )
      .subscribe((data) => {
        this.newReportMetadata.businessowner.collection = data;
      });
  }

  search(event) {

    this.stagingReportsService.getDeployedResources(event.query).pipe(
      map((response: { data: any }) => {

        if (response.data == null) return null;

        const data = response.data.map((resource) => {
          return {
            label: resource.resourceName,
            value: {
              name: resource.resourceName,
              id: resource.id,
              resourceStatus: resource.resourceStatus,
              resourceStatusId: resource.resourceStatusId,
              resourceType: resource.resourceType,
              path: resource.path,
              fields: new MetadataToFormFieldsTransformer( resource.metadata ),
              resourceGroups: resource.resourceGroups,
              userGroups: resource.userGroups,
              cohorts: resource.cohorts,
              userExtracts: resource.userExtracts,
              users: resource.users,
            },
          };
        });

        this.oldReportMetadata.resource.collection = data;

        console.log(data);
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this.sessionStorageService.remove("reportDeployWizard");
  }

  ngAfterViewInit() {
    if (this.sessionStorageService.get("reportDeployWizard")) {
      const currentStep = this.sessionStorageService.get("reportDeployWizard")
        .stepIndex;
      this.formSections.forEach((section, index) => {
        if (index < currentStep) {
          section.status = Status.Complete;
        } else {
          section.status = Status.Pending;
        }
      });
      this.formSections[currentStep].status = Status.Current;

      this.stepper.markSpecificStep(currentStep);
      this.changeDetector.detectChanges();
    }
  }

  completeStep() {
    // console.log('complete step:' + this.selecteddatasources);
    console.log(this.newReportMetadata);
    const tempvalue = Object.assign([], this.selecteddatasources);
    if (tempvalue.length > 0) {
      for (let i = 0; i < tempvalue.length; i++) {
        if (tempvalue[i] === this.dataSourceTypeText.otherdatasource.text) {
          tempvalue[i] = this.OtherDataSource;
        }
      }
      this.newReportMetadata.datasource.value = tempvalue.toString();
    }
    this.newReportMetadata.resourceowner.value = this.selectedRS;
    // this.newReportMetadata.businessowner.value = this.newReportMetadata.businessowner.choice;
    this.newReportMetadata.fields.getFields().filter((metadataField) => {
      if (
        metadataField.modelName === this.dataSourceTypeText.description.text
      ) {
        this.newReportMetadata.description.value = metadataField.value;
      }
      if (
        metadataField.modelName === this.dataSourceTypeText.businessowner.text
      ) {
        this.newReportMetadata.businessowner.value = metadataField.value;
      }
    });
    const descobj = this.newReportMetadata.fields
      .getFields()
      .filter(
        (fields) =>
          fields.modelName === this.dataSourceTypeText.description.text
      );
    // descobj[0].value=descobj[0].value.
    if (descobj && descobj[0].value) {
      this.newReportMetadata.description.value = descobj[0].value;
    }

    let currentIndex = -1;

    this.formSections.forEach((formSection, index) => {
      if (formSection.status === Status.Current) {
        formSection.status = Status.Complete;
        currentIndex = index;
      }
    });

    if (currentIndex + 1 <= this.formSections.length - 1) {
      this.formSections[currentIndex + 1].status = Status.Current;
      const newStep = currentIndex + 1;

      this.updateSessionStorageCache(newStep);
      this.stepper.markNextStep();
    }
  }

  goBack() {
    let currentIndex = 0;

    this.formSections.forEach((formSection, index) => {
      if (formSection.status === Status.Current) {
        if (index > 0) {
          formSection.status = Status.Pending;
          currentIndex = index;
        }
      }
    });

    if (currentIndex - 1 >= 0) {
      this.formSections[currentIndex - 1].status = Status.Current;
      const newStep = currentIndex - 1;
      this.updateSessionStorageCache(newStep);

      this.stepper.markPreviousStep();
    }
  }

  redeployReport() {
    this.loader.showLoader();
    const metadata = [];
    this.newReportMetadata.fields.getFields().forEach((metadataField) => {
      if (this.skiptextdisplay.indexOf(metadataField.modelName) < 0) {
        metadata.push({
          id: metadataField.id,
          detail: metadataField.value,
        });
      }
    });
    // console.log(metadata);
    // data source value will not be available in the fields, so we have to set it seperately
    metadata.push({
      id: this.dsMetaOptionId,
      detail: this.newReportMetadata.datasource.value,
    });
    // console.log(metadata);

    const deploymentObject: DeploymentRequestObject = {
      deployedResourceId: this.oldReportMetadata.resource.choice.value.id,
      metadata: metadata,
      approverIds: [this.security.approvers.choice.value],
    };
    console.log("deploymentObject", deploymentObject);
    const subscription: Subscription = this.stagingReportsService
      .submitReDeploymentData(
        this.newReportMetadata.resourceId,
        deploymentObject
      )
      .subscribe((response) => {
        if (response["data"] === true) {
          this.sessionStorageService.add(
            "message",
            "Successfully submitted " +
            this.newReportMetadata.name.value +
            " for approval."
          );
          this.router.navigate(["/app/manage/resources"]);
        }
        subscription.unsubscribe();
      });
  }

  markFieldAsTouched() {
    this.disabled = true;
    setTimeout(() => {
      this.disabled = false;
      if (this.approverField) {
        this.approverField["dirty"] = true;
      }
      if (this.resourceField) {
        this.resourceField["dirty"] = true;
      }
    }, 400);
  }

  clearTouchedMark() {
    if (this.approverField) {
      this.approverField["dirty"] = false;
    }
    if (this.resourceField) {
      this.resourceField["dirty"] = false;
    }
  }

  onSelectApprover($event) {
    const cachedReportDeployData = this.sessionStorageService.get(
      "reportDeployWizard"
    );
    this.updateSessionStorageCache(cachedReportDeployData.stepIndex, {
      path: "security.approvers.choice",
      value: $event.value,
    });
  }

  private updateUserCollection(data) {
    this.security.users.collection = data.map((user) => {
      user.displayName =
        user.firstName + " " + user.lastName + " (" + user.email + ")";
      user["link"] = ["/app/manage/users/", user.id];

      return user;
    });
  }

  //  private updateUserExtractsCollection(data) {

  //    this.security.userExtracts.collection = data
  //      .map((userExtract) => {
  //        const extract = Object.assign({}, userExtract);
  //        extract.displayName = extract.name;
  //        extract['link'] = ['/app/manage/user-extracts/', extract.id];

  //        return extract;
  //      });
  //  }

  private updateUserGroupsCollection(data) {
    this.security.userGroups.collection = data.map((userGroup) => {
      userGroup.displayName = userGroup.name;

      if (userGroup.description) {
        userGroup.displayName =
          userGroup.displayName + " - (" + userGroup.description + ")";
      }

      userGroup["link"] = ["/app/manage/user-groups/", userGroup.id];

      return userGroup;
    });
  }

  private updateResourceGroupCollection(data) {
    this.security.resourceGroups.collection = data.map((resourceGroup) => {
      resourceGroup.displayName = resourceGroup.name;

      if (resourceGroup.description) {
        resourceGroup.displayName =
          resourceGroup.displayName + " - (" + resourceGroup.description + ")";
      }

      resourceGroup["link"] = [
        "/app/manage/resource-groups/",
        resourceGroup.id,
      ];

      return resourceGroup;
    });
  }

  clearDSField() {
    this.multiselectdatasources = [];
    this.selecteddatasources = [];
    this.OtherDataSource = "";
  }

  onSelectOldResource($event) {
    // mapping of publishing dept and irb NOT happening here.
    console.log($event);
    let oldreportDesc = "";
    let oldreportDS;
    let oldReportResOwner;
    let oldReportBusinessOwner;
    let oldOptOut;
    let oldHideToolBar;
    let oldIRB;
    let oldPub;
    // get description from the report which will be replaced
    this.oldReportMetadata.resource.choice.value.fields.getFields()
      .filter((metadataField) => {
        if(metadataField.modelName === this.dataSourceTypeText.publishingDept.text){
          oldPub = metadataField.value;
        }

        if(metadataField.modelName == this.dataSourceTypeText.irb.text){
          oldIRB = metadataField.value; //"OLD IRB TEST";
        }

        if (
          metadataField.modelName === this.dataSourceTypeText.description.text
        ) {
          oldreportDesc = metadataField.value;
          if (oldreportDesc && oldreportDesc.length > metadataField.maxLength) {
            oldreportDesc = oldreportDesc.substring(0, metadataField.maxLength);
          }
        }
        if (
          metadataField.modelName === this.dataSourceTypeText.datasource.text
        ) {
          oldreportDS = metadataField.value;
        }
        if (
          metadataField.modelName === this.dataSourceTypeText.resourceowner.text
        ) {
          oldReportResOwner = metadataField.value;
          this.updateOptOut(oldReportResOwner);
        }
        if (
          metadataField.modelName === this.dataSourceTypeText.businessowner.text
        ) {
          oldReportBusinessOwner = metadataField.value;
        }

        if (
          metadataField.modelName === this.dataSourceTypeText.optOut.text
        ) {
          oldOptOut = metadataField.value;
        }

        if (
          metadataField.modelName === this.dataSourceTypeText.hideToolBar.text
        ) {
          if (metadataField.value != "" && metadataField.value != null && metadataField.value != undefined)
            oldHideToolBar = Boolean(JSON.parse(metadataField.value));
          else
            oldHideToolBar = false;
        }
      });

    // set the description to the report which will replace
    this.newReportMetadata.fields.getFields().filter((metadataField) => {

      if(metadataField.modelName === this.dataSourceTypeText.publishingDept.text){
        metadataField.value = oldPub;
      }

      if(metadataField.modelName === this.dataSourceTypeText.irb.text){ metadataField.value = oldIRB; }

      if ( metadataField.modelName === this.dataSourceTypeText.description.text )
      { metadataField.value = oldreportDesc; }

      if ( metadataField.modelName === this.dataSourceTypeText.optOut.text )
      { metadataField.value = oldOptOut == "true"; }

      if ( metadataField.modelName === this.dataSourceTypeText.hideToolBar.text )
      { metadataField.value = oldHideToolBar; }

      if ( metadataField.modelName === this.dataSourceTypeText.resourceowner.text )
      {
        // metadataField.value = oldReportResOwner;
        this.selectedRS = oldReportResOwner;
        metadataField.value = oldReportResOwner;
      }

      if ( metadataField.modelName === this.dataSourceTypeText.businessowner.text)
      { metadataField.value = oldReportBusinessOwner; }

      if (metadataField.modelName === this.dataSourceTypeText.datasource.text) {
        if (oldreportDS) {
          this.setDSFieldProperties(oldreportDS.split(","));
        } else {
          this.clearDSField();
        }
      }
    });

    this.isOldResourceSelected = true;
    const cachedReportDeployData = this.sessionStorageService.get(
      "reportDeployWizard"
    );
    this.updateSessionStorageCache(cachedReportDeployData.stepIndex, {
      path: "oldReportMetadata.resource.choice",
      value: $event,
    });

    if ($event.value.users.length === 0) {
      this.security.users.collection = [];
    } else {
      this.updateUserCollection($event.value.users);
    }

    if ($event.value.userGroups.length === 0) {
      this.security.userGroups.collection = [];
    } else {
      this.updateUserGroupsCollection($event.value.userGroups);
    }

    if ($event.value.resourceGroups.length === 0) {
      this.security.resourceGroups.collection = [];
    } else {
      this.updateResourceGroupCollection($event.value.resourceGroups);
    }

  }

  private updateSessionStorageCache(
    stepIndex: number = 0,
    specificProperty?: { path: string; value: any }
  ) {
    if (specificProperty) {
      const cachedReportDeployData = this.sessionStorageService.get(
        "reportDeployWizard"
      );
      let referenceToCache = cachedReportDeployData.data;

      specificProperty.path.split(".").forEach((property, index) => {
        if (index === specificProperty.path.split(".").length - 1) {
          referenceToCache[property] = specificProperty.value;
        } else {
          referenceToCache = referenceToCache[property];
        }
      });

      this.sessionStorageService.add(
        "reportDeployWizard",
        cachedReportDeployData
      );
    } else {
      this.sessionStorageService.add("reportDeployWizard", {
        stepIndex: stepIndex,
        data: {
          oldReportMetadata: this.oldReportMetadata,
          newReportMetadata: this.newReportMetadata,
          security: this.security,
          finish: this.finish,
        },
      });
    }
  }


  validateForm() {
    return (
      (this.checkOther && !this.OtherDataSource) ||
      !this.oldReportMetadata.resource.choice ||
      !this.security.approvers.choice ||
      !this.isOldResourceSelected)//||
    /*  (this.oldReportMetadata.resource.choice != null && this.oldReportMetadata.resource.choice.value && this.oldReportMetadata.resource.choice.value.resourceType == 'SSRS' && this.newReportMetadata.type.value == 'TABLEAU'
      && (this.oldReportMetadata.resource.choice.value.cohorts.length > 0 || this.oldReportMetadata.resource.choice.value.userExtracts.length > 0))*/
  }
}
