import { Observable } from 'rxjs';
import { SearchParams } from '@edw-entities/searchParams';


export interface RelatedEntityService {
  getAddedEntity(id: string, searchParams: SearchParams): Observable<any>;
  getNonAddedEntity(id: string, query: string): Observable<any>;
  persistEntityList(id: string, entityListIds: number[]): Observable<any>;
  removeEntityFromGroup(parentEntityId: string, relatedEntityId: string): Observable<any>;
}

export class RelatedEntityServices{
  getAddedEntity: Observable<any>;
  getNonAddedEntity: Observable<any>;
  persistEntityList: Observable<any>;
  removeEntityFromGroup: Observable<any>;
  constructor(added?, nonadded?, persist?, remove?){
    this.getAddedEntity = added || null;
    this.getNonAddedEntity = nonadded || null;
    this.persistEntityList = persist || null;
    this.removeEntityFromGroup = remove || null;
  }
}
