import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Permissions } from '@edw-app-root/permissions';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { SubscriptionMetadataEntity } from '@edw-entities/subscription';
import { CalendarPeriods, WeekDays } from '@edw-enums/time-periods';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { UserDataService } from '@edw-services/user-data.service';
import * as moment from 'moment';
import { SelectItem } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-subscription-general-metadata',
  templateUrl: './subscription-general-metadata.component.html',
  styleUrls: ['./subscription-general-metadata.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionGeneralMetadataComponent implements OnInit {
  @Input() stepModel: SubscriptionMetadataEntity;
  @Output() stepModelChange = new EventEmitter();

  @Output() onCompleteStep = new EventEmitter();



  @Input() editMode: boolean = false;

  repeatingList: SelectItem[];
  recurringList: SelectItem[];

  weekDaysList: SelectItem[];
  monthDaysList: SelectItem[];
  quarterDaysList: SelectItem[];
  momentjs = moment;

  calendarPeriods = CalendarPeriods;

  permissions = {
    canCreate: false,
    canModify: false
  }

  constructor(private loader: LoaderService,
              private subscriptionService: SubscriptionService,
              private userDataService: UserDataService,
              private genericErrorHandlerService: ErrorHandlerService,
              private toastMessageService : ToastMessageService) { }

  ngOnInit() {
    this.permissions.canCreate = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canCreate);
    this.permissions.canModify = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canModify);

    this.repeatingList = this.setupRepeatingList();
    this.weekDaysList = this.setupWeekDaysList();
    this.monthDaysList = this.generateCorrectLabelsForDays(31);
    this.quarterDaysList = this.generateCorrectLabelsForDays(5, 'quarter');

    if (this.stepModel.schedule.scheduleType === null) {
      this.stepModel.schedule.scheduleType = this.repeatingList[0].value;
      this.recurringList = this.weekDaysList;
    } else {
      this.determineRecurringType({
        value: this.stepModel.schedule.scheduleType
      }, this.stepModel.schedule.day);
    }

    if (this.stepModel.schedule.executionTime === null) {
      this.stepModel.schedule.executionTime = moment().hour(0).minute(0).second(0).toDate();
    } else {
      this.stepModel.schedule.executionTime = moment(this.stepModel.schedule.executionTime).toDate();
    }
  }

  addMetadata() {
    this.loader.showLoader();

    if (this.stepModel.id === 0) {
      const subscription: Subscription = this.subscriptionService
        .addSubscription(this.stepModel)
        .subscribe(
          (response) => {
            if (response['data'] > 0) {
              this.stepModel.id = response['data'];

              this.toastMessageService.addMessageObj({
                severity: 'success',
                summary: 'Successfully created.',
                detail: 'Subscription has been successfully created.'
              });

              this.onCompleteStep.emit();
              this.loader.hideLoader();
            }
          },

          (httpErrorResponse) => {
            let errorMessages = [];
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);
            this.loader.hideLoader();
            subscription.unsubscribe();
          }
        );
    } else {
      const updateRxjsSubscription: Subscription = this.subscriptionService
        .updateSubscription(this.stepModel)
        .subscribe(
          (response) => {
            if (response['data'] === true) {
              if (this.editMode) {
                let subscription: Subscription = this.subscriptionService
                  .scheduleSubscription(this.stepModel.id)
                  .subscribe(
                    (response) => {
                      if (response['data'] === true) {
                        this.toastMessageService.addMessageObj({
                          severity: 'success',
                          summary: 'Success',
                          detail: 'Subscription  has been successfully updated.'
                        });

                        this.loader.hideLoader();
                        subscription.unsubscribe();
                      }
                    },
                    (httpErrorResponse) => {
                      this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

                      subscription.unsubscribe();
                      this.loader.hideLoader();
                    }
                  );
              } else {
                this.toastMessageService.addMessageObj({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Subscription data has been successfully updated.'
                });

                this.loader.hideLoader();
              }

              this.onCompleteStep.emit();
              updateRxjsSubscription.unsubscribe();
            }
          },

          (httpErrorResponse) => {
            let errorMessages = [];
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

            updateRxjsSubscription.unsubscribe();
            this.loader.hideLoader();
          }
        );
    }
  }

  determineRecurringType($event, day?) {
    switch ($event.value) {
      case CalendarPeriods.Daily:
        this.recurringList = [{
          label: '',
          value: null
        }];

        break;
      case CalendarPeriods.Weekly:
        this.recurringList = this.weekDaysList;
        break;
      case CalendarPeriods.Monthly:
        this.recurringList = this.monthDaysList;
        break;
      case CalendarPeriods.Quarterly:
        this.recurringList = this.quarterDaysList;
        break;
      default: this.recurringList = [];
    }

    this.stepModel.schedule.day = day ? day : this.recurringList[0].value;
  }

  private setupRepeatingList(): SelectItem[] {
    let repeatingList: SelectItem[] = [];

    for (let period in CalendarPeriods) {
      repeatingList.push({
        label: period,
        value: CalendarPeriods[period]
      });
    }

    return repeatingList;
  }

  private setupWeekDaysList(): SelectItem[] {
    let weekDaysList: SelectItem[] = [];

    for (let day in WeekDays) {
      weekDaysList.push({
        label: 'Every ' + day,
        value: WeekDays[day]
      });
    }

    return weekDaysList;
  }

  private generateCorrectLabelsForDays(endDay: number, periodType: string = 'month'): SelectItem[] {
    let arrayWithLabeledDays: SelectItem[] = [];

    for (let day = 1; day <= endDay; day++) {
      let label = '';
      let dayAsString = day.toString();
      let particularNaming: boolean = true;

      switch (particularNaming) {
        case dayAsString[dayAsString.length - 1] === '1':
          label = 'Every ' + day + 'st day of the ' + periodType;
          break;
        case dayAsString !== '12' && dayAsString[dayAsString.length - 1] === '2':
          label = 'Every ' + day + 'nd day of the ' + periodType;
          break;
        case dayAsString[dayAsString.length - 1] === '3':
          label = 'Every ' + day + 'rd day of the ' + periodType;
          break;
        default:
          label = 'Every ' + day + 'th day of the ' + periodType;
          break;
      }

      arrayWithLabeledDays.push({
        label: label,
        value: day
      });
    }

    return arrayWithLabeledDays;
  }
}
