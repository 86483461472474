import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IExtractForUser, adHocStatuses } from '@edw-entities/user-extracts';
import { MenuItem } from 'primeng-lts';
import { ExtractService } from '../extract.service';
// import { ActionType_ENUM } from './action-types-enum';

export const ActionType_ENUM = {
  adhocGenerate: 'adhocGEN',
  adhocDownload: 'adhocDL',
  download: 'DL'
};

@Component({
  selector: 'edw-extract-actions',
  templateUrl: './extract-actions.component.html',
  styleUrls: ['./extract-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExtractActionsComponent implements OnInit {

  @Input() extract: IExtractForUser;
  @Input() layoutTile: boolean;
  public adHocStatuses = adHocStatuses;
  public inProgress: boolean;
  public actionType = ActionType_ENUM;


  splitAction: MenuItem[];

  constructor( private _selectedExtract: ExtractService ) {}

  ngOnInit() {
    this.splitAction = [
      {label: "Download Adhoc", icon:'fa-download', command:($event) =>
        {
          this.extractEvent(ActionType_ENUM.adhocDownload, this.extract as IExtractForUser);
        }
      }
    ]

  }

  public extractEvent(type: string, extract: IExtractForUser){
    this._selectedExtract.getExtract({type:type, extract:extract});
  }
}
