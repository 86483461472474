import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CohortsDataService } from '@edw-services/cohorts.service';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable()
export class CohortQueueListResolver implements Resolve<any> {

  constructor(
    private cohortsDataService: CohortsDataService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    return this.cohortsDataService.getAllUnapprovedCohorts()
      .pipe(
       map(responseBody => {
        return responseBody['data'];
      })); 
  }
}
