import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { camelCaseToSnakeCase, snakeCaseToCamelCase } from './../shame';


@Injectable()
export class ExtractSetupDataService {
  constructor(private http: HttpClient) { }
  getExtractSetupOptions() {
    let params = new HttpParams();

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<Response>(environment.api + '/extractSetup/getSetupOptions', options)
      .pipe(
       map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getExtractSetupList(query: string) {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('approvals', 'false');

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extractSetup/get', options)
      .pipe(map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getExtractSetup(id: string) {
    let params = new HttpParams();
    params = params.set('id', id);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extractSetup/getById', options)
      .pipe(map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  addExtractSetup(userExtract: Object): Observable<Object> {
    let userExtractCopy = Object.assign({}, userExtract);

    //Please, please, please, PLEASE use these: https://gist.github.com/DanDiplo/30528387da41332ff22b. THANK YOU.
    userExtractCopy['extract_dependency'] = userExtractCopy['extract_dependency']
      .filter((extractDep) => {
        return extractDep.dependency_detail_key > 0;
      });

    userExtractCopy['extract_parameter'] = userExtractCopy['extract_parameter']
      .filter((extractParam) => {
        return extractParam.parameter_text !== '';
      });

    userExtractCopy['extract_output_configuration'] = userExtractCopy['extract_output_configuration']
      .filter((extractOutputConfig) => {
        return extractOutputConfig.output_subfolder !== '';
      });

    let body = snakeCaseToCamelCase(userExtractCopy);

    return this.http
      .post(environment.api + '/extractSetup/CreateOrUpdate', body);
  }

  editExtractSetup(userExtract: Object): Observable<Object> {
    let userExtractCopy = Object.assign({}, userExtract);

    //Please, please, please, PLEASE use these: https://gist.github.com/DanDiplo/30528387da41332ff22b. THANK YOU.
    userExtractCopy['extract_dependency'] = userExtractCopy['extract_dependency']
      .filter((extractDep) => {
        return (extractDep.dependency_detail_key >= 0 && extractDep.extract_dependency_key >= 0) &&
          !(extractDep.dependency_detail_key === 0 && extractDep.extract_dependency_key === 0);
      })
      .map((extractDep) => {
        function determineIfIsActive() {
          let isActive = extractDep.is_active;

          if (extractDep.dependency_detail_key === 0) {
            isActive = false;
          } else if (extractDep.extract_dependency_key === 0) {
            isActive = true;
          }

          return isActive;
        }

        return {
          'extract_dependency_key': extractDep.extract_dependency_key,
          'dependency_detail_key': (extractDep.dependency_detail_key === 0 && extractDep.extract_dependency_key > 0) ? 1 : extractDep.dependency_detail_key,
          'is_active': determineIfIsActive()
        };
      });

    userExtractCopy['extract_parameter'] = userExtractCopy['extract_parameter']
      .filter((extractParam) => {
        return extractParam.parameter_text !== '' && extractParam.extract_parameter_key >= 0;
      })
      .map((extractParam) => {
        extractParam.is_active = (extractParam.extract_parameter_key > 0) ? !extractParam.delete_param : true;
        return extractParam;
      });

    userExtractCopy['extract_output_configuration'] = userExtractCopy['extract_output_configuration']
      .filter((extractOutputConfig) => {
        return extractOutputConfig.output_subfolder !== '' &&
          extractOutputConfig.extract_output_configuration_key >= 0;
      })
      .map((extractOutputConfig) => {
        extractOutputConfig.is_active = (extractOutputConfig.extract_output_configuration_key > 0) ? !extractOutputConfig.delete_output : extractOutputConfig.is_active;
        return extractOutputConfig;
      });

    //still not sure why the payload would be string.
    let body = snakeCaseToCamelCase(userExtractCopy);

    return this.http
      .post(environment.api + '/extractSetup/CreateOrUpdate', body);
  }

  deactivateExtractSetup(id: string): Observable<Object> {
    let params = new HttpParams();
    params = params.set('id', id);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post<Response>(environment.api + '/extractSetup/deactivate', null, options);
  }
}
