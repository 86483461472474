import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Permissions } from '@edw-app-root/permissions';
import { SubscriptionAssociationEntity } from '@edw-entities/subscription';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-subscription-entity-associations',
  templateUrl: './subscription-entity-associations.component.html',
  styleUrls: ['./subscription-entity-associations.component.scss']
})
export class SubscriptionEntityAssociationsComponent implements OnInit {

  constructor(private loader: LoaderService,
              private subscriptionService: SubscriptionService,
              private changeDetector: ChangeDetectorRef,
              private userDataService: UserDataService,
              private genericErrorHandlerService: ErrorHandlerService) { }

  @Input() stepModel: SubscriptionAssociationEntity;
  @Output() stepModelChange = new EventEmitter();

  @Input() subscriptionId: number = 0;
  @Input() keywords;
  @Output() onCompleteStep = new EventEmitter();
  @Output() onGoBack = new EventEmitter();
  @Output() onSaveAssociation = new EventEmitter();
  @Output() onRemoveAssociation = new EventEmitter();

  @Input() messages: Message[];
  @Output() messagesChange = new EventEmitter();

  @Input() editMode: boolean = false;

  dialog = {
    show: false,
    message: ''
  };

  infoMessages: Message[] = [];
  selectedUsers: {
    displayName: string,
    id: number
  }[] = [];

  usersForAssociationSuggestions: {
    id: number,
    displayName: string
  }[] = [];

  showTestDialog: boolean = false;

  private testModalAssociation = {
    id: 0,
    recipientGroupName: ''
  };

  permissions = {
    canCreate: false,
    canModify: false
  };

  @ViewChild('accordion', {static: true}) accordion;

  ngOnInit() {

    this.permissions.canCreate = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canCreate);
    this.permissions.canModify = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canModify);

    this.infoMessages.push({
      severity: 'info',
      summary: '',
      detail: 'No associations.'
    });
  }

  toggleRecipientGroupKeywordIsDynamicValue(checked, keyword) {
    keyword.model = '';
    this.changeDetector.detectChanges();
  }

  addNewAssociation(membership) {
    let timestampAdded = new Date().getTime().toString();

    membership.associations.push({
      id: 0,
      isPersisted: false,
      timestampAdded: timestampAdded,
      reportConfigs: {
        selectedReport: null,
        selectedConfig: null,
        reportWithConfigsList: this.copyObjectsToANewArray(this.stepModel.configsListPerReport)
      },
      recipientGroupKeywords: this.copyObjectsToANewArray(this.stepModel.recipientGroupKeywords)
    });

    this.stepModel.associationForms[membership.recipientGroupName + '_association' + timestampAdded] = false;
    this.checkIfAllAssociationFormsArePersisted();
  }

  removeAssociation(membership, associationIndex) {
    let association = membership.associations[associationIndex];

    if (association.isPersisted === true) {
      this.loader.showLoader();

      let subscription: Subscription = this.subscriptionService
        .removeSpecificAssociation(this.subscriptionId, association.id)
        .subscribe(
          (response) => {
            if (response['data'] === true) {
              this.messagesChange.emit([{
                severity: 'success',
                summary: 'Successfully removed an association.',
                detail: 'You have successfully removed an association from the ' + membership.recipientGroupName + ' recipient group.'
              }]);

              this.stepModel.associationForms[membership.recipientGroupName + '_association' + association.timestampAdded] = null;
              membership.associations.splice(associationIndex, 1);

              this.checkIfAllAssociationFormsArePersisted();
              this.respawnAnAssoiciationSectionIfAllAreDeleted(membership);

              this.onRemoveAssociation.emit();
              subscription.unsubscribe();
              this.loader.hideLoader();
            }
          },

          (httpErrorResponse) => {
            let errorMessages = [];
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

            this.messagesChange.emit(errorMessages);

            subscription.unsubscribe();
            this.loader.hideLoader();
          }
        );
    } else {
      this.stepModel.associationForms[membership.recipientGroupName + '_association' + association.timestampAdded] = null;
      membership.associations.splice(associationIndex, 1);

      this.checkIfAllAssociationFormsArePersisted();
      this.respawnAnAssoiciationSectionIfAllAreDeleted(membership);
      this.onRemoveAssociation.emit();
    }
  }

  persistAssociation(membership, associationIndex) {
    this.loader.showLoader();
    let association = membership.associations[associationIndex];

    let subscription: Subscription = this.subscriptionService
      .addNewAssociation(this.subscriptionId, membership.recipientGroupId, association)
      .subscribe(
        (response) => {
          if (response['data'] > 0) {
            association.id = response['data'];

            this.messagesChange.emit([{
              severity: 'success',
              summary: 'Successfully added an association.',
              detail: 'You have successfully added a new association to the ' + membership.recipientGroupName + ' recipient group.'
            }]);

            association.isPersisted = true;
            this.stepModel.associationForms[membership.recipientGroupName + '_association' + association.timestampAdded] = true;

            this.checkIfAllAssociationFormsArePersisted();
            this.onSaveAssociation.emit();
            this.loader.hideLoader();
          }
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }

  completeStep() {
    this.onCompleteStep.emit();
  }

  private checkIfAllAssociationFormsArePersisted() {
    let associationFormsValues = [];
    if (this.stepModel.recipientGroupReportConfigMemberships.length > 0) {
      for (let prop in this.stepModel.associationForms) {
        if (this.stepModel.associationForms[prop] !== null) {
          associationFormsValues.push(this.stepModel.associationForms[prop]);
        }
      }

      this.stepModel.areAllAssociationFormsPersisted = associationFormsValues
        .every((value) => {
          return value === true;
        });
    } else {
      this.stepModel.associationForms = {};
      this.stepModel.areAllAssociationFormsPersisted = false;
    }
  }

  private respawnAnAssoiciationSectionIfAllAreDeleted(membership) {
    if (membership.associations.length === 0) {
      this.addNewAssociation(membership);
    }
  }

  hideDialog() {
    this.dialog.show = false;
  }

  confirmDialogWarning() {
    this.dialog.show = false;
    this.loader.showLoader();

    let associationFormsValues = [];

    for (let prop in this.stepModel.associationForms) {
      if (this.stepModel.associationForms[prop] !== null) {
        associationFormsValues.push(this.stepModel.associationForms[prop]);
      }
    }

    let hasPersistedAssociationForms = associationFormsValues
      .some((value) => {
        return value === true;
      });

    if (hasPersistedAssociationForms === true) {
      let subscription: Subscription = this.subscriptionService
        .removeAllAssociationsForSubscription(this.subscriptionId)
        .subscribe(
          (response) => {
            if (response['data'] === true) {
              this.onGoBack.emit();
              this.loader.hideLoader();
            }
          },

          (httpErrorResponse) => {
            let errorMessages = [];
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

            this.messagesChange.emit(errorMessages);

            subscription.unsubscribe();
            this.loader.hideLoader();
          }
        );
    } else {
      this.onGoBack.emit();
      this.loader.hideLoader();
    }
  }

  selectAssociationForTest(membership, associationIndex) {
    this.testModalAssociation = Object.assign({}, membership.associations[associationIndex]);
    this.testModalAssociation.recipientGroupName = membership.recipientGroupName;
    this.showTestDialog = true;
  }

  getUsersListForAssociation($event) {
    let subscription: Subscription = this.subscriptionService
      .getCsvUsersForAssociation(this.subscriptionId, this.testModalAssociation.id, $event.query)
      .subscribe(
        (response) => {
          if (response['data']) {
            this.usersForAssociationSuggestions = response['data'].map((user) => {
              return {
                id: user.rowIndex,
                displayName: user.email
              };
            });
          }
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }

  testAssociation() {
    this.loader.showLoader();
    let selectedUser = Object.assign({}, this.selectedUsers[0]);
    this.showTestDialog = false;

    let subscription: Subscription = this.subscriptionService
      .testAssociation(this.subscriptionId, this.testModalAssociation.id, selectedUser.id)
      .subscribe(
        (response) => {
          if (response['data'] === true) {
            this.messagesChange.emit([{
              severity: 'success',
              summary: 'Successfully generated email.',
              detail: 'You have successfully generated an email for user ' + selectedUser.displayName + ' from the ' +
                this.testModalAssociation.recipientGroupName + ' recipient group.'
            }]);

            subscription.unsubscribe();
            this.loader.hideLoader();
          }
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }

  closeTestDialog() {
    this.showTestDialog = false;
  }

  onCloseTestDialog($event) {
    this.selectedUsers = [];
  }

  private copyObjectsToANewArray(arr) {
    arr = arr.map((obj) => {
      return Object.assign({}, obj);
    });

    return arr;
  }

  markFieldAsTouched(field) {
    setTimeout(() => {
      if (field) {
        field['dirty'] = true;
      }
    }, 400);
  }

  clearTouchedMark(field) {
    if (field) {
      field['dirty'] = false;
    }
  }

  goBack() {
    this.dialog.message = 'Going back to previous steps will erase all of the associations created until now. Are you sure you would like to go back?';
    this.dialog.show = true;
  }
}
