import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product, IProduct, ProductViewModel } from '@edw-entities/product';
import { UserSettingsService } from '@edw-services/user-settings.service';
@Component({
  selector: 'edw-product-pages-listing',
  templateUrl: './product-pages-listing.component.html',
  styleUrls: ['./product-pages-listing.component.scss'],
  styles:[`
    .card {
      border:1px solid #e1e2e2;
      margin:2px;
      padding:10px;
      padding-left:15px;
      min-height:100px;
      cursor:pointer;
      border-radius:2px;
      transition:200ms;
    }
    .card:hover{
      border-color: #514689;
      color:#000000;
      box-shadow: 0px 0px 3px 1px #e1e2e2;
      padding-left:10px;
      border-left:5px solid #514689;
    }
    .card > .name{
      color:#7571b0;
      transition:200ms;
    }
    .card:hover > .name{
      color:#514689;
    }
    .footer{
      padding:1rem;
      width:100%;
      background-color:#e3e3e3;
      text-align:center;
    }
    .footer-action{
      cursor:pointer;
      text-decoration:underline;
    }
  `]
})
export class ProductPagesListingComponent implements OnInit {
  masterList: Product[] = [];
  productpages: Product[] = [];
  query: string;
  filterKeys = ['id', 'name', 'description', 'ownerName' ];
  filterValues = [];
  tileView = false;
  baseUrl = "#/app/resources/product-page";
  pageType = "RESOURCES";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userSettings : UserSettingsService
  ) {
    this.userSettings.currentUserSettings.subscribe(x => {
      this.tileView = ( x.pageView == 'Tile' );
      if( this.tileView ){ this.tilePaging(); }
    });
  }

  ngOnInit() {
    this.route.data
    .subscribe(( data ) => {
      console.log(data);
      this.baseUrl = (this.baseUrl != data.baseUrl) ? data.baseUrl : this.baseUrl;
      if( data.section !='' && data.section != 'RESOURCES' ){ this.pageType = data.section; }
      this.productpages = data.componentData.products.map( product => new ProductViewModel(product) );
      this.masterList = this.productpages;
      this.userSettings.initUserSettings();
      this.filterValues = this.masterList.map( (ele,i,arr) => this.filterKeys.map( e => ele[e].toString().toLowerCase() ) );
    });

  }

  viewMoreCnt : number  = 1;

  tilePaging = (event?) => {
    const baseCount = 12;
    // cnt defatuls to 1 to show 12 items, viewMoreCnt resets to 1 if no event is present
    // if clicking tilePaging from view, event will come through and the viewMoreCNt bump up 1.
    this.viewMoreCnt = ( !!event ) ? this.viewMoreCnt +1  : 1;
    this.productpages = this.masterList.slice( 0, ( baseCount * this.viewMoreCnt ) );
  }

  tablePaging = () => this.productpages = this.masterList;

  onFilter = () => {

    if( this.query == ""){ this.productpages = this.masterList; return false;} // reset the viewModel with all products if query is empty.

    this.query = this.query.toLowerCase();

    this.productpages = this.filterValues.filter( values => ( // loop through filterValues arr...
      values.filter( value => ( value.includes( this.query ) ) ).length > 0 ) ) // filter arrays with values that match the query...
      .map( matches => +matches[0] ) // get a list of matching ids...
      .map( id => this.masterList.filter( product => ( product.id == id ) )[0] ); // return the matching product from the masterlist.
  }

  clearSearch = () => { this.query = ""; this.onFilter(); }

  lazyLoadMore = (ev) => {console.log(ev, 'lazyLoad??'); }

  toggleStyle = (type) => {
    this.tileView = type;
    if(this.tileView){ this.tilePaging(); return; }
    this.tablePaging();
  }

  navigate = (event, product : Product ) => {
    let params = ( this.pageType == "RESOURCES" )
      ?  product.reportId +'/' + product.pageId.toString()  // navigate to Product Page report
      :  product.id.toString() ; // navigate to Product Page Detail page.
      var url = '#'+ this.baseUrl + "/" + params; 
      event.preventDefault();

    window.open(url); 
  }


}
