import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { ExtractDataService } from '@edw-services/extract.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'edw-extract-main',
    templateUrl: './extract-main.component.html',
    styleUrls: ['./extract-main.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class ExtractMainComponent implements OnInit {private extractDataSubsription: Subscription;

  totsched: string;

  constructor(private extractDataService: ExtractDataService,
              private route: ActivatedRoute,
              private router: Router,
              private toastMessageService : ToastMessageService
              ) {}

  ngOnInit() { }

  displayWIP() {
    this.toastMessageService.addMessageObj({severity:'error', summary:'Work In Progress', detail:'The option you selected is still not completed.'});
  }
}

