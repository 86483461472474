export class ReportConfiguration {
    subscriptionName: string;
    subscriptionId: string;
    subscriptionDescription: string;
    isChecked: boolean;

    constructor(reportConfiguration) { 
      this.subscriptionName = reportConfiguration.name; 
      this.subscriptionDescription = reportConfiguration.description;
      this.subscriptionId = reportConfiguration.id;
      this.isChecked = false;
    }
  }
  