import { Injectable } from '@angular/core';
import {
  Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { NotificationsService } from '@edw-services/notifications.service';

@Injectable()
export class NotificationsResolver implements Resolve<any> {

  constructor(private router: Router,
              private notificationsService: NotificationsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];

    let componentData = {
      notifications: {}
    };

    if (lastParam === 'notifications') {
      return this.notificationsService.getNotifications()
        .pipe(map(responseBody => {
          componentData.notifications = responseBody['data'];
          return componentData;
        }));
    }
  }
}
