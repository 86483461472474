import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Status } from '@edw-enums/status';

@Component({
  selector: 'edw-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepperComponent implements OnInit, OnChanges {
  //one-way and plain string bindings
  @Input() steps: string[];

  _stepsConfig = [];
  stepStatus = Status;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changesObjs) {
    if (changesObjs.hasOwnProperty('steps')) {
      if (changesObjs.steps.firstChange) {
        let stepNumber = 1;

        this._stepsConfig = changesObjs.steps.currentValue.map((step, index) => {
          let stepObject = {
            stepNumber: stepNumber,
            title: step,
            status: Status.Pending
          };

          if (index === 0) {
            stepObject.status = Status.Current;
          }

          stepNumber = stepNumber + 1;

          return stepObject;
        });
      }
    }
  }

  markNextStep() {
    let currentIndex = -1;

    this._stepsConfig.forEach((stepObj, index) => {
      if (stepObj.status === Status.Current) {
        stepObj.status = Status.Complete;
        currentIndex = index;
      }
    });

    if (currentIndex + 1 <=  this._stepsConfig.length - 1) {
      this._stepsConfig[currentIndex + 1].status = Status.Current;
    }
  }

  markPreviousStep() {
    let currentIndex = 0;

    this._stepsConfig.forEach((stepObj, index) => {
      if (stepObj.status === Status.Current) {
        if (index > 0) {
          stepObj.status = Status.Pending;
          currentIndex = index;
        }
      }
    });

    if (currentIndex - 1 >= 0) {
      this._stepsConfig[currentIndex - 1].status = Status.Current;
    }
  }

  markSpecificStep(stepIndex: number) {
    this._stepsConfig.forEach((stepObj, index) => {
      if (index < stepIndex) {
        stepObj.status = Status.Complete;
      } else {
        stepObj.status = Status.Pending;
      }
    });

    this._stepsConfig[stepIndex].status = Status.Current;
  }

}
