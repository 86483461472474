import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ReportsService } from '@edw-app-root/services/reports.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

export class ReportRoutingResolver implements Resolve<any> {
  constructor(
    private reportsService: ReportsService,
    //private stagingReportsService: StagingReportsService
    ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const urlFragments = state.url.split('/');
    const reportId = state.url.split('/')[urlFragments.length - 1];

    let componentData   = {resource:{}, isPreview: false, cohortReport: []};

    return combineLatest([
      this.reportsService.getResource(reportId),

    ]).pipe(
      map(([resource]) => {
        if(resource != null){
          componentData = { ...componentData, resource: resource['data'], isPreview : false };
        }



        return componentData;

      })
    )
  }
}
