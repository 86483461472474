import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ReportEmbedConfig } from '@edw-app-root/entities/power-bi';
import { IResource } from '@edw-app-root/entities/resource';
import { FileFormat } from '@edw-app-root/enums/file-format';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { PowerBIService } from '@edw-app-root/services/power-bi.service';
import { UserDataService } from '@edw-app-root/services/user-data.service';
import { DialogService, MenuItem } from 'primeng-lts';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PowerBiExportComponent } from '../containers/powerbi-export/powerbi-export.component';

export interface IResourceView{
  id: string;
  resourceName: string;
  path: string;
  resourceType: string;
  externalId: string;
  resourceStatusId: string;
  metadata: []
}


@Component({
  selector: 'edw-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[DialogService]
})
export class ViewComponent implements OnInit, OnDestroy, AfterViewInit {
  url;
  loaderShown: boolean;
  spinnerShow: boolean = false;
  isPreview: boolean;
  isPowerBIEmbedd: boolean
  showPowerBIExport: boolean = false;
  powerBIReportEmbedConfig: ReportEmbedConfig;
  powerBIResult;
  splitAction: MenuItem[];

  menu : MenuItem[];

  @ViewChild('powerBIContainer', {static: false, read: ElementRef}) powerBIContainer: ElementRef;

  resource : IResource;

  constructor(
    private route: ActivatedRoute,
    private userDataService: UserDataService,
    private powerBIService: PowerBIService,
    private sanitizer: DomSanitizer,
    private toastMessageService : ToastMessageService,
    private dialogService : DialogService
  ) { }

  ngOnInit() {



    this.loaderShown = true;
    this.route.data.pipe(
      map((data: { componentData: { resource: IResource, isPreview: boolean} }) => {

        //console.log(data.componentData);

        this.resource = data.componentData['resource'];
        this.isPreview = data.componentData['isPreview'];
        if(this.isPowerBI()){
          this.isPowerBIEmbedd = true;
          this.showPowerBIExport = true;
          return;
        }

        let toolbar = this.resource.metadata.filter(m => m.name === 'Hide Tool Bar')[0];


        let shouldHideToolBar = ( toolbar?.detail != null )
          ? `&shouldHideToolBar=${toolbar.detail}`
          : ''

         // TODO: Remove
         //shouldHideToolBar =  "&shouldHideToolBar=true";

        this.url = environment.api + '/Views/Viewer.aspx'
          + '?resourceId=' + this.resource.id
          + '&resourcePath=' + this.resource.path
          + '&resourceName=' + encodeURIComponent(this.resource.resourceName)
          + '&resourceType=' + this.resource.resourceType
          + '&previewMode=' + String(this.isPreview)
          + shouldHideToolBar;

        if (data.componentData['page_id']) {
          this.url = this.url + '&productPageId=' + data.componentData['page_id']
        }

        

        console.log(this.url.toString());

        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);


      })).subscribe();
  }

  ngOnDestroy() {}

  ngAfterViewInit(): void {
    if (this.isPowerBIEmbedd) {
      this.setupPowerBi().subscribe();
    }
  }

  hideLoader = () => this.loaderShown = false;

  isPowerBI = () => this.resource.resourceType === 'POWER_BI_REPORT';

  private setupPowerBi = () => {
    return this.powerBIService.initPowerBi(this.resource).pipe(
      map((data => {
        this.powerBIService.showReport(this.powerBIContainer.nativeElement);
      }))
    )
  }

  exportPowerBIExcel(formatType: string) {
    this.spinnerShow = true;
    this.toastMessageService.addMessageObj({
      severity: 'success',
      summary: '',
      detail: 'Report has been queued to be exported. Please do not leave this page until operation is complete.',
      sticky:true
    });

    this.powerBIService.exportPowerBIReport(formatType).subscribe(
      ()  => { console.log("Download complete") },
      (e) => { console.error(e) },
      ()  => { this.spinnerShow = false; }
    );
  }

  xxx = () => {console.log("hi")}

  showExportComponent = () =>{
    console.log("hi");
    const ref = this.dialogService.open( PowerBiExportComponent, {
      header: "Choose your export format",
      data: { exportTypes : this.powerBIService.exportEvents }
    })
  }

  getSupportedPowerBIExportTypes() {
    return [
      {
        label: FileFormat.PDF, icon: 'fa fa-download',
        command: () => {
          this.showExportComponent()
          //this.exportPowerBIExcel("PDF");
        }
      },
      {
        label: FileFormat.PPTX, icon: 'fa fa-download', command: () => {
          this.exportPowerBIExcel("PPTX");
        }
      }
    ]


  }
  getSupportedPaginatedExportTypes() {
    return [
      {
        label: FileFormat.PDF, icon: 'fa-download', command: () => {
          this.exportPowerBIExcel("PDF");
        }
      },
      {
        label: FileFormat.XLSX, icon: 'fa-download', command: () => {
          this.exportPowerBIExcel("XLSX");
        }
      },
      {
        label: FileFormat.PPTX, icon: 'fa-download', command: () => {
          this.exportPowerBIExcel("PPTX");
        }
      },
      {
        label: FileFormat.PNG, icon: 'fa-download', command: () => {
          this.exportPowerBIExcel("PNG");
        }
      },
      {
        label: FileFormat.DOCX, icon: 'fa-download', command: () => {
          this.exportPowerBIExcel("DOCX");
        }
      },
      {
        label: FileFormat.CSV, icon: 'fa-download', command: () => {
          this.exportPowerBIExcel("CSV");
        }
      }
    ]


  }
}
