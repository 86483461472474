import { Component, OnInit } from '@angular/core';
import { APIMapService } from '@edw-app-root/services/httpService/api-map.service';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: 'non-prod-test-component',
  templateUrl: 'non-prod-test.component.html'
})

export class NonProdTestComponent implements OnInit {

  testResultMessages = {
    idle    :"Idle",
    working :"Running test.",
    success :"Test ran successful.",
    error   :"Test failed."
  }

  cohortActivationResult = this.testResultMessages.idle;
  syncAdGroupToUserGroupResult = this.testResultMessages.idle;

  constructor(private api: APIMapService) {}

  manageCohortActivation = () => {
    this.cohortActivationResult = this.testResultMessages.working;
    console.log(this);
    this.api.tests.ManagerCorortActivation().pipe(
      map( (res : any) => {
        this.cohortActivationResult = this.testResultMessages.success;
        if( res != null ){ this.cohortActivationResult += ` ${res}`;}
        console.log(res);
      }),
      catchError((err: Error)=>{
        this.cohortActivationResult = this.testResultMessages.error;
        throw err;
      })
    ).subscribe()
  }

  syncADGroupsToUserGroups = () => {
    this.syncAdGroupToUserGroupResult = this.testResultMessages.working;
    console.log(this);
    this.api.tests.SyncADGroupToUserGroups().pipe(
      map( (res :any) => {
        this.syncAdGroupToUserGroupResult = this.testResultMessages.success;
        if( res != null ){ this.syncAdGroupToUserGroupResult += ` ${res}`;}
        console.log(res);
      }),
      catchError((err: Error)=>{
        this.syncAdGroupToUserGroupResult = this.testResultMessages.error;
        throw err;
      })
    ).subscribe()
  }


  ngOnInit() { }
}
