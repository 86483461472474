import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRestResponse } from '@edw-app-root/entities/rest-response';
import { DeploymentRequestObject } from '@edw-entities/deployment-request';
import { IMetadataElement, IMetadataElementOption, IResourceType, Resource } from '@edw-entities/resource';
import { SearchParams } from '@edw-entities/searchParams';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) { }

  getUserResources = (query: string = ''): Observable<Object> => {
    let params = new HttpParams();
    // doing this value re-assignment because
    // the instances of HttpParams are immutable objects
    params = params.set('query', query);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http.get<Response>(environment.api + '/resource/getUserResources', options);
  }

  upDateDeployedData = (resourceId: number, resourceDeploymentObj: DeploymentRequestObject): Observable<Object> => {
    const body = resourceDeploymentObj;

    const options: Object = {
      observe: 'body'
    };
    return this.http
      .post<Response>(environment.api + '/resource/' + resourceId + '/submit', body, options);
  }

  getResource = (resourceId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<Response>(environment.api + '/resource/' + resourceId, options);
  }

  getResourceDetails = (resourceId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    //return this.http.get<Response>('assets/resource.json', options);
    return this.http.get<Response>(environment.api + '/resource/' + resourceId + '/getDetails', options);
  }

  getSQLDetails = (resourceId: string, isStaging: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http
      .get<Response>(environment.api + '/resource/' + resourceId + '/GetReportDataSet?' + isStaging, options);
  }

  update = (resource: Resource): Observable<Object> => {
    const body = resource;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resource/update', body, options);
  }

  remove = (resourceId: string): Observable<Object> => {
    const postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resource/' + resourceId + '/remove', postBody, options);
  }

  syncTableau = (resourceId: string): Observable<Object> => {
    const postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resource/' + resourceId + '/syncTableau', postBody, options);
  }

  addFavorite = (resourceId: string): Observable<Object> => {
    const postBody = null;

    let params = new HttpParams();
    params = params.set('resourceId', resourceId);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user/addFavorite', postBody, options);
  }

  removeFavorite = (resourceId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceId', resourceId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/user/removeFavorite', body, options);
  }

  getFavoriteReports = (query: string = '') => {
    let params = new HttpParams();
    // doing this value re-assignment because
    // the instances of HttpParams are immutable objects
    params = params.set('query', query);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/user/getFavoriteReports', options);
  }

  getAll = (query: string = '', page: number = 1, quickCall: boolean = false): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());
    params = params.set('quickCall', quickCall.toString());

    console.log(params);
    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http.get<Response>(environment.api + '/resource/getAll', options);
  }

  addResourceGroups = (id: string, resourceGroupIds: number[]): Observable<Object> => {
    const postbody = resourceGroupIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resource/' + id + '/resourcegroup/add', postbody, options);
  }

  removeResourceGroup = (resourceId: string, resourceGroupId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceGroupId', resourceGroupId);

    const postbody = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resource/' + resourceId + '/resourcegroup/remove', postbody, options);
  }

  findNonAddedResourceGroup = (resourceId: string, query: string): Observable<Object> => {
    const postBody = {
      query: query,
      page: 1,
      isAdded: false
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/resource/' + resourceId + '/resourcegroup/get', postBody, options)
      .pipe(
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.

        map((response) => {
          response['data'] = response['data'].map(element => {
            element.displayName = element.name;

            if (element.description) {
              element.displayName = element.displayName + ' - (' + element.description + ')';
            }

            return element;
          });

          return response;
        })
      );
  }

  findAddedResourceGroups = (resourceId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/resource/' + resourceId + '/resourcegroup/get', postBody, options)
      .pipe(tap((response => {
        response['data'] = response['data'].map(element => {
          element.displayName = element.name;

          if (element.description) {
            element.displayName = element.displayName + ' - (' + element.description + ')';
          }

          return element;
        });

        return response;
      })));
  }

  findNonAddedUserGroup = (resourceId: string, query: string): Observable<Object> => {
    const postBody = {
      query: query,
      page: 1,
      isAdded: false
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/resource/' + resourceId + '/usergroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          return element;
        });

        return response;
      }));
  }

  findAddedUserGroup = (resourceId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/resource/' + resourceId + '/usergroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          return element;
        });

        return response;
      }));
  }

  addUserGroups = (resourceId: string, userGroupIds: number[]): Observable<Object> => {
    const postbody = userGroupIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resource/' + resourceId + '/usergroup/add', postbody, options);
  }

  removeUserGroup = (resourceId: string, userGroupId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('userGroupId', userGroupId);

    const postbody = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resource/' + resourceId + '/usergroup/remove', postbody, options);
  }

  findNonAddedUsers = (resourceId: string, query: string): Observable<Object> => {
    const postBody = {
      query: query,
      page: 1,
      isAdded: false
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/resource/' + resourceId + '/users/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element.displayName = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';
          return element;
        });

        return response;
      }));
  }

  findAddedUser = (resourceId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/resource/' + resourceId + '/users/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element.displayName = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';
          return element;
        });

        return response;
      }));
  }

  addUser = (resourceId: string, userIds: number[]): Observable<Object> => {
    const postbody = userIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resource/' + resourceId + '/users/add', postbody, options);
  }

  removeUser = (resourceId: string, userId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('userId', userId);

    const postbody = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resource/' + resourceId + '/users/remove', postbody, options);
  }

  getReportConfigurations = (resourceId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceId', resourceId);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/resource/' + resourceId + '/subscription/getReportConfigurations', options);
  }


  getResourceTypes(): Observable<IRestResponse<IResourceType[]>> {

    const options: Object = {
      observe: 'body'
    };

    return this.http.get<IRestResponse<IResourceType[]>>(environment.api + '/resource/getResourceTypes', options);
  }

  getMetadataByResourceType(resourceTypeId: number): Observable<IRestResponse<IMetadataElement[]>> {
    const options: Object = {
      observe: 'body'
    };
    return this.http.get<IRestResponse<IMetadataElement[]>>(environment.api + '/resource/metadata/' + resourceTypeId + '/getMetadataByResourceType', options);
  }

  getMetadataElementOptions(metadataElementId: number): Observable<IRestResponse<IMetadataElementOption[]>> {
    const options: Object = {
      observe: 'body'
    };
    return this.http.get<IRestResponse<IMetadataElementOption[]>>(environment.api + '/resource/metadata/' + metadataElementId + '/getMetadataElementOptions', options);
  }


  updateMetadataElementOptions(updateDTO): Observable<Object> {
    const postbody = updateDTO;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resource/metadata/updateMetadataElementOptions', postbody, options);
  }

}
