import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UserExtractsService } from '@edw-services/user-extracts.service';
import { Observable } from 'rxjs';

@Injectable()
export class UExtractsResolver implements Resolve<any> {

  constructor(
    private userExtractsService: UserExtractsService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {    

    return this.userExtractsService.getUserExtractsPerUser();
    //return this.userExtractsService.getFavoritesUserExtracts();
    // return true;
  }
}
