import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAudit } from '@edw-entities/audit';
import { IRestResponse } from '@edw-entities/rest-response';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class AuditsDataService {
  private endpoint: string;
  private moment: any;

  constructor(
    private http: HttpClient
    ) {
    this.moment = moment;
    this.endpoint = environment.api;
  }

  getAllAudits(userName: string = '',
               startDate: Date = null,
               endDate: Date = null,
               actionType: string = '',
               page: number = 1): Observable<Object> {

    let parameters = new HttpParams();
    parameters = parameters.set('userName', userName);
    if (startDate) {
      console.log(startDate);
      const convertedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
      const convertedStartDateStr = this.moment(convertedStartDate).format('YYYY-MM-DD');
      console.log(convertedStartDate, convertedStartDateStr);
      parameters = parameters.set('startDate', convertedStartDateStr + 'T00:00:00Z');
    }
    if (endDate) {
      console.log(endDate);
      const convertedEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
      const convertedEndDateStr = this.moment(convertedEndDate).format('YYYY-MM-DD');
      console.log(convertedEndDate, convertedEndDateStr);
      parameters = parameters.set('endDate', convertedEndDateStr + 'T23:59:59Z');
      // 2018-10-15T05:00:00.000Z
    }
    if (actionType) {
      parameters = parameters.set('actionType', actionType);
    }
    parameters = parameters.set('page', page.toString());

    const postBody = parameters;
    const options: Object = {
      params: parameters
    };
    return this.http.post<IRestResponse<IAudit[]>>(`${this.endpoint}/audit/get`, postBody, options);
  }

  updateNmSafetyIntegrity(value): Observable<Object> {
    return this.http
      .post(environment.api + '/audit/'+value+'/updateNmSafetyIntegrity', null, {
        observe: 'body'
      });
  }

  getActionTypesNames(): Observable<Object> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.get<Response>(`${this.endpoint}/audit/getActionTypeNames`, options);
  }

  exportAudit(userName: string = '',
      startDate: string = '',
      endDate: string = '',
      actionType: string = '',
      page: number = 1): Observable<Object> {

      let parameters = new HttpParams();
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/xml');
      parameters = parameters.set('userName', userName);
      if (startDate) {
        const convertedStartDate = new Date(startDate).toUTCString();
        parameters = parameters.set('startDate', convertedStartDate);
      }
      if (endDate) {
        const preparedEndDate = new Date(endDate);
        preparedEndDate.setMilliseconds(preparedEndDate.getMilliseconds() - 1);
        const passedEndDateObj = preparedEndDate.setDate(preparedEndDate.getDate() + 1);
        const convertedEndDate = new Date(passedEndDateObj).toUTCString();
        parameters = parameters.set('endDate', convertedEndDate);
      }
      if (actionType) {
        parameters = parameters.set('actionType', actionType);
      }
      parameters = parameters.set('page', page.toString());
      const postBody = parameters;
      const options: Object = {
        observe: 'body',
        responseType: 'blob',
        headers: headers
      };
      return this.http.post<Response>(`${this.endpoint}/audit/export`, postBody, options);
  }

}
