import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'edw-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusIndicatorComponent implements OnInit {


  @Input() status: boolean;
  get getStatus() {
    return coerceBooleanProperty(this.status);
  }

  constructor() { }

  ngOnInit() {
  }

}
