import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UserSettings } from '@edw-app-root/entities/user-settings';
import { SessionStorageService } from '@edw-app-root/services/session-storage.service';
import { SnowTicketComponent } from '@edw-app-root/shared/snow-ticket/snow-ticket.component';
import { ValueRatingComponent } from '@edw-app-root/shared/value-rating/value-rating.component';
import { LocalStorageService } from '@edw-services/local-storage.service';
import { NotificationsService } from '@edw-services/notifications.service';
import { UserDataService } from '@edw-services/user-data.service';
import { UserSettingsService } from '@edw-services/user-settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  entryComponents: [ValueRatingComponent, SnowTicketComponent]
})
export class TopNavComponent implements OnInit {
  @Output() onNavToggled = new EventEmitter<boolean>();
  userInfo;
  showHelpPanel: boolean = false;
  leftNavVisible: boolean = true;
  notificationsCount: number;
  helpMessage: SafeHtml;
  userSettings: UserSettings;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userDataService: UserDataService,
    private router: Router,
    private notificationsService: NotificationsService,
    private domSanitizer: DomSanitizer,
    public userSettingsService: UserSettingsService,
    private localStorageService: LocalStorageService,
    
  ) { }

  ngOnInit() {

    this.userSettingsService.currentUserSettings
      .subscribe(userSettings => this.userSettings = userSettings);

    this.userDataService.userInfoLoadedObservable
      .subscribe((info) => {
        if (info.loaded === true) {
          let helpMessage = '';
          var domain = this.userDataService.getUserDomain();
          this.userInfo = this.userDataService.getCachedUserData();

          //the domain set above will be used to display the message.
          switch (domain) {
            case 'nu':
              helpMessage = 'Please contact the NMEDW support team at <a href="mailto:nmedw@northwestern.edu" class="emphasis">nmedw@northwestern.edu</a> with questions or data concerns.';
              break;
            case 'nmhc':
              helpMessage = 'Please contact Analytics at <a href="mailto:nmanalytics@nm.org" class="emphasis">nmanalytics@nm.org</a> with your question.';
              break;
            default: ;
          }

          this.helpMessage = this.domSanitizer.bypassSecurityTrustHtml(helpMessage);

        }
      });

    this.notificationsService.notificationsCountObservable
      .subscribe((count) => {
        this.notificationsCount = count;
      });

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.notificationsService.getUnreadCount()
            .subscribe((count) => {
              this.notificationsService.broadcastNotificationsCountChange(count);
            });
        }
      });
  }

  toggleLeftNav() {
    this.leftNavVisible = !this.leftNavVisible;
    this.onNavToggled.emit(this.leftNavVisible);
  }

    




}
