import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { ExtractConfigOptions } from '@edw-entities/extract-config-options';
import { ExtractConfigOutput } from '@edw-entities/extract-config-output';
import { ExtractConfigDataService } from '@edw-services/extract-config.service';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserDataService } from '@edw-services/user-data.service';
import { SelectItem } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-extract-config',
  templateUrl: './extract-config.component.html',
  styleUrls: ['./extract-config.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExtractConfigComponent implements OnInit {
  title: string;
  isInAddNewMode: boolean = false;
  deleteDialogVisible: boolean = false;

  extractConfig = {
    extract_configuration_key: 0,
    extract_setup_key: 0,
    extract_output_configuration_key: 0,
    schedule_key: 0,
    extract_configuration_description: '',
    max_drop_time: '',
    max_allowed_runtime_minutes: '',
    effective_from_dttm: '',
    effective_to_dttm: '',
    is_active: true,
    is_portal_user_adhoc: false,
    extract_notification_configurations: [
      {
        extract_notification_configuration_key: 0,
        fail_email_to: '',
        fail_template_key: 3,
        success_email_to: '',
        success_template_key: 1,
        complete_email_to: '',
        complete_template_key: 2,
        full_detail_email_to: '',
        full_detail_template_key: 6,
      }
    ]
  };
  eslist: Object[] = [];
  eoclist: Object[] = [];
  schlist: Object[] = [];
  eco: ExtractConfigOptions;
  eoc: ExtractConfigOutput[] = [];


  formSubmitted: boolean = false;
  permissions;

  boolvalue: SelectItem[] = [
    { label: 'False', value: false },
    { label: 'True', value: true }
  ];

  runtimes: SelectItem[] = [];

  constructor(private userDataService: UserDataService,
    private extractConfigDataService: ExtractConfigDataService,
    private route: ActivatedRoute,
    private router: Router,
    private loader: LoaderService,
    private sessionStorageService: SessionStorageService,
    private toastMessageService: ToastMessageService) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;

        if (data.componentData.hasOwnProperty('extractConfig')) {
          this.extractConfig = data.componentData['extractConfig'];
          this.extractConfig.max_drop_time = typeof this.extractConfig.max_drop_time !== 'string' ? '' : this.extractConfig.max_drop_time;

          if (this.extractConfig['extract_notification_configurations'].length === 0) {
            this.extractConfig['extract_notification_configurations'].push({
              extract_notification_configuration_key: 0,
              fail_email_to: '',
              fail_template_key: 3,
              success_email_to: '',
              success_template_key: 1,
              complete_email_to: '',
              complete_template_key: 2,
              full_detail_email_to: '',
              full_detail_template_key: 6,
            });
          } else {
            this.extractConfig['extract_notification_configurations'][0].fail_template_key = 3;
            this.extractConfig['extract_notification_configurations'][0].success_template_key = 1;
            this.extractConfig['extract_notification_configurations'][0].complete_template_key = 2;
            this.extractConfig['extract_notification_configurations'][0].full_detail_template_key = 6;
          }

          this.loadOutputConfigurations(this.extractConfig['extract_setup_key']);
        } else {
          this.isInAddNewMode = true;
        }

        this.title = data.componentData.title;
      });

    let value = 0, step = 15;

    while (value < 240) {
      if (value >= 60 && value < 120) {
        step = 30;
      }

      if (value >= 120) {
        step = value;
      }

      value = value + step;

      this.runtimes.push({
        label: value.toString(),
        value: value
      });
    }

    this.loadExtractConfigOptions();
  }

  submitNewExtractConfig(form) {
    this.formSubmitted = true;

    if (form.valid
      && this.extractConfig.extract_setup_key !== 0
      && this.extractConfig.extract_output_configuration_key !== 0) {
      if (this.isInAddNewMode === true) {
        this.addNewExtractConfig();
      } else {
        this.editExtractConfig();
      }
    }
  }

  private addNewExtractConfig() {
    const subscription: Subscription = this.extractConfigDataService
      .addEditExtractConfig(this.extractConfig)
      .subscribe(response => {
        if (response['data'] && parseInt(response['data'])) {
          this.sessionStorageService.add('message', 'Successfully added new extract configuration ' + this.extractConfig['extract_configuration_description']);
          this.router.navigate(['/app/manage/extracts/extract-config-list']);
        }

        subscription.unsubscribe();
      }, httpErrorResponse => {
        let httpError;
        if (httpErrorResponse.error) {
          httpError = typeof httpErrorResponse.error === 'string' ? JSON.parse(httpErrorResponse.error).message : httpErrorResponse.error.message;
        }
        let message = httpError || httpErrorResponse.message;

        this.toastMessageService.addMessageObj({
          severity: 'error',
          summary: 'Something went wrong.',
          detail: message
        });
        this.loader.hideLoader();
        subscription.unsubscribe();
      });
  }

  private editExtractConfig() {
    let extractFields = this.extractConfig;

    this.loader.showLoader();

    const subscription: Subscription = this.extractConfigDataService
      .addEditExtractConfig(extractFields)
      .subscribe(response => {
        if (response['data'] === true) {
          this.sessionStorageService.add('message', 'Successfully updated extract configuration ' + this.extractConfig['extract_configuration_description']);
          this.router.navigate(['/app/manage/extracts/extract-config-list']);
        };

        subscription.unsubscribe();
      }, httpErrorResponse => {
        let httpError;
        if (httpErrorResponse.error) {
          httpError = typeof httpErrorResponse.error === 'string' ? JSON.parse(httpErrorResponse.error).message : httpErrorResponse.error.message;
        }
        let message = httpError || httpErrorResponse.message;

        this.toastMessageService.addMessageObj({
          severity: 'error',
          summary: 'Something went wrong.',
          detail: message
        });
        this.loader.hideLoader();
        subscription.unsubscribe();
      });
  }

  showConfirmationDialog() {
    this.deleteDialogVisible = true;
  }

  hideConfirmationDialog() {
    this.deleteDialogVisible = false;
  }

  deactivateExtractConfig() {
    this.hideConfirmationDialog();
    let extractFields = this.extractConfig['extract_configuration_key'];

    const subscription: Subscription = this.extractConfigDataService
      .deactivateExtractConfig(extractFields.toString())
      .subscribe(response => {
        if (response['data'] === true) {
          this.sessionStorageService.add('message', 'Successfully deactivated extract configuration.');
          this.router.navigate(['/app/manage/extracts/extract-config-list']);
        }

        subscription.unsubscribe();
      });
  }

  loadExtractConfigOptions() {
    const subscription: Subscription = this.extractConfigDataService
      .getExtractConfigOptions()
      .subscribe(responseBody => {
        if (responseBody['data']) {
          this.eco = responseBody['data'];

          this.eslist = this.eco['extract_list'].map((el) => {
            return {
              label: el.extract_name + '--' + el.extract_description,
              value: el.extract_setup_key
            }
          });

          this.schlist = this.eco['schedule_list'].map((sl) => {
            return {
              label: sl.schedule_text,
              value: sl.schedule_key
            }
          });
        }

        subscription.unsubscribe();
      });
  }

  onSetupChange(extract_setup_key) {
    this.loadOutputConfigurations(extract_setup_key);
    this.extractConfig.extract_output_configuration_key = 0;
  }

  loadOutputConfigurations(extract_setup_key) {
    this.eoclist = [];

    const subscription: Subscription = this.extractConfigDataService
      .getExtractConfigOutputs(extract_setup_key)
      .subscribe(responseBody => {
        if (responseBody['data']) {
          this.eoc = responseBody['data'];

          this.eoclist = this.eoc.map((eocl) => {
            return {
              label: eocl.destination_type + '--' + eocl.output_format + '--' + eocl.destination + '\\' + eocl.destination_subfolder + '\\' + eocl.filename + eocl.extension,
              value: eocl.extract_output_configuration_key
            }
          });
        }

        subscription.unsubscribe();
      });
  }
}


