import { Injectable } from '@angular/core';
import { Message } from 'primeng-lts';
import { ToastMessageService } from './messaging.service';


@Injectable({providedIn:'root'})
export class ErrorHandlerService {

  constructor(

    private toastMessageService : ToastMessageService
  ) { }
    /**
     * Will return an error message, either to a toast message or thrown error
     * If message is present, it will overwrite the httpErrorResponse and display
     * it's own message. This gives function a chance to handle messaging.
     * @param httpErrorResponse : HttpErrorResponse object
     * @param message : IMessage <optional>
     *
     */
  httpErrorHandler(httpErrorResponse, message? :Message ) {

    if( message == null ){
      // We will load up another message
      let httpError;

      if (httpErrorResponse.error) {
        httpError = typeof httpErrorResponse.error === 'string' ? JSON.parse(httpErrorResponse.error).message : httpErrorResponse.error.message;
      }

      let msg = httpError || httpErrorResponse.message;

      message  = {
        severity: 'error',
        summary: 'Something went wrong.',
        detail: msg
      }

      if( this.toastMessageService.messages == null ){
        throw message;
      }
    }

    console.log(message);
    this.toastMessageService.clearMessages();
    this.toastMessageService.addMessageObj(message);

  }

}
