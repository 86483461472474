import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ReportsService } from '@edw-app-root/services/reports.service';
import { ToastMessageService } from '@edw-services/messaging.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class ProductPageResolver implements Resolve<any> {

  constructor(private reportsService: ReportsService, private messageService: ToastMessageService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    let componentData = {};

    console.log(route, state);

    if(route.parent.routeConfig.path === 'product-pages'){
      if ( +route.params['id'] > 0 ) {

        return this.reportsService.getResource( route.params['id'] )
        .pipe(
          map(responseBody => {
            componentData = Object.assign({}, { resource:responseBody['data'], isPreview: false, page_id : route.params['ppid']} );
            return componentData;
          }),
          catchError( ( err ) => {
            this.messageService.addMessage('warning', 'An Error Occured', err.error.message || 'Please contact admin.');
            throw err;
          })
        );
    }this.messageService.clearMessages(); this.messageService.addMessage('warning', 'An Error Occured', 'Invalid parameters.');
  }this.messageService.addMessage('warning', 'An Error Occured', 'You are not allowed access to this data.');
}
}
