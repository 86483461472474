export class ExExecutionLog {

    //These objects are used for GET extract list method.
    //Only the below columns will be visible

    extract_execution_log_key: number;
    extract_configuration_description: string;
    extract_name: string;
    actual_sp_call_params: string;
    full_output_path: string;
    expected_start_dt: string;
    actual_start_dt: string;
    expected_completion_dt:string;
    actual_completion_dt: string;
    total_runtime: number;
    error_code: string;
    error_description:string;
    retry_count:number;
    rescheduled_start_dt:string;
    is_rescheduled: string;
    is_adhoc: string;



      constructor(exexecutionlog) {

        this.extract_execution_log_key = exexecutionlog.extract_execution_log_key;
        this.extract_configuration_description=exexecutionlog.extract_configuration_description;
        this.extract_name = exexecutionlog.extract_name;
        this.actual_sp_call_params = exexecutionlog.actual_sp_call_params;
        this.full_output_path=exexecutionlog.full_output_path;
        this.expected_start_dt = exexecutionlog.expected_start_dt;
        this.actual_start_dt = exexecutionlog.actual_start_dt;
        this.expected_completion_dt = exexecutionlog.expected_completion_dt;
        this.actual_completion_dt = exexecutionlog.actual_completion_dt;
        this.total_runtime = exexecutionlog.total_runtime;
        this.error_code = exexecutionlog.error_code;
        this.error_description = exexecutionlog.error_description
        this.retry_count = exexecutionlog.retry_count;
        if (this.rescheduled_start_dt) {
          this.rescheduled_start_dt = Object.keys(exexecutionlog.rescheduled_start_dt).length === 0 ? '' : exexecutionlog.rescheduled_start_dt;
        } else {
          this.rescheduled_start_dt = '';
        }
        this.is_rescheduled = exexecutionlog.is_rescheduled;
        this.is_adhoc = exexecutionlog.is_adhoc;


      }
    }
