import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Services
import { UserExtractsService } from '@edw-app-root/services/user-extracts.service';
// Modules
import { EDWSharedModule } from '@edw-app-root/shared/shared.module';
import { ValueRatingDisplayComponent } from '@edw-app-root/shared/value-rating/value-display.component';
// import { EDWResourcesPagingService } from './components/services/__DELETEpaging.service';
import { EDWMessageService } from '@edw-services/messaging.service';
import { ReportsService } from '@edw-services/reports.service';
import { ToggleNavigationService } from '@edw-services/toggle-navigation.service';
import { AccordionModule, AutoCompleteModule, ButtonModule, CalendarModule, ChartModule, CheckboxModule, ChipsModule, DialogModule, DropdownModule, DynamicDialogModule, InputMaskModule, MenubarModule, MessagesModule, PaginatorModule, SharedModule, SplitButtonModule, TableModule, TabMenuModule, TabViewModule, ToastModule } from 'primeng-lts';
import { UserCollectionService } from '../services/user-collection.service';
import { PowerBiExportComponent } from './all-resources/containers/powerbi-export/powerbi-export.component';
// Components //
import { ViewComponent } from './all-resources/report-viewer/view.component';
import { ReportsListingComponent } from './all-resources/reports-listing/reports-listing.component';
import { AdminComponent, AdminInputComponent } from './collections/admin-component/admin.component';
import { CollectionsComponent } from './collections/collections-component/collections.component';
import { CollectionTabComponent } from './components/collection-tab-selectors/collection-tab.component';
import { ExtractActionsComponent } from './components/extracts/extract-actions/extract-actions.component';
import { ExtractExecuteComponent } from './components/extracts/extract-execute/extract-execute.component';
import { ExtractListComponent } from './components/extracts/extract-list/extract-list.component';
import { FilterInputComponent, FilterInputService } from './components/filter-input/filter-input.component';
import { LayoutSelectorComponent } from './components/layout-selector/layout-selector.component';
import { ReportListComponent } from './components/reports/report-list/report-list.component';
import { FilterBySelected, FilterPipe, OnlyShowSelected, ResourceFilter, SortAlpha, TruncatePipe } from './components/resource.filter';
import { EDWModalService } from './components/services/modal.service';
import { CollectionDataService, CollectionService, ResourceService } from './components/services/resource.service';
import { UserCollectionDataService } from './components/services/user-collection-data.service';
import { ProductPagesListingComponent } from './product-pages/product-pages-listing/product-pages-listing.component';
// Resolvers
import { UExtractsResolver } from './resolvers/extracts.resolver';
import { FavoriteExtractsResolver, FavouritesReportsResolver } from './resolvers/favourites.resolver';
import { PreviewRoutingResolver } from './resolvers/preview.resolver';
import { ProductPageResolver } from './resolvers/product-page.resolver';
import { ProductPagesRoutingResolver } from './resolvers/product-pages.resolver';
import { ReportRoutingResolver } from './resolvers/report.resolver';
import { ReportsRoutingResolver } from './resolvers/reports.resolver';
import { SnowTicketResolver } from './resolvers/snow-ticket.resolver';
import { UserCollectionRoutingResolver } from './resolvers/user-collection.resolver';
import { ValueRatingResolver } from './resolvers/value-rating.resolver';
import { ResourcesRoutingModule } from './resources-routing.module';

@NgModule({
  declarations: [
    ReportsListingComponent,
    ViewComponent,
    LayoutSelectorComponent,
    ReportListComponent,
    ExtractListComponent,
    FilterInputComponent,
    CollectionTabComponent,
    ExtractActionsComponent,
    CollectionsComponent,
    ExtractExecuteComponent,
    ProductPagesListingComponent,
    AdminComponent,
    AdminInputComponent,
    ResourceFilter,
    FilterPipe,
    FilterBySelected,
    OnlyShowSelected,
    TruncatePipe,
    SortAlpha,
    ValueRatingDisplayComponent,
    PowerBiExportComponent
  ],
  imports: [
    DynamicDialogModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    SharedModule,
    TableModule,
    CheckboxModule,
    EDWSharedModule,
    AccordionModule,
    AutoCompleteModule,
    MessagesModule,
    ToastModule,
    DropdownModule,
    ChartModule,
    InputMaskModule,
    CalendarModule,
    TabMenuModule,
    ResourcesRoutingModule,
    ChipsModule,
    TabViewModule,
    PaginatorModule,
    SplitButtonModule,
    ButtonModule,
    MenubarModule
  ],
  providers: [
    ResourceService,
    CollectionService,
    CollectionDataService,
    ToggleNavigationService,
    ReportsService,
    EDWMessageService,
    UserCollectionService,
    UserCollectionDataService,
    UserExtractsService,
    ReportsRoutingResolver,
    ReportRoutingResolver,
    UExtractsResolver,
    FavouritesReportsResolver,
    FavoriteExtractsResolver,
    PreviewRoutingResolver,
    UserCollectionRoutingResolver,
    ProductPagesRoutingResolver,
    EDWModalService,
    // EDWResourcesPagingService,
    FilterInputService,
    ProductPageResolver,
    ValueRatingResolver,
    SnowTicketResolver
  ],
  entryComponents:[PowerBiExportComponent]
})
export class ResourcesModule { }

