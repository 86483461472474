import { Injectable } from '@angular/core';

export interface IFilterState{
  field:string;
  filterVal : boolean | null;
}

@Injectable({providedIn: 'root'})
export class ListToggleFilterService {

  masterList : any[] = [];

  filterStates : IFilterState[]= [];

  constructor() { }

  private _validations = () => {
    if( this.masterList.length > 0 || this.filterStates.length > 0 ) return true;

    if(this.masterList.length == 0){
      console.warn("A full data model is needed to use the List Filter Service");
    }

    if(this.filterStates.length == 0 ){
      console.warn("List Filter requires at least a single IFilterState object");
    }


    return false;
  }

  private _updateFilterState = (field : string, value : boolean ) => {
    if( field == null || value == null ) return;

    this.filterStates.find(state => state.field == field).filterVal = value;
  }

  private _filterList = (list )  => {

    let states = this.filterStates.filter( state => state.filterVal != null );

    if( states.length == 0 ){
      console.log("List is unfiltered");
      return list;
    }

    return list.filter( extract => {

      let matches = states.filter(state => {
        return extract[state.field] == state.filterVal;
      })

      return matches.length == states.length;
    });

  }

  public initService = (_masterList: any[], _filterStates: IFilterState[]) => {
    this.masterList = _masterList;
    this.filterStates = _filterStates;
    this._validations();
  }

  public filterListByCurrentState = ( list ) => {
    return this._filterList(list);
  }

  public filterBy = ( field?: string, value?: boolean ) => {


    this._updateFilterState( field, value );

    if(this._validations() == false) return;

    // let states = this.filterStates.filter( state => state.filterVal != null );


    // if( states.length == 0 ){
    //   console.log("List is unfiltered");
    //   return this.masterList;
    // }

    console.log("List is filtered");

    return this._filterList(this.masterList);

    // this.masterList.filter( extract => {

    //   let matches = states.filter(state => {
    //     return extract[state.field] == state.filterVal;
    //   })

    //   return matches.length == states.length;
    // });
  }



}
