import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APIMapService } from '@edw-app-root/services/httpService/api-map.service';
import { ButtonModule } from 'primeng-lts';

import { NonProdTestComponent } from './non-prod-test.component';
import { NonProdTestGuard } from './non-prod-test.guard';


const routes: Routes = [
  {
    path: '',
    canActivate:[NonProdTestGuard],
    component:NonProdTestComponent
  }
]

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  declarations: [NonProdTestComponent],
  providers: [NonProdTestGuard, APIMapService],
})
export class NonProdTestModule { }
