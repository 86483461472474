
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';


import { IUserExtractParameter } from '@edw-app-root/entities/user-extracts';
import { LoaderService } from '@edw-app-root/services/loader.service';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { UserExtractsService } from '@edw-services/user-extracts.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { EDWMessageService, ToastMessageService } from '@edw-services/messaging.service';
import { CollectionDataService, CollectionService, ResourceService } from '../../services/resource.service';
import { ActionType_ENUM } from '../extract-actions/action-types-enum';
import { ExtractService } from '../extract.service';
@Component({
  selector: 'edw-extract-execute',
  templateUrl: './extract-execute.component.html',
  styleUrls: ['extract-execute.component.scss']
})

export class ExtractExecuteComponent implements OnInit {
  @ViewChild('executeExtractConfirmationModal', {static: true}) executeExtractConfirmationModal;
  @Input() extracts: any[];
  @Input() layoutTile: boolean;
  @Input() extractData:{};
  @Output() pushMessage = new EventEmitter();

  public inProgress = false;
  public params = new BehaviorSubject(null);
  public paramsForm: FormGroup;
  public paramsSub: Subscription;
  public formSub: Subscription;
  public formSubmitted = false;
  public showExtractParams = false;
  public
  public userExtracts = [];
  private loadExtract: Subscription;
  public booleanValues = [
    { label: 'Choose one', value: '' },
    { label: 'Yes', value: 'True' },
    { label: 'No', value: 'False' },
  ];


  public cache;
  constructor(
    private userExtractsService: UserExtractsService,
    private loader: LoaderService,
    private errorService: ErrorHandlerService,
    private fb: FormBuilder,
    private _selectedExtract: ExtractService,
    private toastMessageService: ToastMessageService,
    private messageService:EDWMessageService,
    private resourceService: ResourceService,
    private collectionService:CollectionService,
    private collectionDataService:CollectionDataService
  ) {

    this.paramsForm = this._defaultParamForm();
  }

  ngOnInit() {
    /* listening for event to come from extract-action buttons. */
    this.loadExtract = this._selectedExtract.extract.subscribe( data => this.extractButtonAction(data));

  }

  public extractButtonAction(data){
    switch(data.type){
      case ActionType_ENUM.adhocGenerate:
        console.log("GENERATE ADHOC EXTRACT");
        this.generateParamForm(data.extract);
        break;
      case ActionType_ENUM.adhocDownload:
        console.log('DOWNLOAD ADHOC EXTRACT');
        this.downloadExtract(data.extract.adHocDownloadId, data.extract);
        break;
      case ActionType_ENUM.download:
        console.log('DOWNLOAD EXTRACT');
        this.downloadExtract(data.extract.downloadId, data.extract );
        break;
      default:
        console.log("No extract has been selected");
    }
  }

  private generateParamForm(extract){
    this.cache = extract;
    this.userExtractsService.getUserParams(
      extract.id.toString()
    ).pipe(
      finalize(() =>{
        this.paramsForm.updateValueAndValidity();
        this.showExtractParams = true;
      }),
      catchError(err=> this.ThrowErr(err))
    ).subscribe((data) => {
      this.paramsForm = this._defaultParamForm();
      this.setFormParamID(data);
      this.setFormParams(data);
    });
  }


  public _defaultParamForm(){
    return this.fb.group({
      id: '',
      parameters: this.fb.array([])
    });
  }

  setFormParamID(data): void{
    this.paramsForm.get('id').setValue(data.id);
  }

  setFormParams(data): void{
    const fa = <FormArray>this.paramsForm.get('parameters');

    if (data.parameters) {
      data.parameters.forEach((param: IUserExtractParameter) => {
        fa.push( this.addParam(param) );
      });
    }
  }


  public addParam(param: IUserExtractParameter) {
    const controlGroup = this.fb.group( new ExtractFormArrayValue() );

    controlGroup.patchValue(
      Object.assign({}, param,
        {
          values : this.paramValue(param),
          isDropDown: this.isParamDropDown(param)
        }
      ));
      if(param.isRequired){
        controlGroup.get('values').setValidators(Validators.required);
      }
      return controlGroup;
  }


  private paramValue(param: IUserExtractParameter): {}{
    let values;

    // targeting the param values, whereever they are in the param...
    if(param.defaultValues){
      values = (param.values)
        ? this.targetValue(param.values, param.allowMultipleValues)
        : this.targetValue(param.defaultValues, param.allowMultipleValues);
    }
    // DateTime casting is super yucky and not very functional...
    // if the param is a DateTime, convert the found values into valide js Date objects.
    if (values && param.parameterType === 'DateTime' && (!param.availableValues || param.availableValues.length < 1)) {
      if (Array.isArray(values)) {
        // an array of dates...
        return (values as string[]).map((dateString) => {
          return new Date(dateString as string);
        })
      } else {
        return new Date(values as string);
      }
    }

    return values;
  }

  private targetValue(value, multi){

    if(value.length > 1){ return value;}
    if(value.length === 1){
      return value[0] || null;
    }
    return (multi) ? value : null;
  }

  private isParamDropDown(param:IUserExtractParameter){
    return (
      !param.allowMultipleValues &&
      param.defaultValues &&
      param.availableValues &&
      param.availableValues.length >= 1
    ) ? true : false;
  }


  public getControls(frmGrp: FormGroup, key: string) {
    return (<FormArray>frmGrp.get(key)).controls;
  }

  public executeExtract(report) {
    this.executeExtractConfirmationModal.showDialog(report.externalId);
  }

  public runUserExtract() {

    this.formSubmitted = true;
    // this.runAdHocBtn.nativeElement.disable = true;
    this.inProgress = true;
    this.loader.showLoader();
    if (this.paramsForm.valid) {
      // console.log(this.form.value);
      const formValue = Object.assign({}, this.paramsForm.value);
      if (formValue.parameters.length) {
        formValue.parameters = formValue.parameters.map((param) => {
          if (!Array.isArray(param.values)) {
            param.values = [param.values];
          }
          return param;
        });
      }

      this.userExtractsService.runUserExtract(formValue).pipe(
        finalize(() => {

          this.showExtractParams = false;
          this.loader.hideLoader();
          this.inProgress = false;
          // this.runAdHocBtn.nativeElement.disable = false;
        }),
        catchError((err) => {
          this.errorService.httpErrorHandler(err);
          return Observable.throw(err);
        })
      ).subscribe((res) => {
        console.log('returned extract',res);
        console.log('cached extract',this.cache);

        if(res.adHocStatus === 5){
          this.toastMessageService.addMessage('error', '', 'There was an error trying to execute this extract.')
          return false;
        }
        this.toastMessageService.addMessage('success', '', `The user extract "${res.name}" was set to execute adhoc`);
        this.collectionService.updateCSExtracts(res);
        // this.collectionDataService.refreshCollectionData();
      });
    }

  }

  public downloadExtract(downloadID, extract) {

    if(downloadID == null){
      this.toastMessageService.addMessage('error','', `Unable to Download the extract. Please check to see that the extract is authorized for Downloading.`);
      return false;
    }
    console.log('downloadExtract', extract);
    this.inProgress = true;
    // const name = extract.name.toLowerCase();

    this.userExtractsService.downloadExtract(
      downloadID.toString(), extract.name.toLowerCase()
    ).pipe(
      finalize(() => {
        this.inProgress = false;
      }),
      catchError((err) => {
        this.errorService.httpErrorHandler(err);
        return Observable.throw(err);
      })
    )
    .subscribe((res: { filename: string, data: any }) => {
      console.log('start download:', res);
      let filename = res.filename.replace(/[,\/#!$%\^&\*;:{}=\_`~()]/g, '-');
      filename = filename.replace(/\s+/g, '-');
      filename = filename.replace(/[-]+/g, '-');
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element

    }, (error) => {
      console.log('download error:', JSON.stringify(error));
      this.toastMessageService.addMessage('error','',`There was an error trying to download extract. Error:${error.message}`)
    }, () => {
      console.log('Completed file download.');
    });
  }

  private ThrowErr(err){
    this.errorService.httpErrorHandler(err);
    return Observable.throw(err);
  }

  log(val){
  //  console.log(val);
  }


}

class ExtractFormArrayValue {
  id:any;
  parameterName:string;
  parameterType:string;
  parameterPrompt:string;
  values:string;
  isRequired:boolean;
  isDropdown:boolean;
  allowMultipleValues:null;
  availableValues:null;
  hasDefaultQueryValue:boolean;
  constructor(){
  this.id = null;
    this.parameterName  =  '';
    this.parameterType = '';
    this.parameterPrompt = '';
    this.values = '';
    this.isRequired = false;
    this.isDropdown = false;
    this.allowMultipleValues = null;
    this.availableValues = null;
    this.hasDefaultQueryValue = false;
  }
}
