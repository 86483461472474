
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'edw-tooltip-hover',
  template: `<div class="tooltip">{{text}}</div>`,
  styles: ['.tooltip{padding:1rem; border:1px solid black; position:absolute;}']
})
export class TooltipHoverComponent implements OnInit {
  @Input() text: string = 'toolTip';
  ngOnInit(){}
}

