import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'edw-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements OnInit, OnDestroy {
  //position of the tooltip relative to the element we have placed it under
  @Input() position: string;

  //the element which triggers the showing of the tooltip
  @Input() triggerElement;

  //the event which triggers the showing of the tooltip;
  //e.g.: click, focus, mouseenter, etc.
  @Input() triggerEvent: string;

  @Input() show: boolean;
  @Output() showChange = new EventEmitter<boolean>();


  @ViewChild('tooltip', {static: true}) tooltip: ElementRef;

  private eventTriggerSubscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.eventTriggerSubscription =
      fromEvent(this.triggerElement, this.triggerEvent)
      .subscribe((event: MouseEvent) => {
        this.show = true;
        this.showChange.emit(this.show);
      });


  }

  ngOnDestroy() {
    this.eventTriggerSubscription.unsubscribe();
  }

  ngOnChanges(changesObj) {
    if (changesObj.hasOwnProperty('show')) {
      if (changesObj.show.currentValue === true) {
        this.tooltip.nativeElement.classList.remove('display-none');

        setTimeout(() => {
          this.tooltip.nativeElement.classList.add('fade-in');
        }, 50);
      } else {
        setTimeout(() => {
          this.tooltip.nativeElement.classList.add('display-none');
        }, 300);

        this.tooltip.nativeElement.classList.remove('fade-in');
      }
    }
  }

  onClickOutside($event) {
    if ($event.isOutside) {
      this.show = false;
    } else {
      this.show = true;
    }

    this.showChange.emit(this.show);
  }

}
