import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { camelCaseToSnakeCase, snakeCaseToCamelCase } from './../shame';


@Injectable()
export class ExtractConfigDataService {

  constructor(private http: HttpClient) { }

  getExtractConfigOptions() {
    let params = new HttpParams();

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<Response>(environment.api + '/extractConfig/getOptions', options)
      .pipe(map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getExtractConfigOutputs(id: string) {
    let params = new HttpParams();
    params = params.set('id', id);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extractConfig/getOutputs', options)
      .pipe(map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getExtractConfigList(query: string) {
    let params = new HttpParams();
    params = params.set('query', query);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extractConfig/get', options)
      .pipe(map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  getExtractConfig(id: string) {
    let params = new HttpParams();
    params = params.set('id', id);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/extractConfig/getById', options)
      .pipe(map(response => {
        return camelCaseToSnakeCase(response);
      }));
  }

  addEditExtractConfig(extractConfig: Object): Observable<Object> {
    const options: Object = {
      observe: 'body'
    };

    extractConfig = snakeCaseToCamelCase(extractConfig);

    return this.http.post(environment.api + '/extractConfig/createOrUpdate', extractConfig, options);
  }

  deactivateExtractConfig(id: string): Observable<Object> {
    let params = new HttpParams();
    params = params.set('id', id);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post<Response>(environment.api + '/extractConfig/deactivate', null, options);
  }
}
