export class SearchParams {

  page: number;
  query: string;

  constructor(page: number, query: string) {
    this.page = page;
    this.query = query;
  }

}
