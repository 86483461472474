import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
    AccordionModule,
    AutoCompleteModule,
    DialogModule,
    MessagesModule,
    RadioButtonModule,
    SharedModule,
    TableModule,
    ToastModule
} from 'primeng-lts';

import { ExecuteAdhocExtractConfirmationComponent } from './execute-adhoc-extract-confirmation/execute-adhoc-extract-confirmation.component';
import { ManageSecurityComponent } from './manage.security/manage.security.component';
import { MultipleChoiceListComponent } from './multiple-choice-list/multiple-choice-list.component';
import { RelatedEntityComponent } from './related-entity/related-entity.component';
import { StepperComponent } from './stepper/stepper.component';
import { TooltipComponent } from './tooltip/tooltip.component';

import { ConfirmDialogModule, ConfirmationService } from 'primeng-lts';
import { ClickOutsideDirective } from './click-outside.directive';
import { DomSanitizerPipe } from './dom-sanitizer.pipe';
import { ExpandCollapseEntitiesComponent } from './expand-collapse-entities/expand-collapse-entities.component';
import { ExtractSubstringsMatchingRegex } from './extract-substrings-matching-regex.directive';
import { ObjectToIterablePipe } from './object-to-iterable.pipe';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { TooltipHoverComponent } from './tooltip-hover/tooltip-hover.component';
import { TooltipHoverDirective } from './tooltip-hover/tooltip-hover.directive';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    SharedModule,
    FormsModule,
    AutoCompleteModule,
    DialogModule,
    AccordionModule,
    MessagesModule,
    ToastModule,
    ConfirmDialogModule,
    RadioButtonModule
  ],
  declarations: [
    StepperComponent,
    MultipleChoiceListComponent,
    RelatedEntityComponent,
    ExpandCollapseEntitiesComponent,
    ScrollIntoViewDirective,
    ExecuteAdhocExtractConfirmationComponent,
    ExtractSubstringsMatchingRegex,
    TooltipComponent,
    ClickOutsideDirective,
    DomSanitizerPipe,
    ObjectToIterablePipe,
    StatusIndicatorComponent,
    TooltipHoverComponent,
    TooltipHoverDirective,
    ManageSecurityComponent
  ],
  providers:[ConfirmationService],
  entryComponents:[TooltipHoverComponent],
  exports: [
    StepperComponent,
    MultipleChoiceListComponent,
    StatusIndicatorComponent,
    RelatedEntityComponent,
    ExpandCollapseEntitiesComponent,
    ScrollIntoViewDirective,
    ExecuteAdhocExtractConfirmationComponent,
    ExtractSubstringsMatchingRegex,
    TooltipComponent,
    ClickOutsideDirective,
    DomSanitizerPipe,
    ObjectToIterablePipe,
    TooltipHoverDirective,
    ManageSecurityComponent
  ]
})
export class EDWSharedModule { }
