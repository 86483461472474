import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  add(key: string, value: any) {
    if (typeof value === 'undefined') {
      value = null;
    }

    localStorage[key] = JSON.stringify(value);

    return value;
  }

  get(key: string) {
    let value = null;

    if (localStorage.hasOwnProperty(key) && localStorage[key] !== null) {
      value = JSON.parse(localStorage[key]);
    }

    return value;
  }

  remove(key: string) {
    if (localStorage.hasOwnProperty(key) && localStorage[key] !== null) {
      localStorage.removeItem(key);
    }
  }
}
