import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Permissions } from '@edw-app-root/permissions';
import { UserDataService } from '@edw-services/user-data.service';
import { SessionStorageService } from '@edw-services/session-storage.service';

@Injectable()
export class AuditGuard implements CanActivate {
  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
      let canActivate = false;

      const urlFragments = state.url.split('/');
      const canViewAudits = this.userDataService.checkForSpecificPermission(Permissions.AUDIT.canView);

      if (urlFragments.indexOf('audit') > -1) {
        if (canViewAudits) {
          canActivate = true;
        }
      }

      if (!canActivate) {
        this.router.navigated = false;
        this.sessionStorageService.add(
          'message-error',
          'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
        );
      return true;
    }
    return canActivate;
  }
}
