export class EmbedReport {

  public reportId: string;
  public reportName: string;
  public embedUrl: string;

  constructor(embedReport) {
    this.reportId = embedReport.reportId;
    this.reportName = embedReport.reportName;
    this.embedUrl = embedReport.embedUrl;
  }
}

export interface IEmbedReport {
  reportId: string;
  reportName: string;
  embedUrl: string;
}

export class EmbedToken {
  public token: string;
  public tokenId: string;
  public expiration: Date;

  constructor(embedToken) {
    this.token = embedToken.token;
    this.tokenId = embedToken.tokenId;
    this.expiration = embedToken.expiration;
  }
}

export interface IEmbedToken {
  token: string;
  tokenId: string;
  expiration: Date;
}

export class ReportEmbedConfig {
  public embedReports: EmbedReport[];
  public embedToken: EmbedToken;
  public reportType: string;

  constructor(reportEmbedConfig) {
    this.embedReports = reportEmbedConfig.embedReports;
    this.embedToken = reportEmbedConfig.embedToken;
  }
}

export interface IReportEmbedConfig {
  embedReports: EmbedReport[];
  embedToken: EmbedToken;
}
