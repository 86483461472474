import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { ConfirmationService } from 'primeng-lts';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Report } from '@edw-entities/report';

import { LocalStorageService } from '@edw-services/local-storage.service';
import { UserDataService } from '@edw-services/user-data.service';

@Injectable({providedIn: 'root'})
export class SnowTicketService {

  Resource$ : BehaviorSubject<Report> = new BehaviorSubject<Report>(null); // gets set from the value-rating.resolver
  set resource( value : Report ){ this.Resource$.next(value); }
  get resource(){ return this.Resource$.value; }

  constructor( ) { }

}

@Component({
  selector: 'snow-ticket',
  template: `
  <ng-container *ngIf="( linkVisible && nmhcdomain )">
    <div>&nbsp;</div>
    <a (click)="onLinkClick()" class="emphasis" style="cursor: pointer">Click here to log a ticket to Analytics</a>
  </ng-container>
  `,
  styles:[
    ` .line-height{ line-height:2rem; }
      .padding{ padding: 12px 0; }
      .warning{ color: orange; }
      .danger{ color: red; }
      `
  ],
  providers : [ConfirmationService]
})

export class SnowTicketComponent implements OnInit, OnDestroy {
  linkVisible = false;

  subscriptions : Subscription[] = [];
  userInfo;
  nmhcdomain = true;

  constructor(
    private userDataService: UserDataService,
    private snowTicketService : SnowTicketService,
    private router: Router,
    private localStorageService: LocalStorageService
  ){

    this.subscriptions = [].concat(
      [
        this.snowTicketService.Resource$.subscribe( resource => {
          if( !!resource ){
            this.linkVisible = true;
          }
        }),
        this.router.events.subscribe(event => {
          if( event instanceof NavigationStart ){
            this.ngOnDestroy();
          }
        })
      ]
    );

  }

  ngOnInit() {

    this.userDataService.userInfoLoadedObservable
      .subscribe((info) => {
        if (info.loaded === true) {
          let helpMessage = '';
          var domain = this.userDataService.getUserDomain();
          this.userInfo = this.userDataService.getCachedUserData();
         /*


          let currentUserAccount = this.userInfo.accounts.map((account) => {
            return account.activeDirectory;
          })[0];

          let currentUserAccounts = this.userInfo.accounts;

          let currentUserAccountDomain = this.userInfo.accounts.map((account) => {
            return account.activeDirectory;
          })[0];

          var loginSSO = this.localStorageService.get('loginSSO');

          //default domain will be nmhc
          var domain = "nmhc";

          //when login SSO is known, user context domain is based solely on domain.
          if (loginSSO!=null && loginSSO!="" && (loginSSO=='nmhc' || loginSSO=='nu')) {
            if(loginSSO=='nmhc'){
              domain = "nmhc";
            }
            else{
              domain = "nu";
            }
          } else {
            //when login SSO is not known, it depends on user acounts.
            if(currentUserAccounts.length == 2){
              //when both accounts are present, the user is assumed nmhc
              domain = "nmhc";
            }
            else if(currentUserAccounts.length == 1){
              //other wise, it is the sole user account.
              switch (currentUserAccountDomain) {
                case AuthenticationDomains.nmhc:
                  domain = "nmhc";
                  break;
                case AuthenticationDomains.nwu:
                  domain = "nu";
                  break;
                default: ;
              }
            }
            else{
              //This is a catch all set to nmhc
              domain = "nmhc";
            }
          }
          */
          //the domain set above will be used to display the message.
          switch (domain) {
            case 'nmhc':
              this.nmhcdomain = true;
              break;
            case 'nu':
              this.nmhcdomain = false;
              break;
            default: ;
          }

        }
      });

  }

  ngOnDestroy() {
    this.linkVisible = false;
    this.subscriptions.forEach( sub => sub.unsubscribe() );
  }

  onLinkClick = () => {
   var res;
    this.snowTicketService.Resource$.subscribe( resource => {
      if( !!resource ){
        res = resource;
      }
    });

    var metadata = res['metadata'];
    var resourceowner;
    var businessowner;

    metadata.forEach( (element) => {
      if(element.name == "Business Owner"){
        businessowner = element.detail;
      }
      if(element.name == "Resource Owner"){
        resourceowner = element.detail;
      }
    });

    //description;
    var description = "";
    if(resourceowner != null){
      description = description+resourceowner+"\n";
    }
    description = description+res['modifyUserName']+"\n";
    description = description+res['modifyDate']+"\n";
    if(businessowner != null){
      description = description+businessowner+"\n";
    }
    description = description+"\n*** Please provide detailed information related to your issue or request ***\n";

    window.open(
      "https://nmhc.service-now.com/mynmi?id=sc_cat_item&sys_id=904f053513b15f007d59bd322244b0cb&sysparm_short_desc="+encodeURIComponent(res['resourceName'])+"&sysparm_desc="+encodeURIComponent(description),
      "_blank"
      );
  }

}
