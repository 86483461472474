import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Permissions } from '@edw-app-root/permissions';
import { FontAwesomeService } from '@edw-app-root/shared/fontawesome-icons/fontawesome-icons.service';
import { UserDataService } from '@edw-services/user-data.service';
import { MenuItem } from 'primeng-lts';
import { environment } from '../../environments/environment';

@Component({
  selector: 'edw-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeftNavComponent implements OnInit {
  @Input() show: boolean;

  items: MenuItem[] = [];

  constructor(
    private userDataService: UserDataService,
    private fontAwesomeService : FontAwesomeService
  ) { }

  ngOnInit() {
    this.userDataService.userInfoLoadedObservable
      .subscribe((info) => {
        if (info.loaded === true) {
          this.determineMenuItems();
        }
      });
  }

  private determineMenuItems() {
    const canViewUsers = this.userDataService.checkForSpecificPermission(Permissions.USERS.canView);
    const canViewUserGroups = this.userDataService.checkForSpecificPermission(Permissions.USERGROUPS.canView);
    const canViewResources = this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canView);
    const canViewResourceGroups = this.userDataService.checkForSpecificPermission(Permissions.RESOURCEGROUPS.canView);
    const canViewSubscriptions = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canView);
    const canViewExtracts = this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canView);

    const canApproveReports = this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canApprove);
    const canApproveExtracts = this.userDataService.checkForSpecificPermission(Permissions.EXTRACTS.canApprove);
    const canApproveSubscriptions = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canApprove);

    const canViewPermissionGroups = this.userDataService.checkForSpecificPermission(Permissions.PERMISSION_GROUPS.canView);

    const canViewCohorts = this.userDataService.checkForSpecificPermission(Permissions.COHORTS.canView);
    const canApproveCohorts = this.userDataService.checkForSpecificPermission(Permissions.COHORTS.canApprove);
    const canManageResearchRequest = this.userDataService.checkForSpecificPermission(Permissions.RESEARCH_REQUEST.canManage);
    const canApproveResearchRequest = this.userDataService.checkForSpecificPermission(Permissions.RESEARCH_REQUEST.canApprove);
    const canViewAllResearchRequest = this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canViewAll);
    const canViewResearchRequest = this.userDataService.checkForSpecificPermission(Permissions.APPROVAL_REQUEST.canView);

    const canManageUserExtracts = this.userDataService.checkForSpecificPermission(Permissions.USER_EXTRACTS.canManage);

    const canViewAudits = this.userDataService.checkForSpecificPermission(Permissions.AUDIT.canView);

    const canManageAlerts = this.userDataService.checkForSpecificPermission(Permissions.ALERTS.canManage);

    const canManageProductPages = this.userDataService.checkForSpecificPermission(Permissions.PRODUCT_PAGES.canManageProductPages);

    const canManageMetadata = this.userDataService.checkForSpecificPermission(Permissions.METADATA.canManageMetadata);

    this.items = [];

    const resourcesSection = {
      label: 'Resources',
      items: [
        {
          label: 'All Resources',
          icon: 'fa fa-list',
          routerLink: ['/app/resources/all']
        },
        {
          label: 'Favorites',
          icon: 'fa fa-heart',
          routerLink: ['/app/resources/favorite']
        },

        {
          label:"Data Requests",
          icon:"fa fa-list",
          items:[
            {
              label: 'Research Requests',
              icon: 'fa fa-flask',
              routerLink: ['/app/resources/requests']
            },
            {
              label:'Analytics Requests',
              icon: 'fa fa-flask',
              url:'https://nmhc.service-now.com/mynmi?id=sc_cat_item&sys_id=88adcf61971529d015e3f117f053afa7&sysparm_category=109f0438c6112276003ae8ac13e7009d',
              //url:'https://nmhc.service-now.com/nav_to.do?uri=%2fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D275b3344136c9b407d59bd322244b04c%26sysparm_link_parent%3D109f0438c6112276003ae8ac13e7009d%26sysparm_catalog%3De0d08b13c3330100c8b837659bba8fb4%26sysparm_catalog_view%3Dcatalog_default%26sysparm_view%3Dcatalog_default',
              target:"_blank"
            }
          ]
        },
        {
          label: 'Product Pages',
          icon: 'fa fa-product-hunt',
          routerLink: ['/app/resources/product-pages/']
        },
        {
          label:"Analytics Catalog",
          icon:"fa fa-list",
          items:[
            {
              label:'NM Analytics Catalog',
              routerLink:['/app/resources/reports/10035']
            }
          ]
        }
      ],
      expanded: false
    };

    //Using Manage Alerts persmission temporarily for Product Pages
    // if (canManageProductPages) {
    //   resourcesSection.items.push({
    //     label: 'Product Pages',
    //     icon: 'fa-product-hunt',
    //     routerLink: ['/app/resources/product-pages/']
    //   }
    // );
    //}

    this.items.push(resourcesSection);

    const manageSection = {
      label: 'Manage',
      items: [],
      expanded: false
    };

    // determine if current user can manage users and user groups under Manage
    if (canViewUsers || canViewUserGroups) {
      const peopleSection = {
        label: 'People',
        icon: 'fa fa-user',
        items: []
      };

      if (canViewUsers) {
        peopleSection.items.push({
          label: 'Users',
          routerLink: ['/app/manage/users']
        });
      }

      if (canViewUserGroups) {
        peopleSection.items.push({
          label: 'User Groups',
          routerLink: ['/app/manage/user-groups']
        });
      }

      peopleSection.items.push({ label: 'Roles', disabled: true });

      manageSection.items.push(peopleSection);

      //TODO: Create permission for metadata
      if (canManageMetadata) {
        manageSection.items.push({
          label: 'Metadata',
          icon: 'fa fa-book',
          routerLink: ['/app/manage/metadata']
        });
      }

    }

    // determine if current user can manage resources and resource groups under Manage
    if (canViewResources || canViewResourceGroups) {
      const  resourceSection = {
        label: 'Resources',
        icon: 'fa fa-paste',
        items: []
      };

      if (canViewResourceGroups) {
        resourceSection.items.push({
          label: 'Resource Groups',
          routerLink: ['/app/manage/resource-groups']
        });
      }


      if (canViewResources) {
        resourceSection.items.push({
          label: 'Reports',
          routerLink: ['/app/manage/resources']
        });
      }

      if (canViewExtracts) {
        resourceSection.items.push({
          label: 'Vendor Extracts',
          routerLink: ['/app/manage/extracts'],
          disabled: false
        });
      }

      if (canManageUserExtracts) {
        resourceSection.items.push({
          label: 'User Extracts',
          routerLink: ['/app/manage/user-extracts'],
          disabled: false
        });
      }


      if (canViewSubscriptions) {
        const  subscriptionSection = {
          label: 'Subscriptions',
          items: []
        };

        subscriptionSection.items.push({
          label: 'Subscriptions',
          routerLink: ['/app/manage/subscriptions2'],
          disabled: false
        });

        subscriptionSection.items.push({
          label: 'Recipient Groups',
          routerLink: ['/app/manage/recipient-groups'],
          disabled: false
        });

        resourceSection.items.push(subscriptionSection);
      }

      if (canManageProductPages) {
        resourceSection.items.push({
          label: 'Product Pages',
          routerLink: ['/app/manage/product-pages']
        });
      }

      manageSection.items.push(resourceSection);
    }

    if (canViewPermissionGroups) {
      manageSection.items.push({
        label: 'Permissions',
        icon: 'fa fa-users',
        routerLink: ['/app/manage/permission-groups']
      });
    }

    // if is research analyst
    if (canViewCohorts || canManageResearchRequest || canApproveResearchRequest) {

      const researchSection = {
        label: 'Research',
        icon: 'fa fa-flask',
        items: []
      };

      console.log(canViewCohorts);

      if (canViewCohorts) {
        researchSection.items.push({
          label: 'Cohorts',
          routerLink: ['/app/manage/cohorts']
        });
      }

      if (canApproveCohorts) {
        researchSection.items.push({
          label: 'Cohort Queue',
          routerLink: ['/app/manage/cohorts-queue']
        });
      }

      if (canManageResearchRequest) {
        researchSection.items.push({
          label: 'Research Requests',
          routerLink: ['/app/manage/research-requests']
        });
      }

      if ((canViewResearchRequest || canViewAllResearchRequest) && canApproveResearchRequest) {
        researchSection.items.push({
          label: 'Data Steward Queue',
          routerLink: ['/app/manage/data-steward-queue']
        });
      }

      manageSection.items.push(researchSection);
    }
    //Utility Section
    // determine if current user can manage resources and resource groups under Manage
    if (canViewResources || canViewResourceGroups) {
      const  utilitySection = {
        label: 'Utilities',
        icon: 'fa fa-calculator',
        items: []
      };
         utilitySection.items.push({
          label: 'Report Writer',
          url: environment.reportWriter,
          target: "_blank"
        });
        utilitySection.items.push({
          label: 'AD Browser',
          url: environment.adbrowser,
          target: "_blank"
        });

      manageSection.items.push(utilitySection);

    }
    // if some of the above apply,
    // add requests and audit;
    // if not - add secure dropbox
    // and request access
    if (manageSection.items.length > 0) {
      manageSection.expanded = true;

      if (canApproveReports || canApproveExtracts || canApproveSubscriptions) {
        manageSection.items.push({
          label: 'Approval Requests',
          icon: 'fa fa-list',
          routerLink: ['/app/manage/requests']
        });
      }

      if (canViewAudits) {
          manageSection.items.push({
            label: 'Audit',
            icon: 'fa fa-check-circle',
            routerLink: ['/app/manage/audit']
          }
        );
      }

      if (canManageAlerts) {
        manageSection.items.push({
          label: 'Alerts',
          icon: 'fa fa-exclamation-circle',
          routerLink: ['/app/manage/alerts']
        });
      }

      this.items.push(manageSection);
    }
    else {
      resourcesSection.expanded = true;

      this.items.push(
        { label: 'Request Access', disabled: true }
      );
    }


    if(!environment.production){
      const testSection = {
        label: 'Testing',
        items: [],
        expanded: false
      };

      testSection.items = [
        {label:"Test Procsses", icon:'fa-list',routerLink:["./non-prod-tests"]}
      ]

      this.items.push(testSection);
    }

  }
}
