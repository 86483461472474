import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductPagesListingComponent } from '../resources/product-pages/product-pages-listing/product-pages-listing.component'; // '../manage/product-pages/product-pages-listing/product-pages-listing.component';
import { AuditGuard } from './audit/audit.guard';
import { AuditListComponent } from './audit/containers/audit-list/audit-list.component';
import { AuditListResolveGuard } from './audit/resolvers/audit-list.resolver';
import { CohortQueueListComponent } from './cohort-queue/containers/cohort-queue-list/cohort-queue-list.component';
import { CohortQueueListResolver } from './cohort-queue/resolvers/cohort-queue-list.resolver';
import { DataStewardRequestDetailsResolver } from './data-steward-queue/approval-request-details.resolver';
import { DataStewardLogViewComponent } from './data-steward-queue/containers/data-steward-log-view/data-steward-log-view.component';
import { DataStewardQueueListComponent } from './data-steward-queue/containers/data-steward-queue-list/data-steward-queue-list.component';
import { DataStewardRequestComponent } from './data-steward-queue/containers/data-steward-request/data-steward-request.component';
import { DataStewardLogViewResolver } from './data-steward-queue/data-steward-log-view.resolver';
import { DataStewardQueuePermissionsResolver } from './data-steward-queue/data-steward-queue-permissions.resolver';
import { DataStewardQueueGuard } from './data-steward-queue/data-steward-queue.guard';
import { DataStewardQueueResolver } from './data-steward-queue/data-steward-queue.resolver';
import { ExtractAdhocComponent } from './extracts/extract-adhoc/extract-adhoc.component';
import { ExtractApprovalsComponent } from './extracts/extract-approvals/extract-approvals.component';
import { ExtractConfigListComponent } from './extracts/extract-config-list/extract-config-list.component';
import { ExtractConfigComponent } from './extracts/extract-config/extract-config.component';
import { ExtractExecutionLogComponent } from './extracts/extract-execution-log/extract-execution-log.component';
import { ExtractMainComponent } from './extracts/extract-main/extract-main.component';
import { ExtractNotificationLogComponent } from './extracts/extract-notification-log/extract-notification-log.component';
import { ExtractRunAdhocComponent } from './extracts/extract-run-adhoc/extract-run-adhoc.component';
import { ExtractScheduleComponent } from './extracts/extract-schedule/extract-schedule.component';
import { ExtractSetupListComponent } from './extracts/extract-setup-list/extract-setup-list.component';
import { ExtractSetupComponent } from './extracts/extract-setup/extract-setup.component';
import { ManageExtractsGuard } from './extracts/extracts.guard';
import { ManageExtractRoutingResolver } from './extracts/extracts.resolver';
import { ManageAlertsGuard } from './guards/alerts.guard';
import { ManageCohortsGuard } from './guards/cohort.guard';
import { ManagePermissionsGuard } from './guards/permissions.guard';
// import { ManageProductPagesListingComponent } from './product-pages/product-pages-listing/product-pages-listing.component';
import { ProductPagesGuard } from './guards/product-pages.guard';
import { RecipientGroupGuard } from './guards/recipient-group.guard';
import { ManageResearchRequestsGuard } from './guards/research.guard';
import { ManageUserExtractsGuard } from './guards/user-extracts.guard';
import { ManagePeopleRouteGuard } from './people/people.guard';
import { ManagePeopleRoutingResolver } from './people/people.resolver';
import { UserDetailComponent } from './people/user-detail/user-detail.component';
import { UserGroupDetailComponent } from './people/user-group-detail/user-group-detail.component';
import { UserGroupsListingComponent } from './people/user-groups-listing/user-groups-listing.component';
import { UserListingComponent } from './people/user-listing/user-listing.component';
import { ManageProductPagesDetailComponent } from './product-pages/product-pages-detail/product-pages-detail.component';
import { ProductPagesRoutingResolver, ResourceTeamListResolver } from './product-pages/product-pages.resolver';
import { RequestsComponent } from './requests/requests.component';
import { ManageRequestsRouteGuard } from './requests/requests.guard';
import { ManageRequestsRoutingResolver } from './requests/requests.resolver';
import { DeployedRespurceDetailComponent } from './resources/deployed-resource-detail/deployed-resource-detail.component';
import { ReportDeployComponent } from './resources/report-deploy/report-deploy.component';
import { ReportRedeployComponent } from './resources/report-redeploy/report-redeploy.component';
import { ManageResourcesRoutingResolver } from './resources/resolvers/resources.resolver';
import { ResourceGroupDetailComponent } from './resources/resource-group-detail/resource-group-detail.component';
import { ResourceGroupsListingComponent } from './resources/resource-groups-listing/resource-groups-listing.component';
import { ResourceListingComponent } from './resources/resource-listing/resource-listing.component';
import { ManageResourcesRouteGuard } from './resources/resources.guard';
import { StagingResourceDetailComponent } from './resources/staging-resource-detail/staging-resource-detail.component';
import { ViewSqlComponent } from './resources/viewsql/viewsql.component';
import { SubscriptionDetailComponent } from './subscriptions/subscription-detail/subscription-detail.component';
import { SubscriptionSetupListComponent } from './subscriptions/subscription-setup-list/subscription-setup-list.component';
import { SubscriptionSetupComponent } from './subscriptions/subscription-setup/subscription-setup.component';
import { UpdateRedirectComponent } from './subscriptions/subscriptions-setup-steps/update-redirect/update-redirect.component';
import { ManageSubscriptionsRouteGuard } from './subscriptions/subscriptions.guard';
import { ManageSubscriptionsRoutingResolver } from './subscriptions/subscriptions.resolver';



const routes: Routes = [

// users
  {
    path: 'users',
    canActivate: [ManagePeopleRouteGuard],
    canActivateChild: [ManagePeopleRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserListingComponent,
      },
      {
        path: 'add',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserDetailComponent
      },
      {
        path: ':id',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserDetailComponent
      }
    ]
  },
  // user groups
  {
    path: 'user-groups',
    canActivate: [ManagePeopleRouteGuard],
    canActivateChild: [ManagePeopleRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserGroupsListingComponent,
      },
      {
        path: 'add',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserGroupDetailComponent
      },
      {
        path: ':id',
        resolve: {
          componentData: ManagePeopleRoutingResolver
        },
        component: UserGroupDetailComponent
      }
    ]
  },
  // permission-groups
  {
    path: 'permission-groups',
    canActivate: [ManagePermissionsGuard],
    canActivateChild: [ManagePermissionsGuard],
    loadChildren: './permission-groups/permissions.module#PermissionGroupsModule'

  },
  // alerts
  {
    path: 'alerts',
    canActivate: [ManageAlertsGuard],
    canActivateChild: [ManageAlertsGuard],
    loadChildren: './alerts/alerts.module#AlertsModule'
  },

  // metadata
  {
    path: 'metadata',
    //canActivate: [ManageAlertsGuard],
    //canActivateChild: [ManageAlertsGuard],
    loadChildren: './metadata/metadata.module#MetadataModule'
  },
  {
    path: 'teams',
    loadChildren: './teams/teams.module#TeamsModule'
  },
  // user extracts
  {
    path: 'user-extracts',
    canActivate: [ManageUserExtractsGuard],
    // canActivateChild: [ManageUserExtractsGuard],
    loadChildren: './user-extracts/user-extracts.module#UserExtractsModule'
  },

  // research requests
  {
    path: 'research-requests',
    loadChildren: './research/research.module#ResearchModule',
    canActivate: [ManageResearchRequestsGuard]
  },
  // audit
  {
    path: 'audit',
    component: AuditListComponent,
    canActivate: [AuditGuard],
    resolve: {
      componentData: AuditListResolveGuard
    }
  },
  // cohorts
  {
    path: 'cohorts',
    canActivate: [ManageCohortsGuard],
    canActivateChild: [ManageCohortsGuard],
    loadChildren: './cohorts/cohorts.module#CohortsModule'
  },
  {
    path: 'recipient-groups',
    canActivate: [RecipientGroupGuard],
    loadChildren: './subscriptions2/recipient-groups/recipient-group.module#RecipientGroupModule'
  },
  {
    path: 'subscriptions2',
   // canActivate: [ManageSubscriptionsRouteGuard],
    loadChildren: './subscriptions2/subscriptions/subscriptions.module#SubscriptionsModule'
  },
  // cohorts approval queue
  {
    path: 'cohorts-queue',
    component: CohortQueueListComponent,
    canActivate: [ManageCohortsGuard],
    resolve: {
      cohorts: CohortQueueListResolver
    }
  },
  // data-steward-queue
  {
    path: 'data-steward-queue',
    canActivate: [DataStewardQueueGuard],
    children: [
      {
        path: '',
        component: DataStewardQueueListComponent,
        resolve: {
          componentData: DataStewardQueueResolver,
          permissions: DataStewardQueuePermissionsResolver
        }
      },
      {
        path: ':dsrid',
        component: DataStewardRequestComponent,
        resolve: {
          dataStewardRequest: DataStewardRequestDetailsResolver
        }
      },
      {
        path: 'log/:rid',
        component: DataStewardLogViewComponent,
        resolve: {
          activites: DataStewardLogViewResolver
        }
      }
    ]
  },

  // requests
  {
    path: 'requests',
    component: RequestsComponent,
    canActivate: [ManageRequestsRouteGuard],
    resolve: {
      componentData: ManageRequestsRoutingResolver
    }
  },

  // resource-groups
  {
    path: 'resource-groups',
    canActivate: [ManageResourcesRouteGuard],
    canActivateChild: [ManageResourcesRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ResourceGroupsListingComponent,
      },
      {
        path: 'add',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ResourceGroupDetailComponent
      },
      {
        path: ':id',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ResourceGroupDetailComponent
      }
    ]
  },

  // report-deploy
  {
    path: 'report-deploy/:id',
    component: ReportDeployComponent,
    canActivate: [ManageResourcesRouteGuard],
    resolve: {
      componentData: ManageResourcesRoutingResolver
    }
  },
    // report-redeploy
    {
      path: 'report-redeploy/:id',
      component: ReportRedeployComponent,
      canActivate: [ManageResourcesRouteGuard],
      resolve: {
        componentData: ManageResourcesRoutingResolver
      }
    },
  // resources
  {
    path: 'resources',
    canActivateChild: [ManageResourcesRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ResourceListingComponent,
      },
      {
        path: 'deployed/:id',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: DeployedRespurceDetailComponent
      },
      {
        path: 'staging/:id',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: StagingResourceDetailComponent
      },
      {
        path: 'viewsql/:id',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ViewSqlComponent
      }
    ]
  },

  {
    path: 'product-pages',
    canActivateChild: [ProductPagesGuard],
    children: [
      { path: '', redirectTo: 'all', pathMatch: 'full' },
      {
        path: 'all',
        component: ProductPagesListingComponent,
        resolve: { componentData: ProductPagesRoutingResolver },
        data: { section: 'MANAGE', baseUrl:'/app/manage/product-pages', page:'list' }
      },
      {
        path: 'add',
        component: ManageProductPagesDetailComponent,
        resolve: { componentData: ProductPagesRoutingResolver, resourceTeamList: ResourceTeamListResolver },
        data: { page:'add' }

      },
      {
        path: ':id',
        component: ManageProductPagesDetailComponent,
        resolve: { componentData: ProductPagesRoutingResolver, resourceTeamList: ResourceTeamListResolver },
        data: { page:'edit' }
      }
    ]
  },

    //view sql
    {
      path: 'resources',
      children: [
        {
          path: 'viewsql/:id',
          resolve: {
            componentData: ManageResourcesRoutingResolver
          },
          component: ViewSqlComponent
        }
      ]
    },
  // subscriptions
  {
    path: 'subscriptions',
    canActivate: [ManageSubscriptionsRouteGuard],
    canActivateChild: [ManageSubscriptionsRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManageSubscriptionsRoutingResolver
        },
        component: SubscriptionSetupListComponent
      },
      {
        path: 'add',
        resolve: {
          componentData: ManageSubscriptionsRoutingResolver
        },
        component: SubscriptionSetupComponent
      },
      {
        path: ':id',
        resolve: {
          componentData: ManageSubscriptionsRoutingResolver
        },
        component: SubscriptionDetailComponent
      },
      {
        path: 'update/:id',
        component: UpdateRedirectComponent
      }
    ]
  },
  // extracts
  {
    path: 'extracts',
    canActivate: [ManageExtractsGuard],
    canActivateChild: [ManageExtractsGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractMainComponent,
      },
      {
        path: 'extract-setup-list',
        children: [
          {
            path: '',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractSetupListComponent,
          },
          {
            path: 'add',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractSetupComponent
          },
          {
            path: ':extract_setup_key',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractSetupComponent
          }
        ]
      },
      {
        path: 'extract-config-list',
        children: [
          {
            path: '',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractConfigListComponent,
          },
          {
            path: 'add',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractConfigComponent
          },
          {
            path: ':extract_configuration_key',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractConfigComponent
          }
        ]
      },
      {
        path: 'schedule',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractScheduleComponent
      },
      {
        path: 'adhoc',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractAdhocComponent
      },
      {
        path: 'config-list',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractConfigListComponent
      },
      {
        path: 'runadhoc',
        children: [
          {
            path: '',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractRunAdhocComponent,
          }
        ]
      },
      {
        path: 'approvals',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractApprovalsComponent
      },
      {
        path: 'execution-log',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractExecutionLogComponent
      },
      {
        path: 'notification-log',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractNotificationLogComponent
      }

    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageRoutingModule { }



