import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';
import { LoaderService } from '@edw-services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewInit, OnDestroy {
  private loaderObservableSubscription: Subscription;

  constructor(private loaderService: LoaderService,
              private elementRef: ElementRef) { }

  ngAfterViewInit() {
    const loaderWrapper = this.elementRef.nativeElement.querySelector('.wrapper');

    this.loaderObservableSubscription = this.loaderService.getObservable()
      .subscribe((loader) => {
        if (loader.show) {
          loaderWrapper.classList.remove('display-none');
          loaderWrapper.classList.add('fade-in');
        } else {
          setTimeout(() => {
            loaderWrapper.classList.add('display-none');
          }, 300);

          loaderWrapper.classList.remove('fade-in');
        }
      });
  }

  ngOnDestroy() {
    this.loaderObservableSubscription.unsubscribe();
  }

}
