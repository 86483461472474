import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CollectionData, CollectionStore } from '../entities/CollectionDataClasses';
import { CollectionDataService, CollectionService } from '../services/resource.service';

@Component({
  selector:'collection-tab',
  template:`
  <div class="edw-collection-tabs" #collectionTabs>
    <ng-container *ngFor='let collection of collectionStore.Collections'>
      <span class="collection-tab"
        [attr.id]='collection.id'
        [ngClass]="(isTabActive(collection.id)) ? 'active' : ''"
        (click)="SetActiveCollection($event, collection.id );">
        {{collection.name}}
      </span>
    </ng-container>
  </div>
  `,
  styles:[`
    .edw-collection-tabs{ padding-top:1rem; }
    .collection-tab{
      margin-right:1rem;
      padding:.5rem .25rem .1rem 0 ;
      cursor:pointer;
      display:inline-block;
    }
    .collection-tab.active{
      border-bottom:7px solid #514689;
      font-weight: bold;
    }
  `]
})
export class CollectionTabComponent implements OnInit{
  public collectionStore: CollectionStore;
  public collectionData: CollectionData;

  @ViewChild('collectionTabs', {static: true, read: ElementRef}) collectionTabs: ElementRef;

  constructor( private collectionService:CollectionService, private collectionDataService:CollectionDataService ){
    this.collectionService.CollectionStore$.subscribe(store => {this.collectionStore = store; } )
    this.collectionDataService.CollectionData.subscribe(collection => { this.collectionData = collection;});
  }

  ngOnInit(){}

  public SetActiveCollection( event, id ){


    let collectionsToFilter = [id.toString()];

    if(event.ctrlKey){

      let active = Array.from(this.collectionTabs.nativeElement.getElementsByClassName('active')).map(collection => collection['id']);

      if(active.includes(id.toString())){
        if(active.length > 1 ) {
          collectionsToFilter = active.filter( cid => cid != id.toString() )
        }// else the id is already active... so don't change anything.
      }
      else{
        collectionsToFilter = collectionsToFilter.concat(active);
      }

    }

    this.collectionDataService.setCollectionData_withFilter(collectionsToFilter);


  }

  isTabActive(id){
    return this.collectionData.Collection.filter(collection => collection.id === id).length > 0;
  }

}
