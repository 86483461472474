import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListToggleFilterService } from '@edw-app-root/services/list-filter.service';
import { ExNotificationLog } from '@edw-entities/ex-notification-log';
import { ExtractDataService } from '@edw-services/extract.service';
import { SelectItem } from 'primeng';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: 'edw-extract-notification-log',
  templateUrl: './extract-notification-log.component.html',
  styleUrls: ['./extract-notification-log.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ExtractNotificationLogComponent implements OnInit,OnDestroy {
  notificationLog: ExNotificationLog[] = [];
  query: string;
  adhoc: SelectItem[] = [
    { label: '---', value: null },
    { label: 'No', value: false },
    { label: 'Yes', value: true }
  ];

  subs:Subscription[] = [];

  constructor(
    private extractDataService: ExtractDataService,
    private route: ActivatedRoute,
    private listFilter : ListToggleFilterService
    ) { }

  ngOnInit() {
    this.subs.push(
      this.route.data.pipe(
        map((data: { componentData: any }) => {
          this.notificationLog = data.componentData.notificationlog.map(element => {
            return new ExNotificationLog(element);
          });
          this.listFilter.initService(this.notificationLog,[{field:'is_adhoc',filterVal:null}]);
        })
      ).subscribe()
    )
  }

  ngOnDestroy(): void {
      this.subs.map(s => s.unsubscribe());
  }
  refreshLog() {
    this.query = '';
    this.search();
  }

  clearSearch() {
    this.query = '';
    this.search();
  }

  search() {
    this.subs.push(

      this.extractDataService.getNotificationLog(this.query).pipe(
        map( response => {
          if (response['data'].length > 0) {
            let list = response['data'].map(element => {
              return new ExNotificationLog(element);
            });
            this.notificationLog = this.listFilter.filterListByCurrentState(list);
          }
        }),
        catchError(err => {throw err;})
        //TODO: error handing here
      ).subscribe()
    );
  }
  filterBy = (event :{value: boolean}, field = "is_adhoc") => {
    this.notificationLog = this.listFilter.filterBy(field, event.value);
  }
}
