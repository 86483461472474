import { Account } from './account';
import { MergeAccountDTO } from './merge-account';

export class UserDetails {

  id = 0;
  firstName = '';
  lastName = '';
  fullName = '';
  email = '';
  isActive;
  accounts: Account[] = [];
  accountsDisplay = '';
  resourceGroups = [];
  permissionGroups = [];
  resources = [];
  userGroups = [];
  createDate = '';
  modifyDate = '';
  NmSafetySelection = 3;
  NmSafetyUpdatedDate = '';
  mergedAccount: MergeAccountDTO = null;

  private accountDomains = ['northwestern.edu', 'nm.org', 'JiraUsername'];

  constructor(userDetails?) {
    if (userDetails) {
      this.id = userDetails.id;
      this.firstName = userDetails.firstName;
      this.lastName = userDetails.lastName;
      this.fullName = userDetails.firstName + ' ' + userDetails.lastName;
      this.email = userDetails.email;
      this.isActive = userDetails.isActive;
      this.createDate = userDetails.createDate;
      this.modifyDate = userDetails.modifyDate;
      this.NmSafetySelection = userDetails.nmSafetySelection;
      this.NmSafetyUpdatedDate = userDetails.nmSafetyUpdatedDate;
      this.mergedAccount = userDetails.mergedAccount;
      this.accounts = userDetails.accounts
        .map(element => {
          return new Account(element);
        });

      this.accountDomains.forEach((domainName) => {
        const domainNameIsAlreadyAdded = this.accounts.some((existingUserAccount) => {
          return domainName === existingUserAccount.activeDirectory;
        });

        if (!domainNameIsAlreadyAdded) {
          this.accounts.push(new Account({
            activeDirectory: domainName,
            accountName: ''
          }));
        }
      });

      this.permissionGroups = userDetails.permissionGroups;
      this.resourceGroups = userDetails.resourceGroups;
      this.resources = userDetails.resources;
      this.userGroups = userDetails.userGroups;

      if (userDetails.accounts && userDetails.accounts.length > 0) {
        this.accountsDisplay = userDetails.accounts
          .map(function (acc) {
            return acc.activeDirectory + "\\" + acc.accountName;
          })
          .join(', ');
      }
    } else {
      this.accounts = this.accountDomains.map((domainName) => {
        return new Account({
          activeDirectory: domainName,
          accountName: ''
        });
      });
    }
  }

}
