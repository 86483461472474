import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { ResourceType } from '@edw-enums/resource-type';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { StagingReportsService } from '@edw-services/staging-reports.service';

class ApprovalRequestObject {
  resourceId: number;
  resourceName: string;
  resourceType: string;
  description: string;
  requestedBy: string;
  disableApproving: boolean = false;
  redeployResource?: any
  developerComment: string;
  optOut: boolean;

  constructor(requestObj) {
    this.optOut = requestObj.ratingOptout == 'true';
    this.resourceId = requestObj.id;
    this.resourceName = requestObj.resourceName;
    this.resourceType = requestObj.resourceType;
    this.description = requestObj.description || 'N/A';
    this.requestedBy = requestObj.requestedByEmail;
    this.redeployResource = requestObj.redeployResource;
    this.developerComment = requestObj.developerComment;
  }
}

@Component({
  selector: 'edw-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestsComponent implements OnInit {
  requests = [];

  public form: FormGroup;
  public formSubmitted = false;
  deleteDialogVisible = false;
  developerCommentVisible = false;
  selectedResourceToDeny: number;
  resourceTypeText = ResourceType;
  currentComment = null;
  @ViewChild('dataTable',{static:true}) dataTable;

  constructor(
    private route: ActivatedRoute,
    private stagingReportsService: StagingReportsService,
    private fb: FormBuilder,
    private errorService: ErrorHandlerService,
    private toastMessageService : ToastMessageService
    ) {

    this.form = this.fb.group({
      reasonForDenial: ['', [Validators.required]]
    });


  }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.requests = data.componentData.requests.map((requestObj) => {
          return new ApprovalRequestObject(requestObj);
        });
        console.log('request', this.requests);
      });
  }

  showConfirmationDialog(resource) {
    this.selectedResourceToDeny = resource.resourceId;
    this.deleteDialogVisible = true;
  }

  hideConfirmationDialog() {
    this.selectedResourceToDeny = null;
    this.deleteDialogVisible = false;
    this.form.get('reasonForDenial').setValue('');
  }

  showDeveloperComment(comment) {
    this.currentComment = comment;
    this.developerCommentVisible = true;
  }

  hideDeveloperComment() {
    this.developerCommentVisible = false;
  }

  denySelectedResource() {
    this.stagingReportsService.denyResource(this.selectedResourceToDeny.toString(), this.form.get('reasonForDenial').value)
      .subscribe(
        (response) => {
          if (response['data'] === true) {
            this.requests = this.requests.filter((request) => {
              return request.resourceId !== this.selectedResourceToDeny
            });
            this.hideConfirmationDialog();
            this.toastMessageService.addMessageObj({severity: 'success', detail: 'Successfully Denied Report'})

          }
        },
        (httpErrorResponse) => {
          this.errorService.httpErrorHandler(httpErrorResponse);
          this.hideConfirmationDialog();
        });
  }

  lazyLoadMore($event) {
    let lastPage = this.dataTable.totalRecords / this.dataTable.rows;
    let firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;

    if ($event.first === firstIndexOnLastPage) {
      //BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      let nextPage = Math.ceil(lastPage / 10) + 1;

      this.stagingReportsService.getPendingRequests('', nextPage)
        .subscribe(response => {
          if (response['data'] && response['data'].length > 0) {
            let newPendingRequests = response['data'].map(element => {
              return new ApprovalRequestObject(element);
            });
            console.log('newPendingRequests: ', newPendingRequests);
            this.requests = this.requests.concat(newPendingRequests);
          }
        });
    }
  }

  approve(resource) {
    resource.disableApproving = true;
    this.stagingReportsService.approveDeploymentRequest(resource.resourceId)
      .subscribe(
        (response) => {
          if (response['data'] === true) {
            this.requests = this.requests.filter((request) => {
              return request.resourceId !== resource.resourceId;
            });
            resource.disableApproving = false;

            this.toastMessageService.addMessageObj({severity: 'success', detail: `Successfully approved ${resource.resourceName}.`});
          }
        },
        (httpErrorResponse) => {
          this.errorService.httpErrorHandler(httpErrorResponse);
          resource.disableApproving = false;
        }
      );
  }

  preview(resource) {
    window.open('#/app/resources/reports/preview/' + resource.resourceId);
  }

  viewSQL(resource) {
    window.open('#/app/manage/resources/viewsql/' + resource.resourceId + '?isStaging=true');
  }

}
