import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExtractSchedule } from '@edw-entities/extract-schedule';
import { ExtractDataService } from '@edw-services/extract.service';
import { SelectItem } from 'primeng';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'edw-adhoc-listings',
    templateUrl: './extract-adhoc.component.html',
    styleUrls: ['./extract-adhoc.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class ExtractAdhocComponent implements OnInit {
  extracts: ExtractSchedule[] = [];
  query: string;
  completed: SelectItem[] = [
    { label: '---', value: null },
    { label: 'No', value: false },
    { label: 'Yes', value: true }
  ];


  nodes: TreeNode[];

  constructor(private extractDataService: ExtractDataService,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data
    .subscribe((data: { componentData: any }) => {
      this.extracts  = data.componentData.extracts.map(element => {
        return new ExtractSchedule(element);
      });
    });
  }

  refreshSchedule(): void {
    this.query='';
    this.search();
  }

  clearSearch(autoCompleteRef) {
    this.query = '';
    //the next two lines are here because the autocomplete
    //component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  search() {
    let subscription: Subscription = this.extractDataService.getAdhocExtract(this.query)
      .subscribe(response => {
        this.extracts = [];
        if (response['data'].length > 0) {
          this.extracts = response['data'].map(element => {
            return new ExtractSchedule(element);
          });
        }
        //Unsubscribe here
        subscription.unsubscribe();
      });
    //TODO: error handing here
  }

    }
