import { IUser } from "@edw-entities/user";

export const adHocStatuses = {
  Created: 1,
  Generating: 2,
  Ready: 3,
  Expired: 4,
  Error: 5,
};

// export interface IUser {
//   id: number;
//   firstName: string;
//   lastName: string;
//   fullName: string;
//   email: string;
//   accounts: Account[];
//   accountsDisplay: string;
// }
// user extract parameter interface
export interface IUserExtractParameter {
  id: number;
  parameterName: string;
  parameterType: string;
  parameterPrompt: string;
  values?: any;
  userEditable?: any;
  isDropdown?: boolean;
  isRequired: boolean;
  hasDefaultValue?: boolean;
  hasDefaultQueryValue?: boolean;
  allowMultipleValues?: boolean;
  availableValues: any;
  defaultValues: any;
}

export interface IUserExtractParameterSubmit {
  id: number;
  values?: any;
}

// get user extract response and form interface
export interface IUserExtract {
  id?: number;
  name: string;
  description: string;
  businessOwner: string;
  businessOwnerKey: number;
  resourceOwner: string;
  resourceOwnerKey: number;
  reportId: number;
  reportName: string;
  parameters: IUserExtractParameter[];
  repeats: number;
  recursDay: number;
  recursTime: Date | string;
  expires: Date | string;
  adHoc: boolean;
  shouldEmailUsers: boolean | undefined;
  users?: IUser[];
  createDate?:string;
  modifyDate?:string;
  createdBy?:string;
  updatedBy?:string;
}

// get parameters per report id
export interface IUserExtractReport {
  reportId: number;
  name: string;
  parameters: IUserExtractParameter[];
}

// get ssrs reports reponse should be body = ISSRSReport[]
export interface ISSRSReport {
  id: number;
  name: string;
}

export interface IExtractForUser {
  id: number;
  adHocStatus: number;
  allowsAdHoc: boolean;
  canDownload: boolean;
  canDownloadAdHoc: boolean;
  createdBy: IUser;
  createdOn: string;
  downloadId: number;
  adHocDownloadId: number;
  expiresOn: string;
  adHocExpiresOn: string;
  isExpired: boolean;
  lastExtraction: string;
  lastExtractionParameters: ILastExtractionParameters[];
  name: string;
  nextExtraction: string;
  isFavorite: boolean;
  disableFavoriting: boolean;
}

export interface ILastExtractionParameters {
  name: string;
  type: string;
  values: any;
}
