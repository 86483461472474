import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { RelatedEntityService } from '@edw-entities/related-entity-service.interface';
import { SearchParams } from '@edw-entities/searchParams';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Component({
  selector: 'edw-related-entity',
  templateUrl: './related-entity.component.html',
  styleUrls: ['./related-entity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RelatedEntityComponent implements OnInit, OnChanges, OnDestroy {
  // one-way and plain string bindings

  /**
   * @param entityCollection - the latest added entities
   * shown to the user
  **/
  @Input() entityCollection = [];

  @Output() entityCollectionChange = new EventEmitter();

  /**
   * @param entityName - the name of the entity (in plural form)
  **/
  @Input() entityName: string;

  /**
   * @param permissions - object containing information
   * whether the user can add and/or remove the specified entity.
   * Should be in the following form:
   * {
   *  canAddEntity: <boolean>
   *  canRemoveEntity: <boolean>
   * }
  **/
  @Input() permissions;

  @Input() link: string;

  @Input() service: RelatedEntityService;

  @Input() relatedEntityId: number;

  @Input() isReadOnly : boolean = false;

  @Input() totalCount : number = 0;

  selectedEntities = [];
  query: string;
  dialogVisible = false;
  entitySuggestions;

  noEntitiesPresentMsg: Message[] = [];

  columns: {
    displayColumnName: string,
    columnName: string
  }[] = [];

  currentQuery = '';

  hideSearchBar = true;
  isExpanded = false;

  @ViewChild('searchForAdded') searchForAddedField;
  @ViewChild('entityTable') entityTable;
  @ViewChild('currentTab') currentTab;

  private sessionStorageName: string;

  constructor(
    private sessionStorageService: SessionStorageService,
    private loader: LoaderService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {

    // this.noEntitiesPresentMsg.push({
    //   severity: 'info',
    //   summary: '',
    //   detail: 'No assigned ' + this.entityName + '.'
    // });

    this.hideSearchBar= this.entityCollection?.length == 0;
    this.totalCount = this.entityCollection.length;
  }

  ngOnDestroy() {
    this.sessionStorageService.remove(this.sessionStorageName);
  }

  ngOnChanges(changesObj) {
    if (changesObj.entityName && changesObj.entityName.firstChange) {
      this.noEntitiesPresentMsg = [{
        severity: 'info',
        summary: '',
        detail: 'No assigned ' + changesObj.entityName.currentValue + '.'
      }];
    }

    if (changesObj.entityCollection.currentValue && changesObj.entityCollection.firstChange) {
        if (changesObj.entityCollection.currentValue.length > 0) {
          this.populateColumns();
        }
    }

    if (changesObj.entityName && changesObj.entityName.firstChange) {
      this.sessionStorageName = 'cached_' + this.entityName.split(' ').join('_');

      if (this.entityCollection) {
        this.sessionStorageService.add(this.sessionStorageName, this.entityCollection);
      }
    }
  }

  handlePageChange($event) {
    const lastPage = this.entityTable.totalRecords / this.entityTable.rows;
    const firstIndexOnLastPage = this.entityTable.totalRecords - this.entityTable.rows;

    if ($event.first === firstIndexOnLastPage) {
      // BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      const nextPage = Math.ceil(lastPage / 10) + 1;
      this.searchForAddedEntity({ query: this.currentQuery }, nextPage);
    }
  }

  searchForNonAddedEntity($event) {
    if ($event.query === '') {
      return;
    }

    const id = this.relatedEntityId.toString();
    const subscription: Subscription = this.service
      .getNonAddedEntity(id, $event.query)
      .subscribe(results => {
        this.entitySuggestions = results['data'];
        subscription.unsubscribe();
      });
  }

  searchForAddedEntity($event, page: number = 1) {
    const id = this.relatedEntityId.toString();
    const searchParams: SearchParams = new SearchParams(page, $event.query);

    if (searchParams.query === '' && searchParams.page === 1) {
      this.entityCollection = this.sessionStorageService.get(this.sessionStorageName);
    } else {
      const subscription: Subscription = this.service
        .getAddedEntity(id, searchParams)
        .subscribe(response => {
          if (response['data']) {
            if (searchParams.page === 1) {
              this.updateEntityCollection(response['data']);
            } else {
              this.entityCollection = this.entityCollection.concat(response['data']);
            }

            if (response['data'].length === 0) {
              this.noEntitiesPresentMsg = [{
                severity: 'info',
                summary: '',
                detail: 'No ' + this.entityName + ' found.'
              }];
            }
          }

          subscription.unsubscribe();
        });
    }
  }

  removeEntityFromGroup(entity) {

    entity.disableDeleting = true;

    const subscription: Subscription = this.service.removeEntityFromGroup(this.relatedEntityId.toString(), entity.id.toString())
      .pipe(
        map( response => {
          if (response['data'] != true) { throw Error("Unable to complete this operation.");}

          let cachedData = this.sessionStorageService.get(this.sessionStorageName);

          // remove from the list that is displayed to the user
          this.entityCollection = this.entityCollection
            .filter((collectionEntity) => {
              return entity.id !== collectionEntity.id;
            });

          // remove from the cached info in sessionStorage
          cachedData = cachedData
            .filter((cachedEntity) => {
              return entity.id !== cachedEntity.id;
            });

          // persist the new changes to the object in sessionStorage
          this.sessionStorageService.add(this.sessionStorageName, cachedData);

          this.entityCollectionChange.emit(this.entityCollection);

          return cachedData;
        }),
        map( ( cachedData : any[] ) => {
            // if all the users have been deleted, don't show the search bar
            if (cachedData.length === 0) {
              this.entityCollection = null;
              this.hideSearchBar = true;

              this.noEntitiesPresentMsg = [{ severity: 'info', summary: '', detail: `No assigned ${this.entityName}.`}];
            }

            this.toastMessageService.addMessageObj({ severity: 'success',detail: `${entity.displayName} has been successfully deleted`});

          }
        ),
        catchError( (err)=>{ throw err; } )
        ).subscribe(
          { complete(){ subscription.unsubscribe(); } }
        );
  }

  persistEntityList(entityList) {

    this.closeAddEntitiesDialog();

    const entityListIds: number[] = entityList.map((entity) => {
      return parseInt(entity.id, 10);
    });

    this.loader.showLoader();

    this.currentTab.accordion.tabs.forEach(tab => {
      if (!Object.is(this.currentTab, tab)) {
        tab.selected = false;
      }
    });

    const subscription: Subscription = this.service.persistEntityList(this.relatedEntityId.toString(), entityListIds).pipe(
        map(
          response => {
            //console.log("found ya", response);
            if (response['data'] === true) {

              if (this.entityCollection === null) {
                this.entityCollection = [];
              }

              entityList = entityList.map((entity) => {
                entity['link'] = [this.link, entity.id];
                return entity;
              });

              this.entityCollection = entityList.concat(this.entityCollection);

              if (this.columns.length === 0) {
                this.populateColumns();
              }

              this.sessionStorageService.add(this.sessionStorageName, this.entityCollection);

              this.entityCollectionChange.emit(this.entityCollection);

              let entityName = (this.entityName[this.entityName.length - 1] === 's')
                ? this.entityName.slice(0, -1)
                : this.entityName;

              this.toastMessageService.addMessageObj({severity: 'success',detail: 'Successfully added ' + entityList.length + ' ' + entityName + '(s).'});

            }
          }
        ),
        catchError((err) => {
          this.toastMessageService.addMessageObj({severity:'error', detail: "Process was unsuccessful"});
          throw err;
        }),
        finalize(()=>{
          this.isExpanded = true;
          this.loader.hideLoader();
          subscription.unsubscribe();
        })
      ).subscribe()
  }

  clearSearch() {
    this.query = '';
    // the next two lines are here because the autocomplete
    // component doesn't offer a native way to clear the value :(

    console.log(this.searchForAddedField);
    this.searchForAddedField.inputEL.nativeElement.value = '';
    this.searchForAddedField.inputEL.nativeElement.focus();

    this.entityCollection = this.sessionStorageService.get(this.sessionStorageName);
  }

  showAddEntitiesDialog() {

    this.query = null;

    if (this.searchForAddedField) {
      this.searchForAddedField.inputEL.nativeElement.value = '';
    }

    this.searchForAddedEntity({query: ''});

    if (this.entityTable) {
      this.entityTable.reset();
    }

    this.dialogVisible = true;

  }

  closeAddEntitiesDialog() {
    this.dialogVisible = false;
    this.selectedEntities = [];
  }

  private updateEntityCollection(data) {
    this.entityCollection = data
      .map((entity) => {
        entity['link'] = [this.link, entity.id];
        return entity;
      });
  }

  private populateColumns() {

    const keysToExclude = ['id', 'link', 'displayName', 'constructor'];

    const displayName = (name) => name.replace(/([A-Z])/g, ' $1') // insert a space before all caps
          .replace(/^./, (str) => str.toUpperCase() );

    this.columns = Object.keys(this.entityCollection[0])
      .map( key => (
        keysToExclude.includes(key)
          ? null
          : { displayColumnName: displayName(key), columnName: key }
      )).filter( val => val ); // remove nulls

    //console.log(this.columns);
  }

}
