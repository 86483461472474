import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { UserDataService } from '@edw-services//user-data.service';

@Injectable()
export class AppResolver implements Resolve<any> {

  constructor(private userDataService: UserDataService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    return this.userDataService.getUserData();
  }
}
