export class DeploymentResource {
  resourceId: number;
  resourceName: string;
  resourceStatus: string;
  resourceType: string;
  path: string;
  associatedIRB: string;
  description: string;
  publishingDepartment: string;
  approvers: {id: number, displayName: string}[] = [];
  author: {
    id: number;
    name: string;
  };

  users = [];
  resourceGroups = [];
  userGroups = [];
  metadata = [];

  constructor(resourceData) {
    this.resourceId = resourceData.resourceDeploy.id;
    this.resourceName = resourceData.resourceDeploy.resourceName;
    this.resourceStatus = resourceData.resourceDeploy.resourceStatus;
    this.resourceType = resourceData.resourceDeploy.resourceType;
    this.path = resourceData.resourceDeploy.path;
    this.associatedIRB = resourceData.resourceDeploy.associatedIRB;
    this.description = resourceData.resourceDeploy.description;
    this.publishingDepartment = resourceData.resourceDeploy.publishingDepartment;
    this.author = {
      id: resourceData.resourceDeploy.authorId,
      name: resourceData.resourceDeploy.authorName
    };

    this.approvers = resourceData.approvers.map((approver) => {
      return {
        id: approver.id,
        displayName: approver.firstName + ' ' + approver.lastName + ' (' + approver.email + ')'
      };
    });

    this.resourceGroups = resourceData.resourceDeploy.resourceGroups;
    this.userGroups = resourceData.resourceDeploy.userGroups;
    this.users = resourceData.resourceDeploy.users;
    this.metadata = resourceData.resourceDeploy.metadata;
  }
}
