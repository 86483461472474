import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { KeyValuePair } from '@edw-entities/key-value-pair';
import { RelatedEntityService } from '@edw-entities/related-entity-service.interface';
import { UserGroup } from '@edw-entities/user-group';
import { UserGroupDataService } from '@edw-services//user-group.service';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'edw-user-group-detail',
  templateUrl: './user-group-detail.component.html',
  styleUrls: ['./user-group-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserGroupDetailComponent implements OnInit, OnDestroy {
  title: string;

  isInAddNewMode = false;

  deleteDialogVisible = false;
  addUsersDialogVisible = false;

  userGroupDetails = {
    id: 0,
    name: '',
    description: '',
    assignedUsers: null,
    resources: null,
    resourceGroups: null,
    isSyncedWithAD: false,
    totalUsers: 0
  };

  noUsersResult: Message[] = [{
    severity: 'info',
    summary: '',
    detail: 'No assigned users.'
  }];

  synchronizedWithADMsg: Message[];

  isSyncedWithADChk = false;
  synchronizationPattern;
  permissions;
  query: string;
  userSuggestions;
  resourceGroupsSuggestions;
  resourceSuggestions;
  users = [];

  formSubmitted = false;

  relatedEntitiesPermissions = {
    users: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    resourceGroups: {
      canAddEntity: null,
      canRemoveEntity: null
    },
    resources: {
      canAddEntity: null,
      canRemoveEntity: null
    }
  };

  // this will be the collection which will be passed to the multiple-choice-component
  selectedUsers: KeyValuePair[] = [];

  userHandler: RelatedEntityService;
  resourceHandler: RelatedEntityService;
  resourceGroupHandler: RelatedEntityService;

  @ViewChild('accordion', {static: true}) accordion;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userGroupsService: UserGroupDataService,
    private sessionStorageService: SessionStorageService,
    private changeDetector: ChangeDetectorRef,
    private loader: LoaderService,
    private errorService: ErrorHandlerService,
    private toastMessageService: ToastMessageService
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;

        this.relatedEntitiesPermissions.users.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.users.canRemoveEntity = this.permissions.canModifyContent;

        this.relatedEntitiesPermissions.resourceGroups.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.resourceGroups.canRemoveEntity = this.permissions.canModifyContent;

        this.relatedEntitiesPermissions.resources.canAddEntity = this.permissions.canModifyContent;
        this.relatedEntitiesPermissions.resources.canRemoveEntity = this.permissions.canModifyContent;

        if (data.componentData.hasOwnProperty('userGroupDetails')) {
          this.initViewModel(data.componentData['userGroupDetails']);
        } else {
          this.isInAddNewMode = true;
        }

        this.title = data.componentData.title;
      });

    this.userHandler = {
      getAddedEntity: this.userGroupsService.findAddedUser,
      getNonAddedEntity: this.userGroupsService.findNonAddedUser,
      persistEntityList: this.userGroupsService.addUsersToGroup,
      removeEntityFromGroup: this.userGroupsService.removeUserFromGroup
    };

    this.resourceGroupHandler = {
      getAddedEntity: this.userGroupsService.findAddedResourceGroup,
      getNonAddedEntity: this.userGroupsService.findNonAddedResourceGroup,
      persistEntityList: this.userGroupsService.associateUserGroupWithResourceGroups,
      removeEntityFromGroup: this.userGroupsService.removeUserGroupResourceGroupRelationship
    };

    this.resourceHandler = {
      getAddedEntity: this.userGroupsService.findAddedResource,
      getNonAddedEntity: this.userGroupsService.findNonAddedResource,
      persistEntityList: this.userGroupsService.associateResourcesWithUserGroups,
      removeEntityFromGroup: this.userGroupsService.removeResourceUserGroupsRelationship
    };
  }

  ngOnDestroy() {
    this.sessionStorageService.remove('currentUserGroup');
  }

  saveUserGroup(form) {
    this.formSubmitted = true;

    if (form.valid) {
      if (this.isInAddNewMode === true) {
        this.addNewUserGroup();
      } else {
        this.editUserGroup();
      }
    }
  }


  private editUserGroup() {
    const userGroup = Object.assign({}, this.userGroupDetails);
    userGroup.isSyncedWithAD = this.isSyncedWithADChk;

    const userGroupFields = new UserGroup(userGroup);
    this.loader.showLoader();

    const subscription: Subscription = this.userGroupsService
      .updateUserGroupFields(userGroupFields)
      .pipe(
        finalize(() => {
          this.loader.hideLoader();
          subscription.unsubscribe();
        }))
      .subscribe(response => {
        if (response['data']) {
          this.initViewModel(response['data']);

          this.toastMessageService.addMessageObj({
            severity: 'success',
            summary: '',
            detail: 'User group has been updated successfully.'
          });
        }
      }, httpErrorResponse => {
        this.errorService.httpErrorHandler(httpErrorResponse);
      });
  }

  private addNewUserGroup() {
    this.userGroupDetails.isSyncedWithAD = this.isSyncedWithADChk;
    this.loader.showLoader();

    const subscription: Subscription = this.userGroupsService
      .addUserGroup(this.userGroupDetails)
      .pipe(
        finalize(() => {
          subscription.unsubscribe();
        }))
      .subscribe(response => {
        if (response['data'] && parseInt(response['data'], 10)) {
          this.router.navigate(['/app/manage/user-groups', response['data']]);
        }
      }, httpErrorResponse => {
        this.errorService.httpErrorHandler(httpErrorResponse);
        this.loader.hideLoader();
      });
  }

  private initViewModel(model) {
    this.userGroupDetails = model;
    this.isSyncedWithADChk = model.isSyncedWithAD;

    console.log(model);

    this.toggleSynchronizationWithAD(this.isSyncedWithADChk);

    if (this.isSyncedWithADChk === true) {
      this.synchronizedWithADMsg = [{
        severity: 'info',
        detail: `Users are synchronized with AD Group: ${model.name}`
      }];
    }

    if (this.userGroupDetails.assignedUsers.length === 0) {
      this.userGroupDetails.assignedUsers = [];
    } else {
      this.updateUsersCollection(model.assignedUsers);
    }

    if (this.userGroupDetails.resources.length === 0) {
      this.userGroupDetails.resources = [];
    } else {
      this.updateResourcesCollection(model.resources);
    }

    if (this.userGroupDetails.resourceGroups.length === 0) {
      this.userGroupDetails.resourceGroups = [];
    } else {
      this.updateResourceGroupsCollection(model.resourceGroups);
    }

    this.sessionStorageService.add('currentUserGroup', this.userGroupDetails);
  }

  showConfirmationDialog() {
    this.deleteDialogVisible = true;
  }

  hideConfirmationDialog() {
    this.deleteDialogVisible = false;
  }

  deleteUserGroup() {
    this.deleteDialogVisible = false;
    this.loader.showLoader();

    const subscription: Subscription = this.userGroupsService
      .removeUserGroup(this.userGroupDetails.id.toString())
      .pipe(
        finalize(() => {
          subscription.unsubscribe();
        }))
      .subscribe((response) => {
        if (response['data'] === true) {
          this.sessionStorageService.add('message', this.userGroupDetails.name + ' user group has been deleted successfully.');
          this.router.navigate(['/app/manage/user-groups']);
        }
      },
        (httpErrorResponse) => {
          this.errorService.httpErrorHandler(httpErrorResponse);
          this.loader.hideLoader();
        });
  }

  private updateUsersCollection(data) {
    this.userGroupDetails.assignedUsers = data
      .map((user) => {
        user['displayName'] = user.firstName + ' ' + user.lastName + ' (' + user.email + ')';
        user['link'] = ['/app/manage/users/', user.id];
        return user;
      });
  }

  private updateResourceGroupsCollection(data) {
    this.userGroupDetails.resourceGroups = data
      .map((resourceGroup) => {
        resourceGroup['displayName'] = resourceGroup.name;
        resourceGroup['link'] = ['/app/manage/resource-groups/', resourceGroup.id];
        return resourceGroup;
      });
  }

  private updateResourcesCollection(data) {
    this.userGroupDetails.resources = data
      .map((resource) => {
        resource['displayName'] = resource.resourceName + ' (' + resource.resourceType + ')';
        resource['link'] = ['/app/manage/resources/deployed/', resource.id];

        return resource;
      });
  }

  toggleSynchronizationWithAD(checked) {
    if (checked === true) {
      this.synchronizationPattern = /^[A-Za-z0-9]{1,}[.]?[A-Za-z0-9]{1,}\\[A-Za-z0-9._-\s]{1,}$/;
    } else {
      this.synchronizationPattern = null;
    }

    this.changeDetector.detectChanges();
  }
}
