import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorageService {
  add(key: string, value: any) {
      if (typeof value === 'undefined') {
        value = null;
      }

      sessionStorage[key] = JSON.stringify(value);

      return value;
  }

  get(key: string) {
    let value = null;

    if (sessionStorage.hasOwnProperty(key) && sessionStorage[key] !== null) {
      value = JSON.parse(sessionStorage[key]);
    }

    return value;
  }

  remove(key: string) {
    if (sessionStorage.hasOwnProperty(key) && sessionStorage[key] !== null) {
      sessionStorage.removeItem(key);
    }
  }
}
