import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ReportsService } from '@edw-app-root/services/reports.service';
import { CollectionService } from '../components/services/resource.service';
import { Observable } from 'rxjs';
import { Report } from '@edw-entities/report';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
const uniqeValues = (value, index, self) => self.indexOf(value) === index;

@Injectable()
export class ReportsRoutingResolver implements Resolve<any> {

  constructor(
    private reportsService: ReportsService,
    private collectionService :CollectionService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.reportsService.getUserResources().pipe( map( ( reports ) => reports['data'] ));
  }
}
