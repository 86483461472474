import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  AutoCompleteModule,
  CheckboxModule,
  DialogModule,
  DropdownModule,
  MessagesModule,
  PaginatorModule,
  SharedModule,
  TableModule,
  ToastModule
} from 'primeng-lts';

import { EDWSharedModule } from '@edw-app-root/shared/shared.module';

import { ExtractAdhocComponent } from './extract-adhoc/extract-adhoc.component';
import { ExtractApprovalsComponent } from './extract-approvals/extract-approvals.component';
import { ExtractConfigListComponent } from './extract-config-list/extract-config-list.component';
import { ExtractConfigComponent } from './extract-config/extract-config.component';
import { ExtractExecutionLogComponent } from './extract-execution-log/extract-execution-log.component';
import { ExtractMainComponent } from './extract-main/extract-main.component';
import { ExtractNotificationLogComponent } from './extract-notification-log/extract-notification-log.component';
import { ExtractRunAdhocComponent } from './extract-run-adhoc/extract-run-adhoc.component';
import { ExtractScheduleComponent } from './extract-schedule/extract-schedule.component';
import { ExtractSetupListComponent } from './extract-setup-list/extract-setup-list.component';
import { ExtractSetupComponent } from './extract-setup/extract-setup.component';

import { ManageExtractsGuard } from './extracts.guard';
import { ManageExtractRoutingResolver } from './extracts.resolver';

export const extractsRoutes = [
  {
    path: 'extracts',
    // canActivate: [ManageExtractsGuard],
    // canActivateChild: [ManageExtractsGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractMainComponent,
      },
      {
        path: 'extract-setup-list',
        children: [
          {
            path: '',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractSetupListComponent,
          },
          {
            path: 'add',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractSetupComponent
          },
          {
            path: ':extract_setup_key',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractSetupComponent
          }
        ]
      },
      {
        path: 'extract-config-list',
        children: [
          {
            path: '',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractConfigListComponent,
          }, {
            path: 'add',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractConfigComponent
          },
          {
            path: ':extract_configuration_key',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractConfigComponent
          }
        ]
      },
      {
        path: 'schedule',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractScheduleComponent
      },
      {
        path: 'adhoc',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractAdhocComponent
      },
      {
        path: 'config-list',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractConfigListComponent
      },
      {
        path: 'runadhoc',
        children: [
          {
            path: '',
            resolve: {
              componentData: ManageExtractRoutingResolver
            },
            component: ExtractRunAdhocComponent,
          }
        ]
      },
      {
        path: 'approvals',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractApprovalsComponent
      },
      {
        path: 'execution-log',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractExecutionLogComponent
      },
      {
        path: 'notification-log',
        resolve: {
          componentData: ManageExtractRoutingResolver
        },
        component: ExtractNotificationLogComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    EDWSharedModule,
    TableModule,
    SharedModule,
    AutoCompleteModule,
    PaginatorModule,
    DialogModule,
    MessagesModule,
    ToastModule,
    DropdownModule,
    CheckboxModule
  ],
  declarations: [
    ExtractSetupComponent,
    ExtractSetupListComponent,
    ExtractScheduleComponent,
    ExtractAdhocComponent,
    ExtractConfigListComponent,
    ExtractConfigComponent,
    ExtractMainComponent,
    ExtractApprovalsComponent,
    ExtractExecutionLogComponent,
    ExtractNotificationLogComponent,
    ExtractRunAdhocComponent
  ],
  providers: [
    ManageExtractRoutingResolver,
    ManageExtractsGuard
  ]
})
export class ExtractsModule { }
