import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '@edw-entities/product';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ProductsService {
  private productSubject: BehaviorSubject<any> = new BehaviorSubject<any>({show: true});
  productObservable = this.productSubject.asObservable();
  private productsShown: boolean = true;

  constructor(private http: HttpClient) { }

  getAllProducts = () : Observable<any> =>
    this.http.get(`${environment.api}/Product/getAll`);

  getAll = (query: string, page: number = 1): Observable<Product[]> =>
    this.http.get<Product[]>(`${environment.api}/Product/GetAll?query=${query}&page=${page}`, { observe: 'body' });

  getProduct = (productid: string): Observable<Product[]> =>
    this.http.get<Product[]>(`${environment.api}/Product/${productid}/getDetails`, {observe: 'body'});

  getUserProducts = ( query = "", page = 1 ) : Observable<Product[]> =>
    this.http.get<Product[]>(`${environment.api}/Product/GetUserProducts?query=${query}&page=${page}`, {observe: 'body'});

  saveProduct = (id: number, data: Partial<Product>): Observable<any> =>
    this.http.post(environment.api + '/Product/update', data, {observe: 'body'});

  createProduct = (data: Partial<Product>) =>
    this.http.post(environment.api + '/Product/add', data, {observe:'body'});

  deleteProduct = (id: number): Observable<any> =>
    this.http.post( `${environment.api}/Product/${id}/remove`, {}, {observe: 'body'});

  addProductResourceGroup = ( productId: number, resourceGroupIds:number[] ) =>
    this.http.post(`${environment.api}/Product/${productId}/resourcegroup/add`, resourceGroupIds, {observe:'body'});

  deleteProductResourceGroup = ( productId: number, resourceGroupId: number ) =>
    this.http.post(`${environment.api}/Product/${productId}/resourcegroup/remove?resourceGroupId=${resourceGroupId}`, {}, {observe:'body'});

    show() {
      if (this.productsShown !== true) {
        this.productsShown = true;

        this.productSubject.next({
          show: true
        });
      }
    }

    hide() {
      if (this.productsShown !== false) {
        this.productsShown = false;

        this.productSubject.next({
          show: false
        });
      }
    }

    update(){
      this.productSubject.next({
        update: true
      });
    }

    getObservable() {
      return this.productObservable;
    }

    // deleteProductResourceGroup = ( productId: number, resourceGroupId: number ) =>this.http.get(`${environment.api}/Product/${productId}/resourcegroup/remove/${resourceGroupId}`);

  // productResourceGroup_remove = ( productId: number, resourceGroupId: number ) =>
  //   this.http.get(`${environment.api}/Product/${productId}/resourcegroup/remove/${resourceGroupId}`, this.options);

}
