import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { IDataStewardRequestDetailsResponse } from '@edw-entities/data-steward-queue';
import { DataStewardQueueService } from '@edw-services/data-steward-queue.service';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { finalize, take } from 'rxjs/operators';


@Component({
  selector: 'edw-data-steward-request',
  templateUrl: './data-steward-request.component.html',
  styleUrls: ['./data-steward-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataStewardRequestComponent implements OnInit {
  public details: IDataStewardRequestDetailsResponse;

  public dialogVisible = false;
  public denyReasonText: FormControl;

  public fileIcons = {
    pdf: 'fa fa-file-pdf-o',
    docx: 'fa fa-file-text',
    xlsx: 'fa fa-table',
    jpg : 'fa fa-file-image-o',
    png: 'fa fa-file-image-o'
  };

  @ViewChild('approveBtn', {read: ElementRef, static: true}) approveBtn: ElementRef;
  @ViewChild('denyBtn', {read: ElementRef, static : true}) denyBtn: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dsqservice: DataStewardQueueService,
    private toastMessageService : ToastMessageService,
    private loader: LoaderService,
    private errorService: ErrorHandlerService,
  ) {
    this.denyReasonText = new FormControl('', Validators.required);

  }

  ngOnInit() {
    this.route.data.pipe(
      take(1)
    ).subscribe((data: {dataStewardRequest: IDataStewardRequestDetailsResponse}) => {
      console.log(data);
      this.details = data.dataStewardRequest;
      this.details.attachments = data.dataStewardRequest.attachments.map((file) => {
        const parts = file.name.split('.');
        file.ext = parts[parts.length - 1].toLowerCase();
        return file;
      });
      // console.log(this.details);
      if (this.details.approved !== null) {
        this._disableBtns();
      }
    });
  }

  public approve() {
    this._disableBtns();
    this.dsqservice.approveDataStewardRequest(this.details.id).pipe(
      take(1),
      finalize(() => {
        this.loader.hideLoader();
        this.dialogVisible = false;
      })
    )
    .subscribe((response: any) => {
      console.log(response);
      if (response) {
        this.toastMessageService.addMessageObj({severity: 'success',detail: 'The request was approved successfuly.'});
        this.router.navigate(['/app/manage/data-steward-queue']);
      }
    }, (httpErrorResponse) => {
      this.errorService.httpErrorHandler(httpErrorResponse);
      this._enableBtns();
    });
  }

  public deny() {
    this.dialogVisible = true;
  }

  public viewLog() {
    this.router.navigate(['/app/manage/data-steward-queue/log', this.details.researchRequestId])
  }
  public denyConfirm() {
    if (this.denyReasonText.valid) {
      this._disableBtns();
      this.dsqservice.declineDataStewardRequest(this.details.id, this.denyReasonText.value).pipe(
        take(1),
        finalize(() => {
          this.loader.hideLoader();
        })
      )
      .subscribe((response: any) => {
        console.log(response);
        if (response) {
          this.toastMessageService.addMessageObj({severity: 'success',detail: 'The request was denied successfuly.'});
        }
        this.router.navigate(['/app/manage/data-steward-queue']);
      }, (httpErrorResponse) => {
        this.errorService.httpErrorHandler(httpErrorResponse);
        this._enableBtns();
      });
    }
  }

  public cancelDeny() {
    this.dialogVisible = false;
  }

  public downloadFile(fid, name) {

    this.dsqservice.downloadAttachment(this.details.id.toString(), name, fid)
    .subscribe((res: { filename: string, data: any }) => {
        console.log('start download:', res);
        // const url = 'data:application/octet-stream;base64,';

          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element

      }, (error) => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }

  private _disableBtns() {
    if (this.approveBtn) {
      this.approveBtn.nativeElement.disabled = true;
    }
    if (this.denyBtn) {
      this.denyBtn.nativeElement.disabled = true;
    }
  }
  private _enableBtns() {
    if (this.approveBtn) {
      this.approveBtn.nativeElement.disabled = false;
    }
    if (this.denyBtn) {
      this.denyBtn.nativeElement.disabled = false;
    }
  }




}
