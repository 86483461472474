import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, NG_VALIDATORS } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManageRouteGuard } from '@edw-app-root/guards/manage.guard';
import { ResourcesRouteGuard } from '@edw-app-root/guards/resources.guard';
import { RequiredDeadlineReasonText } from '@edw-app-root/validators/dealine-reason-text.validator';
import { RequiredDeadlineReason } from '@edw-app-root/validators/dealine-reason.validator';
import { RequiredFunding } from '@edw-app-root/validators/funding-source.validator';
import { AlertsService } from '@edw-services/alerts.service';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { InterceptorService } from '@edw-services/http-interceptors/http-interceptor.service';
import { LoaderService } from '@edw-services/loader.service';
import { ToastMessageService } from '@edw-services/messaging.service';
import { PowerBIService } from '@edw-services/power-bi.service';
import { ProductsService } from '@edw-services/products.service';
import { EDWSubscription2Service } from '@edw-services/subscription2.service';
import { UserDataService } from '@edw-services/user-data.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule, CheckboxModule, ConfirmDialogModule, DialogModule, MenubarModule, MessageModule, MessageService, MessagesModule, OverlayPanelModule, PanelMenuModule, RadioButtonModule, RatingModule, ToastModule } from 'primeng-lts';
import { AlertsComponent } from './alerts/alerts.component';
import { AppRoutingModule } from './app-routing.module';
import { AppWrapperComponent } from './app-wrapper/app-wrapper.component';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { AppErrorsHandler } from './custom-error-handlers/app-errors-handler.service';
import { ErrorsCatcher } from './custom-error-handlers/errors-catcher';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './login/login.component';
import { ManageModule } from './manage/manage.module';
import { NonProdTestModule } from './non-prod-tests/non-prod-test.module';
import { NotificationsModule } from './notifications/notifications.module';
import { ResourcesModule } from './resources/resources.module';
import { EDWHttpService } from './services/httpService/edw-http.service';
import { EDWSharedModule } from './shared/shared.module';
import { SnowTicketComponent } from './shared/snow-ticket/snow-ticket.component';
import { ValueRatingComponent } from './shared/value-rating/value-rating.index';
import { TopNavComponent } from './top-nav/top-nav.component';




//import {NgxMaskModule} from 'ngx-mask';

// import { PermissionGroupsModule } from '@edw-app-root/manage/permission-groups/permissions.module';
@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    LeftNavComponent,
    AppWrapperComponent,
    LoaderComponent,
    LoginComponent,
    AlertsComponent,
    ValueRatingComponent,
    SnowTicketComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ResourcesModule,
    // PermissionGroupsModule,
    AuthenticationModule,
    ManageModule,
    DialogModule,
    NotificationsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MenubarModule,
    PanelMenuModule,
    OverlayPanelModule,
    ToastModule,
    CheckboxModule,
    EDWSharedModule,
    MessagesModule,
    MessageModule,
    CarouselModule,
    RatingModule,
    ConfirmDialogModule,
    RadioButtonModule,
    NonProdTestModule,
    FontAwesomeModule
    // NgxMaskModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsCatcher
    },
    { provide: NG_VALIDATORS, useExisting: RequiredFunding, multi: true },
    { provide: NG_VALIDATORS, useExisting: RequiredDeadlineReason, multi: true },
    { provide: NG_VALIDATORS, useExisting: RequiredDeadlineReasonText, multi: true },
    UserDataService,
    LoaderService,
    AppErrorsHandler,
    ErrorHandlerService,
    AlertsService,
    PowerBIService,
    ToastMessageService,
    ResourcesRouteGuard,
    ManageRouteGuard,
    EDWSubscription2Service,
    ProductsService,
    EDWHttpService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
