export const snakeCaseToCamelCase = function snakeCaseToCamelCase(obj) {
  let camelCasedObjProps = {};

  for (let property in obj) {
    let propertyToArr = property.split('_');

    propertyToArr = propertyToArr.map(function (el, index) {
      let str = el;

      if (index > 0) {
        let underscoreSeparatedWordsArr = el.split('');
        underscoreSeparatedWordsArr[0] = underscoreSeparatedWordsArr[0].toUpperCase();
        str = underscoreSeparatedWordsArr.join('');
      }

      return str;
    });

    if (obj[property] !== null && typeof obj[property] === 'object' && !Array.isArray(obj[property])) {
      obj[property] = snakeCaseToCamelCase(obj[property]);
    } else if (Array.isArray(obj[property]) && obj[property].length > 0) {
      obj[property] = obj[property].map((arrObj) => {
        return snakeCaseToCamelCase(arrObj);
      });
    }

    camelCasedObjProps[propertyToArr.join('')] = obj[property];
  }

  return camelCasedObjProps;
}

export const camelCaseToSnakeCase = function camelCaseToSnakeCase(obj) {
  let snakeCasedObjProps = {};
  
  if (obj === null) {
    return obj;
  }

  for (let property in obj) {
    let propertyToArr = property.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, '$1').split(' ');

    propertyToArr = propertyToArr.map(function (el, index) {
      let str = el;

      if (index > 0) {
        str = str.toLocaleLowerCase();
      }

      return str;
    });
    
    if (obj[property] !== null && typeof obj[property] === 'object' && !Array.isArray(obj[property])) {
      obj[property] = camelCaseToSnakeCase(obj[property]);
    } else if (Array.isArray(obj[property]) && obj[property].length > 0) {
      obj[property] = obj[property].map((arrObj) => {
        return camelCaseToSnakeCase(arrObj);
      });
    }

    snakeCasedObjProps[propertyToArr.join('_')] = obj[property];
  }

  return snakeCasedObjProps;
};
