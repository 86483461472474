export class ResourceRating{
  public id : number = 0;
  public resourceKey : number = 0;
  public userKey : number = 0;
  public value: number = 0;
  public comments: string = "";
  public createDate: Date;
  public modifyDate: Date;
  public reason?: number;
  constructor( resourceRating? : ResourceRating ){
    if(!!resourceRating){
      this.id = resourceRating.id;
      this.resourceKey = resourceRating.resourceKey;
      this.userKey = resourceRating.userKey;
      this.value = resourceRating.value;
      this.comments = resourceRating.comments;
      this.createDate = resourceRating.createDate;
      this.modifyDate = resourceRating.modifyDate;
      this.reason = resourceRating.reason || 0;
    }
  }
}

