import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ReportsService } from '@edw-app-root/services/reports.service';
import { UserExtractsService } from '@edw-services/user-extracts.service';
import { UserDataService } from '@edw-app-root/services/user-data.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FavouritesReportsResolver implements Resolve<any> {

  constructor(
    private reportsService: ReportsService,
    private userDataService: UserDataService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const title = 'Favorites';
    const reportFavorites = {};

    return this.reportsService.getFavoriteReports()
      .pipe(map((res: any) => { 
        return res['data']}));
      // .pipe(map((responseBody) => ({
      //   reports: responseBody['data'],
      //   title: title
      // })));
  }
}

@Injectable()
export class FavoriteExtractsResolver implements Resolve<any> {

  constructor(
    private userExtractsService: UserExtractsService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    
    return this.userExtractsService.getFavoritesUserExtracts();
    
  }
}
