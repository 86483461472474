import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AppErrorType, AppErrorsHandler } from './app-errors-handler.service';

@Injectable()
export class ErrorsCatcher extends ErrorHandler {
  constructor(private appErrorsHandler: AppErrorsHandler) {
    super();
  }

  public handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      this.appErrorsHandler.broadcastError(AppErrorType.HttpError, error);
    } else {
      super.handleError(error);
    }
  }

}
