import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { AppNotification } from '@edw-entities/notification';
import { NotificationsService } from '@edw-services/notifications.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit {
  showUnread: boolean = true;
  showRead: boolean = false;
  markedAsRead: boolean = false;
  someDate;
  notifications;
  count;

  noUnreadNotificationsMsg: Message[] = [{
    severity: 'info',
    summary: '',
    detail: 'No unread notifications.'
  }];

  noReadNotificationsMsg: Message[] = [{
    severity: 'info',
    summary: '',
    detail: 'No read notifications.'
  }];



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationsService: NotificationsService,
    private toastMessageService: ToastMessageService

              ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.notifications = data.componentData.notifications;
      });

    let subscription: Subscription = this.notificationsService.notificationsCountObservable
      .subscribe((count) => {
        this.count = count;
        subscription.unsubscribe();
      });
  }

  toggleUnread() {
    this.showUnread = !this.showUnread;
  }

  toggleRead() {
    this.showRead = !this.showRead;
  }

  markAsRead(notification: AppNotification) {
    let subscription: Subscription = this.notificationsService
      .markAsRead([notification.id])
      .subscribe((response) => {
        if (response['data'] === true) {
          notification.removedFromUnread = true;
          this.showRead = true;

          setTimeout(() => {
            this.notifications.unreadNotifications = this.notifications.unreadNotifications
              .filter((unreadNotification) => {
                return unreadNotification.id !== notification.id;
              });

            this.notifications.readNotifications.unshift(notification);

            if (this.notifications.readNotifications.length > 20) {
              this.notifications.readNotifications = this.notifications.readNotifications.slice(0, 20);
            }

            this.broadcastNotificationsCountChange();

            this.toastMessageService.addMessageObj({
              severity: 'success',
              summary: '',
              detail: 'Successfully marked the notification as read.'
            });
          }, 200);
        }

        subscription.unsubscribe();
      });
  }

  markAllAsRead() {
    let subscription: Subscription = this.notificationsService
      .markAllAsRead()
      .subscribe((response) => {
        if (response['data'] === true) {
          this.showRead = true;

          setTimeout(() => {

            const unreadnotificationslocal = this.notifications.unreadNotifications;

            let i;
            for (i = 0; i < unreadnotificationslocal.length; i++) {

              const notification = unreadnotificationslocal[i];

              this.notifications.unreadNotifications = this.notifications.unreadNotifications
                .filter((unreadNotification) => {
                  return unreadNotification.id !== notification.id;
                });

              this.notifications.readNotifications.unshift(notification);

              if (this.notifications.readNotifications.length > 20) {
                this.notifications.readNotifications = this.notifications.readNotifications.slice(0, 20);
              }

            }

            this.broadcastNotificationsCountChange();

            this.toastMessageService.addMessageObj({
              severity: 'success',
              summary: '',
              detail: 'Successfully marked all the notifications as read.'
            });
          }, 200);
        }

        subscription.unsubscribe();
      });
  }

  broadcastNotificationsCountChange() {
    if (this.count > 0) {
      this.count--;
    }

    this.notificationsService.broadcastNotificationsCountChange(this.count);
  }

  proceedWithAction(notification: AppNotification) {
    this.markAsRead(notification);
    this.router.navigate(notification.action.routerLink);
  }
}
