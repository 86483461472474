import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { EDWSharedModule } from '@edw-app-root/shared/shared.module';

import {
    AccordionModule,
    AutoCompleteModule,
    CalendarModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    MessagesModule,
    PaginatorModule,
    RadioButtonModule,
    SharedModule,
    TabViewModule,
    TableModule,
    ToastModule
} from 'primeng-lts';

import { SubscriptionDetailComponent } from './subscription-detail/subscription-detail.component';
import { SubscriptionSetupListComponent } from './subscription-setup-list/subscription-setup-list.component';
import { SubscriptionSetupComponent } from './subscription-setup/subscription-setup.component';
import { SubscriptionCreateEmailComponent } from './subscriptions-setup-steps/subscription-create-email/subscription-create-email.component';
import { SubscriptionCreateKeywordsComponent } from './subscriptions-setup-steps/subscription-create-keywords/subscription-create-keywords.component';
import { SubscriptionEntityAssociationsComponent } from './subscriptions-setup-steps/subscription-entity-associations/subscription-entity-associations.component';
import { SubscriptionGeneralMetadataComponent } from './subscriptions-setup-steps/subscription-general-metadata/subscription-general-metadata.component';
import { SubscriptionRecipientConfigComponent } from './subscriptions-setup-steps/subscription-recipient-config/subscription-recipient-config.component';
import { SubscriptionReportConfigComponent } from './subscriptions-setup-steps/subscription-report-config/subscription-report-config.component';
import { UpdateRedirectComponent } from './subscriptions-setup-steps/update-redirect/update-redirect.component';

import { ManageSubscriptionsRouteGuard } from './subscriptions.guard';
import { ManageSubscriptionsRoutingResolver } from './subscriptions.resolver';

export const subscriptionsRoutes = [
  {
    path: 'subscriptions',
    canActivate: [ManageSubscriptionsRouteGuard],
    canActivateChild: [ManageSubscriptionsRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManageSubscriptionsRoutingResolver
        },
        component: SubscriptionSetupListComponent
      },
      {
        path: 'add',
        resolve: {
          componentData: ManageSubscriptionsRoutingResolver
        },
        component: SubscriptionSetupComponent
      },
      {
        path: ':id',
        resolve: {
          componentData: ManageSubscriptionsRoutingResolver
        },
        component: SubscriptionDetailComponent
      },
      {
        path: 'update/:id',
        component: UpdateRedirectComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    EDWSharedModule,
    TableModule,
    SharedModule,
    AutoCompleteModule,
    PaginatorModule,
    DialogModule,
    AccordionModule,
    MessagesModule,
    ToastModule,
    DropdownModule,
    CheckboxModule,
    CalendarModule,
    FileUploadModule,
    TabViewModule,
    RadioButtonModule
  ],
  declarations: [
    SubscriptionSetupComponent,
    SubscriptionSetupListComponent,
    SubscriptionGeneralMetadataComponent,
    SubscriptionCreateKeywordsComponent,
    SubscriptionCreateEmailComponent,
    SubscriptionRecipientConfigComponent,
    SubscriptionReportConfigComponent,
    SubscriptionEntityAssociationsComponent,
    SubscriptionDetailComponent,
    UpdateRedirectComponent
  ],
  providers: [
    ManageSubscriptionsRoutingResolver,
    ManageSubscriptionsRouteGuard
  ],
  exports: [
    SubscriptionSetupComponent,
    SubscriptionSetupListComponent,
    SubscriptionGeneralMetadataComponent,
    SubscriptionCreateKeywordsComponent,
    SubscriptionCreateEmailComponent,
    SubscriptionRecipientConfigComponent,
    SubscriptionReportConfigComponent,
    SubscriptionEntityAssociationsComponent,
    SubscriptionDetailComponent,
    UpdateRedirectComponent
  ]
})
export class SubscriptionsModule { }
