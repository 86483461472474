import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Permissions } from '@edw-app-root/permissions';
import { SubscriptionRecipientConfigEntity } from '@edw-entities/subscription';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-subscription-recipient-config',
  templateUrl: './subscription-recipient-config.component.html',
  styleUrls: ['./subscription-recipient-config.component.scss']
})
export class SubscriptionRecipientConfigComponent implements OnInit {
  @Input() stepModel: SubscriptionRecipientConfigEntity;
  @Output() stepModelChange = new EventEmitter();

  @Input() subscriptionId: number = 0;
  @Output() onCompleteStep = new EventEmitter();
  @Output() onGoBack = new EventEmitter();
  @Output() onSaveRecipientGroup = new EventEmitter();
  @Output() onRemoveRecipientGroup = new EventEmitter();

  @Input() messages: Message[];
  @Output() messagesChange = new EventEmitter();

  @Input() editMode: boolean = false;

  permissions = {
    canCreate: false,
    canModify: false
  }

  constructor(private loader: LoaderService,
              private sessionStorageService: SessionStorageService,
              private subscriptionService: SubscriptionService,
              private userDataService: UserDataService,
              private genericErrorHandlerService: ErrorHandlerService) { }

  ngOnInit() {
    this.permissions.canCreate = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canCreate);
    this.permissions.canModify = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canModify);
  }

  onFileSelect(event, recipientGroup) {
    recipientGroup.files = [];

    for (let file of event.files) {
      recipientGroup.files.push(file);
    }
  }

  onUpload($event, recipientGroup) {
    if (!this.sessionStorageService.get('manualLoader')) {
      this.loader.showLoader();
    }

    const subscription: Subscription = this.subscriptionService
      .addRecipientGroup(this.subscriptionId, recipientGroup.name, recipientGroup.files[0])
      .subscribe(
        (response) => {
          if (response['data'] > 0) {
            recipientGroup.id = response['data'];
            recipientGroup.isPersisted = true;

            if (!this.sessionStorageService.get('manualLoader')) {
              this.messagesChange.emit([{
                severity: 'success',
                summary: 'Success.',
                detail: recipientGroup.name + ' has been added as a recipient group.'
              }]);
            } else {
              this.sessionStorageService.add('message', recipientGroup.name + ' has been added as a recipient group.');
            }
          }

          this.stepModel.recipientConfigForms['recipientConfig' + recipientGroup.timestampAdded] = true;
          this.checkIfAllRecipientConfigFormsArePersisted();

          this.onSaveRecipientGroup.emit();

          subscription.unsubscribe();
          this.loader.hideLoader();
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }

  removeSelectedFile($event, filesArray, fileIndex) {
    filesArray.splice(fileIndex, 1);
  }

  addNewRecipientGroup() {
    let timestamp = new Date().getTime().toString();
    this.stepModel.recipientConfigForms['recipientConfig' + timestamp] = false;

    this.stepModel.recipientGroups.push({
      id: 0,
      name: '',
      files: [],
      timestampAdded: timestamp,
      isPersisted: false
    });

    this.checkIfAllRecipientConfigFormsArePersisted()
  }

  removeRecipientGroup(index) {
    if (this.stepModel.recipientGroups[index].isPersisted === true) {
      if (!this.sessionStorageService.get('manualLoader')) {
        this.loader.showLoader();
      }

      let id = this.stepModel.recipientGroups[index].id;

      const subscription: Subscription = this.subscriptionService
        .removeRecipientGroup(this.subscriptionId, id)
        .subscribe(
          (response) => {
            if (response['data'] === true) {
              if (!this.sessionStorageService.get('manualLoader')) {
                this.messagesChange.emit([{
                  severity: 'success',
                  summary: 'Removed Succesfully.',
                  detail: this.stepModel.recipientGroups[index].name + ' has been removed as a recipient group.'
                }]);
              } else {
                this.sessionStorageService.add('message', this.stepModel.recipientGroups[index].name + ' has been removed as a recipient group.');
              }

              this.stepModel.recipientConfigForms['recipientConfig' + this.stepModel.recipientGroups[index].timestampAdded] = null;
              this.checkIfAllRecipientConfigFormsArePersisted();

              this.stepModel.recipientGroups.splice(index, 1);
              this.respawnANewRecipientGroupIfAllAreDeleted();

              this.onRemoveRecipientGroup.emit();
              this.loader.hideLoader();
            }
          },
          (httpErrorResponse) => {
            let errorMessages = [];
            this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

            this.messagesChange.emit(errorMessages);

            subscription.unsubscribe();
            this.loader.hideLoader();
          }
        );
    } else {
      this.stepModel.recipientConfigForms['recipientConfig' + this.stepModel.recipientGroups[index].timestampAdded] = null;
      this.checkIfAllRecipientConfigFormsArePersisted();
      this.stepModel.recipientGroups.splice(index, 1);
      this.respawnANewRecipientGroupIfAllAreDeleted();
    }
  }

  completeStep() {
    this.onCompleteStep.emit();
  }

  goBack() {
    this.onGoBack.emit();
  }

  private respawnANewRecipientGroupIfAllAreDeleted() {
    if (this.stepModel.recipientGroups.length === 0) {
      this.addNewRecipientGroup();
    }
  }

  private checkIfAllRecipientConfigFormsArePersisted() {
    let configFormsValues = [];

    if (this.stepModel.recipientGroups.length > 0) {
      for (let prop in this.stepModel.recipientConfigForms) {
        if (this.stepModel.recipientConfigForms[prop] !== null) {
          configFormsValues.push(this.stepModel.recipientConfigForms[prop]);
        }
      }

      this.stepModel.isRecipientGroupListCompleted = configFormsValues
        .every((value) => {
          return value === true;
        });
    } else {
      this.stepModel.recipientConfigForms = {};
      this.stepModel.isRecipientGroupListCompleted = false;
    }
  }

}
