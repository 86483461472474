import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRestResponse } from '@edw-entities/rest-response';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Collection } from '../resources/components/entities/CollectionDataClasses';



const httpOptions = new HttpHeaders();
httpOptions.set('Content-Type', 'application/json');

@Injectable()
export class UserCollectionService {
  constructor( private http: HttpClient ){}

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  getUserCollections = (): Observable<any> => {
    return this.http.get<Response>(environment.api + '/usercollections/get');
  }

  addUserCollection = (collectionName: string ): Observable<any> => {
    return this.http
      .get(`${environment.api}/usercollections/addcollection`, {params: new HttpParams().set('collectionName', collectionName)});
  }

  updateUserCollectionName = (collection: Collection): Observable<any> => {

    return this.http
      .post<Collection>(
        `${environment.api}/usercollections/updatecollectionname`,
        collection,
        { observe: 'body' }
      );
    // return this.http.post<Collection>(`${environment.api}/usercollections/updatecollectionname`, null, { observe: 'body', params: params } );
  }

  updateCollectionResources = (collection: Object): Observable<any> => {
    return this.http
      .post<Collection>(
        `${environment.api}/usercollections/updatecollectionresources`, 
         collection, 
        { observe: 'body' }
      );
  }

  deleteUserCollection = (collectionid: any): Observable<any> => {
    return this.http
      .get(`${environment.api}/usercollections/deletecollection`, {params: new HttpParams().set('collectionid', collectionid)});
  }

}