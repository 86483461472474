export const ResourceStatus = {
  deployed: {
    text: 'Deployed',
    value: 3
  },
  staging: {
    text: 'Staging',
    value: 1
  },
  inProgress: {
    text: 'Under Review',
    value: 2
  }
}
