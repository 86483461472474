export class Account {

accountId: number;
activeDirectory: string;
accountName: string;
accountFullName: string;

constructor(account) {
    this.accountId = account.accountId;
    this.activeDirectory = account.activeDirectory;
    this.accountName = account.accountName;
    if (account.activeDirectory != '' && account.accountName != '') {
      this.accountFullName = account.activeDirectory + '\\' + account.accountName;
    } else {
      this.accountFullName = '';
    }
  }

}
