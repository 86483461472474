import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRestResponse } from '@edw-entities/rest-response';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDataStewardRequest, IDataStewardRequestDetails, IDataStewardRequestDetailsResponse } from '@edw-entities/data-steward-queue';


@Injectable()
export class DataStewardQueueService {
  private endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
  }

  getDataStewardQueue(studyNumber: string = '', page: number = 1, onlyMine: boolean = true): Observable<IDataStewardRequest[]> {
    let params = new HttpParams();
    if (studyNumber !== null) {
      params = params.set('studyNumber', studyNumber);
    }

    params = params.set('onlyMine', onlyMine.toString());
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<IDataStewardRequest[]>(`${this.endpoint}/dataStewardRequest/getAll`, options);
  }

  getDataStewardRequestDetails(id: number): Observable<IDataStewardRequestDetailsResponse> {
    let params = new HttpParams();
    params = params.set('id', id.toString());
    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<IDataStewardRequestDetailsResponse>(`${this.endpoint}/dataStewardRequest/getApprovalRequestDetails`, options);
  }

  approveDataStewardRequest(id: number): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<any>(`${this.endpoint}/dataStewardRequest/${id}/approve`, null, options);
  }

  declineDataStewardRequest(id: number, reason: string): Observable<any> {
    const options: Object = {
      observe: 'body'
    };

    return this.http.post<any>(`${this.endpoint}/dataStewardRequest/${id}/decline`, { text: reason }, options);
  }

  downloadAttachment(id: string, name: string, attachmentId: number) {
    return this.http.get(`${this.endpoint}/dataStewardRequest/${id}/attachment/${attachmentId}/download`, {
      responseType: 'blob'
    })
      .pipe(map((res: any) => {
        return {
          filename: name,
          data: res
          // data: new Blob( [res.blob()], { type: 'application/octet-stream'} )
        };
      }));
  }
}
