import { UserSearchResult } from '@edw-entities/user-search-result';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs';
import { ResourceGroup } from '@edw-entities/resource-group';
import { ResourceSearchResult } from '@edw-entities/resource-search-result';
import { SearchParams } from '@edw-entities/searchParams';


@Injectable()
export class ResourceGroupDataService {

  constructor(private http: HttpClient) { }
  private filteredResourceGroupsSource = new Subject<any>();

  filteredResourceGroupsObservable = this.filteredResourceGroupsSource.asObservable();

  getAllResourceGroups(query: string = '', page: number = 1) {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/resourcegroup/getAll', options);
  }

  getResourceGroupDetails(resourceGroupId: string): Observable<Object> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<Response>(environment.api + '/resourcegroup/' + resourceGroupId + '/getDetails', options);
  }

  addResourceGroup(resourceGroup: Object): Observable<Object> {
    const postBody = null;

    let params = new HttpParams();
    // doing this value re-assignment because
    // the instances of HttpParams are immutable objects
    params = params.set('name', resourceGroup['name']);
    params = params.set('desc', resourceGroup['description']);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resourcegroup/add', postBody, options);
  }

  removeResourceGroup(resourceGroupId: string): Observable<Object> {
    const body = {
      resourceGroupId: resourceGroupId
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/remove', body, options);
  }

  update(resourceGroup: ResourceGroup): Observable<Object> {
    let body = resourceGroup;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroup.id + '/update', body, options);
  }

  findAddedResource = (resourceGroupId: string, searchParams: SearchParams): Observable<Object> => {
    let postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/resource/get', postBody, options);
  }

  findNonAddedResource = (resourceGroupId: string, query: string): Observable<Object> => {
    let postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/resource/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.resourceName + ' (' + element.resourceType + ')';
          return element;
        });

        return response;
      }));
  }

  findAddedUserGroup = (resourceGroupId: string, searchParams: SearchParams): Observable<Object> => {
    let postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/usergroup/get', postBody, options);
  }

  findNonAddedUserGroup = (resourceGroupId: string, query: string): Observable<Object> => {
    let postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/resourcegroup/' + resourceGroupId + '/usergroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;
          return element;
        });

        return response;
      }));
  }

  addResourceToGroup = (resourceGroupId: string, resourceIds: number[]): Observable<Object> => {
    let params = new HttpParams();
    let postBody = resourceIds;

    params = params.set('resourceGroupId', resourceGroupId);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/resource/add', postBody, options);
  }

  removeResourceFromGroup = (resourceGroupId: string, resourceId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceId', resourceId.toString());
    params = params.set('resourceGroupId', resourceGroupId);

    let postBody = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resourcegroup/ ' + resourceGroupId + '/resource/remove', postBody, options);
  }

  associateResourceGroupsWithUserGroups = (resourceGroupId: string, userGroupIds: number[]): Observable<Object> => {
    let params = new HttpParams();
    let postbody = userGroupIds;

    params = params.set('resourceGroupId', resourceGroupId);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/usergroup/add', postbody, options);
  }

  removeResourceGroupsUserGroupsRelationship = (resourceGroupId: string, userGroupId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('userGroupId', userGroupId);
    params = params.set('resourceGroupId', resourceGroupId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/usergroup/remove', body, options);
  }

  findNonAddedUser = (resourceGroupId: string, query: string): Observable<Object> => {
    let postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/resourcegroup/' + resourceGroupId + '/user/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';
          return element;
        });

        return response;
      }));
  }

  findAddedUser = (resourceGroupId: string, searchParams: SearchParams): Observable<Object> => {
    let postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/user/get', postBody, options);
  }

  addUserToGroup = (resourceGroupId: string, userIds: number[]): Observable<Object> => {
    let postBody = userIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/user/add', postBody, options);
  }

  removeUserFromGroup = (resourceGroupId: string, userId: string): Observable<Object>  => {
    let params = new HttpParams();
    params = params.set('resourceGroupId', resourceGroupId);
    params = params.set('userId', userId.toString());

    let postBody = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/resourcegroup/' + resourceGroupId + '/user/remove', postBody, options);
  }
}
