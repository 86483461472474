import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { ManageCohortsGuard } from '@edw-app-root/manage/cohorts/cohort.guard';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { EDWSharedModule } from '@edw-app-root/shared/shared.module';
import {
  AccordionModule,
  AutoCompleteModule,
  CheckboxModule,
  DialogModule,
  DropdownModule,
  InputNumberModule,
  InputTextModule,
  MessagesModule,
  PaginatorModule,
  SharedModule,
  TableModule,
  ToastModule,
  TooltipModule
} from 'primeng-lts';

import { CohortQueueListComponent } from './containers/cohort-queue-list/cohort-queue-list.component';

import { CohortQueueListResolver } from './resolvers/cohort-queue-list.resolver';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EDWSharedModule,
    TableModule,
    SharedModule,
    AutoCompleteModule,
    PaginatorModule,
    DialogModule,
    AccordionModule,
    MessagesModule,
    ToastModule,
    DropdownModule,
    CheckboxModule,
    TooltipModule,
    CodemirrorModule,
    InputTextModule,
    InputNumberModule
  ],
  declarations: [
    CohortQueueListComponent
  ],
  providers: [
    CohortQueueListResolver
  ],
})
export class CohortQueueModule { }
