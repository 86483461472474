import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceType } from '@edw-enums/resource-type';

import { UserSettings } from '@edw-app-root/entities/user-settings';
import { UserSettingsService } from '@edw-app-root/services/user-settings.service';
import { Subscription } from 'rxjs';

import { ExtractService } from '../../components/extracts/extract.service';

import { ToastMessageService } from '@edw-services/messaging.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Message } from 'primeng-lts';
import { Collection, CollectionData, CollectionStore } from '../../components/entities/CollectionDataClasses';
import { CollectionDataService, CollectionService, ResourceService } from '../../components/services/resource.service';


@Component({
  selector: 'edw-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss', '../../components/sharedStyles.scss'],
  providers:[ExtractService]
})


export class CollectionsComponent implements OnInit {
  public userInfo = { id: '1' };
  public resourceType = ResourceType;
  public loaderShown: boolean;
  public query: string;
  public messages: Message[] = [];
  public layoutTile: boolean;
  public momentJS = moment;
  display: boolean = false;
  userSettings: UserSettings;
  public CollectionStore: CollectionStore;
  public collectionData: CollectionData;
  public ExtractData = {};
  public showAdmin = false;

  subs : Subscription[] = [];

  public paging = {
    reports:{
      id: 'collectionReports',
      page:1
    },
    extracts:{
      id:'collectionExtracts',
      page:1
    },
    isActive: true
  };



  @ViewChild('executeExtractConfirmationModal', {static: true}) executeExtractConfirmationModal;

  constructor(
    private route: ActivatedRoute,
    private userSettingsService: UserSettingsService,
    private resourceService: ResourceService,
    private toastMessageService: ToastMessageService,
    private collectionService: CollectionService,
    private collectionDataService: CollectionDataService
  ) {
    this.subs.push(
      this.collectionService.CollectionStore$.subscribe((store) => {this.CollectionStore = store; } ),
      this.collectionDataService.CollectionData.subscribe((collectionData) => {this.collectionData = collectionData;} ),
      this.userSettingsService.currentUserSettings.subscribe(userSettings => this.userSettings = userSettings)
    );
  }

  public ngOnInit() {

    this.layoutTile = (this.userSettings.pageView === 'Tile') ? true : false;

    this.subs.push(
      // GET DATA FROM ROUTE...
      this.route.data
        .subscribe((data: { reportFavorites: any, userExtracts: any, allReports:any, collectionsData:any, extractFavorites:any }) => {

          const uniqueReports = _.uniqBy( [].concat.apply( [], data.allReports ).sort( ( a, b ) => a.id - b.id ), 'id' );

          this.collectionService.setCSReports(uniqueReports);

          this.collectionService.setCSExtracts(this.resourceService.parseData(data.userExtracts));

          const favortieCollection = new Collection(
            0,
            'Favorites',
            ( data.reportFavorites != null && data.reportFavorites.length > 0 ) ? data.reportFavorites[0].map(report => report.id) : [],
            ( data.extractFavorites != null && data.extractFavorites.length > 0 ) ? data.extractFavorites.map(extract => extract.id) : []
          );

          const collectionsArray: Collection[] = (data.collectionsData.collections.length > 0 )
            ? data.collectionsData.collections.map(collection => collection as Collection)
            : [];

          this.collectionService.setCSCollections( [favortieCollection].concat(collectionsArray) )

          this.collectionDataService.setCollectionData_withFilter(['0']);

          if(this.CollectionStore.Collections.length === 0){ this.toastMessageService.addMessage('info','','Please add a collection.');
      }

      })
    );

  }


  toggleAdmin(val) {
    // show/hide admin modal
    this.showAdmin = val;
    // refreshes collection data in active collection view.
    this.collectionDataService.setCollectionData_withFilter(['0']);
  }

  layoutUpdate(layout:boolean){
    this.layoutTile = layout;
  }

  ngOnDistory(){
    this.subs.map(s => s.unsubscribe());
  }


}


    // NEEDS TO MOVE TO EXTRACT LAYER...
    // this.formSub = this.paramsForm.valueChanges.subscribe((formData) => {
    //   this.formSubmitted = false;
    //   // console.log(this.paramsForm, formData);
    // });

