import { SelectItem } from 'primeng-lts';

export class ReportParameter {
  parameterId: number = 0;
  parameterName: string = '';
  parameterValue: any;
  isDynamic: boolean = false;
  keywords: SelectItem[];

  constructor(rawParameter, keywords?) {
    this.parameterId = rawParameter.id || rawParameter.parameterId;
    this.parameterName =  rawParameter.parameterName;
    this.isDynamic = rawParameter.isDynamic || false;
    this.keywords = keywords ? this.copyObjectsToANewArray(keywords) : [];

    if (rawParameter.isDynamic === true) {
      let match = this.keywords.filter((keyword) => {
        let param = '{{' + rawParameter.parameterValue;
        return keyword.value.indexOf(param) === 0
      })[0];

      this.parameterValue = match.value;
    } else {
      this.parameterValue = rawParameter.parameterValue || '';
    }
  }

  private copyObjectsToANewArray(arr) {
    arr = arr.map((obj) => {
      return Object.assign({}, obj);
    });

    return arr;
  }
}


