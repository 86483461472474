import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';

//vendor components and/or modules
import { ToastModule } from 'primeng';
import { MessagesModule } from 'primeng-lts';

//author services
import { UserDataService } from '@edw-services/user-data.service';

//author components
import { CheckAccountComponent } from './check-account/check-account.component';
import { CreateNewAccountComponent } from './create-new-account/create-new-account.component';

//guards and resolvers
import { AuthenticationRouteGuard } from './authentication.guard';
import { InactiveAccountComponent } from './inactive-account/inactive-account.component';

//routes definitions
const authenticationRoutes: Route[] = [{
  path: 'auth',
  canActivate: [AuthenticationRouteGuard],
  children: [
    {
      path: '',
      component: CheckAccountComponent
    },
    {
      path: 'create-new-account',
      component: CreateNewAccountComponent
    },
    {
      path: 'inactive-account',
      component: InactiveAccountComponent
    }
  ]
}];

@NgModule({
  declarations: [
    CheckAccountComponent,
    CreateNewAccountComponent,
    InactiveAccountComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    FormsModule,
    MessagesModule,
    ToastModule,
    RouterModule.forChild(authenticationRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthenticationRouteGuard,
    UserDataService
  ]
})
export class AuthenticationModule { }

