import { Injectable } from '@angular/core';
import { UserSettingsService } from '@edw-services/user-settings.service';
import { UserSettings } from '@edw-app-root/entities/user-settings';
import { CanActivate, Router, ActivatedRouteSnapshot,
         RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ResourcesRouteGuard implements CanActivate {

  private userSettings: UserSettings; 

  constructor(private router: Router,
              private userSettingService: UserSettingsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let canActivate = false;

    if (state.url !== '/app/resources' && state.url !== '/app/resources/') {            
      canActivate = true;
    }

    if (!canActivate) {
      let defaultRoute = '/app/resources/reports/all';      
      if (this.userSettings) {            
        defaultRoute = this.userSettings["defaultPage"];
      }      
      this.router.navigate([defaultRoute]);     
    }

    return canActivate;
  }
}
