import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';

@Injectable()
export class ManagePeopleRouteGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let canActivate = false;

    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];

    const canViewUsers = this.userDataService.checkForSpecificPermission(Permissions.USERS.canView);
    const canViewUserGroups = this.userDataService.checkForSpecificPermission(Permissions.USERGROUPS.canView);

    if (urlFragments.indexOf('users') > -1) {
      if (canViewUsers) {
        canActivate = true;
      }
    }

    if (urlFragments.indexOf('user-groups') > -1) {
      if (canViewUserGroups) {
        canActivate = true;
      }
    }

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }

    return canActivate;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let canActivate = false;

    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];

    //#region Users
    if (urlFragments.indexOf('users') > -1) {
      const canViewUsers = this.userDataService.checkForSpecificPermission(Permissions.USERS.canView);
      const canCreateUsers = this.userDataService.checkForSpecificPermission(Permissions.USERS.canModify);

      // users/
      if (lastParam === 'users') {
        canActivate = canViewUsers ? true : false;
      }

      // users/add
      if (lastParam === 'add') {
        canActivate = canCreateUsers;
      }

      // users/{id}
      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        canActivate = canViewUsers ? true : false;
      }
    }
    //#endregion Users

    //#region User Groups
    if (urlFragments.indexOf('user-groups') > -1) {
      const canViewUserGroups = this.userDataService.checkForSpecificPermission(Permissions.USERGROUPS.canView);
      const canCreateUserGroups = this.userDataService.checkForSpecificPermission(Permissions.USERGROUPS.canCreate);

      // user-groups/
      if (lastParam === 'user-groups') {
        canActivate = canViewUserGroups ? true : false;
      }

      // user-groups/{id}
      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        canActivate = canViewUserGroups ? true : false;
      }

      // user-groups/add
      if (lastParam === 'add') {
        canActivate = canCreateUserGroups ? true : false;
      }
    }
    //#endregion User Groups

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }

    return canActivate;
  }
}
