import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuditModule } from '@edw-app-root/manage/audit/audit.module';
import { CohortQueueModule } from '@edw-app-root/manage/cohort-queue/cohort-queue.module';
import { SubscriptionFormService } from '@edw-app-root/manage/subscriptions2/subscriptions/services/subscription.form.service';
import { TeamService } from '@edw-app-root/services/team-service';
import { CohortsDataService } from '@edw-services/cohorts.service';
import { DataStewardQueueService } from '@edw-services/data-steward-queue.service';
import { ExtractConfigDataService } from '@edw-services/extract-config.service';
import { ExtractSetupDataService } from '@edw-services/extract-setup.service';
import { ExtractDataService } from '@edw-services/extract.service';
import { LocalStorageService } from '@edw-services/local-storage.service';
import { PermissionGroupDataService } from '@edw-services/permission-group.service';
import { RecipientGroupService } from '@edw-services/recipient-group.service';
import { ReportsService } from '@edw-services/reports.service';
import { ResearchRequestsService } from '@edw-services/research-requests.service';
import { ResourceGroupDataService } from '@edw-services/resource-group.service';
import { SAService } from '@edw-services/service-agreement.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { SubscritptionWorkflowService } from '@edw-services/subscription.workflow.service';
import { UserGroupDataService } from '@edw-services/user-group.service';
import { UserSettingsService } from '@edw-services/user-settings.service';
import { UserSettingsModule } from '../user-settings/user-settings.module';
import { DataStewardQueueModule } from './data-steward-queue/data-steward-queue.module';
import { ExtractsModule } from './extracts/extracts.module';
import { ManageAlertsGuard } from './guards/alerts.guard';
import { ManageCohortsGuard } from './guards/cohort.guard';
import { ManagePermissionsGuard } from './guards/permissions.guard';
//guards
import { RecipientGroupGuard } from './guards/recipient-group.guard';
import { ManageResearchRequestsGuard } from './guards/research.guard';
import { ManageUserExtractsGuard } from './guards/user-extracts.guard';
import { ManageRoutingModule } from './manage-routing.module';
import { PeopleModule } from './people/people.module';
import { ManageProductPagesModule } from './product-pages/product-pages.module';
import { RequestsModule } from './requests/requests.module';
import { ResourcesModule } from './resources/resources.module';
import { SubscriptionsModule } from './subscriptions/subscriptions.module';




@NgModule({
  imports: [
    RouterModule,
    PeopleModule,
    AuditModule,
    RequestsModule,
    ResourcesModule,
    ManageRoutingModule,
    SubscriptionsModule,
    ExtractsModule,
    DataStewardQueueModule,
    CohortQueueModule,
    UserSettingsModule,
    ManageProductPagesModule
  ],
  providers: [
    ResourceGroupDataService,
    UserGroupDataService,
    UserSettingsService,
    PermissionGroupDataService,
    CohortsDataService,
    LocalStorageService,
    SessionStorageService,
    StagingReportsService,
    ReportsService,
    ResearchRequestsService,
    RecipientGroupService,
    // ManageRouteGuard,
    ExtractDataService,
    ExtractSetupDataService,
    ExtractConfigDataService,
    SubscriptionService,
    DataStewardQueueService,
    ReportsService,
    SAService,
    SubscriptionFormService,
    SubscritptionWorkflowService,
    ManageResearchRequestsGuard,
    ManagePermissionsGuard,
    ManageAlertsGuard,
    ManageCohortsGuard,
    ManageUserExtractsGuard,
    RecipientGroupGuard,
    TeamService
  ]
})
export class ManageModule { }

