import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { Observable, Subject} from 'rxjs';
import { take } from 'rxjs/operators'
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { ResourceStatus } from '@edw-enums/resource-status';

@Injectable()
export class ManageResourcesRouteGuard implements CanActivate, CanActivateChild {

  private canActivateSubject = new Subject<boolean>();
  private canActivateObservable = this.canActivateSubject.asObservable();
  private urlFragments: string[] = [];
  private lastParam: string = "";

  constructor(private router: Router,
    private userDataService: UserDataService,
    private stagingReportsService: StagingReportsService,
    private sessionStorageService: SessionStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    this.urlFragments = state.url.split('/');
    this.lastParam = state.url.split('/')[this.urlFragments.length - 1];

    this.canActivateObservable.subscribe((canActivate) => {
      if (!canActivate) {
        this.router.navigated = false;
        this.sessionStorageService.add('message-error', 'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.');
        //this.router.navigateByUrl('/app/resources/favorite');
      }
    });

    this.canActivateResourceGroups();
    this.canActivateResource();
    this.canActivateReportDeploy();

    return this.canActivateObservable;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    this.urlFragments = state.url.split('/');
    this.lastParam = state.url.split('/')[this.urlFragments.length - 1];
    this.canActivateObservable.subscribe((canActivate) => {
      if (!canActivate) {
        this.router.navigated = false;
        this.sessionStorageService.add('message-error', 'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.');
        //this.router.navigateByUrl('/app/resources/favorite');
      }
    });
    this.canActivateResourceGroups();
    this.canActivateResource();
    return this.canActivateObservable;
  }



  canActivateResourceGroups() {
    const canViewResourceGroups = this.userDataService.checkForSpecificPermission(Permissions.RESOURCEGROUPS.canView);
    const canCreateResourceGroups = this.userDataService.checkForSpecificPermission(Permissions.RESOURCEGROUPS.canCreate);

    if (this.urlFragments.indexOf('resource-groups') > -1) {
      //resource-groups/
      if (this.lastParam === 'resource-groups') {
        setTimeout(() => {
          this.canActivateSubject.next(canViewResourceGroups);
        }, 100);
      }

      //resource-groups/{id}
      if (this.urlFragments.indexOf('add') === -1 && !isNaN(parseInt(this.lastParam)) && parseInt(this.lastParam) > 0) {
        setTimeout(() => {
          this.canActivateSubject.next(canViewResourceGroups);
        }, 100);
      }

      //resource-groups/add
      if (this.lastParam === 'add') {
        setTimeout(() => {
          this.canActivateSubject.next(canCreateResourceGroups);
        }, 100);
      }
    }
  }

  canActivateResource() {
    const canViewReports = this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canView);
    const canViewSQL = this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canViewSQL);

    if (this.urlFragments.indexOf('resources') > -1) {
      //resources/
      if (this.lastParam === 'resources') {
        setTimeout(() => {
          this.canActivateSubject.next(canViewReports);
        }, 100);
      }

      //resources/deployed/{id}
      if (this.urlFragments.indexOf('deployed') > -1 && !isNaN(parseInt(this.lastParam)) && parseInt(this.lastParam) > 0) {
        setTimeout(() => {
          this.canActivateSubject.next(canViewReports);
        }, 100);
      }

      //resources/viewsql/{id}
      if (this.urlFragments.indexOf('viewsql') > -1 && !isNaN(parseInt(this.lastParam)) && parseInt(this.lastParam) > 0) {
        setTimeout(() => {
          this.canActivateSubject.next(canViewSQL);
        }, 100);
      }

      //resources/staging/{id}
      if (this.urlFragments.indexOf('staging') > -1 && !isNaN(parseInt(this.lastParam)) && parseInt(this.lastParam) > 0) {
        if (canViewReports) {
          this.stagingReportsService
            .getResourceDetails(this.lastParam.toString())
            .subscribe(responseBody => {
              if (responseBody['data']) {
                if (responseBody['data'].resourceStatus === ResourceStatus.staging.text || responseBody['data'].resourceStatus === ResourceStatus.inProgress.text) {
                  setTimeout(() => {
                    this.canActivateSubject.next(true);
                  }, 100);
                } else {
                  setTimeout(() => {
                    this.canActivateSubject.next(false);
                  }, 100);
                }
              }
            });
        } else {
          this.canActivateSubject.next(false);
        }

      }
    }
  }

  canActivateReportDeploy() {
    const canDeployOrRedeployReports = this.userDataService.checkForSpecificPermission(Permissions.RESOURCES.canCreate);

    if ((this.urlFragments.indexOf('report-deploy') > -1) || (this.urlFragments.indexOf('report-redeploy') > -1)) {
      if (!isNaN(parseInt(this.lastParam)) && parseInt(this.lastParam) > 0) {
        if (canDeployOrRedeployReports) {
          this.stagingReportsService
            .getResourceDetails(this.lastParam.toString())
            .subscribe(responseBody => {
              if (responseBody['data']) {
                if (responseBody['data'].resourceStatus === ResourceStatus.staging.text) {
                  this.canActivateSubject.next(true);
                } else {
                  this.canActivateSubject.next(false);
                }
              }
            });
        } else {
          setTimeout(() => {
            this.canActivateSubject.next(false);
          }, 100);
        }
      } else {
        setTimeout(() => {
          this.canActivateSubject.next(false);
        }, 100);
      }
    }
  }
}
