import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserDataService } from '@edw-services/user-data.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@edw-services/local-storage.service';


@Component({
  selector: 'edw-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  //adfsEnvironment: string = environment.adfs;
  currentDomain: string = window.location.origin;

  constructor(private userService: UserDataService, private localStorageService: LocalStorageService) { }

  ngOnInit() {
  }

  onSubmit($event, form) {
    this.localStorageService.add('loginSSO', 'nmhc');
    form.submit();
    return false;
  }

  goToUniversityLogIn(){
    this.localStorageService.add('loginSSO', 'nu');
    window.location.replace(this.currentDomain+"/universityauth");
  }

  goToNMHCLogIn(){
    this.localStorageService.add('loginSSO', 'nmhc');
    window.location.replace(environment.api+"/user/auth");
  }

}
