import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SnowTicketService } from '@edw-app-root/shared/snow-ticket/snow-ticket.component';
import { ReportsService } from '@edw-services/reports.service';
import { Report } from '@edw-entities/report';

@Injectable({ providedIn: 'root' })

export class SnowTicketResolver implements Resolve<any> {
  constructor ( private snowTicketService : SnowTicketService, private reportsService:ReportsService ){}
  resolve( route: ActivatedRouteSnapshot ) {
    return this.reportsService.getResource(route.params.id).subscribe( ( res )=> { this.snowTicketService.resource = res['data']; return this.snowTicketService.resource; } );
  }
}
