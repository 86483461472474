export class ExtractSchedule {

  //These objects are used for GET extract list method.
  //Only the below columns will be visible

  extract_setup_key: number;
  extract_name: string;
  extract_description: string;
  extract_configuration_key: number;
  extract_configuration_description: string;
  output_subfolder: string;
  current_status: string;
  dependency_completed: string;
  started_dt: string;
  completed_dt: string;
  is_completed: boolean;

  constructor(extractschedule) {
    this.extract_setup_key = extractschedule.extract_setup_key;
    this.extract_name = extractschedule.extract_name;
    this.extract_description = extractschedule.extract_description || '';
    this.extract_configuration_key=extractschedule.extract_configuration_key;
        this.extract_configuration_description = extractschedule.extract_configuration_description || '';
    this.output_subfolder = extractschedule.output_folder;
    this.current_status = extractschedule.current_status;
    this.dependency_completed = extractschedule.dependency_completed;
    this.started_dt = this.checkIfDateIsReturned(extractschedule.start_date);
    this.completed_dt = this.checkIfDateIsReturned(extractschedule.end_date);
    this.is_completed = extractschedule.complete;
  }

  private checkIfDateIsReturned(date) {
    let dateValue = '';

    if (typeof date === 'object') {
      if (Object.keys(date).length === 0 ) {
        dateValue = '';
      }
    } else {
      dateValue = date;
    }

    return dateValue;
  }
}
