import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchParams } from '@edw-entities/searchParams';
import { UserGroup } from '@edw-entities/user-group';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable()
export class UserGroupDataService {

  constructor(private http: HttpClient) { }

  getAllUserGroups(query: string, page: number = 1): Observable<Object> {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/usergroup/getAll', options);
  }

  getUserGroupDetails(userGroupId: string): Observable<Object> {
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .get<Response>(environment.api + '/usergroup/' + userGroupId + '/getDetails', options);
  }

  addUserGroup(userGroup: UserGroup): Observable<Object> {
    const postBody = null;

    let params = new HttpParams();
    // doing this value re-assignment because
    // the instances of HttpParams are immutable objects
    params = params.set('name', userGroup.name.trim());
    params = params.set('desc', userGroup.description);
    params = params.set('isSyncedWithAD', userGroup.isSyncedWithAD.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/usergroup/add', postBody, options);
  }

  removeUserGroup(userGroupId: string): Observable<Object> {
    const postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupId + '/remove', postBody, options);
  }

  findAddedUser = (userGroupId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupId + '/user/get', postBody, options);
  }

  findNonAddedUser = (userGroupId: string, query: string): Observable<Object> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/usergroup/' + userGroupId + '/user/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';
          return element;
        });
        return response;
      }));
  }

  findAddedResourceGroup = (userGroupId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/usergroup/' + userGroupId + '/resourcegroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          element['link'] = ['/app/manage/resource-groups/', element.id];

          return element;
        });

        return response;
      }));
  }

  findNonAddedResourceGroup = (userGroupId: string, query: string): Observable<Object> => {
    const postBody = {
      query: query,
      page: 1,
      isAdded: false
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/usergroup/' + userGroupId + '/resourcegroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          element['link'] = ['/app/manage/resource-groups/', element.id];

          return element;
        });

        return response;
      }));
  }

  getResourcePage(userGroupId: string, query: string, page: string): Observable<Object> {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/usergroup/' + userGroupId + '/resource/getPage', options);
  }

  getUserPage(userGroupId: string, query: string, page: string): Observable<Object> {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page);

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .get<Response>(environment.api + '/usergroup/' + userGroupId + '/user/getPage', options);
  }

  addUsersToGroup = (userGroupId: string, userIds: number[]): Observable<Object> => {
    const postBody = userIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupId + '/user/add', postBody, options);
  }

  removeUserFromGroup = (userGroupId: string, userId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('userId', userId);

    const postBody = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupId + '/user/remove', postBody, options);
  }

  updateUserGroupFields(userGroupObj: UserGroup): Observable<Object> {
    const postBody = userGroupObj;
    postBody.name = postBody.name.trim();

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupObj.id + '/update', postBody, options);
  }

  findNonAddedResource = (userGroupId: string, query: string): Observable<Object> => {
    const postBody: SearchParams = new SearchParams(1, query);
    postBody['isAdded'] = false;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/usergroup/' + userGroupId + '/resource/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.resourceName + ' (' + element.resourceType + ')';
          return element;
        });

        return response;
      }));
  }

  findAddedResource = (userGroupId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody: SearchParams = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post<Response>(environment.api + '/usergroup/' + userGroupId + '/resource/get', postBody, options);
  }

  associateResourcesWithUserGroups = (userGroupId: string, resourceIds: number[]): Observable<Object> => {
    const postBody = resourceIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupId + '/resource/add', postBody, options);
  }

  removeResourceUserGroupsRelationship = (userGroupId: string, resourceId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceId', resourceId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupId + '/resource/remove', body, options);
  }

  associateUserGroupWithResourceGroups = (userGroupId: string, resourceGroupIds: number[]): Observable<Object> => {
    const postBody = resourceGroupIds;

    const options: Object = {
      observe: 'body',
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupId + '/resourcegroup/add', postBody, options);
  }

  removeUserGroupResourceGroupRelationship = (userGroupId: string, resourceGroupId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceGroupId', resourceGroupId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/usergroup/' + userGroupId + '/resourcegroup/remove', body, options);
  }
}

