export const InputFieldsType = {
  multilist: {
    text: 'multiselect'
  },
  otherdatasource: {
    text: 'Other'
  },
  datasource: {
    text: 'dataSource'
  },
  datasourceoptionid: {
    SSRS: 12,
    TABLEAU: 13,
    POWER_BI: 29
  },
  resourceowneroptionid: {
    SSRS: 14,
    TABLEAU: 15,
    POWER_BI: 30
  },
  developerCommentid: {
    SSRS: 20,
    TABLEAU: 21,
    POWER_BI: 34
  },
  businessowneroptionid: {
    SSRS: 18,
    TABLEAU: 19,
    POWER_BI: 32
  },
  businessowner: {
    text: 'businessOwner'
  },
  resourceowner: {
    text: 'resourceOwner'
  },
  developerComment: {
    text: 'developerComment'
  },
  irb: {
    text: 'associatedIRB'
  }
  ,
  description: {
    text: 'description',
    id: 1
  }
  ,
  childReport: {
    text: 'childReport'
  },

  optOut: {
    text: 'ratingOpt-out',
    title: 'Opt-Out of Rating Functionality'
  },
  hideToolBar: {
    text: 'hideToolBar',
    title: 'Hide Tool Bar'
  },
  publishingDept:
  {
    text: 'publishingDepartment'
  },
  redeployTarget:
  {
    text: 'redeployTarget'
  }
};
