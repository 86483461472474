import { Directive, ElementRef, HostListener } from '@angular/core';
import * as moment from 'moment';

@Directive({
  selector: '[edwScrollIntoView]'
})
export class ScrollIntoViewDirective {
  constructor(public elRef: ElementRef) {}
  private animationStart;
  private animationEnd;
  private reqAnimFrameId;

  @HostListener('click', ['$event.target'])
  scrollIntoView() {
    this.animationStart = moment(new Date().getTime());
    this.animationEnd = moment(new Date().getTime() + 3000);
    this.animateScroll();
  }

  private animateScroll = () => {
    let timeDiff = this.animationEnd.diff(this.animationStart);

    if (moment.duration(timeDiff).asSeconds() > 0) {
      this.animationStart.add(300, 'ms');
      document.querySelector('main').scrollTop = document.querySelector('main').scrollTop + 50;
      this.reqAnimFrameId = window.requestAnimationFrame(this.animateScroll);
    } else {
      window.cancelAnimationFrame(this.reqAnimFrameId);
    }
  }
}
