import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';


import { Report } from '@edw-entities/report';
import { ResourceRating } from '@edw-entities/resource-rating.entity';
import { ResourceRatingService } from '@edw-services/resource-rating.service';
import { ConfirmationService } from 'primeng-lts';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({providedIn: 'root'})
export class ValueRatingService {

  ResourceRating: BehaviorSubject<ResourceRating> = new BehaviorSubject<ResourceRating>(null);
  get resourceRating(){return this.ResourceRating.value; }
  set resourceRating( value ){this.ResourceRating.next(value); }

  Resource$ : BehaviorSubject<Report> = new BehaviorSubject<Report>(null); // gets set from the value-rating.resolver
  set resource( value : Report ){ this.Resource$.next(value); }
  get resource(){ return this.Resource$.value; }

  constructor( private resourceRatingService : ResourceRatingService) { }

  getResourceRating = ( resourceId ) => // gets called from the value-rating.resolver
    this.resourceRatingService.getResourceRating(resourceId).pipe(
      map(
        ( rating : { data : ResourceRating }) => {
          console.log(rating);
          this.resourceRating = ( !!rating.data )
            ? new ResourceRating(rating.data)
            : Object.assign( {}, new ResourceRating(null), { resourceKey: resourceId } );
          return this.resourceRating;

        }
      )
    ).subscribe( x => x );

  saveResourceRating = ( resourceRating: ResourceRating ) => {
    return ( resourceRating.id == 0 )
      ? this.resourceRatingService.addRating(resourceRating).pipe( map( x => x ) )
      : this.resourceRatingService.updateRating(resourceRating).pipe( map( x => x ) )
  }
}


@Component({
  selector: 'value-rating',
  template: `
    <ng-container *ngIf="( optOutFlag === false && ratingVisible )">
      <section class="display-flex" >
        <span style="padding-right:6px;">{{message}}</span>
        <p-rating [(ngModel)]="resourceRating.value" [stars]="5" [cancel]="false" (onRate)="onRatingSelect($event)"></p-rating>
      </section>

      <p-confirmDialog icon="fa fa-question-circle"></p-confirmDialog>

      <p-dialog header="Additional Review Information" [(visible)]="ratingInfoVisible" [closable]="false" modal="modal" width="300">

        <section *ngIf="resourceRating.value < 4" >
          <ul class="padding">I did not find this resource useful because…
            <li><p-radioButton name="reason" value="1" label="The Visualizations and Formatting" [(ngModel)]="resourceRating.reason"></p-radioButton></li>
            <li><p-radioButton name="reason" value="2" label="The data is inaccurate and/or inconsistent" [(ngModel)]="resourceRating.reason"></p-radioButton></li>
            <li><p-radioButton name="reason" value="3" label="The report does not render quickly" [(ngModel)]="resourceRating.reason"></p-radioButton></li>
          </ul>
          <hr/>
        </section>

        <p class="line-height padding">
          Please share your thoughts on your rating. This comment will not be shared on the EDW portal, it is for our own internal process improvement.
          <span class="bold strong" [ngClass]="(resourceRating.comments.length == commentLengthMax) ? 'danger' : (resourceRating.comments.length >  commentWarningBuffer) ? 'warning' : ''  ">
            <ng-container *ngIf="(resourceRating.comments.length >  commentWarningBuffer)">{{resourceRating.comments.length}}/</ng-container>
            {{commentLengthMax}} character limit
          </span>
        </p>

        <textarea [(ngModel)]="resourceRating.comments" rows="4" cols="100" maxlength="{{commentLengthMax}}"></textarea>

        <p-footer>
          <button type="button" class="action add" style="margin-right:.25rem;" (click)="onRatingSubmit(false)">Submit</button>
          <button type="button" class="action add" (click)="onRatingSubmit(false)">Close without Comment</button>
        </p-footer>
      </p-dialog>
    </ng-container>
  `,
  styles:[
    ` .line-height{ line-height:2rem; }
      .padding{ padding: 12px 0; }
      .warning{ color: orange; }
      .danger{ color: red; }
      `
  ],
  providers : [ConfirmationService]
})

export class ValueRatingComponent implements OnInit, OnDestroy {
  ratingVisible = false;
  ratingInfoVisible = false;
  optOutFlag = false;
  resourceRating: ResourceRating = new ResourceRating(null);
  ratingCache: ResourceRating = new ResourceRating(null);
  subscriptions : Subscription[] = [];
  messageOptions = {
    NOVALUE:"Please rate your experience using this report: ",
    WITHVALUE:"Want to change your rating? Select your new rating:"
  }
  message: string = this.messageOptions.NOVALUE; // default

  commentLengthMax = 300;
  commentWarningBuffer = 250;

  constructor(
    private valueRatingService : ValueRatingService,
    private confirmationService : ConfirmationService,
    private router: Router
  ){

    /**
     * ResourceRating data comes from a call in the router resolver. If the rating value on subscription
     * is not empty, it mean the resolver method was called. This will notify this component that it
     * needs to display the ValueRating Component.
     */

    this.subscriptions = [].concat(
      [
        this.valueRatingService.ResourceRating.subscribe( rating => {
          if( rating != null) this.setData(rating);
        }),
        this.valueRatingService.Resource$.subscribe( resource => {
          if( !!resource ){
            this.optOutFlag = ( new Report(resource).ratingOptout == 'true' );
            this.ratingVisible = true;
          }
        }),
        this.router.events.subscribe(event => {
          if( event instanceof NavigationStart ){
            this.ngOnDestroy();
          }
        })
      ]
    );

  }

  ngOnInit() {  }

  ngOnDestroy() {
    this.optOutFlag = false;
    this.ratingVisible = false;
    this.resourceRating = new ResourceRating(null);
    this.subscriptions.forEach( sub => sub.unsubscribe() );
  }

  setData = ( rating ) => {

    this.resourceRating = Object.assign(
      {},
      new ResourceRating(null),
      { value: rating.value, resourceKey : rating.resourceKey, id : rating.id, userKey: rating.userKey }
    );

    this.ratingCache = Object.assign( {}, this.resourceRating );

    this.message = ( this.resourceRating.id != 0 )
      ? this.messageOptions.WITHVALUE
      : this.messageOptions.NOVALUE;
  }

  onRatingSelect = (event) => {

    ( this.ratingCache.value != 0 && this.ratingCache.value != event.value )
      ? this.confirmRatingChange()
      : this.ratingInfoVisible = true;
  }

  onRatingCancel = () => {
    this.resourceRating = Object.assign( {}, this.ratingCache );
    this.ratingInfoVisible = false;
  }

  onRatingSubmit = ( isVisible: boolean = false ) => {

    let toSave = Object.assign( {}, new ResourceRating(), this.resourceRating );

    this.valueRatingService.saveResourceRating( toSave ).subscribe(
      ( results : { data : ResourceRating } ) => {
        this.setData( results.data );
        this.ratingInfoVisible = isVisible;
      }
    );
  }

  private confirmRatingChange() {
    this.confirmationService.confirm({
        header: 'Confirm Rating Change',
        message: 'Are you sure that you want to perform this action?',
        accept: () => this.ratingInfoVisible = true,
        reject: () => this.onRatingCancel()
    });
  }
}
