import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeploymentRequestObject } from '@edw-entities/deployment-request';
import { SearchParams } from '@edw-entities/searchParams';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class StagingReportsService {
  constructor(private http: HttpClient) { }

  getResourceDetails = (resourceId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    //return this.http  .get<Response>('assets/staging-res.json' , options);
    return this.http  .get<Response>(environment.api + '/staging/' + resourceId, options);
  }

  getMetadataElementOptions = (metadataElementKey: number): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http
      .get<Response>(environment.api + '/staging/' + metadataElementKey+'/GetmetadataElementOptions', options);
  }

  getResourceOwnerOptions = (): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http
      .get<Response>(environment.api + '/staging/getAllTeams', options);
  }

  getFullDeployDetails = (resourceId: string): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    //return this.http.get<Response>('assets/full-deploy.json', options);
    return this.http.get<Response>(environment.api + '/staging/' + resourceId + '/fullDeploy', options);
  }

  getDeployedResources = (query: string = '', page: number = 1): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };
    return this.http
      .get<Response>(environment.api + '/resource/getDeployed', options);
  }


  remove = (stagingResourceId: string): Observable<Object> => {
    const postBody = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/staging/' + stagingResourceId + '/remove', postBody, options);
  }

  approveDeploymentRequest = (resourceId: string): Observable<Object> => {
    const body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/approve', body, options);
  }

  addUserGroups = (resourceId: string, userGroupIds: number[]): Observable<Object> => {
    const body = userGroupIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/usergroup/add', body, options);
  }

  findNonAddedUserGroup = (resourceId: string, query: string): Observable<Object> => {
    const postBody = {
      query: query,
      page: 1,
      isAdded: false
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/usergroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          return element;
        });

        return response;
      }));
  }

  findAddedUserGroup = (resourceId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/usergroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element['displayName'] = element.name;

          if (element.description) {
            element['displayName'] = element['displayName'] + ' - (' + element.description + ')';
          }

          return element;
        });

        return response;
      }));
  }

  removeUserGroup = (resourceId: string, userGroupId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('userGroupId', userGroupId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/usergroup/remove', body, options);
  }

  addResourceGroups = (resourceId: string, resourceGroupIds: number[]): Observable<Object> => {
    const body = resourceGroupIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/resourceGroup/add', body, options);
  }

  findNonAddedResourceGroup = (resourceId: string, query: string): Observable<Object> => {
    const postBody = {
      query: query,
      page: 1,
      isAdded: false
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/resourcegroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element.displayName = element.name;

          if (element.description) {
            element.displayName = element.displayName + ' - (' + element.description + ')';
          }

          return element;
        });

        return response;
      }));
  }

  findAddedResourceGroups = (resourceId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/resourcegroup/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element.displayName = element.name;

          if (element.description) {
            element.displayName = element.displayName + ' - (' + element.description + ')';
          }

          return element;
        });

        return response;
      }));
  }

  removeResourceGroup = (resourceId: string, resourceGroupId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('resourceGroupId', resourceGroupId);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/resourceGroup/remove', body, options);
  }

  denyResource = (stagingResourceId: string, reason: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('stagingResourceId', stagingResourceId);
    params = params.set('reason', reason);

    const body = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/Staging/' + stagingResourceId + '/deny', body, options);
  }

  submitDeploymentData = (resourceId: number, resourceDeploymentObj: DeploymentRequestObject): Observable<Object> => {
    const body = resourceDeploymentObj;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/submit', body, options);
  }


  rescind = (resourceId: number): Observable<Object> => {
    const options: Object = {
      observe: 'body'
    };
    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/rescind', options);
  }

  submitReDeploymentData = (resourceId: number, resourceReDeploymentObj: DeploymentRequestObject): Observable<Object> => {
    const body = resourceReDeploymentObj;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/submitRedeploy', body, options);
  }

  getPendingRequests = (query: string = '', page: number = 1): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
      observe: 'body',
      params: params
    };
    //return this.http.get<Response>('assets/pending-resource.json', options);
    return this.http.get<Response>(environment.api + '/staging/getPendingRequests', options);
  }

  findNonAddedUsers = (resourceId: string, query: string): Observable<Object> => {
    const postBody = {
      query: query,
      page: 1,
      isAdded: false
    };

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/user/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element.displayName = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';

          return element;
        });

        return response;
      }));
  }

  findAddedUser = (resourceId: string, searchParams: SearchParams): Observable<Object> => {
    const postBody = searchParams;
    postBody['isAdded'] = true;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post<Response>(environment.api + '/staging/' + resourceId + '/user/get', postBody, options)
      .pipe(tap(response => {
        response['data'] = response['data'].map(element => {
          element.displayName = element.firstName + ' ' + element.lastName + ' (' + element.email + ')';

          return element;
        });

        return response;
      }));
  }

  addUsers = (resourceId: string, userIds: number[]): Observable<Object> => {
    const postbody = userIds;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/staging/' + resourceId + '/user/add', postbody, options);
  }

  removeUser = (resourceId: string, userId: string): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('userId', userId.toString());

    const postbody = null;

    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/staging/' + resourceId + '/user/remove', postbody, options);
  }
}
