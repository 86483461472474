import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProductsService } from '@edw-services/products.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductPagesRoutingResolver implements Resolve<any> {

  constructor( private productsService: ProductsService ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    let componentData = {
      title: 'Product Pages',
      permissions:{},
      products:[]
    };

    // return this.productsService.getAllProducts()
    return this.productsService.getUserProducts()
    .pipe(
      map((responseBody) => {
        componentData.products = responseBody['data'];
        return componentData;
      }));

    return null;

  }

}
