import { Status } from '@edw-enums/status';
import * as moment from 'moment';
import { SelectItem } from 'primeng-lts';

export class SubscriptionDTO {
  name: string;
  description: string;
  schedule: {
    scheduleType: number,
    day: any,
    executionTime: string
  };

  constructor(subscription) {
    this.name = subscription.name;
    this.description = subscription.description;

    if (subscription.hasOwnProperty('schedule')) {
      this.schedule = {
        scheduleType: subscription.schedule.scheduleType,
        day: subscription.schedule.day,
        executionTime: moment(subscription.schedule.executionTime).format('HH:mm')
      };
    }
  }
}

export class SubscriptionMetadataEntity {
  id: number;
  status: any;
  name: string;
  description: string;
  schedule: {
    scheduleType: any;
    day: any;
    executionTime: Date;
  };

  constructor() {
    this.id = 0;
    this.status = Status.Current;
    this.name = '';
    this.description = '';
    this.schedule = {
      scheduleType: null,
      day: null,
      executionTime: null
    };
  }
}

export class SubscriptionCreateKeywordsEntity {
  status: any;
  userSpecificKeywords: SelectItem[];
  recipientGroupKeywords: SelectItem[];
  userSpecificKeywordInput: string;
  recipientGroupKeywordInput: string;

  constructor() {
    this.status = Status.Pending;
    this.userSpecificKeywords = [{
      label: '{{user:email}}',
      value: '{{email}}'
    }];

    this.recipientGroupKeywords = [];
    this.userSpecificKeywordInput = '';
    this.recipientGroupKeywordInput = '';
  }
}

export class SubscriptionRecipientConfigEntity {
  status: any
  isRecipientGroupListCompleted: boolean;
  recipientConfigForms: {};
  recipientGroups: {
    id: number;
    name: string;
    files: File[];
    timestampAdded: string;
    isPersisted: boolean;
  }[];

  constructor() {
    this.status = Status.Pending;
    this.isRecipientGroupListCompleted = false;
    this.recipientConfigForms = {};
    this.recipientGroups = [{
      id: 0,
      name: '',
      files: [],
      timestampAdded: new Date().getTime().toString(),
      isPersisted: false
    }];
  }
}

export class SubscriptionCreateEmailEntity {
  status: any;

  emailSubject: {
    model: string;
    customErrors: string[]
  };

  emailBody: {
    model: string;
    customErrors: string[]
  };

  regExSeeIfContainsKeywords: RegExp

  constructor() {
    this.status = Status.Pending;

    this.emailSubject = {
      model: '',
      customErrors: []
    };

    this.emailBody = {
      model: '',
      customErrors: []
    };

    this.regExSeeIfContainsKeywords = /{{[\s\S]*?}}|{[\s\S]*?}/g;
  }
}

export class SubscriptionReportConfigEntity {
  status: any;
  searchQuery: string;
  autocompleteSuggestions: any[];
  configurationsPerReport: any[];
  configForms: {};
  areAllConfigFormsPersisted: boolean;

  constructor() {
    this.status  = Status.Pending;
    this.searchQuery = '';
    this.autocompleteSuggestions = [];
    this.configurationsPerReport = [];
    this.configForms = { };
    this.areAllConfigFormsPersisted = false
  }
}

export class SubscriptionAssociationEntity {
  status: any;

  configsListPerReport: {
    //label = resourceName
    label: string;
    //value = list of configs
    value: SelectItem[];
  }[];

  recipientGroupKeywords: {
    model: string;
    label: string;
    value: string;
    isDynamic: boolean;
  }[];

  recipientGroupReportConfigMemberships: {
    recipientGroupId: number,
    recipientGroupName: string,
    associations: {
      id: number;
      isPersisted: boolean;
      timestampAdded: string;
      reportConfigs: {
        selectedReport: any;
        selectedConfig: any;
        reportWithConfigsList: {
          //label = resourceName
          label: string;
          //value = list of configs
          value: SelectItem[];
        }[]
      };

      recipientGroupKeywords: {
        model: string;
        label: string;
        value: string;
        isDynamic: boolean;
      }[];
    }[]
  }[];

  associationForms: {};
  areAllAssociationFormsPersisted: boolean;

  constructor() {
    this.status = Status.Pending;
    this.recipientGroupReportConfigMemberships = [];
    this.configsListPerReport = [];
    this.recipientGroupKeywords = [];
    this.associationForms = { };
    this.areAllAssociationFormsPersisted = false
  }
}
