import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InputFieldsType } from "@edw-app-root/enums/inputfields-type";
import { DeploymentRequestObject } from "@edw-entities/deployment-request";
import { MetadataToFormFieldsTransformer } from "@edw-entities/metadata-to-form-fields";
import { RelatedEntityService } from "@edw-entities/related-entity-service.interface";
import { DeploymentResource } from "@edw-entities/report-deploy";
import { ResourceType } from "@edw-enums/resource-type";
import { Status } from "@edw-enums/status";
import { LoaderService } from "@edw-services/loader.service";
import { ReportsService } from "@edw-services/reports.service";
import { SessionStorageService } from "@edw-services/session-storage.service";
import { StagingReportsService } from "@edw-services/staging-reports.service";
import { UserDataService } from "@edw-services/user-data.service";
import { SelectItem } from "primeng-lts";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";


@Component({
  selector: "edw-report-deploy",
  templateUrl: "./report-deploy.component.html",
  styleUrls: ["./report-deploy.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReportDeployComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("stepper" ,{static:true}) private stepper;
  @ViewChild("approverField",{static:true}) private approverField: ElementRef;

  dataSourceTypeText = InputFieldsType;
  skipfielddisplay =
    this.dataSourceTypeText.redeployTarget.text +
    "," +
    this.dataSourceTypeText.resourceowner.text +
    "," +
    this.dataSourceTypeText.datasource.text +
    "," +
    this.dataSourceTypeText.childReport.text +
    "," +
    this.dataSourceTypeText.businessowner.text +
    "," +
    this.dataSourceTypeText.optOut.text;
  // skipfielddisplay = 'redeployTarget,resourceOwner,dataSource,childReport,businessOwner,optOut';
  steps: string[];
  stepStatus = Status;
  checkOther = false;
  OtherDataSource = "";

  resourceTypeText = ResourceType;
  selectedRS = "";
  isChildReport = false;
  childReportSuggestions: string[];
  childReportName: "";
  childReportPath: "";
  isChildReportSet = false;

  datasourceOptions = "";
  datasources: SelectItem[] = [];
  resourceowner: SelectItem[] = [];
  selecteddatasources: string[] = [];
  dstouched = false;
  resType = "";
  dsMetaOptionId: number;



  metadata = {
    status: Status.Current,
    resourceId: 0,
    resourceType: "",
    name: {
      label: "Report Name",
      value: "",
    },
    path: {
      label: "Report Path",
      value: "",
    },
    businessowner: {
      label: "Business owner",
      placeholder: "Please Select Business Owner",
      collection: [],
      value: "",
    },
    author: {
      label: "Author",
      value: {
        id: 0,
        name: "",
      },
    },
    fields: new MetadataToFormFieldsTransformer(),
  };

  security = {
    status: Status.Pending,
    resourceGroups: {
      label: "Resource groups",
      collection: [],
      suggestions: null,
      permissions: {
        canAddEntity: true,
        canRemoveEntity: true,
      },
    },
    userGroups: {
      label: "User groups",
      collection: [],
      suggestions: null,
      permissions: {
        canAddEntity: true,
        canRemoveEntity: true,
      },
    },
    users: {
      label: "Users",
      collection: [],
      suggestions: null,
      permissions: {
        canAddEntity: true,
        canRemoveEntity: true,
      },
    },
    approvers: {
      label: "Approver",
      collection: [],
      choice: null,
    },
  };

  finish = {
    status: Status.Pending,
  };

  formSections;

  resourceGroupHandler: RelatedEntityService;
  usersHandler: RelatedEntityService;
  userGroupsHandler: RelatedEntityService;

  @ViewChild("accordion") accordion;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stagingReportsService: StagingReportsService,
    private sessionStorageService: SessionStorageService,
    private changeDetector: ChangeDetectorRef,
    private loader: LoaderService,
    private userDataService: UserDataService,
    private reportService: ReportsService
  ) {
  }

  ngOnInit() {
    //  console.log(this.dataSourceTypeText);
    this.formSections = [this.metadata, this.security, this.finish];
    this.steps = ["Metadata", "Security", "Finish"];
    this.setResourceOwner();

    this.route.data.subscribe((data: { componentData: any }) => {
      if (data.componentData.hasOwnProperty("resourceDetails")) {
        // console.log(data.componentData.resourceDetails);
        let resource = new DeploymentResource(
          data.componentData.resourceDetails
        );
        //  this.dataSourceTypeText.businessowner.text;
        this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.SSRS;
        if (resource.resourceType === this.resourceTypeText.tableau.text) {
          this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.TABLEAU;
        } else if (resource.resourceType == this.resourceTypeText.powerbi.text) {
          this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.POWER_BI;
        }

        this.resType = resource.resourceType;
        this.setDataSource();
        this.metadata.resourceId = resource.resourceId;
        this.metadata.name.value = resource.resourceName;
        this.metadata.path.value = resource.path;
        this.metadata.resourceType = this.resType;

        this.metadata.fields = new MetadataToFormFieldsTransformer(
          resource.metadata
        );
        this.setChildReport();
        this.metadata.fields.getFields().filter((metadataField) => {
          if (metadataField.modelName === this.dataSourceTypeText.optOut.text) {
            metadataField.label = this.dataSourceTypeText.optOut.title;
            metadataField.value = metadataField.value == "true";
          }
        });

        this.security.approvers.collection = resource.approvers.map(
          (approver) => {
            return {
              label: approver.displayName,
              value: {
                displayName: approver.displayName,
                value: approver.id,
              },
            };
          }
        );

        if (resource.resourceGroups.length === 0) {
          this.security.resourceGroups.collection = [];
        } else {
          this.updateResourceGroupCollection(resource.resourceGroups);
        }

        if (resource.userGroups.length === 0) {
          this.security.userGroups.collection = [];
        } else {
          this.updateUserGroupsCollection(resource.userGroups);
        }

        if (resource.users.length === 0) {
          this.security.users.collection = [];
        } else {
          this.updateUserCollection(resource.users);
        }
      } else if (data.componentData.hasOwnProperty("cachedResourceDetails")) {
        // console.log('data.componentData['cachedResourceDetails']');
        // console.log(data.componentData['cachedResourceDetails']);
        this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.SSRS;
        if (this.metadata.resourceType === this.resourceTypeText.tableau.text) {
          this.dsMetaOptionId = this.dataSourceTypeText.datasourceoptionid.TABLEAU;
        }
        this.resType = this.metadata.resourceType;
        this.setDataSource();

        this.metadata.author =
          data.componentData["cachedResourceDetails"].metadata.author;
        this.metadata.fields.formFieldsArr =
          data.componentData[
            "cachedResourceDetails"
          ].metadata.fields.formFieldsArr;
        this.metadata.name =
          data.componentData["cachedResourceDetails"].metadata.name;
        this.metadata.path =
          data.componentData["cachedResourceDetails"].metadata.path;
        this.metadata.resourceId =
          data.componentData["cachedResourceDetails"].metadata.resourceId;
        this.metadata.status =
          data.componentData["cachedResourceDetails"].metadata.status;
        //  this.metadata.isMandatory = data.componentData['cachedResourceDetails'].metadata.isMandatory;
        this.setChildReport();
        this.security = data.componentData["cachedResourceDetails"].security;

        this.security.approvers.collection = this.security.approvers.collection.map(
          (approver) => {
            const label =
              approver.firstName +
              " " +
              approver.lastName +
              " (" +
              approver.email +
              ")";

            return {
              label: label,
              value: {
                displayName: label,
                value: approver.id,
              },
            };
          }
        );

        this.finish = data.componentData["cachedResourceDetails"].finish;

        if (this.security.resourceGroups.collection.length > 0) {
          this.updateResourceGroupCollection(
            this.security.resourceGroups.collection
          );
        }

        if (this.security.userGroups.collection.length > 0) {
          this.updateUserGroupsCollection(this.security.userGroups.collection);
        }

        if (this.security.users.collection.length === 0) {
          this.security.users.collection = [];
        } else {
          this.updateUserCollection(this.security.users.collection);
        }

        this.formSections = [this.metadata, this.security, this.finish];
      }

      this.resourceGroupHandler = {
        getAddedEntity: this.stagingReportsService.findAddedResourceGroups,
        getNonAddedEntity: this.stagingReportsService.findNonAddedResourceGroup,
        persistEntityList: this.stagingReportsService.addResourceGroups,
        removeEntityFromGroup: this.stagingReportsService.removeResourceGroup,
      };

      this.usersHandler = {
        getAddedEntity: this.stagingReportsService.findAddedUser,
        getNonAddedEntity: this.stagingReportsService.findNonAddedUsers,
        persistEntityList: this.stagingReportsService.addUsers,
        removeEntityFromGroup: this.stagingReportsService.removeUser,
      };

      this.userGroupsHandler = {
        getAddedEntity: this.stagingReportsService.findAddedUserGroup,
        getNonAddedEntity: this.stagingReportsService.findNonAddedUserGroup,
        persistEntityList: this.stagingReportsService.addUserGroups,
        removeEntityFromGroup: this.stagingReportsService.removeUserGroup,
      };
    });
  }

  ngOnDestroy() {
    this.sessionStorageService.remove("reportDeployWizard");
  }

  setChildReport(e?) {
    if (e != undefined) {
      this.onChildReportSelect(e);
    }
    // console.log(this.resourceDetails.fields);
    let descobj = this.metadata.fields
      .getFields()

      .filter(
        (fields) =>
          fields.modelName === this.dataSourceTypeText.description.text
      );
    // descobj[0].value=descobj[0].value.
    if (
      descobj &&
      descobj[0].value &&
      descobj[0].value.length > descobj[0].maxLength
    ) {
      descobj[0].value = descobj[0].value.substring(0, descobj[0].maxLength);
    }
    // console.log(descobj);
    this.metadata.fields.getFields().filter((fields) => {
      if (fields.modelName === this.dataSourceTypeText.childReport.text) {
        if (fields.value) {
          this.isChildReportSet = true;
          this.isChildReport = true;
          let reportParts = fields.value.toString().split("/");
          this.childReportName = reportParts[reportParts.length - 1];
          reportParts.pop();
          this.childReportPath = reportParts.join("/");
          if (descobj) {
            descobj[0].isMandatory = false;
          }
        } else {
          if (descobj) {
            descobj[0].isMandatory = true;
          }
        }
      }
    });
    // console.log(this.resourceDetails.fields);
  }

  setResourceOwner() {
    this.stagingReportsService
      .getResourceOwnerOptions()
      .subscribe((response) => {
        if (response["data"]) {
          response["data"].forEach((key) => {
            this.resourceowner.push({ label: key.name, value: key.name });
          });
        }
        //  console.log('metadata : ');
        //  console.log(this.metadata);
        this.metadata.fields.getFields().filter((fields) => {
          if (fields.modelName === this.dataSourceTypeText.resourceowner.text) {
            this.selectedRS = fields.value;
          }
        });
        // console.log('this.resourceowner :'+this.resourceowner);
        //  console.log(this.resourceowner);
        //  console.log(this.metadata.fields);
      });
  }

  setDataSource() {
    this.stagingReportsService
      .getMetadataElementOptions(this.dsMetaOptionId)
      .subscribe((response) => {
        if (response["data"]) {
          for (let str of response["data"].value.split(",")) {
            this.datasources.push({ label: str, value: str });
            this.datasourceOptions += str + " ";
          }
        }
        // we get string of datasource from service, convert to array, if other selected set the value in textbox
        let otherDSprovided = "";
        let dsArray: string[];
        let otheravailable: Boolean = false;
        let selectedDS = "";
        this.metadata.fields.getFields().filter((fields) => {
          if (fields.modelName === this.dataSourceTypeText.datasource.text) {
            if (fields.value.toString()) {
              let array = fields.value.toString().split(",");
              if (fields.tempvalue) {
                array = fields.tempvalue.toString().split(",");
              }
              dsArray = [];
              for (let i = 0; i < array.length; i++) {
                if (this.datasourceOptions.indexOf(array[i]) < 0) {
                  otherDSprovided += "," + array[i];
                  otheravailable = true;
                } else {
                  selectedDS += "," + array[i];
                  dsArray.push(array[i]);
                }
              }
            }

            if (otheravailable) {
              dsArray.push(this.dataSourceTypeText.otherdatasource.text);
              selectedDS += "," + this.dataSourceTypeText.otherdatasource.text;
            }
            if (otherDSprovided && otherDSprovided.trim().length > 0) {
              otherDSprovided = otherDSprovided.replace(",", "");
              this.OtherDataSource = otherDSprovided;
            }
            if (selectedDS && selectedDS.trim().length > 0) {
              selectedDS = selectedDS.replace(",", "");
              this.selecteddatasources = selectedDS.split(",");
            }
            //   console.log(dsArray.length);
            if (dsArray && dsArray.length > 0) {
              fields.value = dsArray;
            }
          }
        });
        //  console.log(this.metadata.fields);
      });
  }

  hanldeTouch(e) {
    // console.log('touched');
    this.dstouched = true;
  }
  hanldeMetafieldsTouch(e, obj) {
    obj.touched = true;
    // console.log(obj)
  }
  handleSelected(e) {
    this.selecteddatasources = e.value;

    if (
      this.selecteddatasources.indexOf(
        this.dataSourceTypeText.otherdatasource.text
      ) > -1
    ) {
      this.checkOther = true;
    } else {
      this.checkOther = false;
      this.OtherDataSource = "";
    }
  }

  handleChildReport(event : {checked: boolean }, obj) {

    console.log(event, obj);
    if ( event.checked == false ) {
      obj.value = "";
      obj.tempvalue = "";
      this.clearChildReport();
    }
    this.setChildReport();
  }
  // handleChildReport(e, obj) {
  //   if (!e) {
  //     obj.value = "";
  //     obj.tempvalue = "";
  //     this.clearChildReport();
  //   }
  //   this.setChildReport();
  // }

  updateOptOut(val) {
    if (
      val.includes("Research Analytics") ||
      val.includes("Legacy Data Archive")
    ) {
      this.metadata.fields.getFields().filter((metadataField) => {
        if (metadataField.modelName === this.dataSourceTypeText.optOut.text) {
          metadataField.tempvalue = true;
          metadataField.value = true;
        }
      });
    }
  }
  handleRSSelected(e, obj) {
    this.updateOptOut(e.value);
    this.selectedRS = e.value;
    obj.value = e.value;
  }

  ngAfterViewInit() {
    if (this.sessionStorageService.get("reportDeployWizard")) {
      let currentStep = this.sessionStorageService.get("reportDeployWizard")
        .stepIndex;

      this.formSections.forEach((section, index) => {
        if (index < currentStep) {
          section.status = Status.Complete;
        } else {
          section.status = Status.Pending;
        }
      });

      this.formSections[currentStep].status = Status.Current;
      this.stepper.markSpecificStep(currentStep);
      this.changeDetector.detectChanges();
    }
  }

  updateTempValues() {
    this.metadata.fields.getFields().filter((metadataField) => {
      if (metadataField.modelName === this.dataSourceTypeText.datasource.text) {
        // this wont reference same object
        metadataField.tempvalue = Object.assign([], metadataField.value);
        for (let i = 0; i < metadataField.tempvalue.length; i++) {
          if (
            metadataField.tempvalue[i] ===
            this.dataSourceTypeText.otherdatasource.text
          ) {
            metadataField.tempvalue[i] = this.OtherDataSource;
          }
        }
        metadataField.tempvalue = metadataField.tempvalue.toString();
      } else if (
        metadataField.modelName === this.dataSourceTypeText.resourceowner.text
      ) {
        metadataField.value = this.selectedRS;
        metadataField.tempvalue = metadataField.value;
      } else {
        // This will reference same object
        metadataField.tempvalue = metadataField.value;
      }
    });
  }

  searchUsers(event) {
    this.userDataService
      .getAllUsers(event.query)
      .pipe(
        map((response: { data: any }) => {
          if (response.data) {
            const data = response.data.map((businessowner) => {
              return businessowner.fullName + " (" + businessowner.email + ")";
            });
            return data;
          } else {
            return null;
          }
        })
      )
      .subscribe((data) => {
        this.metadata.businessowner.collection = data;
      });
  }

  completeStep() {
    let currentIndex = -1;
    this.formSections.forEach((formSection, index) => {
      if (formSection.status === Status.Current) {
        formSection.status = Status.Complete;
        currentIndex = index;
      }
    });
    if (currentIndex == 0) {
      this.updateTempValues();
    }

    if (currentIndex + 1 <= this.formSections.length - 1) {
      this.formSections[currentIndex + 1].status = Status.Current;
      const newStep = currentIndex + 1;
      this.updateSessionStorageCache(newStep);
      this.stepper.markNextStep();
    }
    console.log("complete step");
    console.log(this.metadata.fields.getFields());
  }

  goBack() {
    let currentIndex = 0;

    this.formSections.forEach((formSection, index) => {
      if (formSection.status === Status.Current) {
        if (index > 0) {
          formSection.status = Status.Pending;
          currentIndex = index;
        }
      }
    });

    if (currentIndex - 1 >= 0) {
      this.formSections[currentIndex - 1].status = Status.Current;
      const newStep = currentIndex - 1;
      this.updateSessionStorageCache(newStep);

      this.stepper.markPreviousStep();
    }
  }

  deployReport() {
    let deploymentObject: DeploymentRequestObject = {
      resourceName: this.metadata.name.value,
      authorId: this.metadata.author.value.id,
      approverIds: [this.security.approvers.choice.value],

      metadata: this.metadata.fields
        .getFields()
        .filter((metadataField) => {
          return metadataField.isEditable === true;
        })
        .map((metadataField) => {
          return {
            id: metadataField.id,
            detail: metadataField.tempvalue,
          };
        }),
    };
    console.log(deploymentObject);

    const subscription: Subscription = this.stagingReportsService
      .submitDeploymentData(this.metadata.resourceId, deploymentObject)
      .subscribe((response) => {
        if (response["data"] === true) {
          this.sessionStorageService.add(
            "message",
            "Successfully submitted " +
            this.metadata.name.value +
            " for approval."
          );
          this.router.navigate(["/app/manage/resources"]);
        }

        subscription.unsubscribe();
      });
  }

  goBackToSection(sectionIndex) {
    this.formSections.forEach((formSection, index) => {
      if (formSection.status === Status.Current) {
        if (index > 0) {
          formSection.status = Status.Pending;
        }
      }
    });

    this.formSections[sectionIndex].status = Status.Current;
    this.updateSessionStorageCache(sectionIndex);

    this.stepper.markSpecificStep(sectionIndex);
  }

  markFieldAsTouched() {
    setTimeout(() => {
      if (this.approverField) {
        this.approverField["dirty"] = true;
      }
    }, 400);
  }

  clearTouchedMark() {
    if (this.approverField) {
      this.approverField["dirty"] = false;
    }
  }

  onSelectApprover($event) {
    let cachedReportDeployData = this.sessionStorageService.get(
      "reportDeployWizard"
    );
    this.updateSessionStorageCache(cachedReportDeployData.stepIndex, {
      path: "security.approvers.choice",
      value: $event.value,
    });
  }

  private updateUserCollection(data) {
    this.security.users.collection = data.map((user) => {
      user.displayName =
        user.firstName + " " + user.lastName + " (" + user.email + ")";
      user["link"] = ["/app/manage/users/", user.id];
      return user;
    });
  }

  private updateResourceGroupCollection(data) {
    this.security.resourceGroups.collection = data.map((resourceGroup) => {
      resourceGroup.displayName = resourceGroup.name;

      if (resourceGroup.description) {
        resourceGroup.displayName =
          resourceGroup.displayName + " - (" + resourceGroup.description + ")";
      }

      resourceGroup["link"] = [
        "/app/manage/resource-groups/",
        resourceGroup.id,
      ];

      return resourceGroup;
    });
  }

  private updateUserGroupsCollection(data) {
    this.security.userGroups.collection = data.map((userGroup) => {
      userGroup.displayName = userGroup.name;

      if (userGroup.description) {
        userGroup.displayName =
          userGroup.displayName + " - (" + userGroup.description + ")";
      }

      userGroup["link"] = ["/app/manage/user-groups/", userGroup.id];

      return userGroup;
    });
  }

  private updateSessionStorageCache(
    stepIndex: number = 0,
    specificProperty?: { path: string; value: any }
  ) {
    if (specificProperty) {
      let cachedReportDeployData = this.sessionStorageService.get(
        "reportDeployWizard"
      );

      let referenceToCache = cachedReportDeployData.data;

      specificProperty.path.split(".").forEach((property, index) => {
        if (index === specificProperty.path.split(".").length - 1) {
          referenceToCache[property] = specificProperty.value;
        } else {
          referenceToCache = referenceToCache[property];
        }
      });

      this.sessionStorageService.add(
        "reportDeployWizard",
        cachedReportDeployData
      );
    } else {
      this.sessionStorageService.add("reportDeployWizard", {
        stepIndex: stepIndex,
        data: {
          metadata: this.metadata,
          security: this.security,
          finish: this.finish,
        },
      });
    }
  }

  lookUpReports(event) {
    this.reportService.getAll( event.query, 1).subscribe((result: any) => {
      if (result.data) {
        this.childReportSuggestions = result.data.map(
          (v) => v.path + v.resourceName
        );
      }
    });
  }
  clearChildReport() {
    this.metadata.fields.getFields().filter((fields) => {
      if (fields.modelName === this.dataSourceTypeText.childReport.text) {
        this.isChildReportSet = false;
        fields.value = "";
      }
    });
  }

  onChildReportSelect(e) {
    this.isChildReportSet = true;
    let reportParts = e.toString().split("/");
    this.childReportName = reportParts[reportParts.length - 1];
    reportParts.pop();
    this.childReportPath = reportParts.join("/");
  }
}
