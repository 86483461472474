import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EDWSharedModule } from '@edw-app-root/shared/shared.module';
import { AccordionModule, AutoCompleteModule, CheckboxModule, DialogModule, DropdownModule, MessagesModule, MultiSelectModule, PaginatorModule, SharedModule, TableModule, ToastModule } from 'primeng-lts';
import { DeployedRespurceDetailComponent } from './deployed-resource-detail/deployed-resource-detail.component';
import { ReportDeployComponent } from './report-deploy/report-deploy.component';
import { ReportRedeployComponent } from './report-redeploy/report-redeploy.component';
import { ResourcesPageResolver } from './resolvers/resource-page.resolver';
import { ManageResourcesRoutingResolver } from './resolvers/resources.resolver';
import { ResourceGroupDetailComponent } from './resource-group-detail/resource-group-detail.component';
import { ResourceGroupsListingComponent } from './resource-groups-listing/resource-groups-listing.component';
import { ResourceListingComponent } from './resource-listing/resource-listing.component';
import { ManageResourcesRouteGuard } from './resources.guard';
import { StagingResourceDetailComponent } from './staging-resource-detail/staging-resource-detail.component';
import { ViewSqlComponent } from './viewsql/viewsql.component';







export const manageResourcesRoutes = [
  {
    path: 'resource-groups',
    canActivate: [ManageResourcesRouteGuard],
    canActivateChild: [ManageResourcesRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ResourceGroupsListingComponent,
      },
      {
        path: 'add',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ResourceGroupDetailComponent
      },
      {
        path: ':id',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ResourceGroupDetailComponent
      }
    ]
  },
  {
    path: 'report-deploy/:id',
    component: ReportDeployComponent,
    canActivate: [ManageResourcesRouteGuard],
    resolve: {
      componentData: ManageResourcesRoutingResolver
    }
  },
  {
    path: 'report-redeploy/:id',
    component: ReportRedeployComponent,
    canActivate: [ManageResourcesRouteGuard],
    resolve: {
      componentData: ManageResourcesRoutingResolver
    }
  },
  {
    path: 'resources',
    canActivateChild: [ManageResourcesRouteGuard],
    children: [
      {
        path: '',
        resolve: {
          componentData: ResourcesPageResolver
        },
        component: ResourceListingComponent,
      },
      {
        path: 'deployed/:id',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: DeployedRespurceDetailComponent
      },
      {
        path: 'viewsql/:id',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: ViewSqlComponent
      },
      {
        path: 'staging/:id',
        resolve: {
          componentData: ManageResourcesRoutingResolver
        },
        component: StagingResourceDetailComponent
      }
    ]
  }
];

@NgModule({
  declarations: [
    ReportDeployComponent,
    ReportRedeployComponent,
    ResourceGroupsListingComponent,
    ResourceGroupDetailComponent,
    ResourceGroupDetailComponent,
    ResourceListingComponent,
    DeployedRespurceDetailComponent,
    ViewSqlComponent,
    StagingResourceDetailComponent
  ],
  providers: [
    ManageResourcesRoutingResolver,
    ResourcesPageResolver,
    ManageResourcesRouteGuard
  ],
  exports: [
    ReportDeployComponent,
    ReportRedeployComponent,
    ResourceGroupsListingComponent,
    ResourceGroupDetailComponent,
    ResourceGroupDetailComponent,
    ResourceListingComponent,
    DeployedRespurceDetailComponent,
    ViewSqlComponent,
    StagingResourceDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    DialogModule,
    EDWSharedModule,
    TableModule,
    SharedModule,
    AutoCompleteModule,
    PaginatorModule,
    AccordionModule,
    MessagesModule,
    ToastModule,
    DropdownModule,
    CheckboxModule,
    MultiSelectModule,
    ClipboardModule
  ],
})
export class ResourcesModule { }
