import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from '@edw-services/user-data.service';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';

@Injectable()
export class ManageResearchRequestsGuard implements CanActivate {

  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const canManageResearchRequest = this.userDataService.checkForSpecificPermission(Permissions.RESEARCH_REQUEST.canManage);
    if (!canManageResearchRequest) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }
    return canManageResearchRequest;
  }
}
