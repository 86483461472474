import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRestResponse } from '@edw-entities/rest-response';
import { IUserSettings, UserSettings } from '@edw-entities/user-settings';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
//import { initDomAdapter } from '@angular/platform-browser/src/browser';

@Injectable()
export class UserSettingsService {
  private endpoint: string;

  private userSettings: UserSettings = new UserSettings();
  private userSettingSource = new BehaviorSubject(this.userSettings);
  currentUserSettings = this.userSettingSource.asObservable();

  constructor(private http: HttpClient) {
    this.endpoint = environment.api;
    this.initUserSettings();
  }

  initUserSettings() {
    this.getUserSettings().pipe(
      map( response => {
        if (response['data']) {
          this.userSettings.defaultPage = response['data']["defaultPage"];
          this.userSettings.pageView = response['data']["pageView"];
          this.userSettingSource.next(this.userSettings);
        }
      }),
      catchError(err => {
        console.error(err);
        throw err;
      })
    ).subscribe();
  }

  getUserSettings(): Observable<IRestResponse<IUserSettings[]>> {
    return this.http.get<IRestResponse<IUserSettings[]>>(`${this.endpoint}/usersettings/get`, {observe: 'body'});
  }

  updateUserSettings(data: Partial<IUserSettings>) {

    let userSettings = new UserSettings();
    userSettings.defaultPage = data.defaultPage;
    userSettings.pageView = data.pageView;
    this.userSettingSource.next(userSettings);

    return this.http.post<IUserSettings>(`${this.endpoint}/usersettings/update`, data, {observe:'body'});
  }

}
