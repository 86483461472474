
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceGroup } from '@edw-entities/resource-group';
import { ResourceGroupDataService } from '@edw-services/resource-group.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'edw-resource-groups-listing',
  templateUrl: './resource-groups-listing.component.html',
  styleUrls: ['./resource-groups-listing.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ResourceGroupsListingComponent implements OnInit {
  permissions;
  labelResourceGroups: string;
  resourceGroups: ResourceGroup[] = [];
  allRowsSelected: boolean = false;
  query: string;
  dialogVisible: boolean = false;

  @ViewChild('dataTable',{static:true}) dataTable;

  constructor(
    private resourceGroupDataService: ResourceGroupDataService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.pipe(
      map((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;

        this.resourceGroups = data.componentData.resourceGroups.map(element => {
          return new ResourceGroup(element);
        });

      })
    ).subscribe();
  }

  lazyLoadMore($event) {
    let lastPage = this.dataTable.totalRecords / this.dataTable.rows;
    let firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;

    if ($event.first === firstIndexOnLastPage) {
      //BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      let nextPage = Math.ceil(lastPage / 10) + 1;

      this.resourceGroupDataService.getAllResourceGroups('', nextPage)
        .subscribe(response => {
          if (response['data'] && response['data'].length > 0) {
            let newResourceGroups = response['data'].map(element => {
              return new ResourceGroup(element);
            });

            this.resourceGroups = this.resourceGroups.concat(newResourceGroups);
          }
        });
    }
  }

  clearSearch(autoCompleteRef) {
    this.query = '';
    //the next two lines are here because the autocomplete
    //component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  search() {
    const subscription: Subscription = this.resourceGroupDataService.getAllResourceGroups(this.query)
      .subscribe(response => {
        this.resourceGroups = [];
        if (response['data'].length > 0) {
          this.resourceGroups  = response['data'].map(element => {
            return new ResourceGroup(element);
          });
        }
        subscription.unsubscribe();
      });
  }

}
