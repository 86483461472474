import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserGroup } from '@edw-entities/user-group';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserGroupDataService } from '@edw-services/user-group.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'edw-user-groups-listing',
  templateUrl: './user-groups-listing.component.html',
  styleUrls: ['./user-groups-listing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserGroupsListingComponent implements OnInit, OnDestroy {
  private userGroupDataSubsription: Subscription;
  userInfo = {
    id: '1'
  };

  permissions;
  labelUserGroups: string;
  userGroups: UserGroup[] = [];
  allRowsSelected = false;
  query: string;

  @ViewChild('dataTable',{static:true}) dataTable;

  constructor(private userGroupDataService: UserGroupDataService,
              private sessionStorageService: SessionStorageService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { componentData: any }) => {
        this.permissions = data.componentData.permissions;
        this.userGroups = data.componentData.userGroups.map(element => {
          return new UserGroup(element);
        });
      });
  }

  ngOnDestroy() {

  }

  lazyLoadMore($event) {
    const lastPage = this.dataTable.totalRecords / this.dataTable.rows;
    const firstIndexOnLastPage = this.dataTable.totalRecords - this.dataTable.rows;

    if ($event.first === firstIndexOnLastPage) {
      // BE returns 100 items per page
      // => first 10 pages on FE are actually the 1st page on BE
      const nextPage = Math.ceil(lastPage / 10) + 1;

      this.userGroupDataService.getAllUserGroups('', nextPage)
        .subscribe(response => {
          if (response['data'] && response['data'].length > 0) {
            const newUserGroups = response['data'].map(element => {
              return new UserGroup(element);
            });

            this.userGroups = this.userGroups.concat(newUserGroups);
          }
        });
    }
  }

  clearSearch(autoCompleteRef) {
    this.query = '';
    // the next two lines are here because the autocomplete
    // component doesn't offer a native way to clear the value :(
    autoCompleteRef.inputEL.nativeElement.value = '';
    autoCompleteRef.inputEL.nativeElement.focus();
    this.search();
  }

  search() {
    this.userGroupDataSubsription = this.userGroupDataService
      .getAllUserGroups(this.query)
      .subscribe(response => {
        this.userGroups = [];
        if (response['data'].length > 0) {
          this.userGroups = response['data'].map(element => {
            return new UserGroup(element);
          });
        }
        // Unsubscribe here
        this.userGroupDataSubsription.unsubscribe();
      });
    // TODO: error handing here
  }

}
