import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Permissions } from '@edw-app-root/permissions';
import { SubscriptionCreateEmailEntity } from '@edw-entities/subscription';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { SubscriptionService } from '@edw-services/subscription.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Message } from 'primeng-lts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'edw-subscription-create-email',
  templateUrl: './subscription-create-email.component.html',
  styleUrls: ['./subscription-create-email.component.scss']
})
export class SubscriptionCreateEmailComponent implements OnInit {
  @Input() stepModel: SubscriptionCreateEmailEntity;
  @Output() stepModelChange = new EventEmitter();
  @Output() onGoBack = new EventEmitter();
  @Input() editMode: boolean = false;
  @Input() subscriptionId: number = 0;
  @Input() keywords = [];
  @Output() onCompleteStep = new EventEmitter();

  @Input() messages: Message[];
  @Output() messagesChange = new EventEmitter();

  dialog = {
    show: false,
    message: ''
  };

  permissions = {
    canCreate: false,
    canModify: false
  }

  constructor(private loader: LoaderService,
              private subscriptionService: SubscriptionService,
              private userDataService: UserDataService,
              private genericErrorHandlerService: ErrorHandlerService) { }

  ngOnInit() {
    this.permissions.canCreate = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canCreate);
    this.permissions.canModify = this.userDataService.checkForSpecificPermission(Permissions.SUBSCRIPTIONS.canModify);
  }

  persistEmailSubjectAndBody() {
    this.loader.showLoader();

    let emailSubject = this.stepModel.emailSubject.model.replace(/{{user:/g, '{{');
    let emailBody = this.stepModel.emailBody.model.replace(/{{user:/g, '{{');

    const subscription: Subscription = this.subscriptionService
      .createOrUpdateEmail(this.subscriptionId, emailSubject, emailBody)
      .subscribe(
        (response) => {
          if (response['data'] === true) {
            this.messagesChange.emit([{
              severity: 'success',
              summary: 'Successful record.',
              detail: 'Successfully updated email subject and body'
            }]);
          }

          subscription.unsubscribe();
          this.loader.hideLoader();
          this.onCompleteStep.emit();
        },

        (httpErrorResponse) => {
          let errorMessages = [];
          this.genericErrorHandlerService.httpErrorHandler(httpErrorResponse);

          this.messagesChange.emit(errorMessages);

          subscription.unsubscribe();
          this.loader.hideLoader();
        }
      );
  }

  clearEmailErrors(additionalData) {
    if (additionalData.isEmailSubject === true) {
      this.stepModel.emailSubject.customErrors = [];
    } else {
      this.stepModel.emailBody.customErrors = [];
    }
  }

  checkIfContainsExtractedKeywords($event, additionalData) {
    if ($event.extractedKeywords.length > 0) {
      let allKeywords = this.keywords;
      let matchTable = {};

      $event.extractedKeywords.forEach((extractedKeyword) => {
        let isMatch = allKeywords.some((definedDeyword) => {
          return extractedKeyword === definedDeyword.label;
        });

        matchTable[extractedKeyword] = isMatch;
      });

      for (let placeholder in matchTable) {
        if (matchTable[placeholder] === false) {
          let message = 'The placeholder ' + placeholder + ' is not an existing or valid keyword.';

          if (additionalData.isEmailSubject === true) {
            this.stepModel.emailSubject.customErrors.push(message);
          } else {
            this.stepModel.emailBody.customErrors.push(message);
          }
        }
      }
    }
  }

  warnUser() {
    this.dialog.message = 'Modifying the keywords will erase all the data you might have entered in subsequent steps. Are you sure you would like to continue?';
    this.dialog.show = true;
  }

  confirmDialogWarning() {
    this.dialog.show = false;
    this.onGoBack.emit();
  }

  hideDialog() {
    this.dialog.show = false;
  }
}
