import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'edw-layout-selector',
  templateUrl: './layout-selector.component.html',
  styleUrls: ['./layout-selector.component.scss']
})
export class LayoutSelectorComponent implements OnInit {

  @Input() layoutTile: boolean;

  @Output() layoutUpdate: EventEmitter<boolean> = new EventEmitter();

  isTileLayout(val){
    if(val === this.layoutTile){return false;}
    this.layoutUpdate.emit(this.layoutTile = val);
  }

  constructor() { }

  ngOnInit() {
  }

}
