export class AdhocExtractParameter {
adhoc_queue_parameter : number;
extract_configuration_Key : number;
extract_parameter_key : number;
parameter_text: string;
is_dynamic: boolean;
default_value: string;
data_type: string;
user_edited_value: string;

    constructor(result) {
        this.adhoc_queue_parameter = result.adhoc_queue_parameter;
        this.extract_configuration_Key = result.extract_configuration_Key;
        this.extract_parameter_key = result.extract_parameter_key;
        this.parameter_text = result.parameter_text;
        this.is_dynamic = result.is_dynamic;
        this.default_value = result.default_value;
        this.data_type = result.data_type;
        this.user_edited_value = result.user_edited_value;
    }
  }
  