import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '@edw-app-root/permissions';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { UserDataService } from '@edw-services/user-data.service';
import { Observable } from 'rxjs';

@Injectable()
export class ManageCohortsGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private sessionStorageService: SessionStorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let canActivate = false;

    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];

    const canViewCohorts = this.userDataService.checkForSpecificPermission(Permissions.COHORTS.canView);
    const canApproveCohorts = this.userDataService.checkForSpecificPermission(Permissions.COHORTS.canApprove);

    if (urlFragments.indexOf('cohorts') > -1) {
      if (canViewCohorts) {
        canActivate = true;
      }
    }

    if (urlFragments.indexOf('cohorts-queue') > -1) {
      if (canApproveCohorts) {
        canActivate = true;
      }
    }

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }
    // TODO: restore
    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let canActivate = false;
    // console.log(route);
    // console.log(route.parent);
    const urlFragments = state.url.split('/');
    const lastParam = state.url.split('/')[urlFragments.length - 1];
    //#region Cohortd
    if (urlFragments.indexOf('cohorts') > -1) {
      const canViewCohorts = this.userDataService.checkForSpecificPermission(Permissions.COHORTS.canView);
      const canCreateCohorts = this.userDataService.checkForSpecificPermission(Permissions.COHORTS.canCreate);

      // cohorts/
      if (lastParam === 'cohorts') {
        canActivate = canViewCohorts ? true : false;
      }

      // cohorts/{id}
      if (!isNaN(parseInt(lastParam, 10)) && parseInt(lastParam, 10) > 0) {
        canActivate = canViewCohorts ? true : false;
      }

      // cohorts/add
      if (lastParam === 'add') {
        canActivate = canCreateCohorts ? true : false;
      }
    }
    //#endregion Cohorts Groups

    if (!canActivate) {
      this.router.navigated = false;
      this.sessionStorageService.add(
        'message-error',
        'Insufficient permissions. If you feel this is an error, please contact your EDW Support Team.'
      );
      // this.router.navigateByUrl('/app/resources/favorite');
    }
    // TODO: restore
    return true;
  }
}
