

import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '@edw-app-root/entities/product';
import { IResource } from '@edw-app-root/entities/resource';
import { ToastMessageService } from '@edw-app-root/services/messaging.service';
import { ReportsService } from '@edw-app-root/services/reports.service';
import { ManageSecurityObj, ManageSecurityService, Permissions } from '@edw-app-root/shared/manage.security/manage.security.component';
import { RelatedEntityServices } from '@edw-entities/related-entity-service.interface';
import { User } from '@edw-entities/user';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { LoaderService } from '@edw-services/loader.service';
import { ProductsService } from '@edw-services/products.service';
import { ResourceGroupDataService } from '@edw-services/resource-group.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { StagingReportsService } from '@edw-services/staging-reports.service';
import { UserDataService } from '@edw-services/user-data.service';
``

class DropDownItem{
  displayName:string;
  id:number;
  constructor(displayName= "", id = -1){
    this.displayName = displayName;
    this.id = id;
  }
}

@Component({
  selector: 'edw-product-pages-detail',
  templateUrl: './product-pages-detail.component.html',
  styleUrls: ['./product-pages-detail.component.scss'],
  styles:[`
    .valueInput{
      position:relative;
    }
    .valueInput--clear{
      position:absolute;
      right:-10px;
      top:-5px;
    }
    .inputWidth{
      width:225px;
    }
  `],
  encapsulation: ViewEncapsulation.None
})
export class ManageProductPagesDetailComponent implements OnInit, AfterViewInit {
  title: string;

  isInAddNewMode = false;
  deleteDialogVisible = false;
  addResourcesDialogVisible = false;
  productpageDetails : Product = new Product();
  users = [];
  usersCache = [];
  reports = [];
  reportsCache = [];
  query: string;
  //permissions;
  userSuggestions;
  resourceSuggestions;
  userGroupsSuggestions;
  resources = [];

  formSubmitted = false;
  selectedUser;
  selectedReport;
  teamCache = [];
  team;
  teams = [];
  public productVM: FormGroup;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private productsService: ProductsService,
              private sessionStorageService: SessionStorageService,
              private loader: LoaderService,
              private errorService: ErrorHandlerService,
              private securityService : ManageSecurityService,
              private reportService : ReportsService,
              private resourcesGroupDataService : ResourceGroupDataService,
              private toastMessageService: ToastMessageService,
              private userDataService: UserDataService,
              private fb : FormBuilder,
              private stagingService : StagingReportsService
  ) {

    this.productVM = this.fb.group({
      name:['', Validators.required],
      description:['', Validators.required],
      pageId:['', Validators.required],
      //resource:[null, Validators.required],
      reportId:['', Validators.required],
      reportName:['', Validators.required ],
      owner:['', [Validators.required, Validators.min(1)]],
      ownerName:['',Validators.required],
      teamObj: {},
      team:[ '' ,Validators.required],
      teamId:[-1 ,[Validators.required,Validators.min(1)]]
    })

  }

  ngOnInit() {
    this.route.data
      .subscribe(( data: { componentData: any, resourceTeamList: any } ) => {

        this.title = data.componentData.title || 'Product Page';

        this.productpageDetails = new Product();

        if(!!data.resourceTeamList){
          this.teamCache = data.resourceTeamList.map( i => ({label: i.name, value: { id: i.id, label:i.name} }) );
          this.teams = this.teamCache;
          this.team = this.teamCache[2];
        }

        let details = data.componentData.products || null;

        if( details.length == 0 ){ this.isInAddNewMode = true; }
        else{
          let permissions           = new Permissions( data.componentData.permissions.canManageProductPages, data.componentData.permissions.canManageProductPages );
          let resourceGroups        = this.securityService.updateResourceGroupsCollection(details.resourceGroups);
          let resourceGroupsMethods = new RelatedEntityServices( this.reportService.findAddedResourceGroups, this.reportService.findNonAddedResourceGroup, this.productsService.addProductResourceGroup, this.productsService.deleteProductResourceGroup);
          this.securityService.entityModel = [ new ManageSecurityObj( 'resource groups', null, details.id, permissions, resourceGroupsMethods, resourceGroups ) ];


          this.productpageDetails = new Product(details);  // setting full Product obj;
          // set ProductVM
          this.productVM.setValue({
            name:this.productpageDetails.name,
            description:this.productpageDetails.description,
            pageId: (+this.productpageDetails.pageId > 0) ? `${this.productpageDetails.pageId}` : '' ,
            //resource: this.productpageDetails.resource,
            reportId:this.productpageDetails.reportId,
            reportName:this.setReportName(this.productpageDetails.resource),
            owner:this.productpageDetails.owner,
            ownerName: this.setOwnerName(this.productpageDetails.ownerDetails),
            teamObj: (!!this.productpageDetails.team && !!this.productpageDetails.teamId)
              ? this.teamCache.find( t => t.value.id == this.productpageDetails.teamId)
              : null,
            team: this.productpageDetails.team,
            teamId: +this.productpageDetails.teamId
          });


        }
        if( !!this.productVM.get('reportName').value ){ this.productVM.get('reportName').disable(); }
        if( !!this.productVM.get('ownerName').value ){ this.productVM.get('ownerName').disable(); }
        console.log(this.values(), this.productpageDetails)
      });

  }

  values = () => this.productVM.getRawValue();
  ngAfterViewInit(){

  }

  private setOwnerName = ( user:User ) => (!!user) ? `${user.firstName} ${user.lastName}` : null;
  private setReportName = ( resource: IResource ) => (!!resource) ? resource.resourceName : null;

  saveProductPage() {
    this.formSubmitted = true;
    console.log( this.productVM );

    if ( this.productVM.invalid ) { return false; }

    ( this.isInAddNewMode ) ? this.addNewProductPage() : this.editProductPage()
  }

  enable = (value) => { this.clearSelection(['owner', 'ownerName']); }


  validCheck = ( key ) => this.productVM.controls[key].invalid.valueOf();

  private editProductPage(formValues = "") {
    this.loader.showLoader();
    const update : Product = Object.assign( {}, this.productpageDetails, this.productVM.getRawValue() );
    this.productsService.saveProduct(update.id, update).subscribe(
      response => {
        this.toastMessageService.addMessageObj(
          {
            severity: 'success',
            summary: '',
            detail: 'Successfully updated a product page'
          }
        );
        this.router.navigate([`/app/manage/product-pages/${update.id}`]);
      },
      err => this.errorService.httpErrorHandler(err),
      () => this.loader.hideLoader()
    )
    console.log(update);
  }

  private addNewProductPage() {
    this.loader.showLoader();
    const update : Product = Object.assign( {}, this.productpageDetails, this.productVM.getRawValue() );

    this.productsService.createProduct(update).subscribe(
      response => {
        if ( !!response['data'] ) {
          if(response['data'].id == null){ throw Error('An error has occured.')}
          this.toastMessageService.addMessageObj(
            {
              severity: 'success',
              summary: '',
              detail: 'Successfully added a product page'
            }
          );


          this.router.navigate([`/app/manage/product-pages/${response['data'].id}`]);
        }
      },
      err => this.errorService.httpErrorHandler(err),
      () => this.loader.hideLoader()
    )
  }

  clearSelection(items:string[]){
    items.forEach( item => {
      this.productVM.get(item).reset();
      this.productVM.get(item).enable()
    }
    );
    this.formSubmitted = true;

  }

  userSearch = (ev) => {
    this.userDataService.getAllUsers(ev.query).subscribe( results => {
      this.usersCache = results['data'];
      this.users = results['data'].map( result => new DropDownItem(`${result.firstName} ${result.lastName}`, result.id ) );
    })
  }


  reportSearch = (ev) => {
    this.reportService.getAll(ev.query).subscribe( reports => {
      this.reportsCache = reports['data'];
      this.reports = reports['data'].filter( report => ( report.resourceType ==="TABLEAU" ))
        .map( report => new DropDownItem(report.resourceName, report.id));
    });
  }

  userSelect = (ev : DropDownItem) => {
    this.productVM.patchValue({ownerName:ev.displayName, owner:ev.id});
    this.productVM.get('ownerName').disable();
  }

  reportSelect = (ev: DropDownItem) => {
    this.productVM.patchValue({ reportName:ev.displayName, reportId:ev.id });
    this.productVM.get('reportName').disable();
  }

  teamSelect = (ev) => {
    this.productVM.patchValue({ team: ev.value.label, teamId: `${ev.value.id}`});
  }

  showConfirmationDialog = ( toShow: boolean ) => this.deleteDialogVisible = toShow;

  deleteProductPage() {
    this.showConfirmationDialog(false);
    this.productsService.deleteProduct(this.productpageDetails.id).subscribe(response => {
      if (response['data'] === true) { this.router.navigate(['/app/manage/product-pages']); }
    });
  }

  goBackButtonText = () => (this.isInAddNewMode) ? 'CANCEL' : 'BACK';

}


