export class Team { 
    id: number; 
    name: string;
    constructor(team) {
     this.id = team.id; 
     this.name = team.name;
    }      
}
 
export interface ITeam {
    id: number; 
    name: string;
}
