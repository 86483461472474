import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    SubscriptionAssociationEntity,
    SubscriptionCreateEmailEntity,
    SubscriptionCreateKeywordsEntity,
    SubscriptionMetadataEntity,
    SubscriptionRecipientConfigEntity,
    SubscriptionReportConfigEntity
} from '@edw-entities/subscription';
import { ErrorHandlerService } from '@edw-services/error-handler.service';
import { SessionStorageService } from '@edw-services/session-storage.service';
import { SubscriptionService } from '@edw-services/subscription.service';


@Component({
  selector: 'edw-subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private router: Router,
              private changeDetector: ChangeDetectorRef,
              private location: Location,
              private sessionStorageService: SessionStorageService,
              private genericErrorHandlerService: ErrorHandlerService) { }

  @ViewChild('tabView',{static:true}) tabView;



  metadata: SubscriptionMetadataEntity = new SubscriptionMetadataEntity();
  keywords: SubscriptionCreateKeywordsEntity = new SubscriptionCreateKeywordsEntity();
  recipientConfig: SubscriptionRecipientConfigEntity = new SubscriptionRecipientConfigEntity();
  email: SubscriptionCreateEmailEntity = new SubscriptionCreateEmailEntity();
  reportConfig: SubscriptionReportConfigEntity = new SubscriptionReportConfigEntity();
  reportConfigAssociations: SubscriptionAssociationEntity = new SubscriptionAssociationEntity();

  ngOnInit() {
    this.sessionStorageService.add('manualLoader', true);

    this.route.data
      .subscribe((data: { componentData: any }) => {
        if (data.componentData !== null) {
          this.metadata = data.componentData.metadata;
          this.keywords = data.componentData.keywords;
          this.email = data.componentData.email;
          this.recipientConfig = data.componentData.recipientConfig;
          this.reportConfig = data.componentData.reportConfig;
          this.reportConfigAssociations = data.componentData.entityAssociations;
          this.populateAssociationModel();
        }
      });
  }

  ngAfterViewInit() {
    let tabId = 0;

    if (this.sessionStorageService.get('subscriptionTabId')) {
      tabId = this.sessionStorageService.get('subscriptionTabId');
    } else {
      this.sessionStorageService.add('subscriptionTabId', tabId);
    }

    this.tabView.activeIndex = tabId;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.sessionStorageService.remove('subscriptionTabId');
    this.sessionStorageService.remove('manualLoader');
  }

  cancel() {
    this.router.navigate(['/app/manage/subscriptions']);
  }

  addTabIdAsQueryParam($event) {
    this.sessionStorageService.add('subscriptionTabId', $event.index);
  }

  onUpdateBackendModel() {
    this.router.navigate(
      ['/app/manage/subscriptions/update', this.route.snapshot.params.id],
      { queryParams: { tab: this.sessionStorageService.get('subscriptionTabId') } }
    );
  }

  populateAssociationModel() {
    if (this.reportConfig.configurationsPerReport.length > this.reportConfigAssociations.configsListPerReport.length) {
      let lastAssociationReportConfigIndex = this.reportConfigAssociations.configsListPerReport.length;
      let iterator = lastAssociationReportConfigIndex;

      for (iterator; iterator < this.reportConfig.configurationsPerReport.length; iterator++) {
        let configList = this.reportConfig.configurationsPerReport[iterator].configurations
          .map((reportConfig) => {
            return {
              label: reportConfig.configName,
              value: reportConfig.configId
            }
          });

        this.reportConfigAssociations.configsListPerReport.push({
          label: this.reportConfig.configurationsPerReport[iterator].resourceName,
          value: configList
        });

        if (this.reportConfigAssociations.recipientGroupReportConfigMemberships.length > 0) {
          this.reportConfigAssociations.recipientGroupReportConfigMemberships.forEach((membership) => {
            membership.associations.forEach((association) => {
              association.reportConfigs.reportWithConfigsList.push({
                label: this.reportConfig.configurationsPerReport[iterator].resourceName,
                value: configList
              });
            });
          });
        }
      }
    }

    //if new recipient groups have been added
    if (this.recipientConfig.recipientGroups.length > this.reportConfigAssociations.recipientGroupReportConfigMemberships.length) {
      this.recipientConfig.recipientGroups.forEach((recipientGroup) => {
        let existingRecipientGroupMembership = this.reportConfigAssociations.recipientGroupReportConfigMemberships
          .filter((membership) => {
            return recipientGroup.id === membership.recipientGroupId;
          })[0];

        if (!existingRecipientGroupMembership && recipientGroup.id > 0) {
          let membership = {
            recipientGroupId: recipientGroup.id,
            recipientGroupName: recipientGroup.name,
            associations: []
          };

          let timestamp = new Date().getTime().toString();

          membership.associations.push({
            id: 0,
            isPersisted: false,
            timestampAdded: timestamp,
            reportConfigs: {
              reportWithConfigsList: this.copyObjectsToANewArray(this.reportConfigAssociations.configsListPerReport),
              selectedReport: null,
              selectedConfig: null
            },
            recipientGroupKeywords: this.copyObjectsToANewArray(this.reportConfigAssociations.recipientGroupKeywords)
          });

          this.reportConfigAssociations.associationForms[membership.recipientGroupName + '_association' + timestamp] = false;
          this.reportConfigAssociations.recipientGroupReportConfigMemberships.push(membership);
        }
      });
    }
  }

  private copyObjectsToANewArray(arr) {
    arr = arr.map((obj) => {
      if (obj instanceof File) {
        return {
          name: obj.name,
          type: obj.type,
          lastModified: obj.lastModified
        };
      } else {
        return Object.assign({}, obj);
      }
    });

    return arr;
  }

}
