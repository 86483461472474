import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRestResponse } from '@edw-entities/rest-response';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subscription } from '@edw-entities/subscription.2';
import { Recipients } from '@edw-app-root/entities/subscription.2'


@Injectable()
export class EDWSubscription2Service {
  constructor( private http: HttpClient ){}

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  addSubscription = (subscription: Subscription): Observable<any> => {
    return this.http
    .post<Subscription>(
      `${environment.api}/subscription2/add`,
      subscription,
      { observe: 'body' }
    );
  }


  getSubscription = (id: string ): Observable<any> => {
    return this.http.get<Response>(`${environment.api}/subscription2/${id}`);
  }

  updateSubscription = (subscription: Subscription, shouldUpdateSchedule): Observable<any> => {
    subscription.updateSchedule = shouldUpdateSchedule;
    return this.http
    .post<Subscription>(
      `${environment.api}/subscription2/update`,
      subscription,
      { observe: 'body' }
    );
  }

  removeSubscription(subscriptionId: string): Observable<Object> {
    const body = null;
    
    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription2/' + subscriptionId + '/remove', body, options);
  }


  getAll = (query: string, page: number = 1): Observable<Object> => {
    let params = new HttpParams();
    params = params.set('query', query);
    params = params.set('page', page.toString());

    const options: Object = {
        observe: 'body',
        params: params
    };

    return this.http.get<Response>(environment.api + '/subscription2/getAll', options);
  }

  addRecipients = (subscriptionID: string, recipients: Recipients[]): Observable<any> => {
    return this.http
    .post<Recipients[]>(
      `${environment.api}/subscription2/${subscriptionID}/AddRecipients`,
      recipients,
      { observe: 'body' }
    );
  }


  updateRecipients = (subscriptionID: string, recipients: Recipients[]): Observable<any> => {
    return this.http
    .post<Recipients[]>(
      `${environment.api}/subscription2/${subscriptionID}/updateRecipients`,
      recipients,
      { observe: 'body' }
    );
  }


  deleteRecipients = (subscriptionID: string, recipients: Recipients[]): Observable<any> => {
    return this.http
    .post<Recipients[]>(
      `${environment.api}/subscription2/${subscriptionID}/DeleteRecipients`,
      recipients,
      { observe: 'body' }
    );
  }

  removeReport(subscriptionId: string): Observable<Object> {
    const body = null;

    const options: Object = {
      observe: 'body'
    };

    return this.http
      .post(environment.api + '/subscription2/' + subscriptionId + '/removeReport', body, options);
  }

  addReport(subscriptionId: string, reportId: number): Observable<Object> {
    const body = null;
    let params = new HttpParams(); 
    params = params.set('reportId', reportId.toString());
    const options: Object = {
      observe: 'body',
      params: params
    };

    return this.http
      .post(environment.api + '/subscription2/' + subscriptionId + '/addReport', body, options);
  }
}